import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Button, ButtonGroup } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Server } from '../../../../modules/stellar/index';
// import { ToastContainer, ToastStore } from 'react-toasts';
import { shortAddr, shortAddress, get_loc_wallets, getNameAddr, getAsset } from '../../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import Offers from './Effects/Offers';
import Footer from "../../../Layout/footer/Footer";



class AccountOffers extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      my_address: false,
      offers_for_accont: false,
      all_offers: false,
      offers: [],
      load_offers: false,
      load_x_offers: false,
      offers_count: 0,
      no_offers: false,
      wallets_offers: [],
      load_wallets_offers: false,
      wallets: get_loc_wallets(),
      filter_token: false,
    };
  }

  componentDidMount() {

    if (this.props.match.params.id === 'all' || !this.props.match.params.id) {

      this.getWallets('all');

      this.setState({
        all_offers: true,
      });
    }
    else {
      this.getWallets('one');

      this.setState({
        offers_for_accont: true,
      })
    }
  }




  getWallets(type) {

    const wallets = this.state.wallets;
    const address = { pk: this.props.match.params.id };

    if (type === 'one') {

      if (wallets) {

        wallets.forEach((item, index) => {
          if (item.pk === this.props.match.params.id) {
            this.getOffers(item, 'one', index);
            this.setState({
              my_address: true,
            })
          }
          else {
            this.getOffers(address, 'one', index);
          }
        });
      }
      else {
        this.getOffers(address, 'one', 0);
      }
    }

    if (type === 'all') {
      if (wallets) {
        wallets.forEach((item, index) => {
          this.getOffers(item, 'all', index);
        });

        this.setState({
          my_address: true,
          load_offers: true,
        })
      }
    }
  }


  getOffers(wallet, type, index) {
    console.log('wallet: ', wallet)

    Server.offers().forAccount(wallet.pk)
      .order('desc')
      .limit(200)
      .call()
      .then((offerResult) => {
        console.log('offerResult: ', offerResult)

        this.setState({ load_x_offers: true })

        if (type === 'all' && offerResult.records.length > 0) {
          wallet.offers = offerResult.records;
          this.setState({
            wallets_offers: this.state.wallets_offers.concat(wallet),
          })

          if (this.state.wallets.length === (index+1)) {
            this.setState({
              load_wallets_offers: true,
              load_offers: true,
              load_x_offers: true,
            })
          }
        }

        if (type === 'one') {

          if (offerResult.records.length > 0) {
            let wallets_offers, filter_token;
            const pathArr = window.location.pathname.split('/')

            if (pathArr.length === 5) {
              wallets_offers = offerResult.records.filter(item =>
                item.buying.asset_code === pathArr[3] && item.buying.asset_issuer === pathArr[4] ||
                item.selling.asset_code === pathArr[3] && item.selling.asset_issuer === pathArr[4])

              filter_token = {
                asset_code: pathArr[3],
                asset_issuer: pathArr[4],
                asset_type: 'asset'
              }
            }
            else if (pathArr.length === 3) {
              wallets_offers = offerResult.records
            }

            this.setState({
              load_offers: true,
              wallets_offers,
              offers: offerResult.records,
              filter_token: (pathArr.length === 5 ? filter_token : false),
            })
          }
          else {
            this.setState({ no_offers: true, })
          }
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }


  renderOffers(item, index) {

    const name = getNameAddr(item.pk);

    return (
      <Card key={index} style={{height: 'auto'}}>
       <CardBody style={this.state.my_address ? {border: '1px solid rgba(84, 229, 187, 0.71)'} : null}>

        <h4 className="card-title">Account: {shortAddress(item.pk)}</h4>
        {/*{this.state.my_address ? <h4 className="subhead">My Account <b>{item.title}</b></h4> : null}*/}
        <br/>

        <Offers items={item.offers}
                account_id={item.pk}/>

       </CardBody>
      </Card>
    )
  }



  render() {

    // console.log('state: ', this.state);

    const { wallets_offers, offers, filter_token } = this.state;

    return (
        <Container>

          <Row>
            <Col md={12}>
              <h3 className="page-title"><b>Offers</b> {wallets_offers.length ? `(${wallets_offers.length})` : null} </h3>
              <h4 className="subhead subhead-b" style={{marginBottom: '18px'}}>
                {
                  this.props.match.params.id === 'all' || !this.props.match.params.id ?
                    <div>For my Accounts (<b>{this.state.wallets_offers.length}</b>)</div>
                  :
                    <div>
                      <div>
                        For Account: {' '} { shortAddress(this.props.match.params.id) } {' '} <br/>
                        { filter_token && <small>Filter Asset: {getAsset(filter_token)}</small> }
                      </div>
                      {/*{
                        this.state.load_offers ?
                          <div><br/><p><i className="fa fa-info-circle"></i> There are no active Offers for this Account.</p></div>
                        :
                          null
                      }*/}
                    </div>
                }
              </h4>
            </Col>
          </Row>

          <Row>
            <Col md={12}>

              {!this.state.load_x_offers && <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card>}

              {
                this.state.offers_for_accont && this.state.load_offers && this.state.load_x_offers &&
                  <Card>
                   <CardBody className={this.state.my_address ? 'my_elem' : null}>
                      <div>
                        <b>Active Offers</b> {this.state.my_address ? <small className="text-success">My Account</small> : null}
                        <br/>
                        <Offers items={this.state.wallets_offers}
                                account_id={this.props.match.params.id} />
                      </div>
                   </CardBody>
                  </Card>
              }

              {/*{
                this.state.no_offers ?
                  <div>
                    <h3 className="page-title">No Offers</h3>
                    <p>For this Account: <Link to={'/account/'+this.props.match.params.id}><b>{shortAddr(this.props.match.params.id, 4)}</b> <small className="text-info">{name ? `[${name}]` : ''}</small></Link></p>
                  </div>
                : null
              }*/}

              {
                this.state.all_offers ?
                  this.state.wallets_offers.map(this.renderOffers.bind(this))
                : null
              }

            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className={'text-center'}>
                {
                  offers.length ?
                    <div>
                      <Link to={`/offers/${this.props.match.params.id}`} className={'btn btn-secondary'}>Offers for Account (<b>{offers.length}</b>)</Link>
                      <Link to={`/offers/all`} className={'btn btn-secondary'}>Offers for all Accounts</Link>
                    </div>
                    : null
                }
              </div>
            </Col>
          </Row>

          <Footer />

        </Container>
    );
  }
}


AccountOffers.propTypes = {
  t: PropTypes.func.isRequired,
};


export default translate('common')(AccountOffers);
