import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Progress,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
  Modal
} from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import { Field, reduxForm, change} from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../../modules/stellar/index';
import { ToastContainer, ToastStore } from 'react-toasts';
import Countdown from 'react-countdown-now';
import { GLOBE } from '../../../../modules/globeVars/index';
import TimeLineItem from '../../../../shared/components/TimeLineItem';
import axios from 'axios';
import LoadingIcon from 'mdi-react/LoadingIcon';
import {
  get_loc_wallets,
  baseReserve,
  shortAddr,
  numFormat,
  minBalance,
  getTitle,
  formatCount,
  shortAddress,
  getPoolIdFromAssets,
  checkConnectProvider, signConnectProvider, getSigner, showErrorMessage
} from '../../../../modules/index';
import TokenInfo from './TokenInfo';
import Trades from '../../Account/components/Effects/Trades';
import Footer from '../../../Layout/footer/Footer';
import swal from 'sweetalert';
import moment from 'moment';
import ReactApexChart from "react-apexcharts";
import Transactions from "../../Account/components/Effects/Transactions";
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/core";
import {strictReceivePaths, strictSendPaths} from "../../../../endpoints/StellarAPI";
import Draggable from "react-draggable";
import PulseLoader from "react-spinners/PulseLoader";
// import '../../../../scss/containers/swap.scss';

const loaderStyle = css`
  display: inline-block;
  //position: absolute;
  //width: 40px;
  //top: 0;
  //left: 5px;
`;

const bench_logo = `${process.env.PUBLIC_URL}/img/partners/ico-bench-logo.png`;



const renderTextField = ({
  input, label, meta: { touched, error }, children, select, type, disabled
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    disabled={disabled}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);


// const sky_icon = `${process.env.PUBLIC_URL}/img/logo/sky_icon.png`;
let icon_logo = 'https://png.pngtree.com/svg/20170919/0378af9c9d.png';

let depthSell = 0, maxDepthSell = 0;
let total_counter_amount = 0,
    total_base_amount = 0,
    total_usd_amount = 0,
    render_sell = 0,
    render_count = 0;



class Project extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      asset: false,
      base_asset: {
        asset_code: 'XLM',
        asset_issuer: 'native',
        name: 'Stellar',
        image: '/img/logo/stellar-logo-gray.png',
        home_domain: 'stellar.org',
      },
      counter_asset: {
        asset_code: 'SCOP',
        asset_issuer: 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ',
        name: 'Scopuly',
        image: '/img/logo/icon.png',
        home_domain: 'scopuly.com',
      },
      activeTab: '6',
      activeTabPreview: '1',
      loader: false,
      wallets: get_loc_wallets(),
      select_address: false,
      locked: false,
      project: false,
      totals: {
        time: ['--','--']
      },
      load_asset: false,
      load_toml: false,

      total_base_amount: 0,
      total_counter_amount: 0,
      total_usd_amount: 0,
      progress: {
        raised: 0,
        raised_percent: 0
      },

      trades: [],
      load_trades: false,
      offers: false,
      load_trade_account: false,
      base_asset_default: 'SCOP-GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ',
      base_balance: 0,
      counter_balance: 0,
      status: false,
      auth_user: false,

      options: {},
      series: [],
      labels: [],

      base_amount: 0,
      source_amount: 0,
      counter_amount: 0,
      path_receive_loading: false,
      path_send_loading: false,

      base_accept: false,
      counter_accept: false,
      base_asset_code: 'XLM',
      counter_asset_code: 'SCOP',
      current_price: 0,
      path_loading: false,
      path: [],
      show_assets: false,
      modal: false,
      account_assets: false,
      assets_db: false,
    };
  }

  native_image = '/img/logo/stellar-logo-gray.png'

  componentDidMount() {

    this.getMillisec('time');

    let id = this.props.match.params.id;

    let auth_user = localStorage.getItem('auth_user');

    if (auth_user) {
      this.setState({
        auth_user: JSON.parse(auth_user),
      })
    }

    this.setState({
      // options: { labels: ["Foundation", "Marketing & Ecosystem", "Airdrop", "Community Rewards", "Holder Rewards", "Liquidity Rewards", "Trader Rewards", "Cashback (crypto card)", "Public Sale", "Referral Program", "Private Sale"] },
      // series: [10, 10, 10, 10, 10, 10, 10, 10, 10, 5, 5],

      series: [20, 10, 10, 10, 10, 10, 10, 10, 5, 5],
      options: {
        chart: {
          width: 380,
          type: 'pie',
        },
        legend: {
          show: false
        },
        labels: ["Foundation & Ecosystem", "Airdrop", "Community Rewards", "Holder Rewards", "Liquidity Rewards", "Trader Rewards", "Cashback (crypto card)", "Public Sale", "Referral Program", "Private Sale"],
        responsive: [{
          options: {
            chart: {
              width: 200
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    })

    // if (this.props.data.base_asset !== 'XLM-native') {
    //   this.getPriceBaseAsset();
    // }

    // if (id === 'scopuly') {
    //   this.setState({
    //     base_asset: 'XLM-native'
    //   });
    // }
    // else {
    //   this.setState({
    //     base_asset: 'SCOP-GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ'
    //   });
    // }

    axios.post(GLOBE.API_URL+`/get_ieo?path=${this.props.match.params.id}`)
      .then(response => {
        console.log('response: ', response)

        this.getIDO(response);
    });

    this.getAsset()


    switch(window.location.hash) {
      case '#about':
          // this.toggle('1');
        break
      case '#team':
          this.toggle('2');
        break
      case '#roadmap':
          this.toggle('3');
        break
      case '#conditions':
          this.toggle('4');
        break
      case '#offer':
          this.toggle('5');
        break
      case '#trades':
          this.toggle('6');
        break
      default:
        break
    }
  }


  getAsset = () => {

    const asset_code = 'SCOP'
    const asset_issuer = 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ'

    axios.get(`${GLOBE.API_URL}/get_assets_code_issuer/?asset_code=${asset_code}&asset_issuer=${asset_issuer}`)
      .then((response) => {
        console.log('getAsset response: ', response)

        if (response.data.length) {
          this.setState({ asset: response.data[0] })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


  togglePreview = (tab) => {
    if (this.state.activeTabPreview !== tab) {
      this.setState({
        activeTabPreview: tab,
      });
    }
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }

    this.toggleHash(tab);
  }


  toggleHash(tab) {

    switch(tab) {
      case '1':
        window.location.hash = 'about';
        break
      case '2':
        window.location.hash = 'team';
        break
      case '3':
        window.location.hash = 'roadmap';
        break
      case '4':
          window.location.hash = 'conditions';
        break
      case '5':
          window.location.hash = 'offer';
        break
      case '6':
          window.location.hash = 'trades';
        break
      default:
        break
    }
  }


  getSigner(address) {
    var wallets = this.state.wallets;
    if (wallets) {
      var signer = wallets.filter((item) => {
        return item.pk === address;
      });
      this.setState({
        signer: signer,
      })
    }
  }


  getIDO = (response) => {

    if (this.props.match.params.id === 'scopuly' || this.props.match.params.id === '1') {
      var base_asset = 'XLM-native';
    }
    else {
      var base_asset = 'SCOP-GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ';
    }

    this.setState({
      ido: {
        project: response.data,
        rates: JSON.parse(response.data.rates),
        milestones: JSON.parse(response.data.milestones),
        socials: JSON.parse(response.data.socials),
        // team: JSON.parse(response.data.team),
        // advisors: JSON.parse(response.data.advisors),
        totals: JSON.parse(response.data.totals),
        base_asset: base_asset,
        project_id: response.data.id,
      }
    });

    getTitle(response.data.title+' - '+response.data.subtitle);

    this.getStatusIDO();
    this.getPricePair()
  }


  toggleFavorit() {

  }


  youtubeParser(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }



  changeAddress = (value) => {

    let address = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        address = address + value[key];
      }
    }

    this.getSigner(address);
    this.getAccount(address);
  }



  getAccount = (account_id) => {
    const asset = this.state.ido.project.sell_asset.split('-');
    const base_asset = {
      asset_code: this.state.ido.base_asset.split('-')[0],
      asset_issuer: this.state.ido.base_asset.split('-')[1]
    }

    this.setState({
      load_trade_account: false,
      trust_asset: false,
      select_address: true,
      // base_asset,
    });

    this.setState({ loader: true });

    Server.loadAccount(account_id)
      .then((account) => {

        let reserve = ((account.subentry_count * baseReserve()) + minBalance());

        this.setState({
          loader: false,
          load_trade_account: true,
          trade_account: account,
          account_assets: account.balances.filter(item => item.asset_type !== 'liquidity_pool_shares'),
          reserve_balance: reserve,
        }, () => {
          this.getAssetsDB()
        });


        account.balances.forEach((item, index) => {
          if (item.asset_type === 'native') {
            this.setState({
              native_balance: Number(item.balance),
              base_balance: Number(item.balance),
              base_accept: true,
            });
          }
          if (item.asset_code+'-'+item.asset_issuer === this.state.base_asset_default) {
            this.setState({
              base_balance: Number(item.balance),
              trust_asset: true,
            });
          }
          if (item.asset_code === asset[0] && item.asset_issuer === asset[1]) {
            this.setState({
              counter_balance: Number(item.balance),
              trust_asset: true,
              counter_accept: true,
            });
          }
        })

        setTimeout(() => {
          if (!this.state.trust_asset) {
            this.alertNoTrustline(account_id);
          }
        }, 100)
      })
      .catch((err) => {
        console.log('error: ', err);

        this.setState({ loader: false });

        // $this.nextLoadAccount();
    })
  }


  alertNoTrustline(account_id) {

    const message = `<div>Add trustline for <b class="text-warning">${this.state.counter_asset.asset_code}</b> Now?</div>`
    const element = document.createElement("span");
    element.innerHTML = message;

    swal({
      title: `No trustline for ${this.state.counter_asset.asset_code}`,
      content: element,
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
    .then((confirm) => {
      if (confirm) {
        this.setTrustline(account_id);
      }
    })
  }


  setTrustline = (account_id) => {

    // let asset = this.state.ido.project.sell_asset.split('-');
    const { counter_asset } = this.state

    this.setState({ loader: true })

    Server.loadAccount(account_id)
      .then((sourceAccount) => {

        var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: StellarSdk.BASE_FEE,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
          .addOperation(StellarSdk.Operation.changeTrust({
              asset: new StellarSdk.Asset(counter_asset.asset_code, counter_asset.asset_issuer),
          }))
          .setTimeout(100)
          .build();

        transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));
        return Server.submitTransaction(transaction);
      })
      .then((result) => {

        this.setState({ loader: false })

        swal({
          title: `Added new balance ${counter_asset.asset_code}!`,
          text: `Trustline to ${counter_asset.asset_code} token has been successfully installed. Now ${counter_asset.asset_code} token is available on your balance.`,
          icon: "success",
          confirm: true,
        })
        .then(confirm => {
          if (confirm) {
            // this.getAccount(account_id);
          }
        });
      })
      .catch((error) => {
        console.error('Something went wrong!', error);

        this.setState({ loader: false })
        ToastStore.error('Transaction error');
    });
  }



  amountChange(value) {
    let amount = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        amount = amount + value[key];
      }
    }
    var total = (amount * this.state.offers[0].price).toFixed(7);

    this.setState({
      amount_buy: amount,
      total_sell: Number(total),
    });

    this.props.change('total', Number(total));
  }



  totalChange(value) {
    let total = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        total = total + value[key];
      }
    }
    var amount = (total / this.state.offers[0].price).toFixed(7)

    this.setState({
      amount_buy: amount,
      total_sell: Number(total),
    });

    this.props.change('amount', Number(amount))
  }



  submitForm = (values) => {
    // console.log('submitForm values: ', values)

    const { native_balance, ido, select_address, counter_amount, reserve_balance } = this.state

    if (!select_address) {
      ToastStore.warning('Select Address');
    }
    else if (!counter_amount) {
      ToastStore.warning('Enter amount');
    }
    // else if (!values.total) {
    //   ToastStore.warning('Not Total Amount');
    // }
    else {

      if (native_balance <= reserve_balance) {
        ToastStore.warning('Not enough XLM balance');
      }
      else if (ido.base_asset === 'XLM-native' && native_balance < native_balance) {
        ToastStore.warning('Not enough XLM balance');
      }
      else if (ido.base_asset === this.state.base_asset_default && this.state.base_balance < this.state.total_sell) {
        ToastStore.warning(`Not enough ${this.state.base_asset_default.split('-')[0]} balance`);
      }
      else {

        swal({
          title: 'Confirmation',
          text: `Do you really want to make an Exchange of Assets right now?`,
          icon: 'info',
          buttons: {
            cancel: true,
            confirm: true,
          },
        })
        .then((value) => {
          if (value) {
            this.setOffer(values);
          }
        });
      }
    }
  }



  setOffer(values) {

    const select_asset = this.state.ido.project.sell_asset.split('-');
    const base_asset = this.state.ido.base_asset.split('-');
    const asset = {};

    if (this.state.ido.base_asset === 'XLM-native') {
      asset.selling = StellarSdk.Asset.native();
    } else {
      asset.selling = new StellarSdk.Asset(base_asset[0], base_asset[1]);
    }
    asset.buying = new StellarSdk.Asset(select_asset[0], select_asset[1]);
    asset.amount = values.total;
    asset.price = Number(1 / values.price);


    this.setState({ loader: true });

    Server.loadAccount(values.select_address)
      .then((sourceAccount) => {

        var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: StellarSdk.BASE_FEE,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
          .addOperation(StellarSdk.Operation.manageBuyOffer({
              selling: asset.selling,
              buying: asset.buying,
              buyAmount: String(asset.amount),
              price: String(asset.price)
          }))
          .setTimeout(100)
          .build();

        transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));
        return Server.submitTransaction(transaction);
      })
      .then((result) => {

        this.setState({ loader: false });

        swal({
          title: "Offer Added",
          text: "Your Exchange Offer has been successfully added!",
          icon: "success",
        })
      })
      .catch((error) => {
        // console.error('Something went wrong!', error);
        this.setState({ loader: false })
        ToastStore.error('Transaction error...');
    });
  }



  getMillisec = (time) => {
    var myDate = new Date(time);
    return myDate.getTime();
  }


  getOffers = (offers) => {
    console.log('getOffers offers: ', offers)

    // offers.forEach((item, index) => { // short
    //   maxDepthSell = (maxDepthSell + Number(item.amount / item.price));
    // })

    this.setState({
      offers: offers
    }, () => {
      this.countOffers(offers)
    });

    this.props.change('price', numFormat(offers[0].price, 7));
  }


  getTrades = (trades) => {
    console.log('getTrades trades: ', trades)

    if (this.state.trades.length > 0) {
      this.setState({
        trades: [trades, ...this.state.trades],
      });

      let account = trades.base_account

      if (trades.trade_type === 'liquidity_pool') {
        if (!trades.base_is_seller) {
          account = trades.counter_account
        }
      }
      // else if (trades.base_is_seller) {
      //   account = trades.base_account
      // }
      // else {
      //   account = trades.base_account
      // }

      console.log('account: ', account)

      ToastStore.info(`${shortAddr(account, 4)} bought ${numFormat(trades.counter_amount, 7)} ${trades.counter_asset_code}`);
    }
    else {
      this.setState({
        trades: trades
      })
    }

    this.setState({
      load_trades: true,
    })
  }

  // getProgress = (progress) => {
  //
  //   this.setState({
  //     progress: progress,
  //   })
  // }

  getPriceXlmUsd = (price) => {

    this.setState({
      price_xlm: price,
    })
  }


  countOffers = (offers) => {

    offers.forEach((item, index) => {

      if (item.selling.asset_code === this.state.ido.project.sell_asset.split('-')[0] && item.buying.asset_type === 'native') {

        const amount_base = (item.amount * item.price).toFixed(7);
        const price_base_xlm = (this.state.trades[0].price.d / this.state.trades[0].price.n);
        const amount_xlm = (amount_base * price_base_xlm);
        const amount_usd = (amount_base * this.state.price_xlm).toFixed(7);

        console.log('amount_usd: ', amount_usd  )

        total_counter_amount = (total_counter_amount + Number(item.amount));
        total_base_amount = (total_base_amount + Number(amount_base));
        total_usd_amount = (total_usd_amount + Number(amount_usd));

        const raised_counter = (this.state.ido.project.session_supply - total_counter_amount)
        const raised = (this.state.ido.project.session_cap - total_usd_amount)
        // const sold_tokens = (this.state.ido.project.session_supply - total_counter_amount)
        const raised_percent = (100 / (this.state.ido.project.session_cap / raised)).toFixed(2)
        const raised_percent_counter = (100 / (this.state.ido.project.session_supply / raised_counter)).toFixed(2)

        console.log('total_counter_amount: ', total_counter_amount)
        console.log('total_base_amount: ', total_base_amount)
        console.log('total_usd_amount: ', total_usd_amount)
        console.log('raised: ', raised)
        console.log('raised_percent%: ', raised_percent)
        console.log('raised_counter: ', raised_counter)
        console.log('raised_percent_counter%: ', raised_percent_counter)
        console.log('----')

        // if (index+1 === this.state.offers.length) {

          this.setState({
            total_counter_amount,
            total_base_amount,
            total_usd_amount,
            progress: {
              raised,
              raised_percent,
              raised_counter,
              raised_percent_counter,
            }
          })
        // }
      }
    })
  }


  renderSellOffers = (item, index) => {
    render_sell++;

    // console.log('item: ', item)

    let amount_base = (item.amount * item.price).toFixed(7);

    if (this.state.ido.base_asset !== 'XLM-native') {
      var price_base_xlm = (this.state.trades[0].price.d / this.state.trades[0].price.n);
      var amount_xlm = (amount_base * price_base_xlm);
      var amount_usd = (amount_xlm * this.state.price_xlm).toFixed(7);
    }
    else {
      var amount_usd = (amount_base * this.state.price_xlm).toFixed(7);
    }

    if (item.selling.asset_code === this.state.ido.project.sell_asset.split('-')[0] && item.buying.asset_type === 'native') {

      return (
        <tr key={index}>
          {/*<td>{index+1}</td>*/}
          <td><b className={'text-info'}>{numFormat(item.amount, 7)}</b> {this.getBaseAsset(this.state.ido.project.sell_asset)}</td>
          <td><b className={'text-danger'}>{numFormat(item.price, 7)}</b></td>
          <td><b className={'text-info'}>{numFormat(amount_base, 2)}</b> {this.getBaseAsset(this.state.ido.base_asset)}</td>
          {
            this.state.ido.base_asset !== 'XLM-native' ?
              <td><b className={'text-info'}>{numFormat(amount_xlm, 2)}</b></td>
              : null
          }
          <td><b className={'text-info'}>{numFormat(amount_usd, 2)}</b></td>
        </tr>
      )
    }
  }


  getBaseAsset(item) {
    const asset = item.split('-');
    const base_asset = this.state.ido.base_asset.split('-')
    return (
      <Link to={(item === 'XLM-native' ? '/native' :  `/trade/${asset[0]}-${base_asset[0]}/${asset[1]}/${base_asset[1]}`)}>
        <b className="text-warning">{item === 'XLM-native' ? 'XLM' : asset[0]}</b>
      </Link>
    )
  }


  getStatusIDO() {

    var now = moment();
    var date_now = moment(now);
    var date_start = moment(this.state.ido.totals.time ? this.state.ido.totals.time[0] : '0000-00-00');
    var date_end = moment(this.state.ido.totals.time ? this.state.ido.totals.time[1] : '0000-00-00');

    var days_start = date_start.diff(date_now, 'days');
    var days_end = date_end.diff(date_now, 'days');

    this.setState({
      days_start: days_start,
      days_end: (Math.sign(days_end) === 1 ? days_end : String(days_end).substr(1)),
    })

    if (days_start > 0) {
      this.setState({ status: 'future' });
    }

    else if (days_start <= 0 && days_end >= 0) {
      this.setState({
        status: 'current',
      });
    }

    else if (days_start < 0 && days_end <= 0) {
      this.setState({
        status: 'ended',
      });
    }

    else {
      this.setState({
        status: 'undefined',
      });
    }
  }



  renderCountdown() {

    if (this.state.ido && this.state.ido.totals && this.state.ido.totals.time ) {
      if (this.state.status === 'future') {
        var time = this.state.ido.totals.time[0];
      }
      if (this.state.status === 'current') {
        var time = this.state.ido.totals.time[1];
      }
      if (this.state.status === 'ended' || this.state.status === 'undefined') {
        var time = Date.now();
      }
    }
    else {
      var time = Date.now();
    }

    return (
      <Countdown date={this.getMillisec(time)} />
    )
  }


  changeBaseAmount = (value) => {

    this.props.change('counter_amount', '')
    const source_amount = Number(value.target.value).toFixed(7);

    if (source_amount > 0) {
      this.setState({ source_amount })
      // this.setBaseAmount(source_amount)
    }
  }

  changeCounterAmount = (value) => {
    this.props.change('base_amount', '')
    const counter_amount = Number(value.target.value).toFixed(7);

    if (counter_amount > 0) {
      this.setState({ counter_amount })
      // this.setCounterAmount(counter_amount)
    }
  }


  keyUpInput = (type) => {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => this.doneTyping(type), this.doneTypingInterval)
  }

  keyDownInput = () => {
    clearTimeout(this.typingTimer);
  }

  doneTyping = (type) => {
    const { source_amount, counter_amount } = this.state

    if (type === 'base') {
      this.setBaseAmount(source_amount)
    }
    if (type === 'counter') {
      this.setCounterAmount(counter_amount)
    }
  }


  setBaseAmount = (amount) => {

    this.props.change('counter_amount', '')

    const source_asset = (this.state.base_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native()
      : new StellarSdk.Asset(this.state.base_asset.asset_code, this.state.base_asset.asset_issuer)
    const destination_asset = (this.state.counter_asset.asset_issuer === 'native') ? [new StellarSdk.Asset.native()] : [new StellarSdk.Asset(this.state.counter_asset.asset_code, this.state.counter_asset.asset_issuer)]

    this.setState({
      base_amount: amount,
      counter_amount: 0,
      path_send_loading: true,
      path_loading: true,
      min_amount: 0,
      max_amount: 0,
      select_asset_type: 'base_asset',
      path: [],
    })

    strictSendPaths(source_asset, amount, destination_asset).then(result => {
      // console.log('strictSendPaths result: ', result)

      if (result && result.records.length) {
        const min_amount = result.records[result.records.length-1].destination_amount
        const counter_amount = result.records[0].destination_amount

        this.props.change('counter_amount', counter_amount)

        this.setState({
            counter_amount,
            min_amount,
            path: result.records[0].path,
            path_send_loading: false,
            path_loading: false,
          })
      }
    }).catch(error => {
      console.log('error: ', error)
      this.setState({
        path_send_loading: false,
        path_loading: false,
      })
    })
  }


  setCounterAmount = (amount) => {

    this.props.change('base_amount', '')

    this.setState({
      counter_amount: amount,
      base_amount: 0,
      path_receive_loading: true,
      path_loading: true,
      min_amount: 0,
      max_amount: 0,
      select_asset_type: 'counter_asset',
      path: [],
    })

    const source_asset = [(this.state.base_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native()
      : new StellarSdk.Asset(this.state.base_asset.asset_code, this.state.base_asset.asset_issuer)]
    const destination_asset = (this.state.counter_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native() : new StellarSdk.Asset(this.state.counter_asset.asset_code, this.state.counter_asset.asset_issuer)

    strictReceivePaths(source_asset, destination_asset, amount.toString()).then(result => {
      // console.log('strictReceivePaths result: ', result)

      if (result && result.records.length) {
        const base_amount = Number(result.records[0].source_amount)
        const max_amount = result.records[result.records.length-1].source_amount

        this.props.change('base_amount', base_amount)

        this.setState({
            base_amount,
            max_amount,
            path: result.records[0].path,
            path_receive_loading: false,
            path_loading: false,
          })
      }
    }).catch(error => {
      console.log('error: ', error)
      this.setState({
        path_receive_loading: false,
        path_loading: false,
      })
    })
  }


  getPricePair = () => {

    this.setState({
      current_price: false,
      path_receive_loading: true,
      path_send_loading: true,
      // path_loading: true,
      min_amount: 0,
    })

    const source_amount = 1;
    const source_asset = (this.state.base_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native() : new StellarSdk.Asset(this.state.base_asset.asset_code, this.state.base_asset.asset_issuer)
    const destination_asset = (this.state.counter_asset.asset_issuer === 'native') ? [new StellarSdk.Asset.native()] : [new StellarSdk.Asset(this.state.counter_asset.asset_code, this.state.counter_asset.asset_issuer)]

    strictSendPaths(source_asset, source_amount, destination_asset).then(result => {
      // console.log('strictSendPaths result: ', result)

      if (result && result.records.length) {
        const current_price = (result.records[0].source_amount / result.records[0].destination_amount).toFixed(7)

        this.setState({
            start_path_found: result.records,
            current_price,
            base_asset_code: this.state.base_asset.asset_code,
            counter_asset_code: this.state.counter_asset.asset_code,
            path_receive_loading: false,
            path_send_loading: false,
          },
          // () =>  this.getPriceImpact()
        )
      }
    })
  }


  reversePrice = (e) => {
    e.preventDefault()

    const base_price = (this.state.start_path_found[0].destination_amount)
    const counter_price = (this.state.start_path_found[0].source_amount / this.state.start_path_found[0].destination_amount).toFixed(7)

    this.setState({
      current_price: (this.state.counter_asset.asset_code === this.state.counter_asset_code ? base_price : counter_price),
      base_asset_code: this.state.counter_asset_code,
      counter_asset_code: this.state.base_asset_code,
    })
  }


  changeSelectBaseAsset = (e) => {
    e.preventDefault()

    this.setState({
      modal: !this.state.modal,
      select_asset_type: 'base_asset',
      min_amount: 0,
      max_amount: 0,
    })
  }


  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }


  selectAsset = (item, e) => {
    e.preventDefault()

    console.log('selectAsset item: ', item)

    if (item.asset_type === 'native') {
      this.setState({
        base_asset: {
          asset_code: 'XLM',
          asset_issuer: 'native',
          name: 'Stellar',
          image: '/img/logo/stellar-logo-gray.png',
          home_domain: 'stellar.org',
        },
        base_balance: item.balance,
        base_asset_code: 'XLM'
      })
    }
    else {
      const base_asset = this.state.assets_db.filter(asset => item.asset_code === asset.asset_code && item.asset_issuer === asset.asset_issuer)[0]
      // const base_balance = this.state.assets_db.filter(asset => item.asset_code === asset.asset_code && item.asset_issuer === asset.asset_issuer)[0]

      this.setState({
        base_asset,
        base_balance: item.balance,
        base_asset_code: base_asset.asset_code,
      })
    }

    setTimeout(() => this.getPricePair(), 100)

    this.toggleModal()
  }


  getAssetsDB = () => {

    let codes = []
    let issuers = []

    this.state.account_assets.map(item => {

      if (item.asset_type !== 'native' && item.asset_type !== 'liquidity_pool_shares') {
        codes.push(item.asset_code)
        issuers.push(item.asset_issuer)
      }
    })

    console.log('codes: ', codes)
    console.log('issuers: ', issuers)
    //
    const formData = new FormData();
    formData.append('asset_codes', codes)
    formData.append('asset_issuers', issuers)

    axios.post(`${GLOBE.API_URL}/get_pool_assets`, formData).then(result => {
      console.log('get_pool_assets result: ', result)

      if (result.data.assets.length) {

        this.setState({
          assets_db: result.data.assets,
        })
      }
    });
  }


  buyAsset = () => {

    const { native_balance, ido, select_address, base_amount, counter_amount, reserve_balance, counter_asset } = this.state

    if (!select_address) {
      ToastStore.warning('Select Address');
    }
    else if (!base_amount || !counter_amount) {
      ToastStore.warning('Enter amount');
    }
      // else if (!values.total) {
      //   ToastStore.warning('Not Total Amount');
    // }
    else {

      if (native_balance <= reserve_balance) {
        ToastStore.warning('Not enough XLM balance');
      }
      else if (ido.base_asset === 'XLM-native' && native_balance < native_balance) {
        ToastStore.warning('Not enough XLM balance');
      }
      // else if (ido.base_asset === this.state.base_asset_default && this.state.base_balance < this.state.total_sell) {
      //   ToastStore.warning(`Not enough ${this.state.base_asset_default.split('-')[0]} balance`);
      // }
      else {

        const message = `<div>Buy <b class="text-info">${numFormat(counter_amount, 7)}</b> <b class="text-warning">${counter_asset.asset_code}</b> right now?</div>`
        const element = document.createElement("span");
        element.innerHTML = message;

        swal({
          title: 'Confirm',
          content: element,
          icon: 'info',
          buttons: {
            cancel: true,
            confirm: true,
          },
        })
          .then((value) => {
            if (value) {
              this.preparePathPayment();
            }
          });
      }
    }
  }


  preparePathPayment = () => {

    let { select_asset_type, base_amount, base_asset, counter_amount, counter_asset, select_address, min_amount, max_amount, trade_account, path} = this.state
    const destination = trade_account.id

    if (!select_address) {
      ToastStore.warning('Account not selected');
      return
    }
    if (!base_amount) {
      ToastStore.warning('Enter the amount of the sale');
      return
    }
    if (!counter_amount) {
      ToastStore.warning('Enter purchase amount');
      return
    }

    let sendAsset, sendMax, sendAmount, destMin, destAsset, destAmount;

    sendAsset = (base_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native() : new StellarSdk.Asset(base_asset.asset_code, base_asset.asset_issuer)
    destAsset = (counter_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native() : new StellarSdk.Asset(counter_asset.asset_code, counter_asset.asset_issuer)
    path = path.map(item => {
      if (item.asset_type === 'native') {
        return new StellarSdk.Asset.native()
      }
      else {
        return new StellarSdk.Asset(item.asset_code, item.asset_issuer)
      }
    })

    // PathPaymentStrictSend
    if (select_asset_type === 'base_asset') {
      sendAmount = base_amount.toString()
      destMin = min_amount.toString() // min_amount
    }

    // PathPaymentStrictReceive
    if (select_asset_type === 'counter_asset') {
      sendMax = max_amount.toString()
      destAmount = counter_amount.toString()
    }

    this.setPathPayment(sendAsset, sendMax, sendAmount, destMin, destination, destAsset, destAmount, path, select_asset_type)


    // const message = `<div>Swap <b class="text-info">${numFormat(select_asset_type === 'base_asset' ? base_amount : max_amount, 7)}</b> <b class="text-warning">${base_asset.asset_code}</b> to <b class="text-info">${numFormat(counter_amount, 7)}</b> <b class="text-warning">${counter_asset.asset_code}</b> right now?</div>`;
    // const element = document.createElement("span");
    // element.innerHTML = message;
    //
    // swal({
    //   title: "Confirm",
    //   content: element,
    //   icon: "info",
    //   buttons: {
    //     cancel: true,
    //     confirm: true,
    //   },
    // })
    //   .then((confirm) => {
    //     if (confirm) {
    //       this.setPathPayment(sendAsset, sendMax, sendAmount, destMin, destination, destAsset, destAmount, path, select_asset_type)
    //     }
    //   })
  }


  setPathPayment = (sendAsset, sendMax, sendAmount, destMin, destination, destAsset, destAmount, path, type) => {

    const { trade_account, base_asset, counter_asset, base_amount, counter_amount } = this.state
    const select_address = trade_account.id

    console.log('sendAsset: ', sendAsset)
    console.log('sendMax: ', sendMax)
    console.log('sendAmount: ', sendAmount)
    console.log('destMin: ', destMin)
    console.log('destination: ', destination)
    console.log('destAsset: ', destAsset)
    console.log('destAmount: ', destAmount)
    console.log('path: ', path)
    console.log('type: ', type)

    this.setState({ loader: true })

    Server.loadAccount(select_address)
      .then((sourceAccount) => {

        let transaction

        if (type === 'base_asset') {
          transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: StellarSdk.BASE_FEE,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
            .addOperation(StellarSdk.Operation.pathPaymentStrictSend({ sendAsset, sendAmount, destination, destAsset, destMin, path }))
            .addMemo(StellarSdk.Memo.text(this.state.memo ? this.state.memo : ''))
            .setTimeout(100)
            .build();
        }

        if (type === 'counter_asset') {
          transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: StellarSdk.BASE_FEE,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
            .addOperation(StellarSdk.Operation.pathPaymentStrictReceive({ sendAsset, sendMax, destination, destAsset, destAmount, path }))
            .addMemo(StellarSdk.Memo.text(this.state.memo ? this.state.memo : ''))
            .setTimeout(100)
            .build();
        }

        if (checkConnectProvider(select_address)) {
          return signConnectProvider(transaction, select_address).then(result => {
            return Server.submitTransaction(result);
          })
        }
        else {

          const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(select_address).sk))
          transaction.sign(signer);

          return Server.submitTransaction(transaction);
        }
      })
      .then((result) => {

        if (result) {

          this.setState({
            loader: false,
            hash: result.hash,
            operation: false,
          })

          const message = `<div><span class="text-success">Bought</span> <b class="text-info">${numFormat(counter_amount, 7)}</b> <b class="text-warning">${counter_asset.asset_code}</b> <span class="text-danger">Sold</span>  <b class="text-info">${numFormat(base_amount, 7)}</b> <b class="text-warning">${base_asset.asset_code}</b><br><br><small>hash: <a href="/transaction/${result.hash}" target="_blank"><b>${shortAddr(result.hash, 4)}</b></a></small></div>`
          const element = document.createElement("span");
          element.innerHTML = message;

          swal({
            title: `Successful Swap!`,
            content: element,
            icon: "success",
          })

          // this.getOperation(result.hash)
          this.getAccount(destination)

          // Save to DB ...
        }
      })
      .catch((error) => {
        console.error('Error! ', error);

        this.setState({ loader: false })
        showErrorMessage(error, 'path_payment')
      });
  }




  renderModal = (type) => {

    const { base_asset, counter_asset, modal, select_asset_type, assets_db, account_assets } = this.state


    return (
      <Draggable disabled={true}>
        <Modal
          isOpen={modal}
          toggle={this.toggleModal}
          className={`modal-dialog--primary modal-dialog--header`}
        >
          <div>
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" onClick={this.toggleModal} />
              <h4 className="bold-text  modal__title">Select {select_asset_type === 'counter_asset' ? 'Counter' : 'Base'} Asset</h4>
            </div>
            <div className="modal__body modal-scroll">

              <p className={'text-secondary'}><b>My assets:</b></p>
              <br/>

              {
                assets_db ?
                  account_assets.reverse().map((item, index) => {

                    let image = assets_db.filter(asset => item.asset_code === asset.asset_code && item.asset_issuer === asset.asset_issuer)
                    image = image.length ? image[0].image : this.native_image

                    if (item.asset_type === 'native') {
                      item.asset_code = 'XLM'
                    }

                    if (item.asset_code !== counter_asset.asset_code && item.asset_issuer !== counter_asset.asset_issuer) {
                      return (
                        <div key={index}>
                          {
                            <a key={index}
                               href={'#'}
                               onClick={(e) => this.selectAsset(item, e)}
                            >
                              <Card style={{paddingBottom: 6}}>
                                <CardBody className="item-asset text-secondary">
                                  <img src={image} alt="" style={{width: 20}} /> {' '}
                                  <b className="text-warning" style={{fontSize: 14}}>{item.asset_code}</b> <small>(<b className="text-info">{numFormat(item.balance, 7)}</b>)</small> <span style={{float: 'right'}}><small> <font className="text-danger">selling</font> <b className="text-info">{numFormat(item.selling_liabilities, 7)}</b> / <font className="text-success">buying</font> <b className="text-info">{numFormat(item.buying_liabilities, 7)}</b></small></span>
                                </CardBody>
                              </Card>
                            </a>
                          }
                        </div>
                      )
                    }
                  })
                  :
                  <p>Select Account to view all balances</p>
              }

            </div>
          </div>
        </Modal>
      </Draggable>
    )
  }




  render() {
    console.log('state: ', this.state)

    const { handleSubmit, reset, t } = this.props;
    const { asset, base_asset, counter_asset, select_address, base_amount, counter_amount, base_accept, counter_accept, base_balance,
      counter_balance, base_asset_code, counter_asset_code, current_price} = this.state

    // const base_asset_code = (this.state.ido ? this.state.ido.base_asset.split('-')[0] : '...');
    // const counter_asset_code = (this.state.ido ? this.state.ido.project.sell_asset.split('-')[0] : '...');


    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        { this.renderModal() }

        <Row>
          <Col md={12}>
            <h3 className="page-title">IEO: {' '}
              <b>{this.state.ido ? this.state.ido.project.title : ''}</b>
              <font className={`status-ieo ieo-${this.state.status}`}>{this.state.status}</font>

              {
                this.state.auth_user && this.state.ido && this.state.auth_user.user_id === Number(this.state.ido.project.user_id) ?
                  <span>
                    <span>
                      <font className={`status-ieo ieo-ended`}>Edit</font>
                    </span>
                    {' '}
                    <span className="float-right" style={{margin: '0 5px'}}>
                      <Link to={`/edit/${this.state.ido.project_id}`}>
                        <span className="fa fa-cog text-secondary"
                              style={{color: 'rgb(100, 103, 119)'}}/>
                      </Link>
                    </span>
                  </span>
                : null
              }
            </h3>
            <h3 className="page-subhead subhead" style={{marginBottom: '18px'}}>
              {this.state.ido ? this.state.ido.project.subtitle : ''}
            </h3>
          </Col>
        </Row>


        <Row>
          <Col md={8}>
            <Card style={{height: 'auto'}}>
              <CardBody>
                {/*<div className="card__title">
                  <h5 className="bold-text">Intro</h5>
                </div>*/}

                <div className="tabs tabs--bordered-bottom">
                  <div className="tabs__wrap">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTabPreview === '1' })}
                          onClick={() => {
                            this.togglePreview('1');
                          }}
                        >
                          <b>Video</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTabPreview === '2' })}
                          onClick={() => {
                            this.togglePreview('2');
                          }}
                        >
                          <b>Cover</b>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTabPreview}>
                      <TabPane tabId="1">
                        <div className="embed-responsive embed-responsive-16by9" style={{background: 'black'}}>
                          <iframe className="embed-responsive-item" src={this.state.ido ? this.state.ido.project.video : ''} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                        </div>
                      </TabPane>
                      <TabPane tabId="2">
                        <img src={this.state.ido ? this.state.ido.project.cover_url : null} />
                      </TabPane>
                    </TabContent>
                  </div>
                </div>

                {/*<br/>*/}

                {/*<p>{this.state.ido ? this.state.ido.project.description : ''}</p>*/}
                {/*<br/>*/}


                <div className="card__title" style={{marginBottom: '27px'}}>
                  <h5 className="bold-text">Project Summary</h5>
                  {/*<h5 className="subhead">zfbxfbxcbxfgbfgxb</h5>*/}
                </div>

                <p>Scopuly is an innovative system for launching, listing, exchanging and storing assets created on the Stellar blockchain.
                  Since Stellar is many times faster than Ethereum and Bitcoin and charges virtually no transaction fees,
                  this blockchain has the potential to become the foundation of a new international financial system. However,
                  the Stellar ecosystem currently lacks two key elements: an e cient crypto-fiat exchange tool and a space for launching
                  IEOs (Initial Exchange O erings). Scopuly will provide these missing elements.</p>


              </CardBody>
            </Card>

            {/*<Card style={{height: 'auto'}}>*/}
            {/*  <CardBody>*/}
            {/*    <div className="card__title" style={{marginBottom: '27px'}}>*/}
            {/*      <h5 className="bold-text">Token Allocation</h5>*/}
            {/*      /!*<h5 className="subhead">zfbxfbxcbxfgbfgxb</h5>*!/*/}
            {/*    </div>*/}

            {/*    <p>sadfasdfasdfasdf</p>*/}
            {/*    <p>sadfasdfasdfasdf</p>*/}
            {/*    <p>sadfasdfasdfasdf</p>*/}
            {/*    <p>sadfasdfasdfasdf</p>*/}
            {/*    /!*<p>sadfasdfasdfasdf</p>*!/*/}
            {/*    /!*<p>sadfasdfasdfasdf</p>*!/*/}
            {/*  </CardBody>*/}
            {/*</Card>*/}
          </Col>

          <Col md={4}>

            {/*<Card style={{height: 'auto'}}>*/}
            {/*  <CardBody>*/}
            {/*    <div className="inner-kyc">*/}
            {/*      /!*<i class="fa fa-check-circle-o text-success" style={{fontSize: '20px'}}></i>{' '} *!/*/}
            {/*      <span className="lnr lnr-checkmark-circle text-success" style={{fontSize: '20px'}}></span>{' '}*/}
            {/*      <span className="text-secondary">KYC</span>*/}
            {/*    </div>*/}
            {/*    <div className="ieo-verify">*/}
            {/*      <div className="verify-text">Verified by</div>*/}
            {/*      <a href={`https://icobench.com/ico/${this.state.ido ? this.state.ido.project.path : null}`} target='_blank' rel='noopener noreferrer'>*/}
            {/*        <img src={bench_logo} />*/}
            {/*      </a>*/}
            {/*    </div>*/}
            {/*    <div className="text-center">*/}
            {/*      <div>*/}
            {/*        <div className="reward text-info">{this.state.ido ? this.state.ido.rates.total : '...'}</div>*/}
            {/*        <p><b>{this.state.ido ? this.state.ido.rates.experts_count : '...'}</b> expert ratings</p>*/}
            {/*      </div>*/}

            {/*      <br/>*/}

            {/*      <Row>*/}
            {/*        <Col md={4}>*/}
            {/*          <div className="ratings">*/}
            {/*            <small className="bold-text text-secondary">BENCHY</small>*/}
            {/*            <h5><b className="text-info">{this.state.ido ? this.state.ido.rates.bench : '...'}</b></h5>*/}
            {/*            <small>Profile</small>*/}
            {/*          </div>*/}
            {/*        </Col>*/}

            {/*        <Col md={8}>*/}
            {/*          <Row className="ratings">*/}
            {/*            <Col md={12}>*/}
            {/*              <small className="bold-text text-secondary">EXPERTS</small>*/}
            {/*            </Col>*/}
            {/*            <Col md={4} sm={4}>*/}
            {/*              <h5><b className="text-info">{this.state.ido ? this.state.ido.rates.experts.team : '...'}</b></h5>*/}
            {/*              <small>Team</small>*/}
            {/*            </Col>*/}
            {/*            <Col md={4} sm={4}>*/}
            {/*              <h5><b className="text-info">{this.state.ido ? this.state.ido.rates.experts.vision : '...'}</b></h5>*/}
            {/*              <small>Vision</small>*/}
            {/*            </Col>*/}
            {/*            <Col md={4} sm={4}>*/}
            {/*              <h5><b className="text-info">{this.state.ido ? this.state.ido.rates.experts.product : '...'}</b></h5>*/}
            {/*              <small>Product</small>*/}
            {/*            </Col>*/}
            {/*          </Row>*/}
            {/*        </Col>*/}
            {/*      </Row>*/}
            {/*    </div>*/}
            {/*  </CardBody>*/}
            {/*</Card>*/}


            {
              this.state.ido && asset ?
                <TokenInfo data={this.state.ido}
                           offers={this.getOffers}
                           trades={this.getTrades}
                           progress={this.state.progress}
                           price={this.getPriceXlmUsd}
                           asset={asset}
                />
                : null
            }


            {/*<Card className=""  style={{height: 'auto'}}>*/}
            {/*  <CardBody>*/}
            {/*    <ButtonToolbar>*/}
            {/*      <ButtonGroup className="btn-group--justified wr-docs">*/}
            {/*        <Button color="primary" outline href={'#'} target='_blank' rel='noopener noreferrer'>Trade</Button>*/}
            {/*        <Button color="primary" outline href={'#'} target='_blank' rel='noopener noreferrer'>Swap</Button>*/}
            {/*        <Button color="primary" outline href={'#'} target='_blank' rel='noopener noreferrer'>Liquidity Pool</Button>*/}
            {/*      </ButtonGroup>*/}
            {/*    </ButtonToolbar>*/}
            {/*  </CardBody>*/}
            {/*</Card>*/}



            {/*<Card style={{height: 'auto'}}>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Buy SKY</h5>
                </div>
                <div className="dashboard__place-order-form">
                  <form className="form form--horizontal" onSubmit={handleSubmit(this.submitForm.bind(this))}>

                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="distributor_address"
                          component={renderTextField}
                          select
                          label="Select Distributor Address"
                          onChange={(value) => {
                            this.changeAddress(value)
                          }}
                        >
                          {
                            this.state.wallets ?
                              this.state.wallets.map((item, index) => {
                                return (
                                  <MenuItem key={index} className="material-form__option" value={item.pk}><b className="text-secondary">{shortAddr(item.pk, 8)}</b> <small> / {item.title}</small></MenuItem>
                                );
                              })
                            : null
                          }
                        </Field>
                      </div>
                    </div>



                    <div className="form__form-group">
                      <span className="form__form-group-label">Address</span>
                      <div className="form__form-group-field">
                        <div style={{width: '100%'}}>
                          <select class="form-control" style={{border: '1px solid rgb(242, 244, 247)', height: '39px', fontSize: '12px'}}>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                        </div>
                      </div>
                    </div>


                    <div className="form__form-group">
                      <span className="form__form-group-label">Price</span>
                      <div className="form__form-group-field">
                        <Field
                          name="price"
                          component="input"
                          type="text"
                          placeholder="SKY/XLM"
                          disabled
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">Amount</span>
                      <div className="form__form-group-field">
                        <Field
                          name="amount"
                          component="input"
                          type="text"
                          placeholder="Amount SKY"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Total</span>
                      <div className="form__form-group-field">
                        <Field
                          name="total"
                          component="input"
                          type="text"
                          placeholder="Amount XLM"
                        />
                      </div>
                    </div>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button color="success" type="submit">Buy <b>100,947,559 SKY</b></Button>
                    </ButtonToolbar>
                  </form>
                </div>
              </CardBody>
            </Card>*/}

          </Col>
        </Row>


        <Row>

          <Col md={3}>
            <Card style={{height: 'auto'}}>
              <CardBody>
                <div className="card__title" style={{marginBottom: '6px'}}>
                  <h5 className="bold-text">IEO TIME
                    {
                      this.state.status === 'future' ?
                        <span className={`status-badge badge badge-primary`}><span>Soon</span></span>
                        :
                        this.state.status === 'current' ?
                          <span className={`status-badge badge badge-success`}><span>Live</span></span>
                          :
                          this.state.status === 'ended' ?
                            <span className={`status-badge badge badge-secondary`}><span>Ended</span></span>
                            :
                            <span className={`status-badge badge badge-secondary`}><span>Undefined</span></span>
                    }
                  </h5>
                  <h5 className="subhead">{this.state.ido && this.state.ido.totals.time ? this.state.ido.totals.time[0]+' - '+this.state.ido.totals.time[1] : '0000-00-00 - 0000-00-00'}</h5>
                </div>
                <div className="text-info" style={{fontSize: '2.4em', marginBottom: '-16px'}}>
                  {this.renderCountdown()}
                </div>
                <div>
                  <h5 className="subhead subhead-ido">
                    {
                      this.state.status === 'future' ?
                        <span><b>{this.state.days_start}</b> days to start</span>
                        :
                        this.state.status === 'current' ?
                          <span><b>{this.state.days_end}</b> days to end</span>
                          :
                          this.state.status === 'ended' ?
                            <span>Completed <b>{this.state.days_end}</b> days ago</span>
                            :
                            <span>Undefined</span>
                    }
                  </h5>
                </div>
              </CardBody>
            </Card>

            <Card style={{height: 'auto'}}>
              <CardBody>
                <div className="card__title" style={{marginBottom: 30}}>
                  <h5 className="bold-text">Token Info</h5>
                  <h5 className="subhead">Asset meta information</h5>
                </div>

                <div className={'token-info'}>
                  <p>Asset Name: <b>{asset.name}</b></p>
                  <p>Asset Code: <b>{asset.asset_code}</b></p>
                  <p>Asset Issuer: <span className={'text-secondary'}>{shortAddress(asset.asset_issuer)}</span></p>
                  <p>Supply Status: <b><i className="fa fa-lock"></i> Locked</b></p>
                  <p>Toml File: <a href={'https://scopuly.com/.well-known/stellar.toml'} target={'_blank'} className={'text-secondary'}><b>Show</b></a></p>
                  <p>Token Type: <b>Utility</b></p>
                  <p>Platform: <b>Stellar</b></p>
                  <p>Rating: <b>{asset.expert_rating}</b></p>
                  <p>Token created: <b>2019-06-25 10:27:46 UTC</b></p>
                </div>

              </CardBody>
            </Card>
          </Col>

          <Col md={5}>
            <Card style={{height: 'auto'}}>
              <CardBody>
                <div className="card__title" style={{marginBottom: '27px'}}>
                  <h5 className="bold-text">Session Token Distribution</h5>
                  <h5 className="subhead">
                    Session Cap: <b className="text-info">${this.state.ido ? numFormat(this.state.ido.project.session_cap, 0) : '0'}</b> /  {' '}
                    Raised: {' '}
                    {
                      this.state.progress.raised ?
                        <b className="text-success">${this.state.progress.raised ? numFormat(this.state.progress.raised) : '0'}</b>
                        :
                        <BeatLoader
                          css={loaderStyle}
                          color={"#70bbfd"}
                          loading={true}
                          size={8}
                        />
                    }
                  </h5>
                </div>
                <div className="progress-wrap progress-wrap--big mb-0">
                  <Progress value={this.state.progress.raised ? this.state.progress.raised_percent_counter : '0'}>{this.state.progress.raised ? this.state.progress.raised_percent_counter : '0'}%</Progress>
                </div>
                <h5 className="subhead subhead-ido">Remains: {' '}
                  {
                    this.state.progress.raised ?
                      <span>
                        <b>{numFormat(this.state.total_counter_amount, 0)}</b>{' '}
                        {this.state.ido ? this.getBaseAsset(this.state.ido.project.sell_asset) : '...'} / {' '}
                        <b>{numFormat(this.state.total_usd_amount, 0)} <font className="text-warning">USD</font></b>
                      </span>
                    :
                      <BeatLoader
                        css={loaderStyle}
                        color={"#70bbfd"}
                        loading={true}
                        size={8}
                      />
                  }
                </h5>
              </CardBody>
            </Card>

            <Card style={{height: 'auto'}}>
              <CardBody>
                <div className="card__title" style={{marginBottom: '27px'}}>
                  <h5 className="bold-text">Token Allocation</h5>
                  <h5 className="subhead">SCOP Token Distribution Plan</h5>
                </div>
                <Row>
                  <Col md={6}>
                    <ReactApexChart options={this.state.options} series={this.state.series} type="pie" width={330} />
                  </Col>
                  <Col md={6}>
                    <div className={'allocation-block'} >
                      <div><span><i className={'fa fa-circle text-primary'}></i> Foundation: <b>10%</b></span></div>
                      <div><span><i className={'fa fa-circle text-primary'}></i> Marketing & Ecosystem: <b>10%</b></span></div>
                      <div><span><i className={'fa fa-circle text-primary'}></i> Airdrop: <b>10%</b></span></div>
                      <div><span><i className={'fa fa-circle text-primary'}></i> Community Rewards: <b>10%</b></span></div>
                      <div><span><i className={'fa fa-circle text-primary'}></i> Holder Rewards: <b>10%</b></span></div>
                      <div><span><i className={'fa fa-circle text-primary'}></i> Liquidity Rewards: <b>10%</b></span></div>
                      <div><span><i className={'fa fa-circle text-primary'}></i> Trader Rewards: <b>10%</b></span></div>
                      <div><span><i className={'fa fa-circle text-primary'}></i> Cashback (crypto card): <b>10%</b></span></div>
                      <div><span><i className={'fa fa-circle text-primary'}></i> Public Sale: <b>10%</b></span></div>
                      <div><span><i className={'fa fa-circle text-primary'}></i> Referral Program: <b>5%</b></span></div>
                      <div><span><i className={'fa fa-circle text-primary'}></i> Private Sale: <b>5%</b></span></div>
                    </div>
                  </Col>
                </Row>
                {/*460*/}
              </CardBody>
            </Card>
          </Col>

          {/*<Col md={6}>*/}
          {/*  asdfas*/}
          {/*</Col>*/}
          {/*<Col md={6}>*/}
          {/*  asdfas*/}
          {/*</Col>*/}

          <Col md={4}>
            <Card className="swap"  >
              {/*sticky-buy*/}
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Buy {this.state.ido ? this.getBaseAsset(this.state.ido.project.sell_asset) : '...'} Tokens</h5>
                  {/*<h5 className="subhead">Simple and effective Asset Tokenization on Stellar</h5>*/}
                </div>

                { this.state.loader ? <div className="panel__refresh" style={{height: '95%'}}><LoadingIcon /></div> : null }


                <form className="material-form">
                  <div>
                    <Field
                      name="select_address"
                      component={renderTextField}
                      select
                      label="Select Account"
                      onChange={(value) => {
                        this.changeAddress(value)
                      }}
                    >
                      {
                        this.state.wallets ?
                          this.state.wallets.map((item, index) => {
                            return (
                              <MenuItem key={index} className="material-form__option" value={item.pk}><b className="text-secondary">{shortAddr(item.pk, 8)}</b> <small> / {item.title}</small></MenuItem>
                            );
                          })
                          : null
                      }
                    </Field>

                    {/*{*/}
                    {/*  this.state.load_trade_account ?*/}
                    {/*    <div>*/}
                    {/*      <h5 className="subhead">*/}

                    {/*        Balances: <span className="float-right">Reserve: <b className="text-info">{this.state.reserve_balance} <font className="text-warning">XLM</font></b></span><br/>*/}
                    {/*        <b className="text-info">{numFormat(this.state.native_balance, 7)} <font className="text-warning">XLM</font></b> <br/>*/}
                    {/*        {this.state.trust_asset ? <b className="text-info">{numFormat(this.state.counter_balance, 7)} <font className="text-warning">{counter_asset_code}</font></b> : null} <br/>*/}
                    {/*        {this.state.base_balance && this.state.ido.base_asset === this.state.base_asset_default ? <b className="text-info">{numFormat(this.state.base_balance, 7)} <font className="text-warning">{base_asset_code}</font></b> : null}*/}
                    {/*      </h5>*/}
                    {/*      <br/>*/}
                    {/*    </div>*/}
                    {/*    : null*/}
                    {/*}*/}
                  </div>

                  {/*<div>*/}
                  {/*  <Field*/}
                  {/*    name="price"*/}
                  {/*    component={renderTextField}*/}
                  {/*    label={`Price ${counter_asset_code}/${base_asset_code}`}*/}
                  {/*    disabled*/}
                  {/*  />*/}
                  {/*</div>*/}

                  {/*<div>*/}
                  {/*  <Field*/}
                  {/*    name="amount"*/}
                  {/*    component={renderTextField}*/}
                  {/*    label={`Amount buy ${counter_asset_code}`}*/}
                  {/*    onChange={this.amountChange.bind(this)}*/}
                  {/*  />*/}
                  {/*</div>*/}

                  {/*<div>*/}
                  {/*  <Field*/}
                  {/*    name="total"*/}
                  {/*    component={renderTextField}*/}
                  {/*    label={`Total sell ${base_asset_code}`}*/}
                  {/*    onChange={this.totalChange.bind(this)}*/}
                  {/*  />*/}
                  {/*</div>*/}

                  {/*<div style={{marginTop: this.state.load_trade_account ? 9 : 65}}>*/}
                  {/*  <ButtonToolbar className="form__button-toolbar">*/}
                  {/*    <Button color="success"*/}
                  {/*            type="submit"*/}
                  {/*            size="lg"*/}
                  {/*            style={{width: '100%'}}>Buy <b>{numFormat(this.state.amount_buy, 7)} {counter_asset_code}</b></Button>*/}
                  {/*  </ButtonToolbar>*/}
                  {/*</div>*/}
                </form>

                <form className={'form'}>
                  <div className="form__form-group swap-item">
                    <span className="form__form-group-label">From </span> {' '}
                    <span style={{position: "relative"}}>
                            <BeatLoader
                              css={loaderStyle}
                              color={"#70bbfd"}
                              loading={this.state.path_receive_loading}
                              size={8}
                            />
                          </span>
                    <small className={'balance-label text-secondary'}>
                      {
                        select_address ?
                          base_accept  ?
                            <span>
                                    {/*<i className={'fa fa-check-circle-o text-success'}></i> {' '}*/}
                              <b className={'text-info'}>{numFormat(this.state.base_balance, 7)}</b> { base_asset.asset_code }
                                  </span>
                            :
                            <span><i className={'fa fa-minus-circle text-secondary'}></i> No Trustline</span>
                          :
                          <span>Account not selected</span>
                      }
                    </small>
                    <div className="form__form-group-field">
                      <Field
                        name="base_amount"
                        className={'input-swap'}
                        component="input"
                        type="text"
                        placeholder="0.00"
                        onChange={this.changeBaseAmount}
                        onKeyUp={() => this.keyUpInput('base')}
                        onKeyDown={this.keyDownInput}
                        disabled={this.state.path_receive_loading}
                      />
                      <div className="form__form-group-icon select-asset-icon">
                        <a href="#"
                           className={'text-secondary'}
                           onClick={(e) => this.changeSelectBaseAsset(e)}>
                          <div>
                            <img src={base_asset.image}
                                 className={'icon-asset'}
                                 alt={base_asset.name}
                            />
                            <b className={'swap-asset-code'}>{base_asset.asset_code}</b>
                            <div className={'swap-home-domain'}>{base_asset.home_domain}</div>
                          </div>
                          <div className={'arrow'}><i className="fa fa-angle-down"></i></div>
                        </a>
                      </div>
                    </div>
                  </div>

                </form>


                <form className="form">

                  {/*<div className={'form__form-group text-center text-secondary'} style={{margin: '-17px 0 4px'}}>*/}
                  {/*  <small>*/}
                  {/*    {*/}
                  {/*      current_price ?*/}
                  {/*        <span>Price: 1 {counter_asset_code} = <b>{numFormat(current_price, 7)}</b> {base_asset_code}</span>*/}
                  {/*      :*/}
                  {/*        <BeatLoader*/}
                  {/*          css={loaderStyle}*/}
                  {/*          color={"#70bbfd"}*/}
                  {/*          loading={true}*/}
                  {/*          size={8}*/}
                  {/*        />*/}
                  {/*    }*/}

                  {/*    <a href="#" onClick={this.reversePrice}> <i className="fa fa-refresh text-secondary"></i></a>*/}
                  {/*  </small>*/}
                  {/*</div>*/}

                  <div className="form__form-group swap-item" style={{marginTop: -11}}>
                    <span className="form__form-group-label">To</span> {' '}
                    <span style={{position: "relative"}}>
                      <BeatLoader
                        css={loaderStyle}
                        color={"#70bbfd"}
                        loading={this.state.path_send_loading}
                        size={8}
                      />
                    </span>

                    <small className={'balance-label text-secondary'}>
                      {
                        select_address ?
                          counter_accept ?
                            <span>
                                    {/*<i className={'fa fa-check-circle-o text-success'}></i> {' '}*/}
                              <b className={'text-info'}>{numFormat(counter_balance, 7)}</b> { counter_asset.asset_code }
                                  </span>
                            :
                            <span><i className={'fa fa-minus-circle text-secondary'}></i> No Trustline</span>
                          :
                          <span>Account not selected</span>
                      }
                    </small>

                    <div className="form__form-group-field">
                      <Field
                        name="counter_amount"
                        className={'input-swap'}
                        component="input"
                        type="text"
                        placeholder="0.00"
                        onChange={this.changeCounterAmount}
                        onKeyUp={() => this.keyUpInput('counter')}
                        onKeyDown={this.keyDownInput}
                        disabled={this.state.path_send_loading}
                      />
                      <div className="form__form-group-icon select-asset-icon">
                        <a href="#"
                           className={'text-secondary'}
                           onClick={this.changeSelectCounterAsset}>
                          <div>
                            <img src={counter_asset.image}
                                 className={'icon-asset'}
                                 alt={counter_asset.name}
                            />
                            <b className={'swap-asset-code'}>{counter_asset.asset_code}</b>
                            <div className={'swap-home-domain'}>{counter_asset.home_domain}</div>
                          </div>
                          <div className={'arrow'}><i className="fa fa-angle-down"></i></div>
                        </a>
                      </div>
                    </div>

                  </div>

                  <div className={'form__form-group text-center text-secondary'} style={{margin: '-17px 0 4px'}}>
                    <small>
                      {
                        current_price ?
                          <span>
                            Price: 1 {counter_asset_code} = <b>{numFormat(current_price, 7)}</b> {base_asset_code} {' '}
                            <a href="#" onClick={this.reversePrice}> <i className="fa fa-refresh text-secondary"></i></a>
                          </span>
                          :
                          <BeatLoader
                            css={loaderStyle}
                            color={"#70bbfd"}
                            loading={true}
                            size={8}
                          />
                      }
                    </small>
                  </div>

                  <div className={'form__form-group text-center text-secondary'} style={{margin: '-8px 0px 4px'}}>
                    <small>
                      Path: {/*Path: <b>AQUA</b>-><b>BRAVE</b>-><b>XLM</b>-><b>SCOP</b>*/}
                      {
                        !this.state.path_loading ?
                          <span>
                            <span><b className={'text-secondary'}>{base_asset.asset_code}</b> <i className="fa fa-angle-right" ></i></span> {' '}
                            {
                              this.state.path.length ?
                                this.state.path.map((item, index) => {
                                  return (
                                    <span key={index}><a href={item.asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.asset_code}-XLM/${item.asset_issuer}/native`} className="text-warning" target={'_blank'}><b>{item.asset_type === 'native' ? 'XLM' : item.asset_code}</b></a> <i className="fa fa-angle-right"></i> </span>
                                  )
                                })
                                : null
                            }
                            <span><b className={'text-secondary'}>{counter_asset.asset_code}</b></span>
                          </span>
                          :
                          <BeatLoader
                            color={"#70bbfd"}
                            css={loaderStyle}
                            loading={true}
                            size={8}
                          />
                      }
                    </small>
                  </div>


                  <div className={"form__form-group"} style={{marginTop: 8}}>
                    {/*style={{marginTop: this.state.load_trade_account ? 9 : 65}}*/}
                    <ButtonToolbar className="">
                      <Button color="success"
                              onClick={this.buyAsset}
                              // type="submit"
                              size="lg"
                              className={'btn-lgx'}
                              style={{width: '100%'}}>Buy <b>{numFormat(counter_amount, 7)} {counter_asset.asset_code}</b></Button>
                    </ButtonToolbar>
                  </div>

                </form>

                <ButtonToolbar style={{marginTop: 0}}>
                  <ButtonGroup className="btn-group--justified wr-docs">
                    <Button color="secondary" outline href={`/trade/${asset.asset_code}-XLM/${asset.asset_issuer}/native`} target='_blank' rel='noopener noreferrer'>Trade</Button>
                    <Button color="secondary" outline href={`/swap/XLM-${asset.asset_code}/native/${asset.asset_issuer}`} target='_blank' rel='noopener noreferrer'>Swap</Button>
                    <Button color="secondary" outline href={`/pool/${getPoolIdFromAssets(base_asset, counter_asset)}`} target='_blank' rel='noopener noreferrer'>Pool</Button>
                  </ButtonGroup>
                </ButtonToolbar>

              </CardBody>
            </Card>
          </Col>

          {/*<Col md={4}>*/}
          {/*  asdf*/}
          {/*</Col>*/}

          {/*<Col md={4}>*/}
          {/*  asdfas*/}
          {/*</Col>*/}
        </Row>

        <Row>

          <Col md={12}>
            {/*<Card style={{height: 'auto'}}>*/}
            {/*  <CardBody>*/}
            {/*    /!*<div className="card__title">*/}
            {/*      <h5 className="bold-text">Intro</h5>*/}
            {/*    </div>*!/*/}

            {/*    <div className="tabs tabs--bordered-bottom">*/}
            {/*      <div className="tabs__wrap">*/}
            {/*        <Nav tabs>*/}
            {/*          <NavItem>*/}
            {/*            <NavLink*/}
            {/*              className={classnames({ active: this.state.activeTabPreview === '1' })}*/}
            {/*              onClick={() => {*/}
            {/*                this.togglePreview('1');*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              <b>Video</b>*/}
            {/*            </NavLink>*/}
            {/*          </NavItem>*/}
            {/*          <NavItem>*/}
            {/*            <NavLink*/}
            {/*              className={classnames({ active: this.state.activeTabPreview === '2' })}*/}
            {/*              onClick={() => {*/}
            {/*                this.togglePreview('2');*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              <b>Cover</b>*/}
            {/*            </NavLink>*/}
            {/*          </NavItem>*/}
            {/*        </Nav>*/}

            {/*        <TabContent activeTab={this.state.activeTabPreview}>*/}
            {/*          <TabPane tabId="1">*/}
            {/*            <div className="embed-responsive embed-responsive-16by9" style={{background: 'black'}}>*/}
            {/*              <iframe className="embed-responsive-item" src={this.state.ido ? this.state.ido.project.video : ''} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>*/}
            {/*            </div>*/}
            {/*          </TabPane>*/}
            {/*          <TabPane tabId="2">*/}
            {/*            <img src={this.state.ido ? this.state.ido.project.cover_url : null} />*/}
            {/*          </TabPane>*/}
            {/*        </TabContent>*/}
            {/*      </div>*/}
            {/*    </div>*/}

            {/*    <br/>*/}

            {/*    <p>{this.state.ido ? this.state.ido.project.description : ''}</p>*/}
            {/*    <br/>*/}

            {/*  </CardBody>*/}
            {/*</Card>*/}




            <Card style={{height: 'auto'}}>
              <CardBody>


                <div className="tabs tabs--bordered-bottom">
                  <div className="tabs__wrap">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '6' })}
                          onClick={() => {
                            this.toggle('6');
                          }}
                        >
                          <b>Trades</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '5' })}
                          onClick={() => {
                            this.toggle('5');
                          }}
                        >
                          <b>Sell Offers</b>
                        </NavLink>
                      </NavItem>
                      {/*<NavItem>*/}
                      {/*  <NavLink*/}
                      {/*    className={classnames({ active: this.state.activeTab === '2' })}*/}
                      {/*    onClick={() => {*/}
                      {/*      this.toggle('2');*/}
                      {/*    }}*/}
                      {/*  >*/}
                      {/*    <b>Team</b>*/}
                      {/*  </NavLink>*/}
                      {/*</NavItem>*/}

                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '4' })}
                          onClick={() => {
                            this.toggle('4');
                          }}
                        >
                          <b>Conditions</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => {
                            this.toggle('1');
                          }}
                        >
                          <b>About</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '3' })}
                          onClick={() => {
                            this.toggle('3');
                          }}
                        >
                          <b>Roadmap</b>
                        </NavLink>
                      </NavItem>

                      {/*<NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '7' })}
                          onClick={() => {
                            this.toggle('7');
                          }}
                        >
                          <b>News</b>
                        </NavLink>
                      </NavItem>*/}
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        {
                          this.state.ido ?
                            <p dangerouslySetInnerHTML={{__html: this.state.ido.project.about}}></p>
                          : null
                        }
                      </TabPane>

                      <TabPane tabId="2">
                        <br/>
                        <div className="card__title">
                          <h5 className="bold-text">Core Team</h5>
                        </div>
                        <Row>
                          {/*{*/}
                          {/*  this.state.ido ?*/}
                          {/*    this.state.ido.team.map((item, index) => {*/}
                          {/*      return (*/}
                          {/*        <Col md={3} key={index}>*/}
                          {/*          <Card>*/}
                          {/*            <div className="text-center">*/}
                          {/*              <div><img className="team-avatar" src={item.photo} /></div>*/}
                          {/*              <p><b>{item.name}</b></p>*/}
                          {/*              <p><small>{item.who}</small></p>*/}
                          {/*              <a href={item.socials.LinkedIn} target='_blank' rel='noopener noreferrer'><i className="fa fa-linkedin"></i></a>*/}
                          {/*            </div>*/}
                          {/*          </Card>*/}
                          {/*        </Col>*/}
                          {/*      )*/}
                          {/*    })*/}
                          {/*  : null*/}
                          {/*}*/}
                        </Row>

                        <div className="card__title">
                          <h5 className="bold-text">Advisors</h5>
                        </div>
                        <Row>
                          {/*{*/}
                          {/*  this.state.ido && this.state.ido.advisors ?*/}
                          {/*    this.state.ido.advisors.map((item, index) => {*/}
                          {/*      return (*/}
                          {/*        <Col md={3} key={index}>*/}
                          {/*          <Card>*/}
                          {/*            <div className="text-center">*/}
                          {/*              <div><img className="team-avatar" src={item.photo} /></div>*/}
                          {/*              <p><b>{item.name}</b></p>*/}
                          {/*              <p><small>{item.who}</small></p>*/}
                          {/*              <a href={item.socials.LinkedIn} target='_blank' rel='noopener noreferrer'><i className="fa fa-linkedin"></i></a>*/}
                          {/*            </div>*/}
                          {/*          </Card>*/}
                          {/*        </Col>*/}
                          {/*      )*/}
                          {/*    })*/}
                          {/*  : null*/}
                          {/*}*/}
                        </Row>

                        <div className="card__title">
                          <h5 className="bold-text">KYC Report {this.state.ido && this.state.ido.totals.kyc ? `(${this.state.ido.totals.kyc.length})` : null}</h5>
                        </div>
                        <Row>
                          {
                            this.state.ido && this.state.ido.totals.kyc ?
                              this.state.ido.totals.kyc.map((item, index) => {
                                return (
                                  <Col md={3} key={index}>
                                    <Card>
                                      <div className="text-center">
                                        <p><b>{item[0]}</b></p>
                                        <p><small className="text-success">{item[1]}</small></p>
                                      </div>
                                    </Card>
                                  </Col>
                                )
                              })
                            : null
                          }
                        </Row>
                      </TabPane>

                      <TabPane tabId="3">
                        <br/>
                        <div className="timeline">
                          {
                            this.state.ido ?
                              this.state.ido.milestones.map((item, index) => {
                                return (
                                  <TimeLineItem img={icon_logo} title={item.date} key={index}>
                                    <p>{item.stages}</p>
                                  </TimeLineItem>
                                )
                              })
                            : null
                          }

                          {/*<TimeLineItem img={icon_logo} title={item.date}>
                            <p dangerouslySetInnerHTML={{__html: item.stages}}></p>
                          </TimeLineItem>*/}

                          {/*<TimeLineItem img={icon_logo} title="Manage multi-signatures">
                            <p>Manage multi-signatures (getting xdr transactions for signing or sending for signature). Multisig Coordinator.
                            </p>
                          </TimeLineItem>*/}

                        </div>
                      </TabPane>

                      <TabPane tabId="4">
                        <p dangerouslySetInnerHTML={{__html: this.state.ido ? this.state.ido.project.conditions : ''}}></p>
                      </TabPane>

                      <TabPane tabId="5">
                        <Card>
                          <div className="card__title">
                            <h5 className="bold-text">Sell Offers ({this.state.offers ? this.state.offers.length : 0})</h5>
                            <h5 className="subhead">Totals: {this.state.ido ? this.getBaseAsset(this.state.ido.project.sell_asset) : '...'}:{' '}
                              <b className="text-info">{numFormat(this.state.total_counter_amount)}</b> / {this.state.ido ? this.getBaseAsset(this.state.ido.base_asset) : '...'}:{' '}
                              <b className="text-info">{numFormat(this.state.total_base_amount)}</b> / <b className="text-warning">USD</b>:{' '}
                              <b className="text-info">{numFormat(this.state.total_usd_amount)}</b>
                            </h5>
                          </div>

                          {
                            this.state.offers && this.state.load_trades ?
                              <Table striped responsive hover>
                                <thead>
                                <tr>
                                  {/*<th>#</th>*/}
                                  <th>Amount {counter_asset_code}</th>
                                  <th>Price {base_asset_code}</th>
                                  <th>Amount {base_asset_code}</th>
                                  {
                                    base_asset_code !== 'XLM' ?
                                      <th>Amount XLM</th>
                                      : null
                                  }
                                  <th>Amount USD</th>
                                </tr>
                                </thead>
                                <tbody>

                                { this.state.offers && this.state.load_trades ?
                                  this.state.offers.map(this.renderSellOffers)
                                  : null
                                }

                                </tbody>
                              </Table>
                              :
                                <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card>
                          }

                        </Card>
                      </TabPane>

                      <TabPane tabId="6">
                        <div className="card__title">
                          <h5 className="bold-text">Last Trades</h5>
                        </div>

                        {
                          this.state.load_trades ?
                            <Trades items={this.state.trades} />
                          :
                            <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card>
                        }

                        {/*{*/}
                        {/*  this.state.load_trades ?*/}
                        {/*    <div className="sm-trades">*/}
                        {/*      <Trades items={this.state.trades} />*/}
                        {/*    </div>*/}
                        {/*  : null*/}
                        {/*}*/}
                      </TabPane>

                      {/*<TabPane tabId="7">
                        <p>Direction has strangers now believing. Respect enjoyed gay far exposed parlors towards. Enjoyment
                          use tolerably dependent listening men. No peculiar in handsome together unlocked do by. Article
                          concern joy anxious did picture sir her.
                        </p>
                      </TabPane>*/}
                    </TabContent>
                  </div>
                </div>

              </CardBody>
            </Card>

          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card style={{height: 'auto'}}>
              <CardBody>
                <div className="card__title" style={{marginBottom: '27px'}}>
                  <h5 className="bold-text">SCOP on exchanges</h5>
                  <h5 className="subhead">SCOP is available for trading on any Stellar wallets and exchanges</h5>
                </div>

                <Row>
                  <Col md={2}>
                    <div className="text-center">
                      <a
                        href="https://scopuly.com/trade/SCOP-XLM/GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ/native"
                        target="_blank">
                        <img className="img-exchange" src="https://scop.scopuly.com/images/logo/logo_500x200_dark.png" alt="brand-logo"/>
                      </a>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="text-center">
                      <a
                        href="https://lobstr.co/trade/native/SCOP:GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ"
                        target="_blank">
                        <img className="img-exchange" src="https://scop.scopuly.com/images/logos/Logos-01.png" alt="brand-logo"/>
                      </a>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="text-center">
                      <a
                        href="https://www.stellarx.com/markets/native/SCOP:GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ"
                        target="_blank">
                        <img className="img-exchange" src="https://scop.scopuly.com/images/logos/Logos-02.png" alt="brand-logo"/>
                      </a>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="text-center">
                      <a
                        href="https://stellarterm.com/exchange/XLM-native/SCOP-GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ"
                        target="_blank">
                        <img className="img-exchange" src="https://scop.scopuly.com/images/logos/Logos-03.png" alt="brand-logo"/>
                      </a>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="text-center">
                      <a
                        href="https://nicetrade.co/trade/SCOP-GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ/native"
                        target="_blank">
                        <img className="img-exchange" src="https://scop.scopuly.com/images/logos/Logos-13.png" alt="brand-logo"/>
                      </a>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="text-center">
                      <a
                        href="https://stellarport.io/exchange/GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ/SCOP/native/XLM"
                        target="_blank">
                        <img className="img-exchange" src="https://scop.scopuly.com/images/logos/Logos-04.png" alt="brand-logo"/>
                      </a>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card style={{height: 'auto'}}>
              <CardBody>
                {/*<div className="card__title">
                  <h5 className="bold-text">Social links</h5>
                </div>*/}

                <p className="text-center">
                  {
                    this.state.ido && this.state.ido.socials.Twitter ?
                      <a href={this.state.ido.socials.Twitter}
                         target='_blank'
                         rel='noopener noreferrer' className="footer-icon"><i className="fa fa-twitter"></i></a>
                      : null
                  }
                  {
                    this.state.ido && this.state.ido.socials.Reddit ?
                      <a href={this.state.ido.socials.Reddit}
                         target='_blank'
                         rel='noopener noreferrer' className="footer-icon"><i className="fa fa-reddit"></i></a>
                      : null
                  }
                  {
                    this.state.ido && this.state.ido.socials.Medium ?
                      <a href={this.state.ido.socials.Medium}
                         target='_blank'
                         rel='noopener noreferrer' className="footer-icon"><i className="fa fa-medium"></i></a>
                      : null
                  }
                  {
                    this.state.ido && this.state.ido.socials.Telegram ?
                      <a href={this.state.ido.socials.Telegram}
                         target='_blank'
                         rel='noopener noreferrer' className="footer-icon"><i className="fa fa-telegram"></i></a>
                      : null
                  }
                  {
                    this.state.ido && this.state.ido.socials.Facebook ?
                      <a href={this.state.ido.socials.Facebook}
                         target='_blank'
                         rel='noopener noreferrer' className="footer-icon"><i className="fa fa-facebook"></i></a>
                      : null
                  }
                  {
                    this.state.ido && this.state.ido.socials.VK ?
                      <a href={this.state.ido.socials.VK}
                         target='_blank'
                         rel='noopener noreferrer' className="footer-icon"><i className="fa fa-vk"></i></a>
                      : null
                  }
                  {
                    this.state.ido && this.state.ido.socials.Slack ?
                      <a href={this.state.ido.socials.Slack}
                         target='_blank'
                         rel='noopener noreferrer' className="footer-icon"><i className="fa fa-slack"></i></a>
                      : null
                  }
                  {
                    this.state.ido && this.state.ido.socials.BitcoinTalk ?
                      <a href={this.state.ido.socials.BitcoinTalk}
                         target='_blank'
                         rel='noopener noreferrer' className="footer-icon"><i className="fa fa-btc"></i></a>
                      : null
                  }
                  {
                    this.state.ido && this.state.ido.socials.GitHub ?
                      <a href={this.state.ido.socials.GitHub}
                         target='_blank'
                         rel='noopener noreferrer' className="footer-icon"><i className="fa fa-github"></i></a>
                      : null
                  }
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Footer />

      </Container>
    );
  }
}


Project.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'ieo_form',
})(translate('common')(Project));
