import React from 'react';
import moment from 'moment';
import axios from 'axios';
import swal from 'sweetalert';
import { ToastStore } from 'react-toasts';
import StellarSdk from 'stellar-sdk';
import CryptoJS from 'crypto-js';
import { GLOBE } from './globeVars/index';
import iconIdent from '../containers/Pages/Account/components/iconIdent';
import ResultCodes from "../endpoints/ResultCodes";
import { getFederationId } from "../endpoints/StellarAPI";
import getPrice from "../containers/App/GetPrice";
import freighterApi from "@stellar/freighter-api";
import albedo from '@albedo-link/intent'
import scopuly from '@scopuly/intent'



export const getBasePrice = async () => {
  let url = 'https://api.coingecko.com/api/v3/coins/stellar';
  let response = await fetch(url);
  return await response.json();
}

export function shortAddr(address, num = 4) {
	if (address) {
		var first = address.substring(0,num)
		var last = address.substr(-num);
		var short = first+'...'+last;
		return short;
	}
	else {
		return 0;
	}
}

export function shortAddress(address, target) {
	const name = getNameAddr(address);

	if (!address) return

	return (
		<a
      target={target ? target : null}
      href={`/account/${address}`}>{iconIdent(address, 'icon-indent')} <b>{shortAddr(address, 4)}</b> <small className="text-info">{name ? `[${name}]` : ''}</small></a>
	)
}

export function shortPool(pool_id, target) {

  if (!pool_id) return

  return (
    <a
      target={target ? target : null}
      className={'uppercase'}
      href={`/pool/${pool_id}`}>
      &nbsp;{iconIdent(pool_id, 'icon-indent')} {' '}
      <b>
        {/*{shortAddr(pool_id, 4)}*/}
        liquidity pool
      </b>
    </a>
  )
}

export function itemType(type) {
	return type.replace(/_/g," ")[0].toUpperCase() + type.replace(/_/g," ").slice(1);
}

export function numFormat(value, separ) {
	if (value) {
		var res, slice;
		if (typeof value === 'string') {
			value = Number(value).toFixed(separ)
			slice = value.slice(-1);
		}
		if (typeof value === 'number') {
			value = value.toFixed(separ)
			slice = value.slice(-1);
		}

		if (slice === '0') {
			value = parseFloat(value).toString();
		}

		var indexOf = value.indexOf('.') > -1;

		if (indexOf) {
			var a = value.split('.')[0];
			var b = value.split('.')[1];
			var replace = a.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,')
			res = (replace+'.'+b);
		}
		else {
			res = value.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,')
		}
		return res;
	}
	else {
		return 0;
	}
}

export function formatDate(time, type) {
	return moment(time).format(type === 'short' ? "DD/MM/YY HH:mm:ss" : "DD MMMM YYYY HH:mm:ss");
}


// export function get_my_wallets() {

//   let wallets_from_storage = localStorage.getItem('wallets');

//   let wallets_parse;

//   if (wallets_from_storage) {
//       wallets_from_storage = window.atob(wallets_from_storage);
//       wallets_parse = JSON.parse(wallets_from_storage);
//       //...

//       wallets_parse.forEach((item, index) => {

//         console.log('item: ', item)

//         if (Object.keys(item).length > 3) {

//           console.log('item3: ', item)

//           var bytes  = CryptoJS.AES.decrypt(window.atob(item.sk.toString()), item.pk);
//           item.sk = window.btoa(bytes.toString(CryptoJS.enc.Utf8));
//         }
//       })
//   } else {
//       wallets_parse = false;
//   }

//   return wallets_parse;
// }



export function get_loc_wallets() {

  let wallets_from_storage = localStorage.getItem('wallets');

  let wallets_parse = false;

  if (wallets_from_storage) {
      wallets_from_storage = window.atob(wallets_from_storage);
      wallets_parse = JSON.parse(wallets_from_storage);
      // console.log('wallets_parse: ', wallets_parse)

      wallets_parse.forEach((item, index) => {
        // console.log('get_loc_wallets item: ', item)

        if (typeof(item) === 'string') {
          delete wallets_parse[index];
          localStorage.setItem('wallets', window.btoa(JSON.stringify(wallets_parse)))
          get_loc_wallets()
          return
        }

        if (Object.keys(item).length > 3) {
          if (item.type === 'encrypt') {
            // console.log('get_loc_wallets item > 3: ', item)

            if (item.sk !== '') {

              var plaintext;
              var bytes  = CryptoJS.AES.decrypt(window.atob(item.sk.toString()), item.pk);
              // console.log('bytes: ', bytes);

              try {
                  plaintext = window.btoa(bytes.toString(CryptoJS.enc.Utf8));
                  // console.log('plaintext: ', plaintext);

                  if (plaintext) {
                    item.sk = plaintext;
                    // console.log('item.sk: ', item.sk);
                  }
                  else {
                    // console.log('plaintext bad');
                  }
              }
              catch (err) {
                  // console.log('err: ', err);
                  plaintext = "";
                  // ToastStore.error('Encryption error...');
              }

              // if (bytes) {
              //   if (window.btoa(bytes.toString(CryptoJS.enc.Utf8))) {
              //     item.sk = window.btoa(bytes.toString(CryptoJS.enc.Utf8));
              //     console.log('item.sk: ', item.sk);
              //   }
              // }
            }
            else {
              swal({
                title: 'Secret key not found!',
                text: `The secret key was not found on the ${shortAddr(item.pk, 4)} account. Try to de-authorize this account and then authorize it again.`,
                icon: 'warning'
              })
            }
          }
        }
      })
  }
  // else {
  //     wallets_parse = false;
  // }

  return wallets_parse;
}


export function baseReserve() {
	return 0.5;
}

export function minBalance() {
	return (baseReserve() * 2);
}

export function nativeBalance(account) {
	let native_balance = account.balances.filter(item => {
	  return item.asset_type === 'native';
	});

	return Number(native_balance[0].balance);
}


export function lessOneXLMAalert(account) {
	swal({
	  title: "Minimum Account Balance!",
	  text: `For transactions on the balance must be more than ${minBalance() + (account.subentry_count * baseReserve())} XLM`,
	  icon: "warning",
	});
}


const verifiCode = async (code) => {

  const gkey = localStorage.getItem('gkey');
  const email = localStorage.getItem('user');

  const bytes  = CryptoJS.AES.decrypt(gkey.toString(), email);
  const secret = bytes.toString(CryptoJS.enc.Utf8);

  const res = await axios(`${GLOBE.API_URL}/verify_auth_code/?code=${code}&secret=${secret}`);

  // if (res.data === 'FAILED') {
  //   ToastStore.error(`Incorrect value`);
  // }
  // else if (response.data === 'OK') {

  //   return 'accept';
  // }
}

export const getDirectory = async (addess) => {
  const res = await axios(`https://api.stellar.expert/api/explorer/public/directory/${addess}`);
  // console.log('res: ', res)
  if (res.data) {
    return res.data;
  }
}


// export const getRatingAsset = async (asset) => {
//
//   const res = await axios(`${GLOBE.API_URL}/get_rating_asset/?asset_code=${asset.asset_code}&asset_issuer=${asset.asset_issuer}`);
//   console.log('res: ', res)
//
//   // const res = await axios(`https://api.stellar.expert/api/explorer/public/asset/${asset.asset_code}-${asset.asset_issuer}/rating`);
//   //
//   // if (res.data) {
//   //   console.log('getRatiogAsset res: ', res)
//   //   return res.data;
//   // }
//
//   // axios.get(`https://api.stellar.expert/explorer/public/asset/${asset.asset_code}-${asset.asset_issuer}/rating`)
//   //   .then((response) => {
//   //
//   //     console.log('rating response: ', response)
//   //
//   //   })
//   //   .catch((error) => {
//   //     console.log(error);
//   //   });
// }


export const getClaimantStatus = (item, claimant) => {

  // console.log('item: ', item)
  // console.log('predicate: ', claimant.predicate)

  let status = 'Pending', end_time = ''

  if (claimant.predicate.and) {

    status = new Date() > new Date(claimant.predicate.and[0].not ? claimant.predicate.and[0].not.abs_before : claimant.predicate.and[0].abs_before) ?
      'Pending'
      : new Date() > new Date(claimant.predicate.and[1].not ? claimant.predicate.and[1].not.abs_before : claimant.predicate.and[1].abs_before) ? 'Expired' : 'Upcoming'
  }

  if (claimant.predicate.abs_before) {

    status = new Date() < new Date(claimant.predicate.abs_before) ?
      'Pending'
      : new Date() > new Date(claimant.predicate.abs_before) ? 'Expired' : 'Upcoming'
  }

  if (claimant.predicate.rel_before) {
    let rel_before = Number(claimant.predicate.rel_before)
    const created_at = new Date(item.created_at);
    created_at.setSeconds(created_at.getSeconds() + rel_before);
    end_time = created_at

    if (new Date(item.created_at) > new Date(end_time)) {
      status = 'Expired'
    }
  }

  if (claimant.predicate.not) {
    status = new Date() < new Date(claimant.predicate.not.abs_before) ?
      'Pending'
      : new Date() > new Date(claimant.predicate.not.abs_before) ? 'Expired' : 'Upcoming'
  }

  return {
    status, end_time
  }
}



export const getAuth = async () => {

	let auth_method = localStorage.getItem('2fa');

  if (!auth_method) {
    return 'need_deauth_all'
  }

	else if (auth_method === 'false') {
		return 'accept';
	}

	else if (auth_method === 'gooauth') {
		var enter_gcode = await swal({
      title: "Google Autenicator Code",
      text: `Enter the code from the Google Autenicator app.`,
      icon: "info",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter your Google-code (6 digits)",
          type: "number",
        },
      },
      buttons: {
        cancel: true,
        confirm: true,
      },
    })

    if (enter_gcode) {

      const code = enter_gcode;
      const gkey = localStorage.getItem('gkey');
      const email = localStorage.getItem('user');

      const bytes  = CryptoJS.AES.decrypt(gkey.toString(), email);
      const secret = bytes.toString(CryptoJS.enc.Utf8);

      const res = await axios(`${GLOBE.API_URL}/verify_auth_code/?code=${code}&secret=${secret}`);

      if (res.data === 'FAILED') {
        ToastStore.error(`Incorrect value`);
      }
      else if (res.data === 'OK') {

        return 'accept';
      }
    }
	}

	else if (auth_method === 'pincode') {
	  var enter_pin = await swal({
	    title: "PIN-code",
	    text: `Enter your PIN code to authorize this transaction.`,
	    icon: "info",
	    content: {
	      element: "input",
	      attributes: {
	        placeholder: "Enter your PIN-code (4 digits)",
	        type: "password",
	      },
	    },
	    buttons: {
	      cancel: true,
	      confirm: true,
	    },
	  })

	  if (enter_pin) {

	  	let format = 4;
	  	if (isNaN(enter_pin)) {
	  	  ToastStore.error('The value entered must be a number');
	  	}
	  	else if (enter_pin.length < format || enter_pin.length > format) {
	  	  ToastStore.warning(`PIN code must be ${format} digits.`);
	  	}
	  	else {

	  		let pin_text = localStorage.getItem('pin');

	  		let bytes  = CryptoJS.AES.decrypt(pin_text.toString(), auth_method);
	  		let pin = bytes.toString(CryptoJS.enc.Utf8);

	  	  if (pin === enter_pin) {
	  	    ToastStore.info('PIN code authorization!');

	  	    return 'accept';
	  	  }
	  	  else {
	  	    ToastStore.error('The entered PIN code does not match the one set.');
	  	  }
	  	}
	  }
	}
}


export const needDeauthAll = () => {
  swal({
    title: "Suspicious activity!",
    text: `Attention! Detected suspicious activity in your browser. For security reasons, deauthorize all addresses. Then, if necessary, authorize them. Deauthorize all addresses right now?`,
    icon: "warning",
    buttons: {
      cancel: true,
      confirm: true,
    },
  })
  .then((confirm) => {
    if (confirm) {

      swal({
        title: "Confirmation",
        text: `Deauthorize all Addresses right now?`,
        icon: "warning",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
      .then((confirm) => {
        if (confirm) {
          localStorage.removeItem('wallets');
          ToastStore.success("All Accounts have been successfully deauthorized. Now you can authorize them again.");
        }
      })
    }
  })
}

export const setPairToLocal = (pair) => {

  let local_pairs = localStorage.getItem('pairs');

  if (!local_pairs) {
    let pairs = [];
    pairs.push(pair);
    pairs = JSON.stringify(pairs);
    localStorage.setItem('pairs', pairs);
  }
  else {
    local_pairs = JSON.parse(local_pairs);

    local_pairs.forEach((item, index) => {
      if (item.sell_asset.asset_code === pair.sell_asset.asset_code && item.sell_asset.asset_issuer === pair.sell_asset.asset_issuer) {
        if (item.buy_asset.asset_code === pair.buy_asset.asset_code && item.buy_asset.asset_issuer === pair.buy_asset.asset_issuer) {
          local_pairs.splice(index, 1);
        }
      }
    })

    local_pairs.push(pair);
    local_pairs = JSON.stringify(local_pairs);
    localStorage.setItem('pairs', local_pairs);

    // if (!is_item) {
    //
    //   local_pairs.unshift(pair);
    //   local_pairs = JSON.stringify(local_pairs);
    //
    //   localStorage.setItem('pairs', local_pairs);
    // }
  }
}


export const getNameAddr = (address) => {

  let local_favorites = localStorage.getItem('favorites');

	let name = false;

  if (local_favorites) {
    local_favorites = JSON.parse(local_favorites);

    local_favorites.addresses.forEach((item, index) => {
      if (item.address === address) {
        name = item.name;
      }
    });
  }

	// my wallets
	if (!name) {
		const wallets = get_loc_wallets();

		if (wallets) {
			wallets.forEach((item, index) => {
				if (item.pk === address) {
					name = item.title;
				}
			});
		}
	}

	return name;
}



export const checkAuth = async () => {

  const wallets = get_loc_wallets();

  if (!wallets) {
    var alert = await swal({
      title: 'Address not authorized',
      text: 'To continue, you need to authorize Wallet. Do you want to authorize the Wallet right now?',
      icon: 'info',
      buttons: {
        cancel: true,
        confirm: true,
      },
    })

    if (alert) {
      return true;
    }
  }
}


export const getTitle = (title, description) => {
  window.document.title = title + ' | Scopuly';
  window.document.querySelector('meta[property="og:title"]').setAttribute("content", title);

  if (description) {
    window.document.querySelector('meta[name="description"]').setAttribute("content", description);
    window.document.querySelector('meta[property="og:description"]').setAttribute("content", description);
  }

}


export const signTransaction = (signers, transaction) => {
  // console.log('signTransaction: ', transaction)

  const wallets = get_loc_wallets();
  wallets.map((wallet_item) => {
    signers.map((item, index) => {

      if (item === wallet_item.pk ) {
        transaction.sign(StellarSdk.Keypair.fromSecret(atob(wallet_item.sk)));
      }
    })
  });

  return transaction;
}


export const showErrorMessage = (error, type) => {
  console.error('error: ', error);
  console.error('error.response: ', error.response);
  console.log('type: ', type)

  let message, data, tx_code, title;

  if  (!error.response) {
    title = 'Error';
    const invalidEncoded = 'Error: invalid encoded string'
    const errorToString = error.toString()
    message = `<div>${errorToString} ${errorToString === invalidEncoded ? '<span><br/><b>Try to deauthorize the wallet and sign in again.</b></span>' : '<span></span>'}</div>`;
  }
  else if (error.response.data) {
    if (error.response.data.extras) {
      data = error.response.data.extras;
      tx_code = data.result_codes.operations ? data.result_codes.operations[0] : data.result_codes.transaction;

      console.log('tx_code: ', tx_code)
      console.log('ResultCodes: ', ResultCodes)
      console.log('ResultCodes[tx_code]: ', ResultCodes[tx_code])

      let resultCodes;
      if (!ResultCodes[tx_code]) {
        if (type === 'payment' || type === 'path_payment') {
          resultCodes = ResultCodes.path_payment;
        }
        if (type === 'manage_offer') {
          resultCodes = ResultCodes.manage_offer;
        }
        if (type === 'change_trust') {
          resultCodes = ResultCodes.change_trust;
        }
        if (type === 'claim') {
          resultCodes = ResultCodes.claim;
        }
        if (type === 'liquidity') {
          resultCodes = ResultCodes.liquidity;
        }
      } else {
        resultCodes = ResultCodes;
      }

      console.log('resultCodes: ', resultCodes)

      message = `<div>${resultCodes[tx_code] ? resultCodes[tx_code] : 'Incorrect data entered. Check the entered values.'}</div>
               <div><p>Error code: <b>${tx_code}</b></p></div>`;
    }
    else {
      message = `<div><p>Error code: <b>${error.response.data.title}</b></p></div>`;
    }

    title = error.response.data.title
  }
  else if (error.response) {
    title = error.response.title;
    message = `<div><p>${error.response.detail}</p></div>`;
  }


  const element = document.createElement("span");
  element.innerHTML = message;

  swal({
    title: title,
    content: element,
    icon: 'error'
  });
}


export const searchProcess = (value) => {

  const length = value.length;
  const domain = value.split('*')[1];
  const reg = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
  const validDomain = reg.test(domain)

  if (length === 56) {
    window.location.href = window.location.origin+'/account/'+value;
  }
  else if (validDomain) {
    getFederation(value)
  }
  else if (length <= 12 && isNaN(value)) {
    window.location.href = window.location.origin+'/assets/'+value;
  }
  else if (length <= 12 && !isNaN(value)) {
    window.location.href = window.location.origin+'/offer/'+value;
  }
  else if (length === 64) {
    window.location.href = window.location.origin+'/transaction/'+value;
  }
  else if (!isNaN(value) && length > 17) {
    window.location.href = window.location.origin+'/operation/'+value;
  }

  function getFederation(name) {

    getFederationId(name)
      .then(result => {
        window.location.href = window.location.origin+'/account/'+result.account_id;
      })
      .catch(error => {
        console.log('error: ', error)
        ToastStore.error('Federation name not found...');
      });
  }
}


export const getAsset = (item, image, color) => {
  // console.log('getAsset item: ', item)

  let asset = {}

  if (typeof item !== 'object' && item.indexOf(':') + 1) {
    const assetSplit = item.split(':')

    if (assetSplit[0] === 'XLM' && assetSplit[1] === 'native') {
      asset.asset_type = 'native'
    }
    asset.asset_code = assetSplit[0]
    asset.asset_issuer = assetSplit[1]
  }
  else if (item === 'native' || item.asset_issuer === 'native') {
    asset.asset_type = 'native'
    asset.asset_code = 'XLM'
    asset.asset_issuer = 'native'
  }
  // else if (item.source_asset_type === 'native') {
  //   asset.asset_type = 'native'
  //   asset.asset_code = 'XLM'
  //   asset.asset_issuer = 'native'
  // }
  // else if (item.source_asset_type && item.source_asset_type !== 'native') {
  //   asset.asset_code = item.source_asset_code
  //   asset.asset_issuer = item.source_asset_issuer
  // }
  else {

    asset = item
  }

  // console.log('asset: ', asset)

  const render_image = () => {
    if (image) {
      return <img src={image} className={'asset-icon-sm'} alt={asset.asset_code} />
    }
  }

  return <span>{render_image()} <a href={asset.asset_type === 'native' ? '/asset/XLM-native' : `/trade/${asset.asset_code}-XLM/${asset.asset_issuer}/native`} className={`text-${color ? color : 'warning' }`}><b>{asset.asset_type === 'native' ? 'XLM' : asset.asset_code}</b></a></span>
}


export const getSigner = (account) => {
  const wallets = get_loc_wallets()

  if (wallets) {
    return wallets.filter((item) => {
      return item.pk === account;
    })[0];
  }
  return false
}


export const checkConnectProvider = (sourceAddress) => {
  const wallets = get_loc_wallets()
  const connectProvider = wallets.some(item => item.provider && item.pk === sourceAddress)
  return connectProvider
}


export const signConnectProvider = (transaction, sourceAddress) => {

  const wallets = get_loc_wallets()
  const passPhrase = "Public Global Stellar Network ; September 2015"
  const connectProvider = wallets.filter(item => item.provider && item.pk === sourceAddress)

  // Freighter
  if (connectProvider[0].provider === "freighter") {
    const xdr = transaction.toEnvelope().toXDR('base64');

    async function signProvider() {
      return freighterApi.signTransaction(xdr)
    }

    return signProvider().then((result) => {
      return new StellarSdk.Transaction(result, passPhrase);
    })
    .catch(error => {
      errorConnectProvider(error)
    });
  }

  //  Albedo
  if (connectProvider[0].provider === "albedo") {
    const xdr = transaction.toEnvelope().toXDR('base64');

    return albedo.tx({ xdr })
      .then(res => {
        console.log(res.xdr, res.tx_hash, res.signed_envelope_xdr, res.network, res.result)

        return new StellarSdk.Transaction(res.signed_envelope_xdr, passPhrase);
      })
      .catch(error => {
        errorConnectProvider(error.message)
      })
  }

  //  Scopuly
  if (connectProvider[0].provider === "scopuly") {
    const xdr = transaction.toEnvelope().toXDR('base64');

    return scopuly.tx({ xdr })
      .then(res => {
        console.log(res.xdr, res.tx_hash, res.signed_envelope_xdr, res.network, res.result)

        return new StellarSdk.Transaction(res.signed_envelope_xdr, passPhrase);
      })
      .catch(error => {
        errorConnectProvider(error.message)
      })
  }

  // Rabet
  if (connectProvider[0].provider === "rabet") {
    const xdr = transaction.toEnvelope().toXDR('base64');
    const network = 'mainnet'; // testnet

    return  window.rabet.sign(xdr, network)
      .then(result => {
        return new StellarSdk.Transaction(result.xdr, passPhrase);
      })
      .catch(error => {
        console.error(`Error: ${error.message}`)
        errorConnectProvider(error.message)
      });
  }

  // Scopuly

  function errorConnectProvider(message) {
    swal({
      title: "Error",
      text: message,
      icon: "error",
    })
  }
}


export const getIconConnectProvider = (itemWallet) => {
  if (itemWallet && itemWallet.provider) {
    return <img src={`/img/icons/auth/${itemWallet.provider}.svg`} className={'provider-sm-icon'} alt={itemWallet.provider} title={itemWallet.provider} />
  }
}

export const passPhrase = () => {
  return "Public Global Stellar Network ; September 2015"
}

export const getLockStatus = (account) => {

  let med = account.thresholds.med_threshold;
  let signers_weight = 0;
  let status

  account.signers.forEach(function (item) {
    signers_weight = (item.weight + signers_weight);
  });

  if (signers_weight === 0 ) { // false emission
    status = true
  }
  else if (signers_weight < med ) { // false emission
    status = true
  }
  else { // true emission
    status = false
  }

  return status
}

export const formatCount = (count, withAbbr = false, decimals = 2) => {
  const COUNT_ABBRS = [ '', 'K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y' ];
  const i     = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
  let result  = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
  if (withAbbr) {
    result += `${COUNT_ABBRS[i]}`;
  }
  return result;
}


export const change7d = (val) => {

  var today = Number((1 / val.records[0].close).toFixed(7));
  var week = Number((1 / val.records[6].close).toFixed(7));

  if (today > week) {
    more();
  }
  else if (today === week) {
    val.change_7d_color = 'secondary';
    val.change7d = '0.00';
  }
  else {
    less();
  }

  function more() {
    // val.sign = '+';
    val.change7d = '+'+Number(today / week * 100 - 100).toFixed(2);
    val.change_7d_color = 'success';
  }

  function less() {
    val.change7d = Number(100 - week * 100 / today).toFixed(2);
    val.change_7d_color = 'danger';
  }

  // 7d
  val.chart7d = [];
  val.records.forEach((item, index) => {
    if (index+1 <= 7) {
      var price = Number((item.close_r.d / item.close_r.n).toFixed(7));
      if (val.records[0].close > item.close) {
        price = (price * 1.5).toFixed(7)
      }
      else if (val.records[0].close < item.close) {
        price = (price / 1.5).toFixed(7)
      }
      val.chart7d.unshift({ name: index, price: price });
    }
  });

  return val;
}


export const change24 = (val) => {
  // console.log('val: ', val)

  var today = Number((1 / val.records[0].close).toFixed(6));
  var yestoday = Number((1 / val.records[val.records.length === 1 ? 0 : 1].close).toFixed(7));

  if (today > yestoday) {
    more();
  }
  else if (today === yestoday) {
    val.change_24h_color = 'secondary';
    val.change24 = '0.00';
  }
  else {
    less();
  }

  function more() {
    // val.sign = '+';
    val.change24 = '+'+Number(today / yestoday * 100 - 100).toFixed(2);
    val.change_24h_color = 'success';
  }

  function less() {
    val.change24 = Number(100 - yestoday * 100 / today).toFixed(2);
    val.change_24h_color = 'danger';
  }

  return val;
}


export const setLocalWallets = (walets) => {

  const stringify_wallets = JSON.stringify(walets);
  const base64_wallets = window.btoa(stringify_wallets);
  localStorage.setItem('wallets', base64_wallets);
}


export const parseNumber = (x) => {

  if (Math.abs(x) < 1.0) {
    let e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10,e-1);
      x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10,e);
      x += (new Array(e+1)).join('0');
    }
  }
  return x;
}

export const getPoolIdFromAssets = (base_asset, counter_asset) => {

  const orderAssets = (A, B) => {
    return (StellarSdk.Asset.compare(A, B) <= 0) ? [A, B] : [B, A];
  }

  const assetA = base_asset.asset_issuer === 'native' ? new StellarSdk.Asset.native() : new StellarSdk.Asset(base_asset.asset_code, base_asset.asset_issuer)
  const assetB = counter_asset.asset_issuer === 'native' ? new StellarSdk.Asset.native() : new StellarSdk.Asset(counter_asset.asset_code, counter_asset.asset_issuer)

  const [ A, B ] = orderAssets(...[assetA, assetB]);
  const poolShareAsset = new StellarSdk.LiquidityPoolAsset(A, B, StellarSdk.LiquidityPoolFeeV18);
  const poolId = StellarSdk.getLiquidityPoolId("constant_product", poolShareAsset.getLiquidityPoolParameters()).toString("hex");

  return poolId
}
