import React from 'react';
import { Route, Switch } from 'react-router-dom';

import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';
import Landing from '../Landing/index';

import Home from '../Pages/Home/index';
import Signup from '../Pages/LogIn/Signup';
import Login from '../Pages/LogIn/Login';
import Forgot from '../Pages/LogIn/Forgot';
import Assets from '../Pages/Assets/index';
import Asset from '../Pages/Asset/index';
import IssuingAssets from '../Pages/Asset/components/IssuingAssets';
import NativeXLM from '../Pages/Asset/components/NativeXLM';
import Account from '../Pages/Account/index';
import AccountOffers from '../Pages/Account/components/AccountOffers';
import Trustlines from '../Pages/Account/components/Trustlines';
import Wallet from '../Pages/Wallet/index';
import Auth from '../Pages/Auth/index';
import Exchange from '../Pages/Exchange/index';
import Fiat from '../Pages/Fiat/index';
import FiatOrderbook from '../Pages/Fiat/components/FiatOrderbook';
import Send from '../Pages/Send/index';
import Transaction from '../Pages/Transaction/index';
import Operation from '../Pages/Operation/index';
import Offer from '../Pages/Offer/index';
import Search from '../Pages/Search/index';
import Favorites from '../Pages/Favorites/index';
import Contacts from '../Pages/Contacts/index';
import Settings from '../Pages/Settings/index';
import Help from '../Pages/Help/index';
import Dashboard from '../Pages/Dashboard/Dashboard';
import Trading from '../Pages/Trading/Trading';
import Launchpad from '../Pages/Launchpad/Launchpad';
import Project from '../Pages/Launchpad/components/Project';
import Edit from '../Pages/Launchpad/components/Edit';
import Xdr from '../Pages/Xdr/Xdr';
import ForInvestors from '../Pages/ForInvestors/index';
import ForExchanges from '../Pages/ForExchanges/index';
import Info from '../Pages/Info/Info';
import Success from '../Pages/Success/Success';
import Trade from '../Pages/Trade/Trade';
import Receive from '../Pages/Receive/Receive';
import Sep0007 from '../Pages/SEP/SEP0007';
import Pay from '../Pages/SEP/Pay';
import Tx from '../Pages/SEP/Tx';
import PaymentRequests from '../Pages/PaymentRequests/index';
import PendingPayments from '../Pages/ClaimableBalances/index'
import Profile from '../Pages/Profile/Profile';
import MonitorBalances from '../Pages/MonitorBalances'
import Qadsan from '../Pages/Qadsan/Qadsan';
import CreateClaimableBalance from '../Pages/ClaimableBalances/CreateClaimable'
import LiquidityPools from '../Pages/LiquidityPools'
import LiquidityPool from '../Pages/LiquidityPools/components/LiquidityPool'
import Swap from '../Pages/Swap'


const wrappedRoutes = () => (
  <div>

    <Layout />
    <div className="container__wrap">

      {/*<Route path="/" exact component={Landing} />*/}
      <Route path="/" exact component={Home} />
      <Route path="/home" exact component={Home} />
      <Route path="/signup" exact component={Signup} />
      <Route path="/login" exact component={Login} />
      <Route path="/forgot" exact component={Forgot} />
      {/*<Route path="/assets" component={Assets} />*/}
      <Route path="/assets/:id?" component={Assets} />
      <Route path="/native" component={NativeXLM} />
      <Route path="/asset/:id" component={Asset} />
      <Route path="/issuing" component={IssuingAssets} />
      <Route path="/create-token" component={IssuingAssets} />
      <Route path="/account/:id" component={Account} />
      <Route path="/wallet" component={Wallet} />
      {/*<Route path="/auth" component={Auth} />*/}
      <Route path="/auth/:id" component={Auth} />
      <Route path="/exchange" component={Exchange} />
      <Route path="/fiat" component={Fiat} />
      <Route path="/fiat_orderbook" component={FiatOrderbook} />

      <Route path="/send" component={Send} />
      <Route path="/payments" component={Send} />
      {/*<Route path="/send/:id" component={Send} />*/}
      <Route path="/transaction/:id" component={Transaction} />
      <Route path="/operation/:id" component={Operation} />
      {/*<Route path="/offers" component={AccountOffers} />*/}
      <Route path="/offers/:id?" component={AccountOffers} />
      <Route path="/offer/:id" component={Offer} />
      <Route path="/balances/:id" component={Trustlines} />

      <Route path="/explorer" component={Search} />
      <Route path="/search" component={Search} />
      {/*<Route path="/search/:id" component={Search} />*/}
      <Route path="/watchlist" component={Favorites} />
      <Route path="/contacts" component={Contacts} />
      <Route path="/settings" component={Settings} />
      <Route path="/support" component={Help} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/trading" component={Trading} />
      <Route path="/launchpad" component={Launchpad} />
      <Route path="/ieo/:id" component={Project} />
      <Route path="/launch/:id" component={Project} />
      <Route path="/edit/:id" component={Edit} />
      <Route path="/xdr" component={Xdr} />
      <Route path="/forinvestors" component={ForInvestors} />
      <Route path="/forexchanges" component={ForExchanges} />
      <Route path="/info/:id" component={Info} />
      <Route path="/success" component={Success} />
      <Route path="/trade/:id?" component={Trade} />
      <Route path="/receive/:id?" component={Receive} />
      <Route path="/pay/:id?" component={Pay} />
      <Route path="/tx/:id?" component={Tx} />
      <Route path="/sep-0007/:id" component={Sep0007} />
      <Route path="/invoices/:id?" component={PaymentRequests} />
      <Route path="/pending-payments/:id?" component={PendingPayments} />
      <Route path="/claimable-balance/:id?" component={PendingPayments} />
      <Route path="/create-claimable-balance/:id?" component={CreateClaimableBalance} />
      <Route path="/profile" component={Profile} />
      <Route path="/monitor-balances" component={MonitorBalances} />
      <Route path="/pools/" component={LiquidityPools} />
      <Route path="/pool/:id" component={LiquidityPool} />
      <Route path="/swap/:id?" component={Swap} />

      <Route path="/qadsan" component={Qadsan} />

    </div>
  </div>
);


const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        {/*<Route path="/404" component={NotFound404} />
        <Route path="/lock_screen" component={LockScreen} />
        <Route path="/log_in" component={LogIn} />
        <Route path="/log_in_photo" component={LogInPhoto} />
        <Route path="/register" component={Register} />
        <Route path="/register_photo" component={RegisterPhoto} />*/}

        {/*<Route exact path="/" component={Landing} />*/}
        {/*<Route exact path="/about" component={Landing} />*/}
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
