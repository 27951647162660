import {Server} from "../../../../modules/stellar";
import StellarSdk from "stellar-sdk";
import {showErrorMessage} from "../../../../modules";
import {ToastStore} from "react-toasts";


export const massiveReClaims = async (sponsor, claimants, signer, limit, loadClaimArr) => {

  const sponsorAccount = await Server.loadAccount(sponsor).catch(function (err) {
    console.error(`Failed to load sponsor ${sponsor}: ${err}`)
  })
  if (!sponsorAccount) {
    return 'failed_to_load_sponsor'
  }


  // const claimants = []
  //
  // claimantsSponsor.map((item, index) => {
  //   console.log('item: ', item)
  //
  //   const claimBalance = StellarSdk.Operation.claimClaimableBalance({ balanceId: item.id });
  //
  //   if (!loadClaimArr) {
  //     if (index < limit) {
  //       claimants.push(claimBalance)
  //     }
  //   }
  //   else if (index >= limit) { // from Limit
  //     if (claimants.length < limit) {
  //       claimants.push(claimBalance)
  //     }
  //   }
  // })


  console.log('-- claimants -- : ', claimants)

  // return

  let tx = new StellarSdk.TransactionBuilder(sponsorAccount, {fee: StellarSdk.BASE_FEE})
    .addOperation(claimants[0])
    .addOperation(claimants[1])
    .addOperation(claimants[2])
    .addOperation(claimants[3])
    .addOperation(claimants[4])
    .addOperation(claimants[5])
    .addOperation(claimants[6])
    .addOperation(claimants[7])
    .addOperation(claimants[8])
    .addOperation(claimants[9])
    .addOperation(claimants[10])
    .addOperation(claimants[11])
    .addOperation(claimants[12])
    .addOperation(claimants[13])
    .addOperation(claimants[14])
    .addOperation(claimants[15])
    .addOperation(claimants[16])
    .addOperation(claimants[17])
    .addOperation(claimants[18])
    .addOperation(claimants[19])
    .addOperation(claimants[20])
    .addOperation(claimants[21])
    .addOperation(claimants[22])
    .addOperation(claimants[23])
    .addOperation(claimants[24])
    .addOperation(claimants[25])
    .addOperation(claimants[26])
    .addOperation(claimants[27])
    .addOperation(claimants[28])
    .addOperation(claimants[29])
    .addOperation(claimants[30])
    .addOperation(claimants[31])
    .addOperation(claimants[32])
    .addOperation(claimants[33])
    .addOperation(claimants[34])
    .addOperation(claimants[35])
    .addOperation(claimants[36])
    .addOperation(claimants[37])
    .addOperation(claimants[38])
    .addOperation(claimants[39])
    .addOperation(claimants[40])
    .addOperation(claimants[41])
    .addOperation(claimants[42])
    .addOperation(claimants[43])
    .addOperation(claimants[44])
    .addOperation(claimants[45])
    .addOperation(claimants[46])
    .addOperation(claimants[47])
    .addOperation(claimants[48])
    .addOperation(claimants[49])
    // .addOperation(claimants[50])
    // .addOperation(claimants[51])
    // .addOperation(claimants[52])
    // .addOperation(claimants[53])
    // .addOperation(claimants[54])
    // .addOperation(claimants[55])
    // .addOperation(claimants[56])
    // .addOperation(claimants[57])
    // .addOperation(claimants[58])
    // .addOperation(claimants[59])
    // .addOperation(claimants[60])
    // .addOperation(claimants[61])
    // .addOperation(claimants[62])
    // .addOperation(claimants[63])
    // .addOperation(claimants[64])
    // .addOperation(claimants[65])
    // .addOperation(claimants[66])
    // .addOperation(claimants[67])
    // .addOperation(claimants[68])
    // .addOperation(claimants[69])
    // .addOperation(claimants[70])
    // .addOperation(claimants[71])
    // .addOperation(claimants[72])
    // .addOperation(claimants[73])
    // .addOperation(claimants[74])
    // .addOperation(claimants[75])
    // .addOperation(claimants[76])
    // .addOperation(claimants[77])
    // .addOperation(claimants[78])
    // .addOperation(claimants[79])
    // .addOperation(claimants[80])
    // .addOperation(claimants[81])
    // .addOperation(claimants[82])
    // .addOperation(claimants[83])
    // .addOperation(claimants[84])
    // .addOperation(claimants[85])
    // .addOperation(claimants[86])
    // .addOperation(claimants[87])
    // .addOperation(claimants[88])
    // .addOperation(claimants[89])
    // .addOperation(claimants[90])
    // .addOperation(claimants[91])
    // .addOperation(claimants[92])
    // .addOperation(claimants[93])
    // .addOperation(claimants[94])
    // .addOperation(claimants[95])
    // .addOperation(claimants[96])
    // .addOperation(claimants[97])
    // .addOperation(claimants[98])
    // .addOperation(claimants[99])
    .setNetworkPassphrase(StellarSdk.Networks.PUBLIC)
    .setTimeout(180)
    .build();


  tx.sign(signer);

  console.log('tx sign: ', tx)

  // return

  const resClaim = await Server.submitTransaction(tx).catch( (error) => {
    console.error(`Tx submission failed: ${error}`)

    // showErrorMessage(error, 'claim')
    ToastStore.error(`Tx submission failed: ${error}`);
  });

  console.log('resClaim: ', resClaim)

  return resClaim

  // if (resClaim) {
  //
  //   this.getSponsorClaimants(false)
  //   this.getLastsTime()
  //
  //   this.setState({
  //     returned_balances: (this.state.returned_balances + 1),
  //     returned_amount: (Number(this.state.returned_amount) + Number(item.amount)),
  //   })
  // }

}
