import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody, Button, ButtonGroup, Table } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../../modules/stellar/index';
import { ToastContainer, ToastStore } from 'react-toasts';
import {shortAddr, get_loc_wallets, numFormat, showErrorMessage} from '../../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { GLOBE } from '../../../../modules/globeVars/index';
import swal from 'sweetalert';




class FiatOrderbook extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      fiat_orderbook: false,
      load_fiat_orderbook: false,
      loader: true,
      is_admin: false,
      load_is_admin: false,
      admin: {},
      payeer_tx_id: '',
      my_wallets: get_loc_wallets(),
      create_account: false,
      transaction: false,
    };
  }

  componentDidMount() {
    // this.getFiatOrderbook();
    this.checkAdmin();

    // curl "https://stellar.expert/explorer/public/asset/SKY-GB2WB73G5IWRXEUTJANAIF52JFTDXOXORAP4S5HZRW23TZG2ONDVZMVA"

    // axios.get(`${GLOBE.API_URL}/get_expert_data`)
    //   .then((response) => {
    //     if (response) {
    //       console.log('expert response: ', response);
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    // });
  }


  checkAdmin() {

    const wallets = this.state.my_wallets;

    // get admin address from db
    axios.get(`${GLOBE.API_URL}/get_admin_address`)
      .then((response) => {
        if (response) {

          this.setState({ load_is_admin: true, })

          if (wallets) {
            let admin = wallets.filter((item) => {
              return item.pk === response.data[0].address;
            });

            if (admin.length > 0) {

              this.setState({
                is_admin: true,
                admin: admin[0],
              })
              this.getFiatOrderbook();
            }
            else {
              swal({
                title: "No access to the requested URL",
                icon: "warning",
              })
            }
          }
          else {
            swal({
              title: "No authorized addresses",
              icon: "warning",
            })
          }
        }
      })
      .catch(function (error) {
        console.log(error);
    });
  }


  checkAccount = (item, index) => {

    ToastStore.info("Checking Recipient Account...");
    this.setState({ loader: true, });

    Server.loadAccount(item.address)
      .then((account) => {
        console.log('checkAccount: ', account)

        this.setState({ loader: false, });

        const order = this.state.order;
        console.log('order: ', order)

        const asset = order.buy_asset.split('-')
        console.log('asset: ', asset)

        let trust = account.balances.filter((balance) => {
          return balance.asset_code === asset[0] &&
              balance.asset_issuer === asset[1];
        });
        console.log('trust: ', trust)

        this.setState({
          trust: trust.length ? trust  : false,
          account
        },
          () => this.paymentBuild(item, index))
        //
        // if (trust.length) {
        //   ToastStore.success(`${this.state.asset.asset_code} asset is already on your balance`);
        // }
        // else {
        //   swal({
        //     title: "Adding a new balance",
        //     text: `Add ${this.state.asset.asset_code} asset to your balance?`,
        //     icon: "info",
        //     buttons: {
        //       cancel: true,
        //       confirm: true,
        //     },
        //   })
        //       .then((confirm) => {
        //         if (confirm)
        //           this.setTrust()
        //       })
        // }
      })
      .catch((error) => {
        console.log('error: ', error);
        console.log('error.response: ', error.response)

        this.setState({
          loader: false,
          create_account: true,
        },
            () => this.paymentBuild(item, index));
      });
  }


  getFiatOrderbook() {
    const $this = this;

    async function getOrderbook() {
      try {
        const response = await axios.get(GLOBE.API_URL+'/get_fiat_orderbook');

        $this.setState({
          fiat_orderbook: response.data,
          load_fiat_orderbook: true,
          loader: false,
        })
      }
      catch (error) {
        console.error(error);
      }
    }
    getOrderbook();
  }


  sendAsset(item, index) {

    this.setState({order: item})

    if (item.type === 'sell_fiat') {
      swal({
        title: "Confirmation",
        text: `Do you want to pay  this order (${item.amount_buy_asset} ${item.buy_asset.split('-')[0]}) right now?`,
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
      .then((confirm) => {
        if (confirm)
          this.checkAccount(item, index)
      })
    }
    else if (item.type === 'sell_crypto') {
      swal({
        title: `Send to PayeerID: ${item.payeer_id}`,
        text: `To pay for this order, send ${item.amount_buy_asset} ${item.buy_asset.split('-')[0]} to the Payeer account ${item.payeer_id}, then paste the Payeer transaction ID and click the button "Ok"`,
        icon: "info",
        content: "input",
        // buttons: ["Cancel", "I paid the order"],
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
      .then((value) => {
        if (value) {
          let min_length = 9;
          if (isNaN(value)) {
            ToastStore.error('The value entered must be a number');
          }
          else if (value.length < min_length) {
            ToastStore.error(`The number must contain more than ${min_length} characters`);
          }
          else {
            this.setState({ payeer_tx_id: value, });
            // this.paymentBuild(item, index);
            this.checkAccount(item, index)
          }
        }
      })
    }
  }


  paymentBuild(item, index) {
    // console.log('paymentBuild item: ', item)

    let asset;

    if (item.buy_asset === 'USD-fiat-fiat' || item.buy_asset === 'EUR-fiat-fiat' || item.buy_asset === 'RUB-fiat-fiat') {
      // pay from payeer
      // show Payeer ID
      // confirm payment -> close status
      this.updateOrder('', item.id, index);
    }
    else {
      // pay here
      // operation payment -> close status
      if (item.buy_asset === 'XLM-native-crypto') {
        asset = StellarSdk.Asset.native()
      }
      else {
        const asset_code = item.buy_asset.split('-')[0];
        const asset_issuer = item.buy_asset.split('-')[1];
        asset = new StellarSdk.Asset(asset_code, asset_issuer);
      }

      this.setState({ loader: true, })

      Server.loadAccount(this.state.admin.pk)
        .then((sourceAccount) => {
          console.log('sourceAccount admin: ', sourceAccount)

          if (this.state.create_account) {
            console.log('create_account ->')

            var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: StellarSdk.BASE_FEE,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
              .addOperation(StellarSdk.Operation.createAccount({
                destination: item.address,
                startingBalance: String(item.amount_buy_asset)
              }))
              .setTimeout(100)
              .build();
          }
          else {
            console.log('payment ->')

            var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: StellarSdk.BASE_FEE,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
              .addOperation(StellarSdk.Operation.payment({
                destination: item.address,
                asset: asset,
                amount: String(item.amount_buy_asset)
              }))
              .setTimeout(100)
              .build();
          }

          ToastStore.info(`Sending ${item.amount_buy_asset} ${item.buy_asset.split('-')[0]} to ${numFormat(item.address, 4)}`);

          transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.admin.sk)));
          return Server.submitTransaction(transaction);
        })
        .then((result) => {
          this.setState({ loader: false })

          swal({
            title: "Asset sent!",
            icon: "success",
          });

          this.updateOrder(result.hash, item.id, index);

          // Send user an email alert !!!!

        })
        .catch((error) => {

          this.setState({ loader: false })
          showErrorMessage(error, 'payment')
      });
    }
  }


  updateOrder(hash, order_id, index) {

    // console.log('updateOrder hash: ', hash);

    axios.get(`${GLOBE.API_URL}/order_complete/?order_id=${order_id}&hash=${hash}&payeer_tx_id=${this.state.payeer_tx_id}`)
      .then((response) => {
        if (response) {

          if (response.data === 'completed') {

            ToastStore.success('Order completed!');

            let orderbook = this.state.fiat_orderbook;
            orderbook.splice(index, 1);

            this.setState({ fiat_orderbook: [] })
            this.setState({ fiat_orderbook: orderbook })
          }
        }
      })
      .catch(function (error) {
        console.log(error);
    });
  }


  renderItem(item, index) {
    return (
      <tr key={index}>
        <td>{index+1}</td>
        <td><b className="text-warning">{item.sell_asset.split('-')[0]}</b></td>
        <td><b className="">{item.amount_sell_asset}</b></td>
        <td>{item.amount_fee}</td>
        <td><b className="text-warning">{item.buy_asset.split('-')[0]}</b></td>
        <td><b className="">{item.amount_buy_asset}</b></td>
        <td><Link to={`/account/${item.address}`}><b>{shortAddr(item.address, 4)}</b></Link></td>
        <td>{item.status}</td>
        <td>{item.create_date}</td>
        <td>{item.id}</td>
        <td>
          <Button outline size="sm"
                  color="success"
                  style={{marginBottom: '0'}}
                  onClick={() => this.sendAsset(item, index)}>
            <span className="lnr lnr-location" />
          </Button>
        </td>
      </tr>
    );
  }



  render() {
    console.log('this.state: ', this.state)

    return (
        <Container>

          <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

          <Row>
            <Col md={12}>
              <h3 className="page-title">Fiat Orderbook</h3>
             {/* <h4 className="subhead subhead-b" style={{marginBottom: '18px'}}>
                For Account: <a href={`/account/${this.props.match.params.id}`}><b>{shortAddr(this.props.match.params.id, 14)}</b></a>
              </h4>*/}
            </Col>
          </Row>

          <Row>
            <Col md={12}>

              {
                this.state.is_admin ?
                  <Card>
                   <CardBody>

                      { this.state.loader ? <div className="panel__refresh"><LoadingIcon /></div> : null }

                      <Table striped responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Sell Asset</th>
                            <th>Amount Sell</th>
                            <th>Fee</th>
                            <th>Buy Asset</th>
                            <th>Amount Buy</th>
                            <th>Address</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Order</th>
                            <th>Acton</th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            this.state.load_fiat_orderbook && this.state.fiat_orderbook.length > 0 ?
                              this.state.fiat_orderbook.map(this.renderItem.bind(this))
                            : null
                          }

                        </tbody>
                      </Table>

                   </CardBody>
                  </Card>
                : null
              }

            </Col>
          </Row>
        </Container>
    );
  }
}


FiatOrderbook.propTypes = {
  t: PropTypes.func.isRequired,
};


export default translate('common')(FiatOrderbook);
