import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import { shortAddr, numFormat, getNameAddr, getDirectory, getTitle, shortAddress } from '../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import moment from 'moment';
import { ToastContainer, ToastStore } from 'react-toasts';
import Operations from '../Account/components/Effects/Operations';
import Footer from '../../Layout/footer/Footer';
import {CopyToClipboard} from "react-copy-to-clipboard";



class Transaction extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      transaction: {},
      tx_load: false,
      operations: [],
      ops_load: false,
      source_dir_name: false,
    };
  }

  componentDidMount() {
    const hash = this.props.match.params.id;
    getTitle(`Transaction: ${hash}`);

    this.getTransaction(hash);
    this.getOperations(hash)
  }



  getTransaction(hash) {

    Server.transactions()
      .transaction(hash)
      .call()
      .then( (result) => {

        this.setState({
          transaction: result,
          tx_load: true,
        });

        getDirectory(result.source_account)
          .then(data => {
            // console.log('getDirectory data: ', data)

            this.setState({
              source_dir_name: data.name
            })
          })
          .catch(error => {
            console.log('getDirectory error: ', error)
        });
      })
      .catch( (err) => {
        console.log('err: ', err)
        ToastStore.error('Error receiving transaction...');
      })
  }


  getOperations(hash) {
    let $this = this;
    Server.operations()
      .forTransaction(hash)
      .limit(200)
      .order('desc')
      .call()
      .then(function (operationsResult) {

        $this.setState({
          operations: operationsResult.records,
          ops_load: true,
        })
      })
      .catch(function (err) {
        console.log(err)
        ToastStore.error('Error receiving operation...');
      })
  }


  formatDate(time) {
    return moment(time).format("DD MMMM YYYY HH:mm:ss");
  }



  render() {

    const tx = this.state.transaction;
    const name = getNameAddr(tx.source_account);

    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Row>
          <Col md={12}>
            <h3 className="page-title" style={{textTransform: 'none'}}>Transaction ID: {' '}
              <b className="text-info">{this.props.match.params.id}</b> &emsp;
              <CopyToClipboard text={this.props.match.params.id} onCopy={() => {}}>
                <i className="fa fa-clone"
                   color="secondary"
                   style={{cursor: 'pointer', fontSize: 14}}
                   onClick={() => ToastStore.success('Copied!')}></i>
              </CopyToClipboard>
            </h3>
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>

                <div className="card__title">
                  <h5 className="bold-text">Transaction</h5>
                  {/*<h5 className="subhead">Transaction ID: <span className="red-text">{this.props.match.params.id}</span></h5>*/}
                </div>

                <div>
                  { !this.state.tx_load ? <div className="panel__refresh" style={{height: '89%'}}><LoadingIcon /></div> : null }

                  <Row>
                    <Col md={6} lg={6}>
                      <p>Source account: {this.state.tx_load && shortAddress(tx.source_account)} {' '}
                        <CopyToClipboard text={tx.source_account} onCopy={() => {}}>
                          <i className="fa fa-clone"
                             color="secondary"
                             style={{cursor: 'pointer', fontSize: 14}}
                             onClick={() => ToastStore.success('Copied!')}></i>
                        </CopyToClipboard>
                      </p>
                      <p>Memo type: <b className="text-info">{ tx.memo_type } </b></p>
                      <p>Memo: <b className="text-info">{ tx.memo_type === "none" ? "none" :  tx.memo }</b></p>
                      <p>Ledger: <b className="text-info">{ tx.ledger_attr } </b></p>
                    </Col>
                    <Col md={6} lg={6}>
                      <p>Created at: <b className="text-info">{ this.state.tx_load ? this.formatDate(tx.created_at) : '...'} </b></p>
                      <p>Operations: <b className="text-info">{ tx.operation_count } </b></p>
                      <p>Fee paid: <b className="text-info">{ numFormat(tx.fee_charged / 10000000, 7) } <Link to="/XLM-native" className="text-warning">XLM</Link> </b></p>
                      <p>Sequence: <b className="text-info">{ tx.source_account_sequence } </b></p>
                    </Col>
                    <Col md={12} lg={12}>
                      <br/>
                      <p><b>Signatures ({tx.signatures ? tx.signatures.length : '...'}):</b></p>

                      { this.state.tx_load ? tx.signatures.map((item, index) => <p key={index}><b>{index+1}.</b> {item}</p>) : '' }
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>

                { !this.state.ops_load ? <div className="panel__refresh"><LoadingIcon /></div> : null }

                <div className="card__title">
                  <h5 className="bold-text">Operations ({this.state.operations ? this.state.operations.length : '...'})</h5>
                </div>

                {
                  this.state.ops_load ?
                    <Operations items={this.state.operations} />
                  :
                    null
                }

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Footer />

      </Container>
    );
  }
}


Transaction.propTypes = {
  t: PropTypes.func.isRequired,
};


export default translate('common')(Transaction);
