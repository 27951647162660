import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import Collapse from '../../../../shared/components/Collapse';
import { numFormat, formatDate } from '../../../../modules/index';



class Issuer extends PureComponent {

	constructor(props) {
	  super(props);
	  this.state = {
	    // activeTab: '1',
	  };
	}



	render() {

		return (
      <div>
        <div className="card__title">
          <h5 className="bold-text">Issuer Information</h5>
          <h5 className="subhead">Information extracted from <b>stellar.toml</b> from <a href={`https://${this.props.home_domain}`} target='_blank' rel='noopener noreferrer'><b>{this.props.home_domain}</b></a> domain</h5>
        </div>

        <div className="card__title">
          <h5 className="bold-text">DOCUMENTATION</h5>
        </div>
        {
          this.props.toml_full.DOCUMENTATION ?
            (
              !Array.isArray(this.props.toml_full.DOCUMENTATION) ?
                Object.keys(this.props.toml_full.DOCUMENTATION).map((item, index) => (
                  <p key={index}><b className="text-info">{item.toUpperCase()}:</b> {this.props.toml_full.DOCUMENTATION[item]}</p>
                ))
              : null
            )
          : null
        }

        <div className="card__title">
          <h5 className="bold-text">PRINCIPALS</h5>
        </div>
        {
          this.props.toml_full.PRINCIPALS ?
            this.props.toml_full.PRINCIPALS.map((item1, index) => {
              return (
                <div key={index}>
                  {
                    Object.keys(item1).map((item, index) => (
                      <p key={index}><b className="text-info">{item.toUpperCase()}:</b> {item1[item]}</p>
                    ))
                  }
                  <br/>
                </div>
              )
            })
          : null
        }

        <div className="card__title">
          <h5 className="bold-text">CURRENCIES</h5>
        </div>
        {
          this.props.toml_full.CURRENCIES ?
            this.props.toml_full.CURRENCIES.map((item1, index) => {
              // console.log('item1: ', item1)
              // console.log('Object.keys(item1): ', Object.keys(item1))

              return (
                <Col key={index} md={8}>
                  <div className="cur-index">{index+1}</div>
                  <Collapse title={item1.code} className="with-shadow">
                    {
                      Object.keys(item1).map((item, index) => {
                        // console.log('item: ', item)
                        return (
                          <p key={index}><b className="text-info">{item.toUpperCase()}:</b> {item1[item]}</p>
                        )
                      })
                    }
                  </Collapse>
                </Col>
              )
            })
          : null
        }

        <div className="card__title">
          <h5 className="bold-text">Source Toml</h5>
        </div>
        {
          this.props.asset ?
            <a href={this.props.asset._links.toml.href}><b>{this.props.asset._links.toml.href}</b></a>
          : null
        }
      </div>
		)
	}
}


export default translate('common')(Issuer);
