import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Button, Table } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import { Server } from '../../../../modules/stellar/index';
import StellarSdk from 'stellar-sdk';
import {
  shortAddr,
  get_loc_wallets,
  numFormat,
  getAuth,
  needDeauthAll,
  getTitle,
  baseReserve,
  checkConnectProvider, signConnectProvider, showErrorMessage
} from '../../../../modules/index';
import { ToastContainer, ToastStore } from 'react-toasts';
import LoadingIcon from 'mdi-react/LoadingIcon';
import swal from 'sweetalert';
import Footer from "../../../Layout/footer/Footer";

// getTitle(`Balances for Stellar Account`)

const renderTextField = ({
  input, label, type, multiline, rowsMax, meta: { touched, error }, children, select, inputType
}) => {
  return(
    <TextField
      className="material-form__field"
      label={label}
      error={touched && error}
      value={input.value}
      children={children}
      multiline={multiline}
      type={type}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  );
}



class BalancesEdit extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      loader_rm: false,
      my_address: false,
      account: false,
      load_account: false,
      balances_count: 0,
      offers: [],
      load_offers: false,
      signer: false,
      my_wallets: get_loc_wallets(),
      reserve_trustlines: 0,
      reserve_offers: 0,
    };
  }

  componentDidMount() {
    const account_id = this.props.match.params.id;

    getTitle(`Balances for Stellar Account ${shortAddr(account_id, 4)}`)

    this.getWallets(account_id);
    this.getAccount(account_id);
    this.getOffers(account_id);
  }


  getWallets(account_id) {
    var wallets = this.state.my_wallets;

    if (wallets) {
      var signer = wallets.filter((item) => {
        return item.pk === account_id;
      });

      this.setState({
        signer: signer,
      })

      wallets.forEach((wallet, index) => {
        if (wallet.pk === this.props.match.params.id) {
          this.setState({
            my_address: true,
          })
        }
      });
    }
  }


  getAccount(account_id) {

    Server.loadAccount(account_id)
      .then((account) => {

        if (account) {

          const balances_count = (account.balances.length-1)
          const reserve_trustlines = (balances_count * baseReserve());

          this.setState({
            account: account,
            load_account: true,
            balances_count,
            reserve_trustlines,
          })

          // this.getOffers(item.pk);
        }
      })
      .catch((err) => {
        console.error(err);
    })
  }


  getOffers(account_id) {

    Server.offers().forAccount(account_id)
      .order('desc')
      .call()
      .then((offerResult) => {

        this.setState({
          load_offers: true,
          offers: offerResult.records,
          reserve_offers: (offerResult.records.length * baseReserve()),
        })

      })
      .catch((err) => {
        console.error(err);
      })
  }


  getOffersCount(item_balance) {
    let count = 0;

    this.state.offers.forEach((item) => {
      if (item.buying.asset_code === item_balance.asset_code ||
          item.selling.asset_code === item_balance.asset_code) {
        count++;
      }
    })

    return count;
  }


  addBalance(values) {

    const address = this.props.match.params.id

    getAuth()
      .then((value) => {
        if (value === 'accept') {
          acceptSendTX();
        }
        else if (value === 'need_deauth_all') {
          needDeauthAll();
        }
      });

    const acceptSendTX = () => {

      if (!values.asset_code) {
        ToastStore.warning('Enter Asset Code');
      }
      else if (!values.asset_issuer) {
        ToastStore.warning('Enter Asset Issue');
      }
      else {

        this.setState({ loader: true })

        Server.loadAccount(address)
          .then((sourceAccount) => {

            var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: StellarSdk.BASE_FEE,
                networkPassphrase: StellarSdk.Networks.PUBLIC
              })
              .addOperation(StellarSdk.Operation.changeTrust({
                  asset: new StellarSdk.Asset(values.asset_code, values.asset_issuer),
              }))
              .setTimeout(100)
              .build();

            if (checkConnectProvider(address)) {
              return signConnectProvider(transaction, address).then(result => {
                return Server.submitTransaction(result);
              })
            }
            else {
              transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));
              return Server.submitTransaction(transaction);
            }
          })
          .then((result) => {
            this.setState({ loader: false })

            swal({
              title: `Balance ${values.asset_code} added!`,
              text: "The trustline (balance) has been successfully added to your Account.",
              icon: "success",
              confirm: true,
            })
            .then(confirm => {
              if (confirm) {
                window.location.reload();
              }
            });
          })
          .catch((error) => {
            console.error('Something went wrong!', error);

            this.setState({ loader: false })
            ToastStore.error('Transaction error');
        });
      }
    }
  }


  removeBalance(asset, balance) {

    if (balance > 0) {
      swal({
        title: 'Unable to delete',
        text: `To remove trustline, SKY balance must be 0 ${asset.asset_code}. Send all tokens to another address and retry the operation.`,
        icon: "warning",
      });
    }
    else {

      const message = `Remove Trustline to <b class="text-warning">${asset.asset_code}</b> right now?`
      const element = document.createElement("span");
      element.innerHTML = message;

      swal({
        title: "Remove Trustline",
        content: element,
        icon: "warning",
        confirm: true,
      })
      .then(confirm => {
        if (confirm) {
          acceptRemove();
        }
      });
    }

    const acceptRemove = () => {

      getAuth()
        .then((value) => {
          if (value === 'accept') {
            acceptSendTX();
          }
          else if (value === 'need_deauth_all') {
            needDeauthAll();
          }
        });

      const acceptSendTX = () => {
        const address = this.props.match.params.id

        this.setState({ loader_rm: true })

        Server.loadAccount(address)
          .then((sourceAccount) => {

            var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: StellarSdk.BASE_FEE,
                networkPassphrase: StellarSdk.Networks.PUBLIC
              })
              .addOperation(StellarSdk.Operation.changeTrust({
                  asset: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                  limit: '0'
              }))
              .setTimeout(100)
              .build();

            if (checkConnectProvider(address)) {
              return signConnectProvider(transaction, address).then(result => {
                return Server.submitTransaction(result);
              })
            }
            else {
              transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));
              return Server.submitTransaction(transaction);
            }
          })
          .then((result) => {

            this.setState({ loader_rm: false })

            swal({
              title: `Balance ${asset.asset_code} removed`,
              icon: "success",
              confirm: true,
            })
            .then(confirm => {

              this.setState({
                account: {
                  ...this.state.account,
                  balances: this.state.account.balances.filter(item =>
                    item.asset_code !== asset.asset_code && item.asset_issuer !== asset.asset_issuer)
                }
              });

              // if (confirm) {
              //   window.location.reload();
              // }
            });
          })
          .catch((error) => {
            console.error('Something went wrong!', error);

            this.setState({ loader_rm: false })
            showErrorMessage(error, 'change_trust')
        });
      }
    }
  }


  renderBalance = (item, index) => {
    console.log('item: ', item)

    if (item.asset_type !== 'native') {

      const offers_count = this.getOffersCount(item);
      const asset = {
        asset_code: item.asset_code,
        asset_issuer: item.asset_issuer,
      }

      return (
        <tr key={index}>
          <td>{index+1}</td>
          <td><Link to={`/trade/${item.asset_code}-XLM/${item.asset_issuer}/native`}><b className="text-warning">{item.asset_code}</b></Link></td>
          <td><b className="text-info">{numFormat(item.balance, 7)}</b></td>
          <td><Link to={`/account/${item.asset_issuer}`}><b>{shortAddr(item.asset_issuer, 4)}</b></Link></td>
          <td><b className={'text-info'}>{numFormat(item.buying_liabilities, 7)}</b></td>
          <td><b className={'text-info'}>{numFormat(item.selling_liabilities, 7)}</b></td>
          <td><Link to={`/offers/${this.props.match.params.id}/${item.asset_code}/${item.asset_issuer}`}><b>{offers_count}</b></Link></td>
          <td>{item.asset_type}</td>
          <td>{numFormat(item.limit, 2)}</td>
          {
            this.state.my_address ?
              <th><Button outline size="sm"
                          color="secondary"
                          style={{marginBottom: '0'}}
                          onClick={() => this.removeBalance(asset, item.balance)}>
                <span className="lnr lnr-cross-circle" />
              </Button></th>
            : null
          }
        </tr>
      )
    }
  }


  render() {

    console.log('state: ', this.state)

    const { handleSubmit, reset } = this.props;
    const { balances_count, offers, reserve_offers, reserve_trustlines, account } = this.state;

    return (
      <Container className="dashboard">

        <Row>
          <Col md={12}>
            <h3 className="page-title">Trustlines { account ? `(${balances_count})` : null }
              {
                offers.length ?
                  <span style={{float: 'right'}}>
                    <Link to={`/offers/${this.props.match.params.id}`} className={'btn btn-primary'} size="sm">Offers ({offers.length})</Link>
                  </span>
                  : null
              }
            </h3>
            <h4 className="subhead subhead-b">
              For Account: <Link to={`/account/${this.props.match.params.id}`}><b>{shortAddr(this.props.match.params.id, 4)}</b></Link>
            </h4>
          </Col>
        </Row>

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {
          this.state.balances_count > 0 ?
            <Row className="addr-list-wr">
              <Col md={12}>
                <Card>
                  <CardBody className={this.state.my_address ? 'my_elem' : null}>

                    { this.state.loader_rm ? <div className="panel__refresh" style={{height: '89%'}}><LoadingIcon /></div> : null }

                    { this.state.my_address ? <h4 className="subhead">My Account</h4> : null }

                    { account &&
                      <p>
                        Reserve <b className={'text-info'}>{ (reserve_offers + reserve_trustlines) }</b> <Link to={`/native`}><b className={'text-warning'}>XLM</b></Link>{' '}
                        <small>(Trustlines: <b>{ balances_count }</b> / <b className={'text-info'}>{ reserve_trustlines }</b> <b>XLM</b>, Offers: <b>{offers.length}</b> / <b className={'text-info'}>{ reserve_offers }</b> <b>XLM</b>)</small>
                      </p>
                    }
                    <br/>

                    <Table striped responsive style={{fontSize: 13}}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Asset Code</th>
                          <th>Balance</th>
                          <th>Asset Issuer</th>
                          <th>Buying</th>
                          <th>Selling</th>
                          <th>Offers</th>
                          <th>Asset type</th>
                          <th>Limit</th>
                          {this.state.my_address ? <th>Remove</th> : null}
                        </tr>
                      </thead>
                      <tbody>

                        { account.balances.map(this.renderBalance) }

                      </tbody>
                    </Table>

                  </CardBody>
                </Card>
              </Col>
            </Row>
          :
            !account ?
              <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> : null

        }


        {
          this.state.my_address ?
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>

                    { this.state.loader ? <div className="panel__refresh"><LoadingIcon /></div> : null }

                    <Col md={6}>
                      <div>
                        <div className="card__title">
                          <h5 className="bold-text">Add New Trustline</h5>
                          <h5 className="subhead">Adding a new <b>trust line</b> to an Asset</h5>
                        </div>

                        <form className="material-form" onSubmit={handleSubmit(this.addBalance.bind(this))}>

                          <div>
                            <Field
                              name="asset_code"
                              component={renderTextField}
                              label="Asset Code"
                            />
                          </div>

                          <div>
                            <Field
                              name="asset_issuer"
                              component={renderTextField}
                              label="Asset Issuer"
                            />
                          </div>

                          <br/>
                          <Button color="primary" type="submit">Add Balance</Button>
                        </form>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          : null
        }

        <Footer />

      </Container>
    );
  }
}


BalancesEdit.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'floating_labels_form',
})(translate('common')(BalancesEdit));
