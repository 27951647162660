import StellarSdk from 'stellar-sdk';
import { Server } from '../modules/stellar/index';
import swal from "sweetalert";
import {ToastStore} from "react-toasts";


export const getAccount = (address) => {

  return Server.loadAccount(address)
    .then((result) => result)
    .catch((error) => error)
}


export const getPendingPayments = (address, limit = 200, order = 'desc') => {

  return Server.claimableBalances()
    .claimant(address)
    .limit(limit)
    .order(order)
    .call()
    .then((result) => result)
    .catch((error) => error)
}



export const getFederationId = (name) => {
  return StellarSdk.FederationServer.resolve(name)
}

export const strictReceivePaths = (source_asset, destination_asset, counter_amount) => {
  return Server.strictReceivePaths(source_asset, destination_asset, counter_amount).call()
}

export const strictSendPaths = (source_asset, source_amount, destination_asset) => {
  return Server.strictSendPaths(source_asset, source_amount, destination_asset).call()
}

export const getOrderbook = (base_asset, counter_asset, limit = 10) => {
  return Server
    .orderbook(base_asset, counter_asset)
    .limit(limit)
    .call()
}

export const getOperationForTransaction = (hash, limit = 200, order = 'desc') => {
  return Server.operations()
    .forTransaction(hash)
    .limit(limit)
    .order(order)
    .call()
}

export const liquidityPools = (limit, order) => {
  return Server
    .liquidityPools()
    .limit(limit)
    .order(order)
    .call()
}

export const getLiquidityPool = (pool_id) => {
  return Server
    .liquidityPools()
    .liquidityPoolId(pool_id)
    .call()
}


export const effectsForPools = (pool_id, limit, order, cursor) => {
  return Server
    .effects()
    .forLiquidityPool(pool_id)
    .limit(limit)
    .order(order)
    .cursor(cursor)
    .call()
}

export const tradesForPools = (pool_id, limit, order) => {
  return Server
    .trades()
    .forLiquidityPool(pool_id)
    .limit(limit)
    .order(order)
    .call()
}

export const operationsForPools = (pool_id, limit, order) => {
  return Server
    .operations()
    .forLiquidityPool(pool_id)
    .limit(limit)
    .order(order)
    .call()
}

export const transactionsForPools = (pool_id, limit, order) => {
  return Server
    .transactions()
    .forLiquidityPool(pool_id)
    .limit(limit)
    .order(order)
    .call()
}

export const streamEffects = (pool_id) => {

  return Server.effects()
    .forLiquidityPool(pool_id)
    .cursor('now')
    .stream();
}

export const streamTrades = (pool_id) => {

  return Server.trades()
    .forLiquidityPool(pool_id)
    .cursor('now')
    .stream();
}

export const streamOperations = (pool_id) => {

  return Server.operations()
    .forLiquidityPool(pool_id)
    .cursor('now')
    .stream();
}

export const streamTransactions = (pool_id) => {

  return Server.transactions()
    .forLiquidityPool(pool_id)
    .cursor('now')
    .stream();
}

export const addLiquidity = (source, signer, poolId, reserveA, reserveB) => {
  const exactPrice = reserveA / reserveB;
  const minPrice = exactPrice - (exactPrice * 0.10); // 0.10
  const maxPrice = exactPrice + (exactPrice * 0.10); // 0.10

  console.log('exactPrice: ', exactPrice)
  console.log('minPrice: ', minPrice)
  console.log('maxPrice: ', maxPrice)

  return Server.submitTransaction(
    buildTx(source, signer,
      StellarSdk.Operation.liquidityPoolDeposit({
        liquidityPoolId: poolId,
        maxAmountA: reserveA,
        maxAmountB: reserveB,
        minPrice: minPrice.toFixed(7),
        maxPrice: maxPrice.toFixed(7),
      })
    )
  );
}

export const buildTx = (source, signer, ...ops) => {

  let tx = new StellarSdk.TransactionBuilder(source, {
    fee: StellarSdk.BASE_FEE,
    networkPassphrase: StellarSdk.Networks.PUBLIC,
    // withMuxing: true,
  });
  ops.forEach(op => tx.addOperation(op));
  tx = tx.setTimeout(100).build();
  tx.sign(signer);

  return tx;
}


// export const loadAccount = (account_id) => {
//   return StellarSdk.FederationServer.resolve(name)
// }
