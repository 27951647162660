/* eslint-disable react/no-children-prop */
import React, { PureComponent }  from 'react';
import { Redirect } from 'react-router-dom';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../../modules/stellar/index';
import { shortAddr, numFormat, get_loc_wallets, minBalance, baseReserve, getAuth, needDeauthAll, setPairToLocal, checkAuth } from '../../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from 'sweetalert';



const renderTextField = ({
  input, label, meta: { touched, error }, children, select, type
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class ExchangeForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      wallets: get_loc_wallets(),
      load_wallets: false,
      signer: '',

      load_account: false,
      account: {},
      reserve_balance: 0,

      native_balance: 0,
      counter_balance: 0,

      trades: [],
      load_trades: false,

      trade_agregation: [],
      load_trade_agregation: false,

      select_sell_asset: false,
      select_buy_asset: false,
      sell_asset: { asset_code: '' },
      buy_asset: { asset_code: '' },

      price_change: 0,
      amount_change: 0,
      total_change: 0,

      orderbook: [],
      load_orderbook: false,
      is_auth: false,
      price_reverse: false,
    };
  }

  componentDidMount() {

    this.getWallets();
    this.setTradePair();

  }


  getWallets(address) {
    const wallets = this.state.wallets;

    if (wallets) {
      var signer = wallets.filter((item) => {
        return item.pk === address;
      });

      this.setState({
        // wallets: wallets,
        signer: signer,
      })
    }
  }


  setTradePair() {

    const pathArray = window.location.pathname.split('/');

    if (pathArray.length === 3 && pathArray[2] !== '') {

      const assetsArray = pathArray[2].split(':');

      const sell_asset = assetsArray[0].split('-');
      const buy_asset = assetsArray[1].split('-');
      const address = assetsArray[2];

      this.getAccount(address);

      this.setState({
        sell_asset: {
          asset_code: sell_asset[0],
          asset_issuer: sell_asset[1],
        },
        buy_asset: {
          asset_code: buy_asset[0],
          asset_issuer: buy_asset[1],
        },
        address: address,
      });

      setTimeout(() => {
        this.checkGetTradeAgregation();
      }, 100)
    }
  }


  changeAddress(value) {

    let address = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        address = address + value[key];
      }
    }

    this.setState({
      address: address,
    })

    this.getAccount(address);
    this.getWallets(address);
    this.getOffers(address);
  }


  changeSellAsset(value) {
    let asset = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        asset = asset + value[key];
      }
    }

    let part = asset.split('-');

    const sell_asset = {
      asset_code: part[0],
      asset_issuer: part[1],
    }

    this.setState({
      select_sell_asset: true,
      sell_asset: sell_asset,
      buy_asset: {},
      price_change: 0,
      amount_change: 0,
      total_change: 0,
    })

    this.props.change('asset_price', 0)
    this.props.change('asset_amount', 0)
    this.props.change('asset_total', 0)

    this.props.select_asset({
      sell_asset: sell_asset,
      buy_asset: this.state.buy_asset
    });

    this.checkGetTradeAgregation();
  }


  changeBuyAsset(value) {
    let asset = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        asset = asset + value[key];
      }
    }

    let part = asset.split('-');
    const buy_asset = {
      asset_code: part[0],
      asset_issuer: part[1],
    }

    this.setState({
      select_buy_asset: true,
      buy_asset: buy_asset,
      // sell_asset:
    })

    this.props.select_asset({
      sell_asset: buy_asset,
      buy_asset: this.state.sell_asset
    });

    this.checkGetTradeAgregation();
  }


  checkGetTradeAgregation() {

    setTimeout(() => {

      if (this.state.buy_asset.asset_code && this.state.sell_asset.asset_code) {
        if (this.state.buy_asset.asset_code !== '' && this.state.sell_asset.asset_code !== '') {
          this.getTradeAggregation();
        }
      }
    }, 300)
  }


  getTradeAggregation() {
    var $this = this;

    let baseAsset, counterAsset;

    if (this.state.sell_asset.asset_code === 'XLM') {
      baseAsset = new StellarSdk.Asset.native();
      counterAsset = new StellarSdk.Asset(this.state.buy_asset.asset_code, this.state.buy_asset.asset_issuer);

      this.getOrderbook(baseAsset, counterAsset);
      this.getTradesPair(baseAsset, counterAsset);
    }
    else if (this.state.buy_asset.asset_code === 'XLM') {
      baseAsset = new StellarSdk.Asset.native();
      counterAsset = new StellarSdk.Asset(this.state.sell_asset.asset_code, this.state.sell_asset.asset_issuer);

      this.getOrderbook(baseAsset, counterAsset);
      this.getTradesPair(baseAsset, counterAsset);
    }
    else {
      baseAsset = new StellarSdk.Asset(this.state.buy_asset.asset_code, this.state.buy_asset.asset_issuer);
      counterAsset = new StellarSdk.Asset(this.state.sell_asset.asset_code, this.state.sell_asset.asset_issuer);

      this.getOrderbook(counterAsset, baseAsset);
      this.getTradesPair(counterAsset, baseAsset);
    }

    // this.getOrderbook(baseAsset, counterAsset);

    var startTime = 0;
    var endTime = 0;
    var resolution = 86400000;
    var offset = 0;

    Server.tradeAggregation(baseAsset, counterAsset, startTime, endTime, resolution, offset)
      .order('desc')
      .limit(2)
      .call()
      .then((result) => {

        // console.log('result: ', result)

        if (result.records.length > 0) {
          // const price_buy_asset = Number((result.records[0].close_r.D / result.records[0].close_r.N).toFixed(7));
          // console.log('price_buy_asset: ', price_buy_asset)

          let price;
          if (this.state.buy_asset.asset_code === 'XLM') {
            price = Number((result.records[0].close_r.n / result.records[0].close_r.d).toFixed(7));
          }
          else {
            price = Number((result.records[0].close_r.d / result.records[0].close_r.n).toFixed(7));
          }

          // console.log('price: ', price)

          this.setState({
            trades: result.records,
            load_trades: true,
            price_change: price,
            // price_test_buy_xlm: price_buy_asset,
            select_sell_asset: true,
            price_reverse: (1 / price),
          });

          this.getWallets(this.state.address);

          this.props.change('asset_price', price);
          this.props.change('select_address', this.state.address);
          this.props.change('select_sell_asset', this.state.sell_asset.asset_code+'-'+this.state.sell_asset.asset_issuer);
          this.props.change('select_buy_asset', this.state.buy_asset.asset_code+'-'+this.state.buy_asset.asset_issuer);


          loadAgregateForChart();
        }
        else {
          ToastStore.warning('No trades found for this exchange pair');
        }
      })
      .catch((err) => {
        console.log('err trades count', err);
        ToastStore.error('The trading history is not loaded');
    })

    function loadAgregateForChart() {

      var startTime = 0;
      var endTime = 0;
      var resolution = 86400000;
      var offset = 0;

      Server.tradeAggregation(baseAsset, counterAsset, startTime, endTime, resolution, offset)
        .order('desc')
        .limit(100)
        .call()
        .then(function(result) {

          if (result.records.length > 0) {

            let to_parent_data = {
              trade_agregation: result.records,
              buy_asset: $this.state.buy_asset,
              sell_asset: $this.state.sell_asset,
              address: $this.state.signer[0].pk,
            }

            $this.props.direct_exchange(to_parent_data);
          }
          else {
            ToastStore.warning('No trades found for this exchange pair');
          }
        })
        .catch(function(err){
          console.log('err trades count', err);
          ToastStore.error('The trading history is not loaded');
      })
    }
  }



  getShortOrderbook(baseAsset, counterAsset) {
    let $this = this;

    Server.orderbook(baseAsset, counterAsset)
      .limit(30)
      .call()
      .then(function(result) {

        if (result) {
          $this.props.getShortOrderbook(result);
        }

      })
      .catch(function(err) {
        console.log(err);
    })
  }

  getOrderbook(baseAsset, counterAsset) {
    let $this = this;

    this.getShortOrderbook(baseAsset, counterAsset)

    Server.orderbook(baseAsset, counterAsset)
      .limit(200)
      .call()
      .then((result) => {

        if (result) {
          this.props.getOrderbook(result);
        }

      })
      .catch(function(err) {
        console.log(err);
    })
  }



  getTradesPair(baseAsset, counterAsset) {
    const $this = this;

    // this.setState({ load_more: false })

    const ServerTrades = Server.trades()
      .forAssetPair(baseAsset, counterAsset)
      .order('desc')
      .limit(30)

      // if ($this.state.last_trades_pair) {
      //   ServerTrades.cursor($this.state.last_trades_pair);
      //   // var length = this.state.trades_pair.length;
      // }

    ServerTrades
      .call()
      .then(function(result){

        if (result.records.length > 0) {
          $this.props.trades(result.records);
        }
        else {
          ToastStore.info('No Trades were found for this pair of Assets.');
        }

        // if(result.records.length < limit_items || result.records.length === 0) {
        //   $this.setState({ trades_pair_more_btn: false });
        // } else {
        //   $this.setState({ trades_pair_more_btn: true });
        // }

        // $this.setState({
        //   trades_pair: $this.state.trades_pair.concat(result.records),
        //   last_trades_pair: result.records[result.records.length - 1].paging_token,
        //   load_trades_pair: true,
        //   load_more: true,
        // });
      })
      .catch(function(err){
        console.log('err trades count', err);
    })
  }



  getOffers(account_id) {
    const $this = this;

    // this.setState({ load_more: false })

    Server.offers('accounts', account_id)
      .limit(30)
      .order('desc')
      .call()
      .then(function(result) {

        // if(result.records.length < limit_items || result.records.length === 0) {
        //   $this.setState({trades_offer_more_btn: false});
        // } else {
        //   $this.setState({trades_offer_more_btn: true});
        // }

        // $this.setState({
        //   offers: $this.state.offers.concat(result.records),
        //   load_offers: true,
        //   // load_more: true,
        // })

        $this.props.offers(result.records);
      })
      .catch(function (err) {
        console.error(err);
    })
  }



  getAccount(account_id) {
    const $this = this;

    this.setState({ loader: true });

    Server.loadAccount(account_id)
      .then(function (account) {
        let reserve = ((account.subentry_count * baseReserve()) + minBalance());

        account.balances.reverse()
        account.balances.forEach((item, index) => {
          if (item.asset_type === 'native') {

            $this.setState({
              native_balance: item.balance,
              avaliable_balance: (item.balance - reserve)
            });

            item.asset_code = 'XLM';
            item.asset_issuer = 'native';
            item.reserve = (account.subentry_count * baseReserve());
            item.avaliable = (item.balance - item.reserve).toFixed(7);
          }
          else {
            item.avaliable = item.balance;
          }
          // if (item.asset_code === $this.props.state.asset.asset_code) {
          //   $this.setState({
          //     counter_balance: item.balance,
          //   });
          // }
        })

        $this.setState({
          loader: false,
          load_account: true,
          account: account,
          reserve_balance: reserve,
        });
      })
      .catch(function (err) {
        console.log('error: ', err);
        $this.setState({ loader: false });
        ToastStore.error('Error: '+err.toString());

        // $this.nextLoadAccount();
    })
  }


  priceChange(value) {
    let price = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        price = price + value[key];
      }
    }

    this.setState({
      price_change: Number(price),
      price_reverse: (1 / price),
    })

    if (this.state.sell_asset.asset_code !== 'XLM' && this.state.buy_asset.asset_code !== 'XLM') {
      var total = (this.state.amount_change / price).toFixed(7)
    }
    else {
      var total = (this.state.amount_change * price).toFixed(7)
    }

    this.setState({
      total_change: Number(total),
    })

    this.props.change('asset_total', Number(total))
  }


  amountChange(value) {
    let amount = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        amount = amount + value[key];
      }
    }

    this.setState({
      amount_change: Number(amount),
    })

    if (this.state.sell_asset.asset_code !== 'XLM' && this.state.buy_asset.asset_code !== 'XLM') {
      var total = (amount / this.state.price_change).toFixed(7)
    }
    else {
      var total = (amount * this.state.price_change).toFixed(7)
    }

    this.setState({
      total_change: Number(total),
    })

    this.props.change('asset_total', Number(total))
  }


  totalChange(value) {
    let total = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        total = total + value[key];
      }
    }

    this.setState({
      total_change: Number(total),
    })

    if (this.state.sell_asset.asset_code !== 'XLM' && this.state.buy_asset.asset_code !== 'XLM') {
      var amount = (total * this.state.price_change).toFixed(7)
    }
    else {
      var amount = (total / this.state.price_change).toFixed(7)
    }

    this.setState({
      amount_change: Number(amount),
    })

    this.props.change('asset_amount', Number(amount))
  }


  submitForm(values) {

    if (!values.select_address) {
      ToastStore.warning('Select Trading Address');
    }
    else {

      if (!values.select_sell_asset) {
        ToastStore.warning('Choose a selling Asset');
      }
      else if (!values.select_buy_asset) {
        ToastStore.warning('Select the buing Asset');
      }
      else if (!values.asset_price) {
        ToastStore.warning('Enter your price');
      }
      else if (!values.asset_amount) {
        ToastStore.warning('Enter Asset amount');
      }
      else if (!values.asset_total) {
        ToastStore.warning('Not total amount');
      }
      else {
        // ...
        this.setOffer(values);
      }
    }
  }


  setOffer(values) {
    const $this = this;

    let asset = {};
    asset.amount = values.asset_total;
    asset.price = Number(1 / values.asset_price);

    if (this.state.sell_asset.asset_code === 'XLM') {
      asset.buying = new StellarSdk.Asset(this.state.buy_asset.asset_code, this.state.buy_asset.asset_issuer);
      asset.selling = StellarSdk.Asset.native();
      // asset.amount = values.asset_total;
      // asset.price = Number(1 / values.asset_price);
    }
    else if (this.state.buy_asset.asset_code === 'XLM') {
      asset.buying = StellarSdk.Asset.native();
      asset.selling = new StellarSdk.Asset(this.state.sell_asset.asset_code, this.state.sell_asset.asset_issuer);
      // asset.amount = values.asset_total;
      // asset.price = Number((1 / values.asset_price));
    }
    else {
      asset.buying = new StellarSdk.Asset(this.state.buy_asset.asset_code, this.state.buy_asset.asset_issuer);
      asset.selling = new StellarSdk.Asset(this.state.sell_asset.asset_code, this.state.sell_asset.asset_issuer);
      asset.amount = Number(values.asset_total);
      asset.price = Number(values.asset_price);
    }

    getAuth()
      .then((value) => {
        if (value === 'accept') {
          acceptSendTX();
        }
        else if (value === 'need_deauth_all') {
          needDeauthAll();
        }
      });

    const acceptSendTX = () => {

      swal({
        title: "Confirmation",
        text: `Create an Order to sell ${numFormat(asset.amount, 7)} ${asset.selling.code} right now?`,
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
      .then((confirm) => {
        if (confirm)
          addOpOffer();
      })
    }

    function addOpOffer() {

      $this.setState({ loader: true })

      Server.loadAccount(values.select_address)
        .then(function(sourceAccount) {

          var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: StellarSdk.BASE_FEE,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
          .addOperation(StellarSdk.Operation.manageOffer({
              selling: asset.selling,
              buying: asset.buying,
              amount: String(asset.amount),
              price: String(asset.price)
          }))
          .setTimeout(100)
          .build();

          transaction.sign(StellarSdk.Keypair.fromSecret(window.atob($this.state.signer[0].sk)));
          return Server.submitTransaction(transaction);
        })
        .then(function(result) {

          let pair = {
            buy_asset: $this.state.buy_asset,
            sell_asset: $this.state.sell_asset,
            address: $this.state.address,
          }

          setPairToLocal(pair);

          $this.setState({ loader: false });

          swal({
            title: "Offer added!",
            text: "Your exchange Offer has been successfully added.",
            icon: "success",
          })
        })
        .catch(function(error) {
          console.error('Something went wrong!', error);

          $this.setState({ loader: false })
          ToastStore.error('Transaction error');
      });
    }
  }


  // setPairToLocal() {

  //   var local_pairs = localStorage.getItem('pairs');

  //   let pair = {
  //     buy_asset: this.state.buy_asset,
  //     sell_asset: this.state.sell_asset,
  //     address: this.state.address,
  //   }

  //   if (!local_pairs) {
  //     var pairs = [];
  //     pairs.push(pair);
  //     pairs = JSON.stringify(pairs);

  //     localStorage.setItem('pairs', pairs);
  //   }
  //   else {

  //     var local_pairs = JSON.parse(local_pairs);

  //     let is_item = false;
  //     local_pairs.forEach((item, index) => {

  //       if (item.sell_asset.asset_code === pair.sell_asset.asset_code && item.sell_asset.asset_issuer === pair.sell_asset.asset_issuer) {
  //         if (item.buy_asset.asset_code === pair.buy_asset.asset_code && item.buy_asset.asset_issuer === pair.buy_asset.asset_issuer) {
  //           is_item = true;
  //         }
  //       }
  //     })

  //     if (!is_item) {

  //       local_pairs.push(pair);
  //       local_pairs = JSON.stringify(local_pairs);

  //       localStorage.setItem('pairs', local_pairs);
  //     }
  //   }
  // }


  checkAuthAddr() {
    checkAuth().then((value) => {
      if (value) {
        this.setState({
          is_auth: true
        })
      }
    })
  }



  renderTradeAddress() {
    return (
        <div>
          <div id="test-field">
            <Field
              name="select_address"
              component={renderTextField}
              select
              label="Select an exchange Address"
              onChange={(value) => {
                this.changeAddress(value)
              }}
            >
              {
                this.state.wallets
                ? this.state.wallets.map((item, index) => {
                    return (
                      <MenuItem key={index} className="material-form__option" value={item.pk}><b className="text-secondary">{shortAddr(item.pk, 8)}</b> <small> / {item.title}</small></MenuItem>
                    );
                  })
                : null
              }
            </Field>
          </div>

          {
            // this.state.load_trade_account ?
            //   <div>
            //     <h5 className="subhead">Balances: <b className="text-info">{numFormat(this.state.native_balance, 7)} <font className="text-warning">XLM</font></b> &nbsp; / &nbsp; <b className="text-info">{numFormat(this.state.counter_balance, 7)} <font className="text-warning">{this.props.state.asset.asset_code}</font></b>
            //     <span className="float-right">Reserve: <b className="text-info">{this.state.reserve_balance} <font className="text-warning">XLM</font></b></span> </h5>
            //     <br/>
            //   </div>
            // : null
          }
        </div>
    )
  }


  renderSellAssets() {
    return (
      <div>
        <Field
          name="select_sell_asset"
          component={renderTextField}
          select
          label="Sell Asset"
          onChange={(value) => {
            this.changeSellAsset(value)
          }}
        >
          {
            this.state.load_account ? this.state.account.balances.map((item, index) => {
              return (
                <MenuItem key={index}
                          className="material-form__option"
                          value={`${item.asset_code}-${item.asset_issuer}`}><b className="text-secondary">{item.asset_code}</b> &nbsp;({numFormat(item.avaliable, 7)}) &nbsp; {item.asset_type === 'native' ? 'native' : shortAddr(item.asset_issuer, 4)}</MenuItem>
              )
            }) : null
          }
        </Field>
      </div>
    )
  }

  renderBuyAssets() {
    return (
      <div>
        <Field
          name="select_buy_asset"
          component={renderTextField}
          select
          label="Buy Asset"
          onChange={(value) => {
            this.changeBuyAsset(value)
          }}
        >
          { this.state.select_sell_asset ? this.state.account.balances.map((item, index) => {
            if (item.asset_code !== this.state.sell_asset.asset_code) {
              return (
                <MenuItem key={index}
                          className={`material-form__option`}
                          value={`${item.asset_code}-${item.asset_issuer}`}>
                        <b className="text-secondary">{item.asset_code}</b> &nbsp;({numFormat(item.avaliable, 7)}) &nbsp; {item.asset_type === 'native' ? 'native' : shortAddr(item.asset_issuer, 4)}</MenuItem>
              )
            }
          }) : null }
        </Field>
      </div>
    )
  }



  render() {

    // console.log('ExForm state: ', this.state)

    const { reset, handleSubmit } = this.props;

    let buy_asset_code = this.state.buy_asset.asset_code;
    let sell_asset_code = this.state.sell_asset.asset_code;

    // if (this.state.buy_asset.asset_code === 'XLM') {
    //   buy_asset_code = this.state.sell_asset.asset_code;
    //   sell_asset_code = this.state.buy_asset.asset_code;
    // }
    if (this.state.buy_asset.asset_code !== 'XLM' && this.state.sell_asset.asset_code !== 'XLM') {
      buy_asset_code = this.state.buy_asset.asset_code;
      sell_asset_code = this.state.sell_asset.asset_code;
    }

    if (this.state.is_auth) {
      return <Redirect to='/wallet' />
    }


    return (
      <div>
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        { this.state.loader ? <div className="panel__refresh" style={{height: '95%'}}><LoadingIcon /></div> : '' }

        <form className="material-form"
              onSubmit={handleSubmit(this.submitForm.bind(this))}
              onClick={this.checkAuthAddr.bind(this)}>

          { this.renderTradeAddress() }

          { this.renderSellAssets() }

          { this.renderBuyAssets() }

          <div>
            <Field
              name="asset_price"
              component={renderTextField}
              className="m-form"
              label={`Price ${buy_asset_code}/${sell_asset_code}  ${this.state.price_reverse ? `( reverse: ${numFormat(this.state.price_reverse, 7)} )` : '' }`}
              onChange={this.priceChange.bind(this)}
            />
          </div>

          <div>
            <Field
              name="asset_amount"
              component={renderTextField}
              label={`Amount buy ${this.state.buy_asset.asset_code}`}
              onChange={this.amountChange.bind(this)}
            />
          </div>

          <div>
            <Field
              name="asset_total"
              component={renderTextField}
              label={`Total sell ${this.state.sell_asset.asset_code}`}
              onChange={this.totalChange.bind(this)}
            />
          </div>

          <ButtonToolbar className="form__button-toolbar float-right">
            <Button type="button" onClick={reset}>Reset</Button>
            <Button color="primary" type="submit">Exchange</Button>
          </ButtonToolbar>
        </form>

      </div>
    )
  }
}



ExchangeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'floating_labels_form, initializeFromState', // a unique identifier for this form
})(translate('common')(ExchangeForm));
