import React, { PureComponent, useState } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import StellarSdk from 'stellar-sdk';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { ToastContainer, ToastStore } from 'react-toasts';
import Footer from '../../Layout/footer/Footer';
import {
  get_loc_wallets,
  getTitle,
  shortAddr,
} from '../../../modules/index';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
} from 'reactstrap';
import {getPendingPayments} from "../../../endpoints/StellarAPI";
import RequiredSigners from "../Auth/components/RequiredSigners";
import {getFederationId} from "../../../endpoints/StellarAPI";
import WrapClaimBalances from "./WrapClaimBalances"



class PendingPayments extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      allAccounts: [],
      allPayments: [],
      loading: false,
      transaction: false,
      account: {},
      need_add_signers: false,
      btn_name: '',
      btn_icon: '',
      type_tx: '',
      type_set_tx: '',
      select_index: 0,
      search: false,
      search_value: false,
      is_my_address: false,
    };
  }

  wallets = get_loc_wallets();


  componentDidMount = () => {

    getTitle(`Claimable Balance - Pending Payments on Stellar`);

    console.log('this.props.match.params.id: ', this.props.match.params.id)

    if (!this.props.match.params.id && !this.wallets) {
      this.setState({ search: true })
    }

    else if (!this.props.match.params.id && this.wallets) {

      this.wallets.forEach(item => {

        getPendingPayments(item.pk, 200)
          .then(result => {
            // console.log('result: ', result)

            this.setState({
              allAccounts: this.state.allAccounts.concat({
                account: item.pk,
                payments: result.records,
              }),
              allPayments: this.state.allPayments.concat(result.records),
              is_my_address: true,
            })
          })
      })
    }

    else {

      getTitle(`Claimable Balance for Stellar Account ${shortAddr(this.props.match.params.id, 4)}`);

      getPendingPayments(this.props.match.params.id, 200)
        .then(result => {
          // console.log('result: ', result)

          this.setState({
            allAccounts: this.state.allAccounts.concat({
              account: this.props.match.params.id,
              payments: result.records,
            }),
            allPayments: this.state.allPayments.concat(result.records),
            is_my_address: this.wallets ? this.wallets.some(item => item.pk === this.props.match.params.id) : false
          })
        })
    }
  }


  notValueMessage = () => {
    ToastStore.warning('Enter Stellar Address');
  }

  handleSearchClick = () => {

    if (!this.state.search_value) {
      this.notValueMessage()
      return
    }

    this.searchProcess(this.state.search_value)
  }

  searchChange = (value) => {
    this.setState({ search_value: value.target.value })

    if (value.length === 56) {
      this.searchProcess(value)
    }
  }


  submitSearchForm = (values) => {
    if (!values) {
      this.notValueMessage()
    }
    else {
      this.searchProcess(values.search)
    }
  }

  searchProcess = (value) => {

    const domain = value.split('*')[1];
    const reg = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
    const validDomain = reg.test(domain)

    if (value.length === 56) {
      if (StellarSdk.StrKey.isValidEd25519PublicKey(value)) {

        window.location.href = `${window.location.origin}/pending-payments/${value}`
      }
      else {
        ToastStore.warning('Invalid Stellar Address');
      }
    }
    else if (validDomain) {
      this.getFederation(value)
    }
    else {
      ToastStore.warning('Invalid Stellar Address');
    }
  }

  getFederation = (name) => {

    getFederationId(name)
      .then(result => {
        // console.log('result: ', result)
        window.location.href = window.location.origin+'/claimable-balance/'+result.account_id;
      })
      .catch(error => {
        console.log('error: ', error)
        ToastStore.error('Federation name not found...');
      });
  }


  render() {
    // console.log('this.state: ', this.state)

    const { handleSubmit, t } = this.props;


    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {
          this.state.need_add_signers && this.state.transaction ?
            <RequiredSigners account={this.state.account}
                             add_signers={this.addSigners}
                             transaction={this.state.transaction}
                             btn_name={this.state.btn_name}
                             icon={this.state.btn_icon}
                             type_tx={'low'} />
            : null
        }

        <Row>
          <Col md={{size:12}}>
            <h3 className="page-title"><i className={'fa fa-clock-o'}></i> Claimable Balance <span className={'float-right'}>{this.state.allPayments.length ? this.state.allPayments.length : ''}</span></h3>
            <h4 className="subhead" style={{marginBottom: '18px'}}>
              Check and claim your claimable balances. To receive pending payments, <a href="/auth/connect">connect</a> your account.
            </h4>
          </Col>
        </Row>

        <div>
          <Card>
            <CardBody>
              <br/>
              <Row>
                <Col md={{size:6, offset:3}}>
                  <form className="form" onSubmit={handleSubmit(this.submitSearchForm)}>
                    <div className="form__form-group">
                      <span className="form__form-group-label" style={{marginBottom: 20}}><b>Enter Stellar address to check claimable balances</b></span>
                      <div className="form__form-group-field">
                        <Field
                          name="search"
                          component="input"
                          type="text"
                          placeholder="Stellar Account"
                          onChange={this.searchChange}
                        />
                        <button
                          type="button"
                          className={`form__form-group-button`}
                          style={{width: '90px'}}
                          onClick={this.handleSearchClick}
                        ><i className="fa fa-search" aria-hidden="true"></i></button>
                      </div>
                    </div>
                  </form>
                  <p><small>Can be searched by <b className="text-lightblue">Address</b> or <b className="text-lightblue">Federation name</b>.</small></p>
                  <br/>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>


        <WrapClaimBalances
          accounts={this.state.allAccounts}
          search={this.state.search}
          loading={this.state.loading}
          params_id={this.props.match.params.id}
          type_url={'claimable'}
        />


        <Footer />

      </Container>
    )
  }
}

PendingPayments.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'vertical_form_layout', // a unique identifier for this form
})(translate('common')(PendingPayments));
