import React, { PureComponent } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabPane, TabContent, Modal, Button, ButtonToolbar } from 'reactstrap';
import { translate } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoadingIcon from 'mdi-react/LoadingIcon';
import TopAssets from '../Home/components/TopAssets';
import SearchField from '../Search/components/SearchField';
import StellarAssets from './components/StellarAssets';
import { ToastContainer, ToastStore } from 'react-toasts';
import Footer from '../../Layout/footer/Footer';
import getPrice from '../../App/GetPrice';
import {
  baseReserve,
  get_loc_wallets,
  getAuth,
  getIconConnectProvider,
  getTitle,
  needDeauthAll,
  numFormat,
  shortAddr
} from '../../../modules/index';
import {Field, reduxForm} from "redux-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import swal from "sweetalert";
import RequiredSigners from "../Auth/components/RequiredSigners";
import PulseLoader from "react-spinners/PulseLoader";
import {css} from "@emotion/core";
import SpinnerLoader from "../../../modules/SpinnerLoader";
import iconIdent from "../Account/components/iconIdent";
import AcceptAsset from "./components/AcceptAsset";
// import AcceptAsset from "./components/AcceptAsset";

const override = css`
  display: inline-block;
  margin-bottom: -2px;
`;


const renderTextField = ({
                           input, label, meta: { touched, error }, children, select, type,
                         }) => (
    <TextField
        className="material-form__field"
        label={label}
        error={touched && error}
        value={input.value}
        children={children}
        select={select}
        type={type}
        onChange={(e) => {
          e.preventDefault();
          input.onChange(e.target.value);
        }}
    />
);


class Assets extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      wallets: get_loc_wallets(),
      limit: 30,
      account: false,
      asset: false,
      assets: [],
      activeTab: '1',
      search_data: false,
      load_search_data: false,
      start_get_assets: false,
      stellar_assets: [],
      price: false,
      loader: false,
      // modal: false,
      // trust: false,
      transaction: false,
      need_add_signers: false,
      signer: false,
      loading_stellar_assets: false,
      search_asets_db: false,
      show_accept_modal: false,
    };

    this.getSearchFieldData = this.getSearchFieldData.bind(this);
    this.addSigners = this.addSigners.bind(this);
  }

  count = 0;

  componentDidMount() {

    const title = 'All Stellar Assets, Decentralized Asset Exchange (SDEX)';
    const description = 'Trade on SDEX, the global digital asset market. Without registration, without intermediaries, without minimum deposits, without commissions, at a speed of 3-5 sec / tx.';
    getTitle(title, description);

    this.getAccountsBalances()

    if (!this.props.match.params.id) {
      this.setState({ start_get_assets: true, })
    }
    else { // search

      this.setState({
        search_data: {
          asset_code: this.props.match.params.id
        },
        load_search_data: true,
      })
    }

    this.getBasePrice()
  }


  getBasePrice = () => {
    let price = JSON.parse(localStorage.getItem('price'));

    if (price && price.crypto_price) {
      this.setState({ price: price.crypto_price.xlm_usd });
    }
    else if (price && price.local_price) {
      this.setState({ price: price.local_price.USD })
    }
    else {
      if (this.count < 3) {
        getPrice()
        setTimeout(() => {
          this.getBasePrice()
          this.count++;
        }, 2000)
      }
    }
  }


  toggleAcceptModal = (value) => {
    if (value) {
      this.setState({ show_accept_modal: true })
      return
    }
    setTimeout(() => this.setState({ show_accept_modal: !this.state.show_accept_modal }), 1000)
  }


  getAccountsBalances = () => {

    const getItemAccount = (item, index) => {
      // var item = item;
      item.id = index;
      let wallets_arr = []

      Server.loadAccount(item.pk)
        .then((account) => {

          if (account) {
            item.account = account;
            item.balance = numFormat(account.balances[account.balances.length-1].balance, 2);
            item.reserve = (account.subentry_count * baseReserve());
            item.avaliable = (item.balance - item.reserve).toFixed(2);
          }

          wallets_arr.push(item);
          setNewState(wallets_arr)
        })
        .catch((err) => {
          console.error(err);

          wallets_arr.push(item)
          setNewState(wallets_arr)
        })

      const setNewState = (wallets_arr) => {
        if (this.state.wallets.length === wallets_arr.length) {
          newState();
        }
      }

      const newState = () => {
        this.setState({
          wallets: wallets_arr,
        })
      }
    }

    if (this.state.wallets) {
      this.state.wallets.forEach((item, index) => {
        getItemAccount(item, index);
      })
    }
  }


  getSearchFieldData(asset) {

    this.setState({
      load_search_data: false,
      // search_data: asset,
    })

    setTimeout(() => {
      this.setState({
        search_data: asset,
        load_search_data: true,
        start_get_assets: false,
      })
    }, 100)

    if (asset.asset_code !== '') {
      window.history.replaceState(null,null, `/assets/${asset.asset_code}`)
      this.getStellarAssets(asset.asset_code)
    }
  }


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };


  stellarAssetsCount(count) {
    this.setState({
      stellar_assets_count: count,
    })

    ToastStore.info(`${count} ${this.props.match.params.id} tokens found in Stellar Ecosystem. Click on All Stellar Assets to view.`);
  }

  acceptAsset = (asset) => {

    if (this.state.account) {
      const trustItem = this.state.account.balances.filter(item => {
        return (item.asset_code === asset.asset_code && item.asset_issuer === asset.asset_issuer)
      })
      if (trustItem.length) {
        this.setState({
          trust: trustItem,
          accept_asset: asset,
        })
      }
    }

    this.setState({asset})

    // this.toggleModal()
    this.toggleAcceptModal(true)
  }


  toggleModal = () => {
    this.setState({modal: !this.state.modal})
  }


  getSigner(address) {
    const wallets = this.state.wallets;

    if (wallets) {
      var signer = wallets.filter((item) => {
        return item.pk === address;
      });

      this.setState({
        signer: signer,
      })
    }
  }


  setTrust = () => {

    getAuth()
        .then((value) => {
          if (value === 'accept') {
            setTrustTx()
          }
          else if (value === 'need_deauth_all') {
            needDeauthAll();
          }
        });


    const setTrustTx = () => {

      this.setState({ loader: true })

      Server.loadAccount(this.state.address)
          .then((sourceAccount) => {

            const submitTx = (num) => {
              return Server.submitTransaction(this.state.transaction);
            }

            if (!this.state.transaction) {

              this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: StellarSdk.BASE_FEE,
                networkPassphrase: StellarSdk.Networks.PUBLIC
              })
              .addOperation(StellarSdk.Operation.changeTrust({
                asset: new StellarSdk.Asset(this.state.asset.asset_code, this.state.asset.asset_issuer),
              }))
              .setTimeout(100)
              .build();

              this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));

              const master_weight = sourceAccount.signers.reverse()[0].weight;

              if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {
                // alert(sourceAccount.thresholds.med_threshold)

                this.setState({
                  // transaction: this.state.transaction,
                  account: sourceAccount,
                  need_add_signers: true,
                  btn_name: 'Set Trustline',
                  btn_icon: 'cube',
                  type_tx: 'med',
                  type_set_tx: 'trust'
                })

                return false;
              }
              else {
                return submitTx();
              }
            }
            else {
              return submitTx();
            }
          })
          .then((result) => {
            if (result) {
              // console.log('result: ', result)

              this.setState({ loader: false, transaction: false })

              swal({
                title: `Added new balance ${this.state.asset.asset_code}!`,
                text: `Trustline to ${this.state.asset.asset_code} token has been successfully installed. Now ${this.state.asset.asset_code} token is available on your balance.`,
                icon: "success",
                confirm: true,
              })
              .then(confirm => {
                // if (confirm) {
                //   this.toggleModal();
                // }
              });
            }
          })
          .catch((error) => {
            console.error('Something went wrong!', error);

            this.setState({ loader: false })
            ToastStore.error('Transaction error');
          });
    }
  }

  selectAccount(value) {

    let address = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        address = address + value[key];
      }
    }

    this.setState({
      address,
      account: false,
      trust: false
    })

    this.checkAccount(address);
    this.getSigner(address);
  }


  checkAccount = (address) => {

    ToastStore.info("Checking Account...");
    this.setState({ loader: true, });

    // Check Trust
    Server.loadAccount(address)
      .then((account) => {

        this.setState({ loader: false, });

        let trust = account.balances.filter((balance) => {
          return balance.asset_code === this.state.asset.asset_code &&
              balance.asset_issuer === this.state.asset.asset_issuer;
        });

        this.setState({
          trust:(trust.length ? trust  : false),
          account
        })

        if (trust.length) {
          ToastStore.success(`${this.state.asset.asset_code} asset is already on your balance`);
        }
        else {
          swal({
            title: "Adding a new balance",
            text: `Add ${this.state.asset.asset_code} asset to your balance?`,
            icon: "info",
            buttons: {
              cancel: true,
              confirm: true,
            },
          })
          .then((confirm) => {
            if (confirm)
              this.setTrust()
          })
        }
      })
      .catch((error) => {
        console.log('error: ', error);
        this.setState({ loader: false, });
      });
  }



  addSigners(value) {
    this.setState({
      transaction: value,
      need_add_signers: false,
    })
  }


  submitForm = (values) => {

    if (Object.keys(values).length === 0) {
      ToastStore.warning(`Account not selected`);
    }
    else if (this.state.account && !this.state.trust) {
      this.setTrust()
    }
  }


  getStellarAssets(asset_code) {

    this.setState({ loading_stellar_assets: true })

    Server.assets()
      .forCode(asset_code)
      .limit(200)
      .call()
      .then((result) => {
        this.setState({ loading_stellar_assets: false })

        let assets = result.records;

        if (assets.length > 0) {
          assets.forEach((item, index) => {
            item.home_domain = item._links.toml.href.replace('https://', '').replace('/.well-known/stellar.toml', '');
          })

          this.setState({
            stellar_assets: assets,
          })
        }

      })
      .catch( (err) => {
        console.log(err)
        this.setState({ loading_stellar_assets: false })
      })
  }


  // renderModal() {
  //
  //   const {reset, handleSubmit } = this.props;
  //
  //   let classElem = '';
  //   if (localStorage.getItem('theme') === 'theme-dark') {
  //     classElem = 'modal-dark'
  //   }
  //
  //   return (
  //       <Modal
  //           isOpen={this.state.modal}
  //           toggle={this.toggleModal}
  //           className={`modal-dialog--primary modal-dialog--header`}
  //       >
  //         <div className={`modal-content ${classElem}`}>
  //           <div className="modal__header">
  //             <button className="lnr lnr-cross modal__close-btn" onClick={this.toggleModal} />
  //             <h4 className="bold-text  modal__title">Trustline for <b>{this.state.asset ? this.state.asset.asset_code : null}</b>&nbsp;&nbsp;|&nbsp;&nbsp;
  //               <small className="">
  //                 <small className=""><b>{this.state.asset.name !== 'undefined' ? this.state.asset.name : null}</b></small>&nbsp;&nbsp;|&nbsp;&nbsp;
  //                 <small className="">{this.state.asset.home_domain}</small>
  //               </small></h4>
  //           </div>
  //           <div className={`modal__body`}>
  //             <div>
  //               {this.state.loader ? <div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div> : ''}
  //               <div>Select the Account to add Trustline</div>
  //
  //               <form className="material-form"
  //                     onSubmit={handleSubmit(this.submitForm.bind(this))}
  //                     // onClick={this.checkAuthAddr.bind(this)}
  //               >
  //                 <div>
  //                   <Field
  //                       name="sender_addr"
  //                       component={renderTextField}
  //                       select
  //                       label="Select Account"
  //                       onChange={(value) => {
  //                         this.selectAccount(value)
  //                       }}
  //                   >
  //                     {
  //                       this.state.wallets && this.state.wallets.map((item, index) => {
  //                         // if (item.balance) {
  //                           return (
  //                             <MenuItem
  //                               key={index}
  //                               className={`material-form__option ${item.provider && 'connect-provider'}`}
  //                               value={item.pk}>
  //                               <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 8)}</b> <small>&nbsp;/ {item.title} / {item.balance} XLM</small> { getIconConnectProvider(item) }
  //                             </MenuItem>
  //                           )
  //                         // }
  //                       })
  //                     }
  //
  //                   </Field>
  //
  //                   {
  //                     this.state.account && this.state.trust ?
  //                       <h5 className="subhead">Balance: <b className="text-info">{this.state.trust.length ? this.state.trust[0].balance : '...'}</b> <b className="text-warning">{this.state.asset.asset_code}</b>
  //                         <br/><br/><span className="text-success">The <b>{this.state.asset.asset_code}</b> asset is allowed</span></h5>
  //                       : <h5 className="subhead">Select the Account to which you want to add the <b>{this.state.asset.asset_code}</b> token.</h5>
  //                   }
  //                 </div>
  //
  //                 <br/>
  //
  //                 <ButtonToolbar className="form__button-toolbar float-right">
  //                   {/*<Button type="button" onClick={reset}>Reset</Button>*/}
  //                   <Button color="success" type="submit"
  //                   >Accept <b>{this.state.asset.asset_code}</b></Button>
  //                   <Link
  //                       to={`/trade/${this.state.asset.asset_code}-XLM/${this.state.asset.asset_issuer}/native`}
  //                       class={'btn btn-secondary'}>Trade <b>{this.state.asset.asset_code}</b></Link>
  //
  //                 </ButtonToolbar>
  //
  //               </form>
  //             </div>
  //           </div>
  //         </div>
  //       </Modal>
  //   )
  // }


  searchResult = (value) => {
    this.setState({ search_asets_db: value })
    if (!value) {
      this.getStellarAssets(this.props.match.params.id);
    }
  }



  render() {
    // console.log('state: ', this.state)

    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {
          this.state.need_add_signers && this.state.transaction ?
              <RequiredSigners account={this.state.account}
                               add_signers={this.addSigners}
                               transaction={this.state.transaction}
                               btn_name={this.state.btn_name}
                               icon={this.state.btn_icon}
                               type_tx={this.state.type_tx} />
              : null
        }

        {/*{ this.renderModal() }*/}

        {/*<AcceptAsset asset={this.state.asset} />*/}

        {
          this.state.show_accept_modal &&
          <AcceptAsset
            asset={this.state.asset}
            toggleAcceptModal={this.toggleAcceptModal}
            wallets={this.state.wallets}/>
        }

        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                {
                  this.state.load_search_data || this.state.start_get_assets ?
                    <SearchField search_data={this.getSearchFieldData}
                                 asset={this.state.search_data} />
                  : null
                }

                {
                  this.state.price ?
                    <div>
                      {
                        this.state.start_get_assets ?
                          <TopAssets
                            acceptAsset={this.acceptAsset}
                            limit={this.state.limit} {...this.state} />
                          : null
                      }

                      {
                        this.state.load_search_data ?
                          <TopAssets acceptAsset={this.acceptAsset}
                                     search_data={this.state.search_data}
                                     searchResult={this.searchResult}
                                     {...this.state} />
                          : null
                      }

                      {
                        !this.state.search_asets_db &&
                        !this.state.stellar_assets.length &&
                        this.props.match.params.id ?
                          <p className={'text-center'}>The asset was not found in Scopuly. Searching from Stellar Ledger... <br/><br/></p>
                          : null
                      }
                    </div>
                    : null
                }

                {
                  this.state.stellar_assets.length ?
                    <div>
                      <p className={'card__title'}><b>All Stellar Assets ({this.state.stellar_assets.length})</b></p>
                      <br/>
                      <StellarAssets filter_asset={this.state.search_data.asset_code}
                                     stellar_assets_count={this.stellarAssetsCount.bind(this)}
                                     assets={this.state.stellar_assets}
                      />
                    </div>
                    : null
                }

                {
                  this.state.search_data &&
                  !this.state.loading_stellar_assets &&
                  !this.state.stellar_assets.length ?
                    <div className={'text-center'}>
                      <Button color="secondary" onClick={() => this.getStellarAssets(this.props.match.params.id)} >
                        Get all <b className={'text-info'}>{this.state.search_data.asset_code}</b> from Stellar</Button>
                    </div>
                  : null
                }

                {
                  this.state.loading_stellar_assets ?
                    <p className={'text-center'}>
                      <PulseLoader
                        css={override}
                        size={10}
                        color={"#4ce1b6"}
                        loading={true} />
                      <br/>Search all <b className={'text-warning'}>{this.state.search_data.asset_code}</b> tokens from Stellar<br/><br/>
                    </p>
                    : null
                }
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Footer />

      </Container>
    );
  }
}


Assets.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'assets_form', 
})(translate('common')(Assets));
