import React, { PureComponent } from 'react';
import {
  Card,
  CardBody,
  Button,
  ButtonToolbar,
  UncontrolledTooltip,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Popover, PopoverBody, ButtonGroup,
} from 'reactstrap';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  getIconConnectProvider, numFormat, shortAddr, get_loc_wallets, baseReserve, minBalance, getAsset, showErrorMessage, getSigner, itemType, formatDate,
  shortAddress, checkConnectProvider, signConnectProvider, setLocalWallets, parseNumber
} from '../../../../modules/index';
import Footer from '../../../Layout/footer/Footer';
import {ToastContainer, ToastStore} from "react-toasts";
import {Field, reduxForm} from "redux-form";
import MenuItem from "@material-ui/core/MenuItem";
import iconIdent from "../../Account/components/iconIdent";
import TextField from "@material-ui/core/TextField";
import Collapse from '../../../../shared/components/Collapse';
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/core";
import LoadingIcon from "mdi-react/LoadingIcon";
import {getMarketAssets} from "../../../../endpoints/API";
import PropagateLoader from 'react-spinners/PropagateLoader'
import classnames from "classnames";
import {GLOBE} from "../../../../modules/globeVars";
import axios from "axios";
import StellarSdk from 'stellar-sdk';
import {Server} from "../../../../modules/stellar";
import {
  getOperationForTransaction,
  addLiquidity,
  buildTx,
  getLiquidityPool,
  getOrderbook,
  strictSendPaths
} from '../../../../endpoints/StellarAPI'
import swal from "sweetalert";
import BN from '../../../../modules/BN';
import renderCheckBoxField from "../../../../shared/components/form/CheckBox";


const loaderStyle = css`
  display: inline-block;
  position: absolute;
  width: 40px;
  top: 0;
  left: 5px;
`;

const  loaderPrice = css`
  margin-bottom: -25px;
`

const loaderAccounts = css`
  text-align: center;
  display: inline-block;
`


const renderTextField = ({
                           input, label, meta: { touched, error }, children, select, type,
                         }) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);




class DepositWithdraw extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      pool_id: this.props.type !== 'create' ? this.props.pool_db.pool_id : null,
      pool: this.props.type !== 'create' ? this.props.pool : false,
      pool_asset_order: false,
      wallets: get_loc_wallets(),
      wallets_loaded: false,
      trader_balances: [],
      select_account: false,
      select_address: '',
      show_assets: false,
      select_asset_type: '',
      activeTabAssets: '1',
      search_asset_code: '',
      assets_db: [],
      base_asset: {
        asset_code: 'XLM',
        asset_issuer: 'native',
        name: 'Stellar',
        image: '/img/logo/stellar-logo.png',
        home_domain: 'stellar.org',
      },
      // counter_asset: {
      //   asset_code: 'USDC',
      //   asset_issuer: 'GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN',
      //   name: 'USDC Coin',
      //   image: 'https://www.centre.io/images/usdc/usdc-icon-86074d9d49.png',
      //   home_domain: 'centre.io',
      // },
      counter_asset: {
        asset_code: 'SCOP',
        asset_issuer: 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ',
        name: 'Scopuly Asset',
        image: 'https://scopuly.com/img/logo/icon.png',
        home_domain: 'scopuly.com',
      },
      native_asset: {
        asset_code: 'XLM',
        asset_issuer: 'native',
        name: 'Stellar',
        image: '/img/logo/stellar-logo.png',
        home_domain: 'stellar.org',
      },
      base_balance: 0,
      counter_balance: 0,
      base_accept: 0,
      counter_accept: 0,
      destination: '',
      memo: '',
      path: [],
      path_loading: false,
      path_send_loading: false,
      path_receive_loading: false,
      min_amount: 0,
      max_amount: 0,
      orderbook_pair: false,
      current_price: 0,
      pair_price: 0,
      base_asset_code: '',
      counter_asset_code: '',
      price_impact: 0,
      loader: false,
      hash: '',
      base_amount: 0,
      counter_amount: 0,
      class01: 'secondary',
      class02: 'secondary',
      operation: false,
      source_amount: 0,
      reserves_a: 0,
      reserves_b: 0,
      page_type: 'pools',
      liquidity_usd: 0,
      part_amount_usd: 0,
      base_price: this.props.base_price,
      balance_native: 0,
      balance_reserve: 0,
      balance_shares: 0,
      amount_shares: 0,
      all_reserves: false,
      popoverBaseOpen: false,
      popoverCounterOpen: false,
      is_reverse: false,
      is_reverse_price: false,
      pool_not_found: false,
    }
  }

  wallets_arr = []
  page_count = 0;
  doneTypingInterval = 1000;
  typingTimer = 0


  componentDidMount() {

    if (this.props.type === 'create') {

      const formData = new FormData();
      formData.append('asset_code', this.state.counter_asset.asset_code)
      formData.append('asset_issuer', this.state.counter_asset.asset_issuer)

      axios.post(`${GLOBE.API_URL}/get_assets_code_issuer`, formData).then(result => {
        // console.log('get counter asset result: ', result)
        if (result.data) {
          this.setState({ counter_asset: result.data[0] })
        }
      });

      this.setState({
        asset_a: this.state.base_asset,
        asset_b: this.state.counter_asset,
      }, () => {
        // this.getOrderbookPrice()
        this.getPool()
        this.getAllAccounts()
        // this.getSpotPrice()
      })
    }

    if (window.location.pathname.split('/').length > 2) { // Pool

      const base_asset = this.props.asset_a
      const counter_asset = this.props.asset_b

      const assetA = base_asset.asset_issuer === 'native' ? new StellarSdk.Asset.native() : new StellarSdk.Asset(base_asset.asset_code, base_asset.asset_issuer)
      const assetB = counter_asset.asset_issuer === 'native' ? new StellarSdk.Asset.native() : new StellarSdk.Asset(counter_asset.asset_code, counter_asset.asset_issuer)

      const [ A, B ] = this.orderAssets(...[assetA, assetB]);

      this.setState({
        page_type: 'pool',
        base_asset: this.props.asset_a,
        counter_asset: this.props.asset_b,
        pool_asset_order: { A, B },
      },
        () => {
          if (this.props.pool) {
            this.getSpotPrice()

            if (this.props.wallets.length) { // isset wallets

              this.setState({
                wallets: this.props.wallets,
                wallets_loaded: true,
              })

              if (this.props.select_account) {
                const select_account = this.props.select_account
                this.setState({ select_account },
                  () => {
                  this.selectAccount(select_account.pk)

                  setTimeout(() => {
                    this.props.change('select_addr', select_account.pk)
                  }, 1000)
                })
              }
            }
            else {
              this.getAllAccounts()
            }
          }
        })
    }

    this.getAssetsDB(0)

    // this.getSpotPrice()

    // this.getAccount(this.state.wallets[0], 'one')
  }


  getPool = () => {

    const { base_asset, counter_asset, select_address } = this.state

    const assetA = base_asset.asset_issuer === 'native' ? new StellarSdk.Asset.native() : new StellarSdk.Asset(base_asset.asset_code, base_asset.asset_issuer)
    const assetB = counter_asset.asset_issuer === 'native' ? new StellarSdk.Asset.native() : new StellarSdk.Asset(counter_asset.asset_code, counter_asset.asset_issuer)

    const [ A, B ] = this.orderAssets(...[assetA, assetB]);
    const poolShareAsset = new StellarSdk.LiquidityPoolAsset(A, B, StellarSdk.LiquidityPoolFeeV18);
    const poolId = StellarSdk.getLiquidityPoolId("constant_product", poolShareAsset.getLiquidityPoolParameters()).toString("hex");

    console.log('poolId: ', poolId)

    if (poolId) {
      this.setState({
        pool_id: poolId,
        pool_asset_order: { A, B }
      }, () => {
        // this.getAccount(select_address)
        // if (select_address) {
        //
        // }
        select_address && this.selectAccount(select_address)
      })
    }

    getLiquidityPool(poolId).then(resp => {
      console.log('liquidityPool resp: ', resp);

      this.setState({
        pool: resp
      }, () => {
        this.getSpotPrice()
      })

    }).catch( (error) => {
      console.log('Error: ', error);
      console.log('error.response.status: ', error.response.status);
      console.log('error.response.title: ', error.response.title);

      // create pool ...
      if (error.response.status === 404 && error.response.title === 'Resource Missing') {

        const price = (base_asset.price / counter_asset.price)

        this.setState({
          pool_not_found: true,
          pool: false,
          current_price: price,
          pair_price: price,
          base_asset_code: base_asset.asset_code,
          counter_asset_code: counter_asset.asset_code,
      })
        // this.getOrderbookPrice()
      }
    });
  }


  getSpotPrice = () => {

    const { base_asset, pool_asset_order } = this.state
    let base_asset_code, counter_asset_code, current_price

    const [a, b] = this.state.pool.reserves.map(r => r)
    const A = pool_asset_order.A
    const B = pool_asset_order.B

    console.log('a: ', a)
    console.log('b: ', b)

    current_price = (a.amount / b.amount).toFixed(7)

    if (a.asset === 'native') {

      // alert('native in pair')

      base_asset_code = B.code
      counter_asset_code = A.code
    }
    else if (A.code !== base_asset.asset_code && A.issuer !== base_asset.asset_issuer) {

      // alert('reverse on load pool')

      current_price = (b.amount / a.amount).toFixed(7)
      base_asset_code = A.code
      counter_asset_code = B.code
    }
    else {

      // alert('right pool position')

      base_asset_code = B.code
      counter_asset_code = A.code
    }

    console.log('base_asset_code: ', base_asset_code)
    console.log('counter_asset_code: ', counter_asset_code)
    console.log('current_price: ', current_price)

    this.setState({
      reserves_a: a.amount,
      reserves_b: b.amount,
      current_price,
      base_asset_code,
      counter_asset_code,
    })
  }


  orderAssets = (A, B) => {
    return (StellarSdk.Asset.compare(A, B) <= 0) ? [A, B] : [B, A];
  }


  getAllAccounts = () => {

    if (this.state.wallets) {
      this.state.wallets.forEach((item, index) => {
        this.getAccount(item);
      })
    }
  }


  getAccount = (item, type) => {

    Server.loadAccount(item.pk ? item.pk : item)
      .then((account) => {
        console.log('sourceAccount: ', account)

        const { base_asset, counter_asset, pool_id } = this.state

        if (type === 'one') {

          // this.setState({
          //   balance_shares: 0,
          //   base_balance: 0,
          //   counter_balance: 0,
          // })

          account.balances.map(balance => {
            console.log('balance')

            if (balance.asset_type === 'liquidity_pool_shares') {
              if (balance.liquidity_pool_id === pool_id) {
                balance.shares = balance.balance
                this.setState({ balance_shares: balance.balance })
              }
            }

            if (balance.asset_type === 'native') {
              balance.asset_code = 'XLM'
              balance.asset_issuer = 'native'
            }
            if (base_asset.asset_code === balance.asset_code && base_asset.asset_issuer === balance.asset_issuer) {
              this.setState({ base_balance: balance.balance })
            }
            if (counter_asset.asset_code === balance.asset_code && counter_asset.asset_issuer === balance.asset_issuer) {
              this.setState({ counter_balance: balance.balance })
            }
          })

          // const wallets = this.state.wallets.map(wallet => {
          //   if (wallet.pk === item.pk) {
          //     wallet = item
          //   }
          //   return wallet
          // })
          //
          // this.setState({ wallets: [] }, () => this.setState({ wallets, wallets_loaded: true, }))

        }
        else {

          let sponsored = account.num_sponsored;
          let sponsoring = account.num_sponsoring;
          const sponsorReserve = (Number(sponsored) + Number(sponsoring))

          item.account = account;
          item.balance = account.balances[account.balances.length-1].balance
          item.reserve  = ((account.subentry_count + sponsorReserve) * baseReserve()) + minBalance()
          item.avaliable = (item.balance - item.reserve).toFixed(2);

          this.wallets_arr.push(item);

          if (this.state.wallets.length === this.wallets_arr.length) {
            newState();
          }
        }
      })
      .catch((err) => {
        console.error(err);

        this.wallets_arr.push(item)
        if (this.state.wallets.length === this.wallets_arr.length) {
          newState();
        }
      })

    const newState = () => {

      this.setState({
        wallets: this.wallets_arr,
        wallets_loaded: true,
      }, () => {

        this.props.setWallets(this.wallets_arr)

        if (this.state.select_address) {
          this.selectAccount(this.state.select_address)
        }
      })
    }
  }



  getAssetsDB = (page) => {

    let limit = 80;
    if (!page) {
      var page = 0;
    }

    getMarketAssets(page, limit)
      .then(result => {
        // console.log('getMarketAssets result: ', result)
        // const counter_asset = result.data[0];

        if (result.data && result.data.length) {
          this.setState({
              assets_db: this.state.assets_db.concat(result.data),
            },
            () => {
              // this.getPricePair()
            })

        }
      })
      .catch(error => {
        console.log('error: ', error)
      })
  }


  selectAccount = (address) => {

    const { base_asset, counter_asset, pool_id, wallets } = this.state

    this.setState({
      base_balance: 0,
      counter_balance: 0,
      balance_shares: 0,
      // base_amount: 0,
      // counter_amount: 0,
      select_address: address,
    })

    const select_account = wallets.filter((item) => {
      return item && address === item.pk;
    })[0];

    if (!select_account.account) return

    select_account.account.balances.forEach((item, index) => {

      if (item.asset_type === 'liquidity_pool_shares') {
        if (item.liquidity_pool_id === pool_id) {
          item.shares = item.balance
          this.setState({ balance_shares: item.balance })
        }
      }

      if (item.asset_type === 'native') {

        const sponsored = select_account.account.num_sponsored;
        const sponsoring = select_account.account.num_sponsoring;
        const sponsorReserve = (Number(sponsored) + Number(sponsoring))

        item.reserve  = (((select_account.account.subentry_count + sponsorReserve) * baseReserve()) + minBalance())
        item.avaliable = (item.balance - item.reserve).toFixed(2);
        item.asset_code = 'XLM';
        item.asset_issuer = 'native';

        this.setState({
          balance_native: item.balance,
          balance_reserve: item.reserve,
        })
      }

      if (base_asset.asset_code === item.asset_code && base_asset.asset_issuer === item.asset_issuer) {
        this.setState({ base_balance: item.balance })
      }
      if (counter_asset.asset_code === item.asset_code && counter_asset.asset_issuer === item.asset_issuer) {
        this.setState({ counter_balance: item.balance })
      }
    })

    const balance_shares = select_account.account.balances.filter(item => item.asset_type === 'liquidity_pool_shares' && item.liquidity_pool_id === pool_id)

    this.setState({
      select_account: select_account,
      balance_shares: balance_shares.length && balance_shares[0].balance,
      loading: true,
      base_accept: select_account.account.balances.some(item => base_asset.asset_code === item.asset_code && base_asset.asset_issuer === item.asset_issuer),
      counter_accept: select_account.account.balances.some(item => counter_asset.asset_code === item.asset_code && counter_asset.asset_issuer === item.asset_issuer),
    },
    () => {
      console.log('SELECT ACCOUNT STATE: ', this.state)
      this.props.selectAccount(select_account)

      if (this.props.type !== 'create') {
        this.setAllShares()
      }
    })
  }


  toggleAssets = (tab) => {
    if (this.state.activeTabAssets !== tab) {
      this.setState({
        activeTabAssets: tab,
      });
    }
  };

  changeTrader = (value) => {

    let address = '';
    for (const key of Object.keys(value)) {
      if (key < 56) {
        address = (address + value[key])
      }
      else {
        this.selectAccount(address)
      }
    }
  }


  changeBaseAmount = (value) => {
    const base_amount = Number(value.target.value).toFixed(7);

    if (base_amount > 0) {
      const counter_amount = this.setAmount(base_amount, 'base')
      this.setState({ base_amount, counter_amount, amount_shares: 0, },
        () => this.setTotalShares())
      this.props.change('counter_amount', counter_amount)
    }
  }

  changeCounterAmount = (value) => {
    const counter_amount = Number(value.target ? value.target.value : value).toFixed(7);

    if (counter_amount > 0) {
      const base_amount = this.setAmount(counter_amount, 'counter')
      this.setState({ counter_amount, base_amount, amount_shares: 0, },
        () => this.setTotalShares())
      this.props.change('base_amount', base_amount)
    }
  }


  setAmount = (amount, type) => {

    const { base_asset, counter_asset, pool_not_found, pool_asset_order, pair_price } = this.state

    // const price = Number(pool_not_found ? (type === 'base' ? pair_price : 1 / pair_price ) : this.getPartPrice(type)).toFixed(7)
    const price = Number(pool_not_found ? (type === 'base' ? (1 / pair_price) : pair_price) : this.getPartPrice(type)).toFixed(7)

    console.log('setAmount price: ', price)
    console.log('setAmount amount: ', amount)

    this.setAmountUSD(amount, price, type)

    const A = pool_asset_order.A

    if (counter_asset.asset_issuer === 'native') {
      return (amount / price).toFixed(7)
    }
    else if (A.issuer && A.code !== base_asset.asset_code) {
      return (amount / price).toFixed(7)
    }

    return (amount * price).toFixed(7)
  }


  setTotalShares = () => {

    const { pool, reserves_a, base_amount, } = this.state

    const total = pool.total_shares
    const sharesA = Number((total * base_amount / reserves_a).toFixed(7))
    // const sharesB = Number((total * counter_amount / reserves_b).toFixed(7))
    // const amountPoolShares = (sharesA + sharesB)
    console.log('amountPoolShares: ', sharesA)

    this.setState({
      amount_shares: sharesA
    })
  }


  getPartPrice = (type) => {
    const { pool } = this.state

    if (pool) {

      const reserves_a = pool.reserves[0].amount
      const reserves_b = pool.reserves[1].amount
      let price

      if (type === 'base') price = (reserves_b / reserves_a).toFixed(7)
      if (type === 'counter') price = (reserves_a / reserves_b).toFixed(7)

      if (pool.reserves[0].asset)

        console.log('getPartPrice: ', price)

      return price
    }

    return 0
  }


  setAmountUSD = (amount, price, type) => {

    const { base_price, base_asset, counter_asset, } = this.state
    let part_amount_usd

    console.log('setAmountUSD amount: ', amount)
    console.log('setAmountUSD price: ', price)

    if (type === 'base' && base_asset.asset_issuer === 'native') {
      part_amount_usd = (amount * base_price)
    }
    else if (type === 'counter' && counter_asset.asset_issuer === 'native') {
      part_amount_usd = (amount * base_price)
    }
    else if (base_asset.asset_issuer !== 'native' || counter_asset.asset_issuer !== 'native') {
      if (type === 'base') {
        part_amount_usd = (amount * base_asset.price) * base_price
      }
      if (type === 'counter') {
        part_amount_usd = (amount * counter_asset.price) * base_price
      }
    }

    console.log('part_amount_usd: ', part_amount_usd)

    this.setState({
      part_amount_usd,
      liquidity_usd: (part_amount_usd * 2).toFixed(2),
    })
  }


  changeSelectBaseAsset = (asset) => {
    this.setState({
      show_assets: (this.state.page_type === 'pool' ? false : true),
      select_asset_type: 'base_asset',
      min_amount: 0,
      max_amount: 0,
      class01: 'secondary',
      class02: 'secondary',
    })
  }

  changeSelectCounterAsset = (asset) => {
    this.setState({
      show_assets: (this.state.page_type === 'pool' ? false : true),
      select_asset_type: 'counter_asset',
      min_amount: 0,
      max_amount: 0,
      class01: 'secondary',
      class02: 'secondary',
    })
  }


  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  }

  toggleSelectAssets = () => {
    this.setState({ show_assets: !this.state.show_assets })
  }

  searchAssetForm = (values) => {
    this.searchAsset(values.search_asset)
  }

  searchAssetChange = (value) => {
    this.setState({
      search_asset_code: value.target.value
    })
    this.searchAsset(value.target.value)
  }


  searchAssetClick = () => {
    // this.searchAsset(this.state.search_asset_code)
  }


  searchAsset = (assetCode) => {
    // console.log('searchAsset: ', assetCode)

    if (!assetCode || assetCode === '') {
      this.setState({
        assets_db: [],
        stellar_assets: [],
      })
      this.getAssetsDB(0)
      ToastStore.warning('Enter the name of the Asset');
    }
    else {

      const url = `${GLOBE.API_URL}/get_assets_code_issuer/?asset_code=${assetCode}&asset_issuer=`;
      axios.post(url).then(res => {
          // console.log('res: ', res)

          if (res.data.length > 0) {
            this.setState({
              assets_db: res.data,
            })
          }
          else {
            ToastStore.info('Asset not found');
          }
        })
    }
  }


  loadMoreAssetsDB = () => {
    this.page_count+=1;
    this.getAssetsDB(this.page_count);
  }


  reversePair = (e) => {
    e.preventDefault()

    const { asset_a, asset_b, base_asset, counter_asset, base_balance, counter_balance, select_address } = this.state


    this.setState({
      base_asset: counter_asset,
      counter_asset: base_asset,
      base_amount: 0,
      counter_amount: 0,
      base_balance: counter_balance,
      counter_balance: base_balance,
      asset_a: asset_b,
      asset_b: asset_a,
      // pool: false,
      // current_price: false,
      part_amount_usd: 0,
      liquidity_usd: 0,
      // base_asset_code: counter_asset.asset_code,
      // counter_asset_code: base_asset.asset_code,
    },
    () => {

      // this.props.change('counter_amount', Number(base_amount))
      // this.changeCounterAmount(base_amount)

      // this.getPool()
      // this.reversePrice()

      this.clearInputs()
      select_address && this.selectAccount(select_address)

      // if (base_amount) {
      //
      //   this.setState({
      //       counter_amount: base_amount,
      //     },
      //     () => {
      //       this.props.change('counter_amount', Number(base_amount))
      //       this.changeCounterAmount(base_amount)
      //     })
      //
      // }
    })
  }


  clearInputs = () => {
    this.props.change('base_amount', '')
    this.props.change('counter_amount', '')
  }


  clickItemAsset = (e, item) => {
    e.preventDefault()
    // console.log('item: ', item)

    const reversePairMatch = () => {
      // this.reversePair()
      this.toggleSelectAssets()
    }

    if (item === 'native' && this.state.base_asset.asset_issuer === item) { //  || this.state.counter_asset.asset_issuer === item
      // ToastStore.warning('This asset has already been selected');
      reversePairMatch()
      return
    }
    if (this.state.base_asset.asset_code === item.asset_code && this.state.base_asset.asset_issuer === item.asset_issuer) {
      // ToastStore.warning('This asset has already been selected');
      reversePairMatch()
      return
    }
    if (this.state.counter_asset.asset_code === item.asset_code && this.state.counter_asset.asset_issuer === item.asset_issuer) {
      // ToastStore.warning('This asset has already been selected');
      reversePairMatch()
      return
    }

    this.clearInputs()

    this.setState({
      current_price: false,
      base_amount: 0,
      counter_amount: 0,
      balance_shares: 0,
      amount_shares: 0,
      pool_id: false,
      pool: false,
    })

    if (this.state.select_asset_type === 'base_asset') {
      this.setState({
        base_asset: item,
        asset_a: item,
        counter_amount: 0,
      })
    }
    if (this.state.select_asset_type === 'counter_asset') {
      this.setState({
        counter_asset: item,
        asset_b: item,
        base_amount: 0,
      })
    }

    this.toggleSelectAssets()

    setTimeout(() => {

      this.getPool()

    }, 100)
  }



  addLiquidity = (type) => {

    let { base_amount, counter_amount, base_asset, counter_asset, select_address, base_accept, balance_shares,
      counter_accept, base_balance, counter_balance, select_account } = this.state

    const { manage_pool_type } = this.props

    if (!select_address) {
      ToastStore.warning('Account not selected');
      return
    }
    if (!base_amount || !counter_amount) {
      ToastStore.warning('No amount selected');
      return
    }
    if (!select_account) {
      ToastStore.warning('Account loading...');
      return
    }
    if (type === 'Withdraw' && !balance_shares) {
      ToastStore.warning('No shares to withdraw');
      return
    }
    if (Number(base_balance) === 0) {
      ToastStore.warning(`Your balance is ${base_balance} ${base_asset.asset_code}`);
      return
    }
    if (Number(counter_balance) === 0) {
      ToastStore.warning(`Your balance is ${counter_balance} ${counter_asset.asset_code}`);
      return
    }

    const confirmAddTrustline = (asset, type) => {

      const message = `<div>The selected account does not have a Trustline to <b class="text-warning">${asset.asset_code}</b>. <br> Add Trustline for <b class="text-warning">${asset.asset_code}</b> Now?</div>`
      const element = document.createElement("span");
      element.innerHTML = message;

      swal({
        title: "Confirm Trustline",
        content: element,
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
        .then((confirm) => {
          if (confirm) {
            if (type === 'base_asset') {
              this.setTrustline(base_asset, type)
            }
            if (type === 'counter_asset') {
              this.setTrustline(counter_asset, type)
            }
          }
        })
    }

    if (!base_accept) {
      confirmAddTrustline(base_asset, 'base_asset')
      return
    }
    if (!counter_accept) {
      confirmAddTrustline(counter_asset, 'counter_asset')
      return
    }

    const message = `<div>${type === 'Deposit' || type === 'create' ? `<b class="text-success">Deposit</b>` : `<b class="text-danger">Withdraw</b>`} liquidity right now?</div>`;
    const element = document.createElement("span");
    element.innerHTML = message;

    swal({
      title: "Confirm",
      content: element,
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then((confirm) => {
        if (confirm) {
          this.setLiquidity(type)
        }
      })
  }



  setTrustline = (asset, type) => {

    const { select_address } = this.state

    this.setState({ loader: true })

    Server.loadAccount(select_address)
      .then((sourceAccount) => {

        var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee: StellarSdk.BASE_FEE,
          networkPassphrase: StellarSdk.Networks.PUBLIC
        })
          .addOperation(StellarSdk.Operation.changeTrust({
            asset: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
          }))
          .setTimeout(100)
          .build();

        if (checkConnectProvider(select_address)) {
          return signConnectProvider(transaction, select_address).then(result => {
            return Server.submitTransaction(result);
          })
        }
        else {
          // transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));
          if (!getSigner(select_address).sk || !window.atob(getSigner(select_address).sk)) return
          const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(select_address).sk))
          transaction.sign(signer);

          return Server.submitTransaction(transaction);
        }

        // if (!getSigner(address).sk || !window.atob(getSigner(address).sk)) return
        // const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(address).sk))
        //
        // transaction.sign(signer);
        //
        // return Server.submitTransaction(transaction);
      })
      .then((result) => {

        if (result) {
          this.setState({ loader: false })

          if (type === 'base_asset') {
            this.setState({ base_accept: true })
          }
          if (type === 'counter_asset') {
            this.setState({ counter_accept: true })
          }

          swal({
            title: `Added Trustline!`,
            text: `Trustline to ${asset.asset_code} has been successfully added. Now ${asset.asset_code} is available on your balance.`,
            icon: "success",
          })

          this.getAccount(this.state.select_account, 'one')
        }
      })
      .catch((error) => {
        console.error('Something went wrong!', error);

        showErrorMessage(error, 'change_trust')

        this.setState({ loader: false })
        ToastStore.error('Transaction error');
      });
  }


  createLiquidityPool = (poolId) => {

    const { asset_a, asset_b, select_address, select_account, base_amount, counter_amount } = this.state

    if (!select_address) {
      ToastStore.warning('Account not selected');
      return
    }
    if (!base_amount || !counter_amount) {
      ToastStore.warning('No amount selected');
      return
    }
    if (!select_account) {
      ToastStore.warning('Account loading...');
      return
    }

    const assetA = asset_a.asset_issuer === 'native' ? new StellarSdk.Asset.native() : new StellarSdk.Asset(asset_a.asset_code, asset_a.asset_issuer)
    const assetB = asset_b.asset_issuer === 'native' ? new StellarSdk.Asset.native() : new StellarSdk.Asset(asset_b.asset_code, asset_b.asset_issuer)

    const [ A, B ] = this.orderAssets(...[assetA, assetB]);
    const poolShareAsset = new StellarSdk.LiquidityPoolAsset(A, B, StellarSdk.LiquidityPoolFeeV18);
    const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(select_address).sk))
    let ops, reverse = true

    console.log('poolId: ', poolId)
    console.log('A: ', A)
    console.log('B: ', B)

    // A:  Asset {code: 'SCOP', issuer: 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ'}
    // B:  Asset {code: 'USD', issuer: 'GDUKMGUGDZQK6YHYA5Z6AY2G4XDSZPSZ3SW5UN3ARVMO6QSRDWP5YLEX'}

    if (A.code === asset_a.asset_issuer) {
      reverse = false
    }
    else if (A.code === asset_a.asset_code && A.issuer === asset_a.asset_issuer) {
      reverse = false
    }

    console.log('reverse: ', reverse)

    const exactPrice = !reverse ? (base_amount / counter_amount) : (counter_amount / base_amount);
    const amount_a = !reverse ? base_amount : counter_amount
    const amount_b = !reverse ? counter_amount : base_amount

    console.log('exactPrice: ', exactPrice)
    console.log('amount_a: ', amount_a)
    console.log('amount_b: ', amount_b)

    const minPrice = (exactPrice - (exactPrice * 0.05)).toFixed(7); // 0.10
    const maxPrice = (exactPrice + (exactPrice * 0.05)).toFixed(7); // 0.10

    ops = [
      StellarSdk.Operation.changeTrust({
        asset: poolShareAsset,
        // limit: "100000"
      }),
      StellarSdk.Operation.liquidityPoolDeposit({
        liquidityPoolId: poolId,
        maxAmountA: amount_a,
        maxAmountB: amount_b,
        minPrice: minPrice,
        maxPrice: maxPrice,
      })
    ]

    // ops.unshift(
    //   StellarSdk.Operation.changeTrust({
    //     asset: poolShareAsset,
    //     // limit: "100000"
    //   }),
    // )

    console.log('ops: ', ops)

    this.setState({ loader: true })

    Server.loadAccount(select_address).then(source => {

      let tx = buildTx(source, signer, ...ops);
      console.log('tx: ', tx)

      return Server.submitTransaction(tx);

    }).then(result => {
      console.log('result create: ', result)

      if (result.hash) {

        this.setState({
          loader: false,
          hash: result.hash,
          operation: false,
        })

        const message = `<div><span class="text-success">Deposited</span> <b class="text-info">${numFormat(base_amount, 7)}</b> <b class="text-warning">${asset_a.asset_code}</b> / <b class="text-info">${numFormat(counter_amount, 7)}</b> <b class="text-warning">${asset_b.asset_code}</b></div> <br><small>hash: <a href="/transaction/${result.hash}" target="_blank"><b>${shortAddr(result.hash, 4)}</b></a></small>`
        const element = document.createElement("span");
        element.innerHTML = message;

        swal({
          title: `Deposited Liquidity!`,
          content: element,
          icon: "success",
        })

        this.getOperation(result.hash)
        // this.getAllAccounts()
        this.getAccount(select_address, 'one')

        // Save to DB ...
      }

    }).catch(error => {
      this.setState({ loader: false })
      console.log('error: ', error)
      showErrorMessage(error, 'liquidity')
    })
  }



  setLiquidity = (type) => {

    console.log('type: ', type)

    const { select_address, base_amount, counter_amount, balance_shares, pool_not_found } = this.state
    const { pool, asset_a, asset_b, pool_id } = type === 'create' ? this.state : this.props

    if (pool_not_found) {
      this.createLiquidityPool(pool_id)
      return
    }

    const assetA = asset_a.asset_issuer === 'native' ? new StellarSdk.Asset.native() : new StellarSdk.Asset(asset_a.asset_code, asset_a.asset_issuer)
    const assetB = asset_b.asset_issuer === 'native' ? new StellarSdk.Asset.native() : new StellarSdk.Asset(asset_b.asset_code, asset_b.asset_issuer)

    const [ A, B ] = this.orderAssets(...[assetA, assetB]);
    const poolShareAsset = new StellarSdk.LiquidityPoolAsset(A, B, StellarSdk.LiquidityPoolFeeV18);
    const poolId = StellarSdk.getLiquidityPoolId("constant_product", poolShareAsset.getLiquidityPoolParameters()).toString("hex");
    const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(select_address).sk))
    let ops


    console.log('poolId: ', poolId)
    console.log('A: ', A)
    console.log('B: ', B)

    let exactPrice
    let reverse = true

    if (pool.reserves[0].asset === asset_a.asset_issuer) {
      reverse = false
    }
    if  (pool.reserves[0].asset.split(':')[0] === asset_a.asset_code
      && pool.reserves[0].asset.split(':')[1] === asset_a.asset_issuer) {
      reverse = false
    }

    exactPrice = !reverse ? (base_amount / counter_amount) : (counter_amount / base_amount);

    const amount_a = !reverse ? base_amount : counter_amount
    const amount_b = !reverse ? counter_amount : base_amount

    console.log('reverse: ', reverse)
    console.log('amount_a: ', amount_a)
    console.log('amount_b: ', amount_b)
    console.log('exactPrice: ', exactPrice)

    if (type === 'Deposit' || type === 'create') {

      const minPrice = Number(exactPrice - (exactPrice * 0.05)).toFixed(7); // 0.10
      const maxPrice = Number(exactPrice + (exactPrice * 0.05)).toFixed(7); // 0.10

      ops = [
        StellarSdk.Operation.liquidityPoolDeposit({
          liquidityPoolId: pool.id,
          maxAmountA: amount_a,
          maxAmountB: amount_b,
          minPrice, // : minPrice.toFixed(7),
          maxPrice, // : maxPrice.toFixed(7),
        })
      ]

      if (!balance_shares) {
        ops.unshift(
          StellarSdk.Operation.changeTrust({
            asset: poolShareAsset,
            // limit: "100000"
          }),
        )
      }
    }

    if (type === 'Withdraw') {

      const minAmountA = (amount_a - (amount_a * 0.05)).toFixed(7);
      const minAmountB = (amount_b - (amount_b * 0.05)).toFixed(7);

      console.log('minAmountA: ', minAmountA)
      console.log('minAmountB: ', minAmountB)

      ops = [
        StellarSdk.Operation.liquidityPoolWithdraw({
          liquidityPoolId: pool.id,
          amount: (pool.total_shares * amount_a / pool.reserves[0].amount).toFixed(7),
          minAmountA: minAmountA,
          minAmountB: minAmountB,
        })
      ]
    }

    console.log('ops: ', ops)

    this.setState({ loader: true, hash: false })

    Server.loadAccount(select_address).then(source => {

      let tx = buildTx(source, signer, ...ops);
      console.log('tx: ', tx)

      return Server.submitTransaction(tx);

    }).then(result => {
      console.log('result: ', result)

      if (result) {

        this.setState({
          loader: false,
          hash: result.hash,
          operation: false,
        })

        const message = `<div>${type === 'Deposit' || type === 'create' ? `<span class="text-success">Deposited</span>` :
          `<span className="text-danger">Withdrawn</span>` } <b class="text-info">${numFormat(base_amount, 7)}</b> <b class="text-warning">${asset_a.asset_code}</b> / <b class="text-info">${numFormat(counter_amount, 7)}</b> <b class="text-warning">${asset_b.asset_code}</b></div> <br><small>hash: <a href="/transaction/${result.hash}" target="_blank"><b>${shortAddr(result.hash, 4)}</b></a></small>`
        const element = document.createElement("span");
        element.innerHTML = message;

        swal({
          title: `${type === 'Deposit' || type === 'create' ? 'Deposited' : 'Withdrawn'} Liquidity!`,
          content: element,
          icon: "success",
        })

        this.getOperation(result.hash)
        // this.getAllAccounts()
        this.getAccount(select_address, 'one')

        // Save to DB ...
      }

    }).catch(error => {
      this.setState({ loader: false })
      console.log('error: ', error)
      showErrorMessage(error, 'liquidity')
    })


    return;



    // console.log('select_address: ', select_address)
    // console.log('signer: ', signer)
    // console.log('pool: ', pool.id)
    // console.log('base_amount: ', base_amount)
    // console.log('counter_amount: ', counter_amount)
    //
    // let exactPrice = base_amount / counter_amount;
    // const minPrice = exactPrice - (exactPrice * 0.10); // 0.10
    // const maxPrice = exactPrice + (exactPrice * 0.10); // 0.10
    //
    // Server.loadAccount(select_address).then(sourceAccount => {
    //   console.log('sourceAccount: ', sourceAccount)
    //
    //   let transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
    //     fee: StellarSdk.BASE_FEE,
    //     networkPassphrase: StellarSdk.Networks.PUBLIC
    //   })
    //     .addOperation(StellarSdk.Operation.liquidityPoolDeposit({
    //       liquidityPoolId: pool.id,
    //       maxAmountA: base_amount,
    //       maxAmountB: counter_amount,
    //       minPrice: minPrice.toFixed(7),
    //       maxPrice: maxPrice.toFixed(7),
    //     }))
    //     .setTimeout(100)
    //     .build();
    //
    //   const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(select_address).sk))
    //   transaction.sign(signer);
    //
    //   return Server.submitTransaction(transaction);
    //
    //   // addLiquidity(source, signer, pool.id, base_amount, counter_amount).then(result => {
    //   //   this.setState({ loader: false })
    //   //   console.log('addLiquidity result: ', result)
    //   // }).catch(error => {
    //   //   this.setState({ loader: false })
    //   //   console.log('error: ', error)
    //   // })
    // })
    //   .then(result => {
    //     this.setState({ loader: false })
    //     console.log('addLiquidity result: ', result)
    //   })
    //   .catch(error => {
    //     this.setState({ loader: false })
    //     console.log('error: ', error)
    //     showErrorMessage(error, 'liquidity')
    //   })




    return

    Server.loadAccount(select_address)
      .then((sourceAccount) => {

        let transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee: StellarSdk.BASE_FEE,
          networkPassphrase: StellarSdk.Networks.PUBLIC
        })
          // .addOperation(StellarSdk.Operation.pathPaymentStrictReceive({ sendAsset, sendMax, destination, destAsset, destAmount, path }))
          .setTimeout(100)
          .build();

        if (checkConnectProvider(select_address)) {
          return signConnectProvider(transaction, select_address).then(result => {
            return Server.submitTransaction(result);
          })
        }
        else {

          const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(select_address).sk))
          transaction.sign(signer);

          return Server.submitTransaction(transaction);
        }
      })
      .then((result) => {

        if (result) {

          this.setState({
            loader: false,
            hash: result.hash,
            operation: false,
          })

          const message = `<b class="text-warning">${this.state.base_asset.asset_code}</b> to <b class="text-warning">${this.state.counter_asset.asset_code}</b><br><br><small>hash: <a href="/transaction/${result.hash}" target="_blank"><b>${shortAddr(result.hash, 4)}</b></a></small>`
          const element = document.createElement("span");
          element.innerHTML = message;

          swal({
            title: `Successful Swap!`,
            content: element,
            icon: "success",
          })

          this.getOperation(result.hash)
          this.getAccount(this.state.select_account, 'one')

          // Save to DB ...
        }
      })
      .catch((error) => {
        console.error('Error! ', error);

        this.setState({ loader: false })
        showErrorMessage(error, 'path_payment')
      });
  }



  reversePrice = (e) => {
    e && e.preventDefault()

    const { base_asset_code, counter_asset_code, is_reverse_price, pool, base_asset, counter_asset, pool_asset_order } = this.state

    let current_price, base_price, counter_price

    if (pool) {
      base_price = (pool.reserves[0].amount / pool.reserves[1].amount).toFixed(7)
      counter_price = (pool.reserves[1].amount / pool.reserves[0].amount).toFixed(7)
    }
    else {
      base_price = Number(parseNumber(base_asset.price)).toFixed(7)
      counter_price = Number(parseNumber(counter_asset.price)).toFixed(7)
    }

    current_price = !is_reverse_price ? counter_price : base_price
    console.log('current_price: ', current_price)

    if (pool_asset_order.A.code === 'XLM' && !pool_asset_order.A.issuer) {
      if (base_asset.asset_code !== pool_asset_order.A.code && base_asset.asset_issuer !== pool_asset_order.A.issuer) {
        current_price = !is_reverse_price ? counter_price : base_price
      }
    }
    else if (base_asset.asset_code !== pool_asset_order.A.code && base_asset.asset_issuer !== pool_asset_order.A.issuer) {
      current_price = !is_reverse_price ? base_price : counter_price
    }
    else {
      current_price = !is_reverse_price ? counter_price : base_price
    }

    if (!pool) {
      current_price = (counter_price / base_price).toFixed(7)
    }


    this.setState({
      current_price,
      is_reverse_price: !is_reverse_price,
      base_asset_code: counter_asset_code,
      counter_asset_code: base_asset_code,
    })
  }


  getOperation = (hash) => {

    getOperationForTransaction(hash, 1).then(result => {

      if (result) {
        this.setState({
          operation: result.records[0],
        })
      }
    }).catch(error => {
      console.log('error: ', error)
    })
  }


  renderFirstAsset = (item) => {
    return <Link to={item.source_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.source_asset_code}-XLM/${item.source_asset_issuer}/native`} className="text-warning"> <b>{item.source_asset_type === 'native' ? 'XLM' : item.source_asset_code}</b></Link>
  }


  closeLastTx = () => {
    this.setState({ hash: false })
  }


  allShares = (e) => {

    if (e.target) {
      this.setState({ all_reserves: e.target.checked },
        () => this.setAllShares())
    }
  }


  setAllShares = () => {

    const { balance_shares, all_reserves, } = this.state
    const { total_shares, reserves } = this.props.pool

    let amountA = '', amountB = ''

    if (balance_shares && all_reserves) {

      amountA = (balance_shares / total_shares * reserves[0].amount).toFixed(7)
      amountB = (balance_shares / total_shares * reserves[1].amount).toFixed(7)

      this.setState({
        base_amount: amountA,
        counter_amount: amountB,
      })

      const price = this.getPartPrice('base')
      this.setAmountUSD(amountA, price, 'base')
    }
    else {
      this.setState({
        part_amount_usd: 0,
        liquidity_usd: 0,
      })
    }

    this.props.change('base_amount', amountA)
    this.props.change('counter_amount', amountB)
  }


  // getOrderbookPrice = () => {
  //
  //   const { base_asset, counter_asset } = this.state
  //
  //   // this.setState({ current_price: false, })
  //
  //   const asset_a = (base_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native() : new StellarSdk.Asset(base_asset.asset_code, base_asset.asset_issuer)
  //   const asset_b = (counter_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native() : new StellarSdk.Asset(counter_asset.asset_code, counter_asset.asset_issuer)
  //
  //   // console.log('asset_a: ', asset_a)
  //   // console.log('asset_b: ', asset_b)
  //   //
  //   // strictSendPaths(asset_a, 0.1, [asset_b]).then(result => {
  //   //   console.log('strictSendPaths result: ', result)
  //   //
  //   //   if (result && result.records.length) {
  //   //     const min_amount = result.records[result.records.length-1].destination_amount
  //   //     const counter_amount = result.records[0].destination_amount
  //   //
  //   //     // this.props.change('counter_amount', counter_amount)
  //   //
  //   //     // this.setState({
  //   //     //     counter_amount,
  //   //     //     min_amount,
  //   //     //     path: result.records[0].path,
  //   //     //     path_send_loading: false,
  //   //     //     path_loading: false,
  //   //     //   },
  //   //     //   () =>  this.getPriceImpact())
  //   //   }
  //   // }).catch(error => {
  //   //   console.log('error: ', error)
  //   //   // this.setState({
  //   //   //   path_send_loading: false,
  //   //   //   path_loading: false,
  //   //   // })
  //   // })
  //
  //   getOrderbook(asset_a, asset_b, 1).then(result => {
  //     console.log('getOrderbook result: ', result)
  //
  //     // const current_price = result.bids.length ? (result.bids[0].price_r.d / result.bids[0].price_r.n).toFixed(7) : false
  //     const current_price = result.bids.length ? result.bids[0].price : 0
  //
  //     this.setState({
  //       orderbook_pair: result,
  //       current_price,
  //       base_asset_code: base_asset.asset_code,
  //       counter_asset_code: counter_asset.asset_code,
  //     })
  //   })
  // }


  // getPricePair = () => {
  //
  //   this.setState({
  //     current_price: false,
  //   })
  //
  //   const source_amount = 1;
  //   const source_asset = (this.state.base_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native() : new StellarSdk.Asset(this.state.base_asset.asset_code, this.state.base_asset.asset_issuer)
  //   const destination_asset = (this.state.counter_asset.asset_issuer === 'native') ? [new StellarSdk.Asset.native()] : [new StellarSdk.Asset(this.state.counter_asset.asset_code, this.state.counter_asset.asset_issuer)]
  //
  //   strictSendPaths(source_asset, source_amount, destination_asset).then(result => {
  //     // console.log('strictSendPaths result: ', result)
  //
  //     if (result && result.records.length) {
  //       const current_price = (result.records[0].source_amount / result.records[0].destination_amount).toFixed(7)
  //
  //       this.setState({
  //           start_path_found: result.records,
  //           current_price,
  //           base_asset_code: this.state.base_asset.asset_code,
  //           counter_asset_code: this.state.counter_asset.asset_code,
  //         },
  //         // () =>  this.getPriceImpact()
  //       )
  //     }
  //   })
  // }

  togglePopoverBase = () => {
    this.setState(prevState => ({
      popoverBaseOpen: !prevState.popoverBaseOpen
    }));
  }

  togglePopoverCounter = () => {
    this.setState(prevState => ({
      popoverCounterOpen: !prevState.popoverCounterOpen
    }));
  }



  render() {

    console.log('DW this.state: ', this.state)
    console.log('DW this.props: ', this.props)

    const { handleSubmit, manage_pool_type } = this.props;
    const { base_asset, counter_asset, base_amount, counter_amount, native_asset, base_balance, show_assets, pool_asset_order,
      counter_balance, base_accept, counter_accept, select_address, pool, pool_id, current_price, amount_shares,
      operation, page_type, liquidity_usd, part_amount_usd, wallets_loaded, select_account, balance_shares, hash, wallets, all_reserves,
    } = this.state

    return (
      <Card>
        <CardBody className="swap p-0">

          <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

          { this.state.loader ? <div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div> : null }

          {
            wallets && !wallets_loaded ?
              <div className={'text-center'}>
                <PropagateLoader
                  css={loaderAccounts}
                  color={'#80808014'}
                  loading={true}
                  size={9}
                />
              </div>
              : null
          }

          <form className="material-form">
            <div>
              {
                wallets ?

                  <div>
                    <Field
                      name="select_addr"
                      component={renderTextField}
                      select
                      label="Select Account"
                      onChange={(e) => {
                        this.changeTrader(e)
                      }} >
                      {
                        wallets.length ? wallets.map((item, index) => {

                          if (item) {
                            return (
                              <MenuItem
                                key={index}
                                style={{fontSize: '12px'}}
                                className={`material-form__option ${item.provider ? 'connect-provider' : ''}`}
                                value={item.pk}>
                                <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 4)}</b> <small>&nbsp;/ {item.title} / <b>{item.balance ? numFormat(item.balance, 7) : null} XLM</b></small> { getIconConnectProvider(item) }
                              </MenuItem>
                            )
                          }
                        }) : null
                      }
                    </Field>

                    {
                      select_account ?
                        <div style={{marginTop: -9}}>
                          <h5 className="subhead">
                            {
                              balance_shares ?
                                <span><b className="text-info">{numFormat(balance_shares, 7)} <font className="text-warning">SHARES</font></b> &nbsp; / &nbsp;</span>
                              : null
                            }
                            Avaliable: <b className="text-info">{numFormat(select_account.avaliable, 7)} <font className="text-warning">XLM</font></b>
                            <span className="float-right">Reserve: <b className="text-info">{numFormat(select_account.reserve, 7)} <font className="text-warning">XLM</font></b></span>
                          </h5>
                          <br/>
                        </div>
                        : null
                    }
                  </div>

                : null
              }
            </div>
          </form>

          {
            show_assets ?
              <div>
                <div className="card__title" style={{marginBottom: 19}}>
                  <h5 className="bold-text">
                    <a href={'#'} onClick={this.toggleSelectAssets} className="panel__btn" type="button">
                      <i className="fa fa-arrow-left"></i>
                    </a> &nbsp;
                    Select Asset
                  </h5>
                </div>
                {/*<div className="panel__btns">*/}
                {/*  <div className={'panel__btn'} style={{marginTop: -3}}>*/}
                {/*    <a href="#" onClick={this.toggleSelectAssets} className=" text-secondary">*/}
                {/*      <i className="fa fa-close"></i>*/}
                {/*    </a>*/}
                {/*  </div>*/}
                {/*</div>*/}

                <div className="tabs tabs--justify tabs--bordered-bottom">
                  <div className="tabs__wrap">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTabAssets === '1' })}
                          onClick={() => {
                            this.toggleAssets('1');
                          }}
                        >
                          <b>ALL ASSETS</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTabAssets === '2' })}
                          onClick={() => {
                            this.toggleAssets('2');
                          }}
                        >
                          <b>MY ASSETS</b>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTabAssets} style={{height: 470}}>
                      <TabPane tabId="1">

                        <form className="form modal-form" onSubmit={handleSubmit(this.searchAssetForm)}>
                          <div className="form__form-group">
                            <div className="form__form-group-field">
                              <Field
                                name="search_asset"
                                component="input"
                                type="text"
                                placeholder="Asset Code"
                                onChange={this.searchAssetChange}
                              />
                              <button
                                type="button"
                                className={`form__form-group-button`}
                                onClick={this.searchAssetClick}
                              ><i className="fa fa-search"></i></button>
                            </div>
                          </div>
                        </form>

                        {/*{*/}
                        {/*  this.state.select_asset_type === 'base_asset' && this.state.base_asset.asset_issuer !== 'native' ?*/}
                        {/*    <a href={`#`} onClick={(e) => this.clickItemAsset(e, 'native')}>*/}
                        {/*      <Card style={{paddingBottom: 6}}>*/}
                        {/*        <CardBody className="item-asset text-secondary">*/}
                        {/*          <div className="wr-asset-item-icon">*/}
                        {/*            <img className="asset-item-icon" src={this.state.native_asset.image} />*/}
                        {/*          </div>*/}
                        {/*          <b className="text-info" style={{fontSize: 15}}>{this.state.native_asset.asset_code}</b> <small><b>{this.state.native_asset.name}</b> / <span className={'text-info'}>{this.state.native_asset.home_domain}</span></small> <span style={{float: 'right'}}> <small className={`text-${this.state.changeColor}`}><b>{this.state.change > 0 ? '+' : ''}{numFormat(this.state.change, 2)}%</b></small></span>*/}
                        {/*        </CardBody>*/}
                        {/*      </Card>*/}
                        {/*    </a>*/}
                        {/*    : null*/}
                        {/*}*/}

                        {/*<div style={{height: 476}}>*/}
                        {/*             */}
                        {/*</div>       */}

                        <a href={`#`} onClick={(e) => this.clickItemAsset(e, native_asset)}>
                          <Card style={{paddingBottom: 6}}>
                            <CardBody className="item-asset text-secondary">
                              <div className="wr-asset-item-icon">
                                <img className="asset-item-icon" src={native_asset.image} />
                              </div>
                              <b className="text-info" style={{fontSize: 17}}>{native_asset.asset_code}</b> <small><b>{native_asset.name}</b> / <span>{native_asset.home_domain}</span></small>
                              {/*<span style={{float: 'right'}}> <small className={`text-${this.state.changeColor}`}><b>{this.state.change > 0 ? '+' : ''}{numFormat(this.state.change, 2)}%</b></small></span>*/}
                              <div>
                                <span className="text-secondary"><small><i className={'fa fa-trophy'}></i> <b>native</b></small></span>
                                <span style={{float: 'right'}}><small><b></b></small></span>
                              </div>
                            </CardBody>
                          </Card>
                        </a>


                        {
                          this.state.select_asset_type === 'base_asset' ?
                            this.state.assets_db.length ?
                              this.state.assets_db.map((item, index) => {

                                return (
                                  <a key={index}
                                     href={'#'}
                                     onClick={(e) => this.clickItemAsset(e, item)}
                                  >
                                    <Card style={{paddingBottom: 6}}>
                                      <CardBody className="item-asset text-secondary">
                                        <div className="wr-asset-item-icon">
                                          <img className="asset-item-icon" src={item.image === 'undefined' || item.image === '' ? '/img/digitalcoin.png' : item.image} />
                                        </div>
                                        <b className={'text-info'} style={{fontSize: 17}}>{item.asset_code}</b> <small><b>{item.name}</b> / <span>{item.home_domain}</span></small>
                                        <div style={{marginTop: -4}}>
                                          <span className="text-secondary"><small><i className={'fa fa-bar-chart'}></i> <b>{numFormat(item.base_volume)}</b> XLM</small> &nbsp;&nbsp; <small><i className={'fa fa-user'}></i> <b>{numFormat(item.holders)}</b></small> &nbsp;&nbsp; <small><i className={'fa fa-trophy'}></i> <b>{item.expert_rating}</b></small></span>
                                          <span style={{float: 'right'}}><small className={`text-${item.change_24h_color}`}><b>{item.change24}%</b></small></span>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </a>
                                )
                              })
                              : <div className="panel__refresh"><LoadingIcon /></div>
                            : null
                        }


                        {
                          this.state.select_asset_type === 'counter_asset' ?
                            this.state.assets_db.length ?
                              this.state.assets_db.map((item, index) => {
                                // console.log('item: ', item)

                                return (
                                  <a key={index}
                                     href={'#'}
                                     onClick={(e) => this.clickItemAsset(e, item)}
                                  >
                                    <Card style={{paddingBottom: 6}}>
                                      <CardBody className="item-asset text-secondary">
                                        <div className="wr-asset-item-icon">
                                          <img className="asset-item-icon" src={item.image === 'undefined' || item.image === '' ? '/img/digitalcoin.png' : item.image} />
                                        </div>
                                        <b className="text-info" style={{fontSize: 17}}>{item.asset_code}</b> <small><b>{item.name}</b> / <span className={'text-info'}>{item.home_domain}</span></small>
                                        <div>
                                          <span className="text-secondary"><small><i className={'fa fa-bar-chart'}></i> <b className={'text-info'}>{numFormat(item.base_volume)}</b> XLM</small> &nbsp;&nbsp; <small><i className={'fa fa-users'}></i> <b className={'text-info'}>{numFormat(item.holders)}</b></small> &nbsp;&nbsp; <small><i className={'fa fa-trophy'}></i> <b className={'text-info'}>{item.expert_rating}</b></small></span>
                                          <span style={{float: 'right'}}><small className={`text-${item.change_24h_color}`}><b>{item.change24}%</b></small></span>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </a>
                                )
                              })
                              : <div className="panel__refresh"><LoadingIcon /></div>
                            : null
                        }

                        {
                          !this.state.search_asset_code ?
                            <div className="text-center">
                              <Button
                                size="sm"
                                style={{margin: '13px 0 0'}}
                                onClick={() => this.loadMoreAssetsDB()}>Load more</Button>
                            </div>
                            : null
                        }
                      </TabPane>

                      <TabPane tabId="2">
                        {
                          this.state.account ?
                            this.state.account.balances.map((item, index) => {
                              return (
                                <div key={index}>
                                  {
                                    item.asset_type != 'native' ?
                                      <a href={'#'}
                                         onClick={(e) => this.clickItemAsset(e, item)}
                                      >
                                        {/*<a href={`/trade/${item.asset_code}-${this.state.base_asset.asset_code}/${item.asset_issuer}/${this.state.base_asset.asset_issuer}`}*/}
                                        {/*>*/}
                                        <Card style={{paddingBottom: 6}}>
                                          <CardBody className="item-asset text-secondary">
                                            <b className="text-warning" style={{fontSize: 14}}>{item.asset_code}</b> <small>(<b className="text-info">{numFormat(item.balance, 7)}</b>)</small> <span style={{float: 'right'}}><small> <font className="text-danger">selling</font> <b className="text-info">{numFormat(item.selling_liabilities, 7)}</b> / <font className="text-success">buying</font> <b className="text-info">{numFormat(item.buying_liabilities, 7)}</b></small></span>
                                          </CardBody>
                                        </Card>
                                      </a>
                                      :
                                      null
                                  }
                                </div>
                              )
                            })
                            :
                            <p>Select Account to view all Balances (Assets)</p>
                        }
                      </TabPane>
                    </TabContent>
                  </div>
                </div>

              </div>

              : // next condition

              <div>

                <div className={'mb-8 relative'}>

                  <span>{page_type === 'pool' ? <span className={'text-secondary'}>{manage_pool_type === 'Deposit' ? <span>Deposit</span> : <span>Withdraw</span>}</span>  : 'Select pair'} </span>

                  {
                    page_type === 'pool' && manage_pool_type === 'Withdraw' && select_account ?
                      <form className="form absolute-right">
                        <div className="form__form-group form__form-group-field">
                          <Field
                            name={`stream-trades`}
                            component={renderCheckBoxField}
                            label={'All reserves'}
                            // className={'colored-click'}
                            // color={'#f6da6e'}
                            onChange={(e) => this.allShares(e)}
                          />
                        </div>
                      </form>
                    : null
                  }

                </div>

                <form className={'form'}>
                  <div className="form__form-group swap-item">
                    <small className="form__form-group-label">Amount &nbsp; {part_amount_usd ? <span className={'text-secondary'}>${numFormat(part_amount_usd, 2)}</span> : null}</small> {' '}
                    <span style={{position: "relative"}}>
                            <BeatLoader
                              css={loaderStyle}
                              color={"#70bbfd"}
                              loading={this.state.path_receive_loading}
                              size={8}
                            />
                          </span>
                    <small className={'balance-label text-secondary'}>
                      {
                        select_address ?
                          base_accept  ?
                            <span>
                              {/*<i className={'fa fa-check-circle-o text-success'}></i> {' '}*/}
                              <b className={'text-info'}>{numFormat(base_balance, 7)}</b> { base_asset.asset_code } {' '}
                              {/*<i id="popover-base" onClick={this.togglePopoverBase} className="fa fa-question-circle-o fs-14"></i> {' '}*/}
                              {/*<Popover*/}
                              {/*  placement="top"*/}
                              {/*  isOpen={this.state.popoverBaseOpen}*/}
                              {/*  target="popover-base"*/}
                              {/*  trigger="legacy"*/}
                              {/*  toggle={this.togglePopoverBase}*/}
                              {/*>*/}
                              {/*<PopoverBody>*/}
                              {/*  <br/>*/}
                              {/*  <ButtonToolbar>*/}
                              {/*    {*/}
                              {/*      base_asset.asset_issuer === 'native' ?*/}
                              {/*        <div>*/}
                              {/*          <Link to={`/fiat`}*/}
                              {/*                target={'_blank'}*/}
                              {/*                className={'btn btn-primary btn-sm'} >Buy <b>XLM</b></Link>*/}
                              {/*        </div>*/}
                              {/*        :*/}
                              {/*        <div>*/}
                              {/*          <Link to={`/swap/${base_asset.asset_code}-${counter_asset.asset_code}/${base_asset.asset_issuer}/${counter_asset.asset_issuer}`}*/}
                              {/*                target={'_blank'}*/}
                              {/*                className={'btn btn-primary btn-sm'} >Swap <b>{base_asset.asset_code}</b></Link>*/}
                              {/*          <Link to={`/trade/${base_asset.asset_code}-${counter_asset.asset_code}/${base_asset.asset_issuer}/${counter_asset.asset_issuer}`}*/}
                              {/*                target={'_blank'}*/}
                              {/*                className={'btn btn-primary btn-sm'} >Trade <b>{base_asset.asset_code}</b></Link>*/}
                              {/*        </div>*/}
                              {/*    }*/}

                              {/*    </ButtonToolbar>*/}
                              {/*  </PopoverBody>*/}
                              {/*</Popover>*/}
                            </span>
                            :
                            <span><i className={'fa fa-minus-circle text-secondary'}></i> No Trustline</span>
                          :
                          <span>Account not selected</span>
                      }
                    </small>
                    <div className="form__form-group-field">
                      <Field
                        name="base_amount"
                        className={'input-swap'}
                        component="input"
                        type="text"
                        placeholder="0.00"
                        disabled={all_reserves || !current_price || !pool_id ? true : false}
                        onChange={this.changeBaseAmount}
                      />
                      <div className="form__form-group-icon select-asset-icon">
                        <a href="#"
                           className={'text-secondary'}
                           onClick={this.changeSelectBaseAsset}>
                          <div>
                            <img src={base_asset.image}
                                 className={'icon-asset'}
                                 alt={base_asset.name}
                            />
                            <b className={'swap-asset-code'}>{base_asset.asset_code}</b>
                            <div className={'swap-home-domain'}>{base_asset.home_domain}</div>
                          </div>
                          { page_type === 'pools' ? <div className={'arrow'}><i className="fa fa-angle-down"></i></div> : null }
                        </a>
                      </div>
                    </div>
                  </div>
                </form>

                {
                  manage_pool_type === 'create' ?
                    <div className={'reverse-assets'}>
                      <a href="#" onClick={this.reversePair} className={'text-secondary'}><i className="fa fa-arrow-down"></i></a>
                    </div>
                  : null
                }

                <form className="form" onSubmit={handleSubmit}>

                  <div className="form__form-group swap-item">
                    <small className="form__form-group-label">Amount &nbsp; {part_amount_usd ? <span className={'text-secondary'}>${numFormat(part_amount_usd, 2)}</span> : null}</small> {' '}
                    <span style={{position: "relative"}}>
                            <BeatLoader
                              css={loaderStyle}
                              color={"#70bbfd"}
                              loading={this.state.path_send_loading}
                              size={8}
                            />
                          </span>

                    <small className={'balance-label text-secondary'}>
                      {
                        select_address ?
                          counter_accept ?
                            <span>
                              {/*<i className={'fa fa-check-circle-o text-success'}></i> {' '}*/}
                              <b className={'text-info'}>{numFormat(counter_balance, 7)}</b> { counter_asset.asset_code } {' '}
                              {/*<i id="popover-counter" onClick={this.togglePopoverCounter} className="fa fa-question-circle-o fs-14"></i> {' '}*/}
                              {/*<Popover*/}
                              {/*  placement="top"*/}
                              {/*  isOpen={this.state.popoverCounterOpen}*/}
                              {/*  target="popover-counter"*/}
                              {/*  trigger="legacy"*/}
                              {/*  toggle={this.togglePopoverCounter}*/}
                              {/*>*/}
                              {/*<PopoverBody>*/}
                              {/*  <br/>*/}
                              {/*  <ButtonToolbar>*/}
                              {/*    {*/}
                              {/*      counter_asset.asset_issuer === 'native' ?*/}
                              {/*        <div>*/}
                              {/*          <Link to={`/fiat`}*/}
                              {/*                target={'_blank'}*/}
                              {/*                className={'btn btn-primary btn-sm'} >Buy <b>XLM</b></Link>*/}
                              {/*        </div>*/}
                              {/*        :*/}
                              {/*        <div>*/}
                              {/*          <Link to={`/swap/${base_asset.asset_code}-${counter_asset.asset_code}/${base_asset.asset_issuer}/${counter_asset.asset_issuer}`}*/}
                              {/*                target={'_blank'}*/}
                              {/*                className={'btn btn-primary btn-sm'} >Swap <b>{counter_asset.asset_code}</b></Link>*/}
                              {/*          <Link to={`/trade/${base_asset.asset_code}-${counter_asset.asset_code}/${base_asset.asset_issuer}/${counter_asset.asset_issuer}`}*/}
                              {/*                target={'_blank'}*/}
                              {/*                className={'btn btn-primary btn-sm'} >Trade <b>{counter_asset.asset_code}</b></Link>*/}
                              {/*        </div>*/}
                              {/*    }*/}

                              {/*    </ButtonToolbar>*/}
                              {/*  </PopoverBody>*/}
                              {/*</Popover>*/}
                            </span>
                            :
                            <span><i className={'fa fa-minus-circle text-secondary'}></i> No Trustline</span>
                          :
                          <span>Account not selected</span>
                      }
                    </small>

                    <div className="form__form-group-field">
                      <Field
                        name="counter_amount"
                        className={'input-swap'}
                        component="input"
                        type="text"
                        placeholder="0.00"
                        disabled={all_reserves || !current_price || !pool_id ? true : false}
                        onChange={this.changeCounterAmount}
                      />
                      <div className="form__form-group-icon select-asset-icon">
                        <a href="#"
                           className={'text-secondary'}
                           onClick={this.changeSelectCounterAsset}>
                          <div>
                            <img src={counter_asset.image}
                                 className={'icon-asset'}
                                 alt={counter_asset.name}
                            />
                            <b className={'swap-asset-code'}>{counter_asset.asset_code}</b>
                            <div className={'swap-home-domain'}>{counter_asset.home_domain}</div>
                          </div>
                          { page_type === 'pools' ? <div className={'arrow'}><i className="fa fa-angle-down"></i></div> : null }
                        </a>
                      </div>
                    </div>
                  </div>
                </form>


                <div>

                  {
                    current_price ? //
                      <div className={'text-right current-price'}>
                        {/*<small className={'text-secondary'}>1 {this.state.counter_asset_code} = <b>{base_asset.asset_issuer !== 'native' || counter_asset.asset_issuer !== 'native' && is_reverse ? current_price : numFormat(1 / current_price, 7)}</b> {this.state.base_asset_code} &nbsp;*/}
                        <small className={'text-secondary'}>1 {this.state.base_asset_code} = <b>{numFormat(current_price, 7)}</b> {this.state.counter_asset_code} &nbsp;
                          <a href="#" onClick={this.reversePrice}> <i className="fa fa-refresh text-secondary"></i></a>
                        </small>
                      </div>
                      :
                      <div className={'text-right current-price'}>
                        <BeatLoader
                          css={loaderPrice}
                          color={"#70bbfd"}
                          loading={!current_price}
                          size={8}
                        />
                      </div>
                  }

                  <small className={'text-secondary'}>Current price</small>
                </div>


                <ButtonToolbar className="form__button-toolbar" style={{width: '100%'}}>
                  {
                    wallets ?
                      <Button onClick={() => this.addLiquidity(manage_pool_type)}
                              color={manage_pool_type === 'create' ? 'primary' : manage_pool_type === 'Deposit' ? 'success' : 'danger'}
                              size={'lg'}
                              className={'mb-0'}
                              // disabled={!wallets_loaded ? true : false}
                              style={{width: '100%'}}>{manage_pool_type === 'Deposit' || manage_pool_type === 'create' ? 'Deposit Liquidity' : 'Withdraw Liquidity'} {liquidity_usd ? <b>${numFormat(liquidity_usd, 2)}</b> : ''}</Button>
                      :
                      <Link to={'/auth/connect'}
                            className={'btn btn-secondary btn-lg'}
                            style={{width: '100%'}}><b>Connect Wallet</b></Link>
                  }
                </ButtonToolbar>
                {
                  base_amount && counter_amount && amount_shares ?
                    <div className={'text-center fs-12 text-secondary'}>Pool shares: <b>{numFormat(amount_shares, 7)}</b></div>
                  : null
                }

                {
                  manage_pool_type === 'create' && pool  ?
                    <Row>
                      <Col sm={3}></Col>
                      <Col sm={6}>
                        <ButtonToolbar className="form__button-toolbar">
                          <Link to={`/pool/${pool_id}`}
                                target={'_blank'}
                                className={'btn btn-secondary btn-sm'}
                                style={{width: '100%'}}>Pool <b>{pool_asset_order.A.code}</b>/<b>{pool_asset_order.B.code}</b></Link>
                        </ButtonToolbar>
                      </Col>
                      {/*<Col sm={3}></Col>*/}
                    </Row>
                    : null
                }
              </div>
          }

          {
            hash ?
              <div>

                <br/>
                <hr/>

                <Card className={'last-tx-card'}>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Last Transaction</h5>
                    </div>
                    <div className="panel__btns">
                      <button className="panel__btn text-secondary" type="button" onClick={this.closeLastTx}>
                        <i className={'fa fa-close'}></i>
                      </button>
                    </div>
                    <div className={'stats-block'}>

                      {
                        manage_pool_type === 'create' ?
                          <p>
                            <small>
                              <span>Pool:</span>
                              <span className={'swap-data'}>
                            <b><a href={`/pool/${pool_id}`}>{ shortAddr(pool_id, 4) }</a></b>
                          </span>
                            </small>
                          </p>
                          : null
                      }

                      <p>
                        <small>
                          <span>Transaction:</span>
                            <span className={'swap-data'}>
                            <b><a href={`/transaction/${this.state.hash}`}>{ shortAddr(this.state.hash, 4) }</a></b>
                          </span>
                        </small>
                      </p>

                      { !operation ? <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> : null }

                      {
                        operation ?
                          <p>

                            <p>
                              <small>
                                <span>Operation:</span>
                                <span className={'swap-data'}>
                                  <b><a href={`/operation/${operation.id}`}>{ shortAddr(operation.id, 4) }</a></b>
                                </span>
                              </small>
                            </p>

                            <p>
                              <small>
                                <span>Account:</span>
                                <span className={'swap-data'}>
                                  {shortAddress(operation.source_account)}
                                </span>
                              </small>
                            </p>

                            <p>
                              <small>
                                <span>Shares:</span>
                                <span className={'swap-data'}>
                                  { operation.shares_received ? <b className="text-info">{numFormat(operation.shares_received, 7)}</b> : null }
                                  { operation.shares ? <b className="text-info">{numFormat(operation.shares, 7)}</b> : null }

                                </span>
                              </small>
                            </p>

                            <p>
                              <small>
                                <span>Reserves:</span>
                                <span className={'swap-data'}>

                                  {
                                    operation.reserves_deposited ?
                                      <span>
                                        <b className="text-info">{numFormat(operation.reserves_deposited[0].amount, 7)}</b> {getAsset(operation.reserves_deposited[0].asset)} / {' '}
                                        <b className="text-info">{numFormat(operation.reserves_deposited[1].amount, 7)}</b> {getAsset(operation.reserves_deposited[1].asset)}
                                      </span>
                                    :
                                      <span>
                                        <b className="text-info">{numFormat(operation.reserves_received[0].amount, 7)}</b> {getAsset(operation.reserves_received[0].asset)} / {' '}
                                        <b className="text-info">{numFormat(operation.reserves_received[1].amount, 7)}</b> {getAsset(operation.reserves_received[1].asset)}
                                      </span>
                                  }
                                </span>
                              </small>
                            </p>

                            <p>
                              <small>
                                <span>Type:</span>
                                <span className={'swap-data'}>
                                  <b className={`text-${manage_pool_type === 'Deposit' || manage_pool_type === 'create' ? 'success' : 'danger'}`}>{ itemType(operation.type) }</b>
                                </span>
                              </small>
                            </p>

                            <p>
                              <small><span>Date:</span>
                                <span className={'swap-data'}>
                                  <span>{formatDate(operation.created_at)}</span>
                                </span>
                              </small>
                            </p>

                          </p>
                          : null
                      }

                    </div>
                  </CardBody>
                </Card>
              </div>
              : null
          }
        </CardBody>
      </Card>
    )
  }
}



export default reduxForm({
  form: 'DepositWithdraw', // a unique identifier for this form
})(translate('common')(DepositWithdraw));
