import axios from "axios";
import {GLOBE} from "../modules/globeVars";
import {Server} from "../modules/stellar";
import {getDirectory} from "../modules";

const formData = new FormData();

export function getMarketAssets(page_count, limit) {
    formData.append('page', page_count)
    formData.append('limit', limit)
    return axios.post(`${GLOBE.API_URL}/get_market_assets`, formData);
}


export function getPaymentRequests(address, limit) {

    const formData = new FormData();
    formData.append('limit', limit)
    formData.append('address', address)
    return axios.post(`${GLOBE.API_URL}/payment_requests`, formData);
}

export function isScamer(scamer, asset, type) {

    const formData = new FormData();
    formData.append('scamer', scamer)
    formData.append('asset', asset)
    formData.append('type', type)
    return axios.post(`${GLOBE.API_URL}/scamer`, formData);
}

export function setClaimant(claimants, asset) {

    const formData = new FormData();
    formData.append('claimants', claimants)
    formData.append('asset', asset)
    return axios.post(`${GLOBE.API_URL}/set_claimant`, formData);
}

export function getAquaRewards() {
    return fetch('https://voting-tracker.aqua.network/api/voting-rewards/');
}





