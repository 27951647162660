import React, { PureComponent } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import StellarSdk from 'stellar-sdk';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { ToastContainer, ToastStore } from 'react-toasts';
import Footer from '../../Layout/footer/Footer';
import { get_loc_wallets, getTitle, numFormat, shortAddr, shortAddress, formatDate } from '../../../modules/index';
import LoadingIcon from "mdi-react/LoadingIcon";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button, Table,
} from 'reactstrap';
import {getPaymentRequests} from "../../../endpoints/API";
import iconIdent from "../Account/components/iconIdent";
import {Link} from "react-router-dom";
import { parseStellarUri, PayStellarUri } from '@stellarguard/stellar-uri';
import {CopyToClipboard} from "react-copy-to-clipboard";



class PaymentRequests extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      fill_accounts: [],
      all_invoices: [],
    };
  }

  wallets = get_loc_wallets();

  componentDidMount() {

    if (!this.props.match.params.id && this.wallets) {
      this.wallets.forEach(item => {
        getPaymentRequests(item.pk, 50)
          .then(result => {

            if (result.data !== 'not_found') {
              this.setState({
                fill_accounts: this.state.fill_accounts.concat({
                  account: item.pk,
                  invoices: result.data,
                }),
                all_invoices: this.state.all_invoices.concat(result.data)
              })
            }
          })
      })
    }
    else {
      getPaymentRequests(this.props.match.params.id, 50)
        .then(result => {

          if (result.data !== 'not_found') {
            this.setState({
              fill_accounts: this.state.fill_accounts.concat({
                account: this.props.match.params.id,
                invoices: result.data,
              }),
              // all_invoices: this.state.all_invoices.concat(result.data)
            })
          }
        })
    }
  }


  getPaymentRequests(address) {
    getPaymentRequests(50, address)
  }


  removeItem = (item) => {
    console.log('removeItem: ', item)
  }


  renderInvoice = (item, index) => {

    const parse_uri = parseStellarUri(item.uri);
    // console.log('item: ', item)
    // console.log('parse_uri: ', parse_uri)

    return (
      <tr key={index}>
        <td>{index+1}</td>
        <td><Link to={`/pay/${item.qr_id}`} style={{fontSize: '18px'}}><b><i className="fa fa-qrcode"></i></b></Link> {' '}
          <CopyToClipboard text={`${window.location.origin}/pay/${item.qr_id}`} onCopy={() => ToastStore.success(`Copied`)}>
            <i className="fa fa-clone" style={{cursor: 'pointer'}}></i>
          </CopyToClipboard>
        </td>
        <td><b className={'text-info'}>{numFormat(parse_uri.amount, 7)}</b></td>
        <td><Link className={'text-warning'}
                  to={parse_uri.assetIssuer ? `/trade/${parse_uri.assetCode}-XLM/${parse_uri.assetIssuer}/native` : '/native'}>
          <b>{parse_uri.assetCode && parse_uri.assetIssuer ? parse_uri.assetCode : 'XLM' }</b></Link></td>
        <td><i className="fa fa-long-arrow-right"></i> <Link to={`/account/${parse_uri.destination}`}><b>{shortAddr(parse_uri.destination, 4)}</b></Link></td>
        <td>{parse_uri.memo ? decodeURIComponent(parse_uri.memo) : null}</td>
        <td>{parse_uri.msg ? decodeURIComponent(parse_uri.msg) : null}</td>
        <td>{formatDate(item.date)}</td>
        <td> <CopyToClipboard text={parse_uri.uri.href} onCopy={() => ToastStore.success(`Copied`)}>
                <span><span className={'text-lightblue'}>web+stellar</span> <i className="fa fa-clone" aria-hidden="true" style={{cursor: 'pointer'}}></i></span>
             </CopyToClipboard>
        </td>
        <td><i className="fa fa-trash-o"
               aria-hidden="true"
               style={{cursor: 'pointer'}}
               onClick={() => this.removeItem(item)}></i></td>
      </tr>
    )
  }



  render() {
    // console.log('this.state: ', this.state)

    const { handleSubmit, t } = this.props;

    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Row>
          <Col md={{size:12}}>
            <h3 className="page-title">Payment Requests <span className={'float-right'}>{this.state.all_invoices.length ? this.state.all_invoices.length : ''}</span></h3>
            {/*<h4 className="subhead" style={{marginBottom: '18px'}}>*/}
            {/*  Search and detailed data analytics from <a href="https://stellar.org" target='_blank' rel='noopener noreferrer'>Stellar</a>*/}
            {/*</h4>*/}
          </Col>
        </Row>

        <Row>
          {
            this.state.fill_accounts.length ? this.state.fill_accounts.map((item, index) => {

              return (
                <Col md={{size:12}} key={index}>
                  <Card>
                    <CardBody>
                      {/*<h3 className="subhead" style={{marginBottom: '18px'}}>*/}
                      {/*  <b>{shortAddress(item.account)}</b>*/}
                      {/*</h3>*/}
                      <div className="card__title">
                        <h5 className="bold-text">Account: {shortAddress(item.account)} {' '}
                          <CopyToClipboard text={item.account} onCopy={() => ToastStore.success(`Copied`)}>
                            <i className="fa fa-clone" style={{cursor: 'pointer'}}></i>
                          </CopyToClipboard> {' '} &nbsp;&nbsp;&nbsp;
                          <span><a href={`/invoices/${item.account}`} target={'_blank'}><i className={'fa fa-external-link'}></i></a></span>
                          <b className={'float-right'}>(<span className={'text-info'}>{item.invoices.length}</span>)</b>
                        </h5>
                      </div>

                      <Table striped responsive>
                        <thead>
                        <tr>
                          <th>#</th>
                          <th style={{width: 55}}><i className="fa fa-qrcode"></i></th>
                          <th>Amount</th>
                          <th>Asset</th>
                          <th style={{width: 130}}>Destination</th>
                          <th>Memo</th>
                          <th>Message</th>
                          <th style={{width: 190}}>Date</th>
                          <th style={{width: 110}}>URI</th>
                          <th><i className="fa fa-trash-o"></i></th>
                        </tr>
                        </thead>
                        <tbody>

                        { item.invoices && item.invoices.map(this.renderInvoice) }

                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              )
            }) : <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card>
          }
        </Row>

        <Footer />

      </Container>
    )
  }
}

PaymentRequests.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'vertical_form_layout', // a unique identifier for this form
})(translate('common')(PaymentRequests));
