import React, { PureComponent } from 'react';
import { Row, Col, Button, ButtonToolbar, Table, Card, CardBody, Modal, Badge } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import { ToastContainer, ToastStore } from 'react-toasts';
import LoadingIcon from 'mdi-react/LoadingIcon';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../../../modules/stellar/index';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';
import PropTypes from 'prop-types';
import axios from 'axios';
import { GLOBE } from '../../../../../modules/globeVars/index';
import {
  get_loc_wallets,
  shortAddr,
  nativeBalance,
  baseReserve,
  minBalance,
  getAuth,
  needDeauthAll,
  checkConnectProvider, signConnectProvider, getTitle
} from '../../../../../modules/index';
import swal from 'sweetalert';
import RequiredSigners from '../../../Auth/components/RequiredSigners';

// import Collapse from '../../../../../shared/components/Collapse';



const renderTextField = ({
  input, label, type, multiline, rowsMax, meta: { touched, error }, children, select, inputType
}) => {
  return(
    <TextField
      className="material-form__field"
      label={label}
      error={touched && error}
      value={input.value}
      children={children}
      multiline={multiline}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  );
}




class Multisig extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      loader_signer: false,
      loader_threshold: false,
      wallets: get_loc_wallets(),
      signer: false,
      signers: false,
      all_signers_weight: 0,
      accept_signers: false,
      transaction: false,
      type_sign_action: false,
      need_add_signers: false,
    };

    this.addSigners = this.addSigners.bind(this);
  }


  componentDidMount() {

    const title = 'Managing multi-signatures for Stellar accounts';
    const description = 'Scopuly - simple and convenient interface for managing multi-signatures on Stellar addresses. Adding and removing signers, as well as managing threshold values.';
    getTitle(title, description);

    this.props.change('low_threshold', this.props.account_data.thresholds.low_threshold)
    this.props.change('med_threshold', this.props.account_data.thresholds.med_threshold)
    this.props.change('high_threshold', this.props.account_data.thresholds.high_threshold)

    let all_signers_weight = 0;
    this.props.account_data.signers.map((item) => {
      all_signers_weight = (all_signers_weight + item.weight)
    })

    const wallets = this.state.wallets;
    if (wallets) {
      var signer = wallets.filter((item) => {
        return item.pk === this.props.account_data.id;
      });

      this.setState({
        signer,
        signers: this.props.account_data.signers,
        all_signers_weight: all_signers_weight,
      })
    }
  }


  addSigners(value) {

    // console.log('addSigners value: ', value)

    this.setState({
      transaction: value,
    })

    if (this.state.type_sign_action === 'add_signer' || this.state.type_sign_action === 'remove_signer') {
      this.setOpSigner();
    }
    else if (this.state.type_sign_action === 'update_thresholds') {
      this.updateThreshold();
    }
  }


  setSigner = (values) => {

    if (!values.signer_addr) {
      ToastStore.warning('Enter New Signature Account');
    }
    else if (!StellarSdk.StrKey.isValidEd25519PublicKey(values.signer_addr)) {
      ToastStore.error('The entered Address is not valid');
    }
    else if (!values.signer_wight) {
      ToastStore.warning('Enter weight for new Signer');
    }
    else if (isNaN(values.signer_wight)) {
      ToastStore.warning('Weight must be a number');
    }
    else {

      this.setState({
        type_sign_action: 'add_signer',
        btn_name: 'Add Signer',
        btn_icon: 'key',
        select_weight: values.signer_wight,
        select_signer: values.signer_addr,
      });

      swal({
        title: "Add Signer",
        text: `Add a new signer right now?`,
        icon: "warning",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
        .then((confirm) => {
          if (confirm)
            this.setOpSigner();
        })
    }
  }


  setThreshold(values) {

    if (!values.low_threshold || !values.med_threshold || !values.high_threshold) {
      ToastStore.warning('All Thresholds fields must be filled.');
    }
    else if (isNaN(values.low_threshold) || isNaN(values.med_threshold) || isNaN(values.high_threshold)) {
      ToastStore.warning('Values for Thresholds must be numbers.');
    }
    else {

      this.setState({
        type_sign_action: 'update_thresholds',
        btn_name: 'Update Thresholds',
        btn_icon: 'cogs',
        thresholds_values: values,
      },
        () => this.updateThreshold());
    }
  }


  updateThreshold(values) {

    this.setState({ loader_threshold: true })
    const address = this.props.account_data.id

    Server.loadAccount(address)
      .then((sourceAccount) => {

        const submitTx = () => {
          return Server.submitTransaction(this.state.transaction);
        }

        if (!this.state.transaction) {

          this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: StellarSdk.BASE_FEE,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
          .addOperation(StellarSdk.Operation.setOptions({
            lowThreshold: String(this.state.thresholds_values.low_threshold),
            medThreshold: String(this.state.thresholds_values.med_threshold),
            highThreshold: String(this.state.thresholds_values.high_threshold)
          }))
          .setTimeout(100)
          .build();

          if (checkConnectProvider(address)) {

            return signConnectProvider(this.state.transaction, address).then(result => {
              this.state.transaction = result
              return submitTx();
            })
          }
          else {
            this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));

            const master_weight = sourceAccount.signers.reverse()[0].weight;
            if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {
              this.setState({ need_add_signers: true })
            }
            else {
              return submitTx();
            }
          }
        }
        else {
          return submitTx();
        }
      })
      .then((result) => {
        // console.log('result: ', result)

        this.setState({ loader_threshold: false, });

        if (result) {
          this.setState({ transaction: false });

          swal({
            title: "Thresholds updated!",
            text: `The thresholds for this Account have been successfully updated.`,
            icon: "success",
          })
        }
      })
      .catch((error) => {
        console.error('Something went wrong!', error);

        this.setState({
          loader_threshold: false,
          transaction: false,
        })
        ToastStore.error('Transaction error');
    });
  }



  removeItem(item, index) {
    // console.log('item: ', item);

    let del_signer = item.key;
    let del_signer_weight = 0;
    let thresholds = this.props.account_data.thresholds;
    let signers_length = this.props.account_data.signers.length;

    this.props.account_data.signers.map((item, index) => {
      if (del_signer === item.key) {
        del_signer_weight = item.weight;
      }
    });

    let weight_after_del = (this.state.all_signers_weight - del_signer_weight);

    this.setState({
      need_add_signers: false,
      weight_after_del: weight_after_del,
      select_signer: del_signer,
      select_weight: item.weight,
      type_sign_action: 'remove_signer',
      btn_name: 'Delete Signer',
      btn_icon: 'trash-o',
    })

    if (thresholds.high_threshold > weight_after_del) {

      swal({
        title: "Attention!",
        text: `When deleting signers from the original account, the High_threshold value should not exceed the total remaining weight of the signing accounts. Before deleting a signer, you must reduce the High_threshold value for this source account. Now the weight is High_threshold: ${thresholds.high_threshold}, and the total weight after deleting the signer: ${weight_after_del}. If you ignore and decide to delete the signer, this initial account will no longer be able to add or delete subscribers and change the threshold values, nor will it be able to perform an account merge operation. Still want to delete a signer right now?`,
        icon: "warning",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
      .then((confirm) => {
        if (confirm) {
          this.confirmDel();
        }
      })
    }
    else {
      this.confirmDel();
    }
  }


  confirmDel() {
    swal({
      title: "Confirmation",
      text: `To confirm the deletion of a signer enter this text: DELETE`,
      icon: "info",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter text: DELETE",
        },
      },
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
    .then((value) => {
      if (value === 'DELETE') {
        this.setOpSigner();
      }
      else {
        ToastStore.warning('Enter the word DELETE');
      }
    })
  }


  setOpSigner() {

    this.setState({ loader_signer: true })
    const address = this.props.account_data.id

    Server.loadAccount(address)
      .then((sourceAccount) => {

        const submitTx = () => {
          return Server.submitTransaction(this.state.transaction);
        }

        if (!this.state.transaction) {

          this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: StellarSdk.BASE_FEE,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
          .addOperation(StellarSdk.Operation.setOptions({
            signer: {
              ed25519PublicKey: this.state.select_signer,
              weight: (this.state.type_sign_action === 'add_signer' ? this.state.select_weight : 0)
            }
          }))
          .setTimeout(100)
          .build();

          if (checkConnectProvider(address)) {

            return signConnectProvider(this.state.transaction, address).then(result => {
              this.state.transaction = result
              return submitTx();
            })
          }
          else {

            this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));

            const master_weight = sourceAccount.signers.reverse()[0].weight;
            if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {
              this.setState({ need_add_signers: true })
            }
            else {
              return submitTx();
            }
          }
        }
        else {
          return submitTx();
        }
      })
      .then((result) => {
        // console.log('result: ', result)

        this.setState({ loader_signer: false });

        if (result) {
          this.setState({ transaction: false });

          if (this.state.type_sign_action === 'remove_signer') {
            const signers = this.state.signers.filter(item => item.key !== this.state.select_signer)
            this.setState({ signers })

            setTimeout(() => {
              swal({
                title: "Signer Removed!",
                text: `Signer ${shortAddr(this.state.select_signer, 4)} was successfully removed from the original account.`,
                icon: "success",
              })
            }, 1000)
          }
          if (this.state.type_sign_action === 'add_signer') {
            const newSigner = {
              key:this.state.select_signer,
              weight: this.state.select_weight
            }
            this.setState({ signers: this.state.signers.concat(newSigner) })

            setTimeout(() => {
              swal({
                title: "Signer Added!",
                text: `Signer ${shortAddr(this.state.select_signer, 4)} was successfully added from the source account.`,
                icon: "success",
              })
            }, 1000)
          }

        }
      })
      .catch((error) => {
        console.error('Something went wrong!', error);

        this.setState({
          loader_signer: false,
          transaction: false,
        })
        ToastStore.error('Transaction error');
    });
  }




  renderSigner = (item, index) => {

    return (
      <tr key={index}>
        <td><Link to={`/account/${item.key}`}><b className={item.key === this.props.account_data.account_id ? 'text-warning' : null}>{shortAddr(item.key, 4)}</b></Link></td>
        <td>{item.weight}</td>
        <td><small>{item.key !== this.props.account_data.account_id ? '0.5' : '0'} xlm</small></td>
        <td>
          {
            item.key !== this.props.account_data.account_id ?
              <Button color="secondary"
                        size="sm" outline
                        className="table-btn"
                        onClick={() => this.removeItem(item, index)}>
                  <span className="lnr lnr-trash" />
              </Button>
            :
              <small>Root Signer</small>
          }
        </td>
      </tr>
    )
  }




  render() {
    const { handleSubmit } = this.props;

    // console.log('Multisig state: ', this.state)
    // console.log('Multisig props: ', this.props)

    const signers_length = this.props.account_data.signers.length;
    const reserve = (baseReserve() * (signers_length-1))


    return (

      <div>

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {
          this.state.need_add_signers && this.state.transaction ?
            <RequiredSigners account={this.props.account_data}
                             add_signers={this.addSigners}
                             transaction={this.state.transaction}
                             btn_name={this.state.btn_name}
                             icon={this.state.btn_icon}
                             type_tx={'high'} />
          : null
        }

        <Row>
          <Col lg={6}>
            <Card style={{height: 'auto'}}>
              <CardBody className="card-body-border">

                {this.state.loader_signer ? <div className="panel__refresh" style={{height: '96%'}}><LoadingIcon /></div> : ''}

                <div className="card__title">
                  <h5 className="bold-text">Signers ({signers_length})</h5>
                  <h5 className="subhead">Signing Accounts for this Wallet</h5>
                </div>

                <Table className="table--bordered table--head-accent" responsive hover>
                  <thead>
                    <tr>
                      <th>Signer</th>
                      <th>Weight ({this.state.signers.length})</th>
                      <th>Reserve ({reserve} XLM)</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.signers && this.state.signers.reverse().map(this.renderSigner)
                    }
                  </tbody>
                </Table>
                <hr/>
                <div className="card__title">
                  <h5 className="bold-text">Add Signer</h5>
                  <h5 className="subhead">Add a new Signing Account</h5>
                </div>

                <form className="material-form" onSubmit={handleSubmit(this.setSigner)}>
                  <div>
                    <Field
                      name="signer_addr"
                      component={renderTextField}
                      label="Signing Account (Address)"
                    />
                  </div>
                  <div>
                    <Field
                      name="signer_wight"
                      component={renderTextField}
                      label="Weight (Number)"
                    />
                  </div>
                  <br/>
                  <Button color="primary" type="submit">Add Signer</Button>
                </form>
              </CardBody>
            </Card>
          </Col>

          <Col lg={6}>
            <Card>
              <CardBody className="card-body-border">

                {this.state.loader_threshold ? <div className="panel__refresh" style={{height: '97%'}}><LoadingIcon /></div> : ''}

                <div className="card__title">
                  <h5 className="bold-text">Thresholds</h5>
                  <h5 className="subhead">Thresholds for this Account</h5>
                </div>

                <Table className="table--bordered table--head-accent" responsive hover>
                  <thead>
                    <tr>
                      <th>Threshold Type</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Low_threshold:</td>
                      <td><b>{this.props.account_data.thresholds.low_threshold}</b></td>
                    </tr>
                    <tr>
                      <td>Med_threshold:</td>
                      <td><b>{this.props.account_data.thresholds.med_threshold}</b></td>
                    </tr>
                    <tr>
                      <td>High_threshold:</td>
                      <td><b>{this.props.account_data.thresholds.high_threshold}</b></td>
                    </tr>
                  </tbody>
                </Table>
                <hr/>
                <div className="card__title">
                  <h5 className="bold-text">Edit Thresholds</h5>
                  <h5 className="subhead">Editing the Thresholds value</h5>
                </div>
                <form className="material-form" onSubmit={handleSubmit(this.setThreshold.bind(this))}>
                  <div>
                    <Field
                      name="low_threshold"
                      component={renderTextField}
                      label="Low_threshold"
                    />
                  </div>
                  <div>
                    <Field
                      name="med_threshold"
                      component={renderTextField}
                      label="Med_threshold"
                    />
                  </div>
                  <div>
                    <Field
                      name="high_threshold"
                      component={renderTextField}
                      label="High_threshold"
                    />
                  </div>
                  <br/>
                  <Button color="primary" type="submit">Update Thresholds</Button>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default reduxForm({
  form: 'colored_controls, defaults_controls_colored_click', // a unique identifier for this form
})(Multisig);
