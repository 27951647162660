import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody, Button, ButtonToolbar, Popover, PopoverBody  } from 'reactstrap';
import { Field, reduxForm, change} from 'redux-form';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { GLOBE } from '../../../modules/globeVars/index';
import axios from 'axios';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import { parseStellarUri, PayStellarUri } from '@stellarguard/stellar-uri';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import {
  shortAddr,
  numFormat,
  formatDate,
  get_loc_wallets,
  baseReserve,
  minBalance,
  getAuth,
  needDeauthAll,
  checkAuth,
  getTitle,
  showErrorMessage,
  getIconConnectProvider, checkConnectProvider, signConnectProvider
} from '../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from 'sweetalert';
import Footer from '../../Layout/footer/Footer';
import { QRCode } from 'react-qrcode-logo';
import iconIdent from '../Account/components/iconIdent';
import AutorenewIcon from "mdi-react/AutorenewIcon";


let wallets_arr = [], path_asset_code = false;


const renderTextField = ({
  input, label, meta: { touched, error }, children, select, type,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);



class Pay extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      wallets: get_loc_wallets(),
      signer: false,
      is_auth: false,
      loader: false,
      select_addr: false,
      select_asset: false,
      uri: false,
      asset: false,
      amount: 0,
      memo: false,
      msg: false,
      domain: false,
      recipient: false,
      date: false,
      path_payment: false,
      path_result: false,
      pay_show: false,
      popoverOpen: false,
      disabled: true,

      transaction: false,
      // invoice_id: false,
      result_tx: false,
    };
  }


  componentDidMount() {

    const params = this.props.match.params.id;
    const uri = decodeURIComponent(params)

    if (uri.indexOf('web+stellar') + 1) {
      this.setOptionsUri(uri)
    }
    else {
      this.getInvoice(params)
    }

    this.filterAddress()

    //
    // this.testGetStrictReceivePaths()
  }


  testGetStrictReceivePaths = () => {


    var sourceAccount = 'GBT2RH3RELBKMXVPEKTR7TMEV6YFSIEDOK63SH2XOHM74KFU3PZF2BMY';
    var destinationAmount = 100;

    // if (this.state.asset[1] === 'native') {
    //   var destinationAsset = StellarSdk.Asset.native();
    // } else {
    //   var destinationAsset = new StellarSdk.Asset(this.state.asset[0], this.state.asset[1])
    // }

    // var destinationAsset = StellarSdk.Asset.native();
    // var destinationAsset = new StellarSdk.Asset('SKY', 'GB2WB73G5IWRXEUTJANAIF52JFTDXOXORAP4S5HZRW23TZG2ONDVZMVA')
    var destinationAsset = new StellarSdk.Asset('SCOP', 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ')

    Server.strictReceivePaths(sourceAccount, destinationAsset, destinationAmount)
      .call()
      .then( (pathResult) => {
        console.log('strictReceivePaths Result: ', pathResult)

        if (!pathResult.records.length) {
          swal({
            title: 'Path Payments not found',
            text: 'Choose another sender account or fund the selected account.',
            icon: 'info'
          })
        }
      })
      .catch( (err) => {
        console.log(err)
      })

    Server.strictSendPaths(destinationAsset, destinationAmount, sourceAccount)
      .call()
      .then( (pathResult) => {
        console.log('strictSendPaths Result: ', pathResult)

        if (!pathResult.records.length) {
          swal({
            title: 'Path Payments not found',
            text: 'Choose another sender account or fund the selected account.',
            icon: 'info'
          })
        }
      })
      .catch( (err) => {
        console.log(err)
      })


    // var callback = function (resp) {
    //   console.log('strictReceivePaths resp: ', resp);
    // };

    // var es = Server
    //   .strictReceivePaths(
    //     [
    //       new StellarSdk.Asset('SCOP', 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ'),
    //     ],
    //     new StellarSdk.Asset('SKY', 'GB2WB73G5IWRXEUTJANAIF52JFTDXOXORAP4S5HZRW23TZG2ONDVZMVA'),
    //     "5",
    //   )
    //   .cursor("now")
    //   .stream({ onmessage: callback });
    //
    //
    // var callbackSend = function (resp) {
    //   console.log('strictSendPaths resp: ', resp);
    // };
    //
    // var es = Server
    //   .strictSendPaths(
    //     new StellarSdk.Asset('SKY', 'GB2WB73G5IWRXEUTJANAIF52JFTDXOXORAP4S5HZRW23TZG2ONDVZMVA'),
    //     "40000",
    //     "GBT2RH3RELBKMXVPEKTR7TMEV6YFSIEDOK63SH2XOHM74KFU3PZF2BMY",
    //   )
    //   .cursor("now")
    //   .stream({ onmessage: callbackSend });
  }



  setOptionsUri(uri) {

    const parse_uri = parseStellarUri(uri);
    const split = parse_uri.uri.href.split('&')
    const encodeUri = split.map((item, index) => {

      if (item.indexOf('memo')+1 && !item.indexOf('memo_type')-1) {
        item = 'memo=' + encodeURIComponent(item.split('memo=')[1]);
      }
      if (item.indexOf('msg')+1) {
        item = 'msg=' + encodeURIComponent(item.split('msg=')[1]);
      }
      if (item.indexOf('signature')+1) {
        item = 'signature=' + encodeURIComponent(item.split('signature=')[1]);
      }

      return item;
    })


    if (parse_uri.assetCode && parse_uri.assetIssuer) {
      var asset = [parse_uri.assetCode, parse_uri.assetIssuer];
    } else {
      var asset = ['XLM', 'native']
    }

    this.setState({
      uri,
      parse_uri,
      encodeUri,
      asset,
      recipient: parse_uri.destination,
      amount: parse_uri.amount,
      memo: parse_uri.memo ? decodeURIComponent(parse_uri.memo) : false,
      msg: parse_uri.msg ? decodeURIComponent(parse_uri.msg) : false,
      domain: parse_uri.originDomain || false,
    }, () => {
      // this.checkRecipientAccount()
    })
  }



  getInvoice(invoice_id) {

    const formData = new FormData();
    formData.append('invoice_id', invoice_id)

    axios.post(GLOBE.API_URL+`/get_invoice`, formData)
      .then(result => {
        // console.log('result DB: ', result)

        if (result.data !== 'not_found') {
          this.setOptionsUri(result.data.uri)
        }
        else {
          swal({
            title: 'Payment request not found',
            text: `Make sure the payment request id in the URL is correct`,
            icon: 'info'
          })
          // this.setState({ loader: false })
        }
      })
      .catch((error) => {
        console.log('get_invoice error: ', error)
    })
  }



  getSigner(address) {

      const wallets = this.state.wallets;

      if (wallets) {
        var signer = wallets.filter((item) => {
          return item.pk === address;
        });

        this.setState({
          signer: signer,
        })
      }
  }



  filterAddress() {

    const wallets = this.state.wallets;

    this.setState({ loader: true, });

    if (wallets) {
      wallets.forEach((item, index) => {
        this.getAccount(item, index);
      })
    }

    if (this.state.wallets) {
        setTimeout(() => {
          ToastStore.info(`Loading ${this.state.wallets.length} Accounts...`);
        }, 3000)
    }
    else {
        this.setState({
          loader: false
        })
    }
  }


  getAccount(item, index) {

    item.id = index;

    Server.loadAccount(item.pk)
      .then(account => {

        item.account = account;
        item.balance = numFormat(account.balances[account.balances.length-1].balance, 2);
        item.reserve = (account.subentry_count * baseReserve());
        item.available = (item.balance - item.reserve).toFixed(2);

        wallets_arr.push(item);

        if (this.state.wallets.length === wallets_arr.length) {
          newState();
        }
      })
      .catch((err) => {
        console.error(err);

        wallets_arr.push(item)

        if (this.state.wallets.length === wallets_arr.length) {
          newState();
        }
    })

    const newState = () => {
      this.setState({
        select_addrs: wallets_arr,
        load_select_addrs: true,
        loader: false
      })
    }
  }



  changeSender(value) {

    this.setState({ path_result: false })

    let addr = '';
    for (const key of Object.keys(value)) {
      if (key < 56) {
        addr = (addr+value[key])
      }
      else {

        this.getSigner(addr);

        let select_account = wallets_arr.filter((item) => {
          return addr === item.pk;
        });

        const account = select_account[0].account;
        const balances = account.balances;

        balances.forEach((item, index) => {
          if (item.asset_type === 'native') {
            item.asset_code = 'XLM';
            item.asset_issuer = 'native';
            item.reserve = (account.subentry_count * baseReserve() + minBalance());
            item.available = (item.balance - item.reserve).toFixed(7);

            this.setState({
              balance_native: item.balance,
              available_native: item.available,
              reserve_balance: item.reserve,
            })
          }
          else {
            item.available = item.balance;
          }
        });

        const trustline = balances.filter((item) => {
          return (item.asset_code === this.state.asset[0] && item.asset_issuer === this.state.asset[1])
        })[0];

        if (!trustline) {
          swal({
            title: `No asset ${this.state.asset[0]}`,
            text: `There is no ${this.state.asset[0]} asset on the balance sheet. But you can make this payment using Path Payments.`,
            icon: 'info'
          })
          .then(() => {
            this.getStrictReceivePaths()
          })
        }
        else {

          if (Number(trustline.balance) < Number(this.state.amount)) {
            swal({
              title: `Not enough ${this.state.asset[0]}`,
              text: `There is not enough ${this.state.asset[0]} on the balance of the selected Account to send. You can complete this payment in your other tokens.`,
              icon: 'info'
            })
            .then(() => {
              this.getStrictReceivePaths()
            })
          }
          // else {
          //   this.setState({ path_payment: false })
          // }
        }

        path_asset_code = false;

        this.setState({
          select_assets: balances.reverse(),
          load_select_assets: true,
          select_addr: addr,
          select_account,
          trustline,
          disabled: false,
        })
      }
    }
  }


  showToast = () => {
    ToastStore.info(`Search Path Payments...`);
  }


  getStrictReceivePaths() {

    this.showToast()

    var sourceAccount = this.state.select_addr;
    var destinationAmount = this.state.amount;

    if (this.state.asset[1] === 'native') {
      var destinationAsset = StellarSdk.Asset.native();
    } else {
      var destinationAsset = new StellarSdk.Asset(this.state.asset[0], this.state.asset[1])
    }

    this.setState({ loader: true })

    Server.strictReceivePaths(sourceAccount, destinationAsset, destinationAmount)
      .call()
      .then( (pathResult) => {
        if (!pathResult.records.length) {
          swal({
            title: 'Path Payments not found',
            text: 'Choose another sender account or fund the selected account.',
            icon: 'info'
          })
        }

        this.setState({
          path_result: pathResult.records,
          path_payment: true,
          loader: false,
          disabled: true,
        })
      })
      .catch( (err) => {
        console.log(err)
        this.setState({ loader: false })
      })
  }


  changeSelectAsset(select_asset) {
    this.setState({
      select_asset,
      disabled: false,
    });
  }


  // checkRecipientAccount() {
  //
  //   this.setState({ loader: true, })
  //   const asset = this.state.asset;
  //
  //   ToastStore.info("Checking the Recipient's Account...");
  //
  //   // Check isset account & Trust asset
  //   Server.loadAccount(this.state.recipient)
  //       .then((account) => {
  //
  //         this.setState({ loader: false, });
  //
  //         // if (asset[0].asset_code !== 'XLM') {
  //         //   let recipient_trust = account.balances.some((balance) => {
  //         //     return balance.asset_code === asset[0].asset_code &&
  //         //         balance.asset_issuer === asset[0].asset_issuer;
  //         //   });
  //         //
  //         //   if (!recipient_trust && this.state.recipient !== asset[0].asset_issuer) {
  //         //     ToastStore.warning("Recipient's address is not trusted by "+asset[0].asset_code);
  //         //   }
  //         //   else {
  //         //     // ... ASSET
  //         //     // allow_transaction();
  //         //     alert('ASSET')
  //         //   }
  //         // }
  //         // else {
  //         //   // ... XLM
  //         //   // allow_transaction();
  //         //   alert('XLM')
  //         // }
  //       })
  //       .catch(function (error) {
  //         console.log('error: ', error);
  //         this.setState({ loader: false, });
  //         // nextLoadAccount();
  //       });
  // }


  submitForm = (values) => {

    this.setState({result_tx: false})

    if (this.state.path_payment) {
      this.pathPayment(values)
    }
    else {
      this.payment()
    }
  }



  payment() {

    if (this.state.amount > this.state.select_account[0].available) {
      ToastStore.warning('Not enough funds for this transaction');
    }
    else {
      this.allowTransaction()
    }
  }

  allowTransaction = () => {

    getAuth()
      .then((value) => {
        if (value === 'accept') {
          acceptSendTX();
        }
        else if (value === 'need_deauth_all') {
          needDeauthAll();
        }
      });

    const acceptSendTX = () => {

      const message = `Send <b class="text-info">${this.state.amount}</b>  <b class="text-warning">${this.state.asset[0]}</b> right now?`
      const element = document.createElement("span");
      element.innerHTML = message;

      swal({
        title: "Confirm",
        content: element,
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
      .then((confirm) => {
        if (confirm) {
          this.setSendTx();
          this.setState({
            tx_hash: false,
          })
        }
      })
    }
  }


  setSendTx() {

    var signer = this.state.wallets.filter((item) => {
      return item.pk === this.state.select_addr;
    });

    const { select_addr } = this.state


    let asset;
    if (this.state.asset[0] === 'XLM') {
      asset = StellarSdk.Asset.native();
    } else {
      asset = new StellarSdk.Asset(this.state.asset[0], this.state.asset[1]);
    }

    this.setState({ loader: true, });
    // ToastStore.info(`Loading an Account ...`);
    // payment =============
    Server.loadAccount(select_addr)
      .then(sourceAccount => {

        const submitTx = (num) => {
          ToastStore.info(`Sending a transaction ...`);
          return Server.submitTransaction(this.state.transaction);
        }

        if (!this.state.transaction) {

          this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: StellarSdk.BASE_FEE,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
          .addOperation(StellarSdk.Operation.payment({
            destination: this.state.recipient,
            asset: asset,
            amount: String(this.state.amount)
          }))
          .addMemo(StellarSdk.Memo.text(this.state.memo ? this.state.memo : ''))
          .setTimeout(180)
          .build();

          if (checkConnectProvider(select_addr)) {

            return signConnectProvider(this.state.transaction, select_addr).then(result => {
              this.state.transaction = result
              return submitTx();
            })
          }
          else {

            this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(signer[0].sk)));

            const master_weight = sourceAccount.signers.reverse()[0].weight;

            if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {
              this.setState({
                account: sourceAccount,
                need_add_signers: true,
                btn_name: 'Send Asset',
                btn_icon: 'send',
                type_tx: 'med',
              })
            }
            else {
              return submitTx();
            }
          }
        }
        else {
          return submitTx();
        }
      })
      .then((result) => {

        this.setState({ loader: false, });

        if (result.hash) {
          this.swallSuccessSend();

          this.setState({
            transaction: false,
            tx_hash: result.hash
          })
        }
        else {
          ToastStore.warning('Something went wrong. Try refreshing the page.');
        }
      })
      .catch((error) => {
        console.error('Something went wrong: ', error);
        this.setState({ loader: false, });

        ToastStore.error('Transaction error');
    });
    //--------------/
  }


  swallSuccessSend() {
    const message = `You sent <b class="text-info">${this.state.amount}</b>  <b class="text-warning">${this.state.asset[0]}</b> to <b class="text-info">${shortAddr(this.state.recipient, 4)}</b>.`
    const element = document.createElement("span");
    element.innerHTML = message;

    swal({
      title: "Sent!",
      content: element,
      icon: "success",
    })
  }



  pathPayment(values) {

    const path = this.state.select_asset.path.map(item => {
      if (item.asset_type === 'native') {
        return new StellarSdk.Asset.native()
      }
      else {
        return new StellarSdk.Asset(item.asset_code, item.asset_issuer)
      }
    })


    const setPathPayment = (sendAsset, destAsset, destAmount, sendMax, path) => {
      this.setState({ loader: true })

      Server.loadAccount(this.state.select_addr)
        .then((sourceAccount) => {

          var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: StellarSdk.BASE_FEE,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
            .addOperation(StellarSdk.Operation.pathPaymentStrictReceive({
                sendAsset,
                sendMax,
                destination: this.state.recipient,
                destAsset,
                destAmount,
                path,
            }))
            .setTimeout(100)
            .build();

          transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));
          return Server.submitTransaction(transaction);
        })
        .then((result) => {
          this.setState({ loader: false, result_tx: result })

          const message = `<div><b class="text-info">${this.state.select_asset.source_amount}</b> <b class="text-warning">${this.state.select_asset.source_asset_code ? this.state.select_asset.source_asset_code : 'XLM'}</b> <i class="fa fa-long-arrow-right"></i> <b class="text-info">${numFormat(this.state.select_asset.destination_amount, 7)}</b> <b class="text-warning">${this.state.select_asset.destination_asset_code ? this.state.select_asset.destination_asset_code : 'XLM'}</b></div>`;
          const element = document.createElement("span");
          element.innerHTML = message;

          swal({
            title: `Successful Payment!`,
            content: element,
            icon: "success",
          })
        })
        .catch((error) => {
          console.error('Error! ', error);
          console.error('error.response: ', error.response);

          this.setState({
            buy_sell_loader: false,
            loader: false
          })
          if (error.response) {
            showErrorMessage(error, 'path_payment')
          } else {
            ToastStore.error(error.toString());
          }
      });
    }

    let destAsset, sendAsset, destAmount, sendMax;

    if (values.radio) {
      var send_asset_split = values.radio.split('-');
      var dest_asset_split = this.state.asset;

      destAmount = this.state.select_asset.destination_amount;
      sendMax = this.state.select_asset.source_amount;

      if (send_asset_split[1] === 'native') {
        sendAsset = StellarSdk.Asset.native();
      } else {
        sendAsset = new StellarSdk.Asset(send_asset_split[0], send_asset_split[1]);
      }

      if (dest_asset_split[1] === 'native') {
        destAsset = StellarSdk.Asset.native();
      } else {
        destAsset = new StellarSdk.Asset(dest_asset_split[0], dest_asset_split[1]);
      }

      const message = `<div>Send <b class="text-info">${this.state.select_asset.source_amount}</b> <b class="text-warning">${this.state.select_asset.source_asset_code ? this.state.select_asset.source_asset_code : 'XLM'}</b> <i class="fa fa-long-arrow-right"></i> <b class="text-info">${numFormat(this.state.select_asset.destination_amount, 7)}</b> <b class="text-warning">${this.state.select_asset.destination_asset_code ? this.state.select_asset.destination_asset_code : 'XLM'}</b></div> right now?`;
      const element = document.createElement("span");
      element.innerHTML = message;

      swal({
        title: "Confirmation!",
        // text: `${sendMax} ${this.state.asset[0]} now?`,
        content: element,
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
        .then((confirm) => {
          if (confirm) {
            setPathPayment(sendAsset, destAsset, destAmount, sendMax, path)
          }
        })
    }
    else {

      if (!this.state.path_result) {
        ToastStore.warning('Choose Path Payment');
      } else {
        //...
      }
    }
  }


  togglePopover = () => {
    this.setState(prevState => ({
      popoverOpen: !prevState.popoverOpen
    }));
  }



  renderPath(item, index) {

    let asset_code;
    if (item.source_asset_type === 'native') {
      asset_code = 'XLM'
    } else {
      asset_code = item.source_asset_code;
    }

    // if (asset_code !== path_asset_code) {
    //   path_asset_code = asset_code

      return (
        <div className="form__form-group-field" key={index}>
          <Field
            name="radio"
            component={renderRadioButtonField}
            label={`${numFormat(item.source_amount, 7)} ${item.source_asset_type === 'native' ? 'XLM' : item.source_asset_code} ${item.path.length ? `-> ${item.path.length ? `[ ${item.path.map(item => item.asset_type === 'native' ? 'XLM' : item.asset_code)} ]` : null}` : '' }  ->  ${numFormat(item.destination_amount, 7)} ${item.destination_asset_type === 'native' ? 'XLM' : item.destination_asset_code }`}
            radioValue={`${(item.source_asset_type === 'native' ? 'XLM-native' : item.source_asset_code+'-'+item.source_asset_issuer)}-${item.source_amount}`}
            onChange={() => this.changeSelectAsset(item)}
          />
        </div>
      )
    // }
  }


  refreshPathPayments = () => {
    this.getStrictReceivePaths()
  }




  render() {

    const {reset, handleSubmit, match } = this.props;
    const { recipient, amount, asset, memo, msg, encodeUri } = this.state

    let webStellar = false;
    const str = match.params.id;
    if (str.indexOf('web%2Bstellar') + 1) {
      webStellar = true
    }



    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {/*<Row>
          <Col md={12}>
            <h3 className="page-title">Send Assets</h3>
          </Col>true
        </Row>*/}
        <Row>

          <Col md={{ size: 4, offset: 4 }}  className="align-self-center">
            <Card>
              <CardBody>

                <div className="card__title">
                  <h5 className="bold-text">Payment Request {' '}
                  <i id="popover" onClick={this.togglePopover} className="fa fa-question-circle-o"></i> {' '}
                  <Popover
                    placement="top"
                    isOpen={this.state.popoverOpen}
                    target="popover"
                    trigger="legacy"
                    toggle={this.togglePopover}
                  >
                    <PopoverBody>
                      Supports {' '}
                      <a href="https://www.stellar.org/developers/guides/concepts/list-of-operations.html#path-payment-strict-send"
                         target='_blank' rel='noopener noreferrer'>Path Payment</a> and {' '}
                      <a href="https://github.com/stellar/stellar-protocol/blob/master/ecosystem/sep-0007.md"
                         target='_blank' rel='noopener noreferrer'>SEP-0007</a>.
                    </PopoverBody>
                  </Popover>
                  </h5>

                  <h5 className="subhead">Multicurrency cross-border payments on Stellar</h5>
                </div>

                {
                  this.state.uri ?
                    <>
                      <div className="text-center">
                        <small className={'qr-url text-secondary'}>

                          {
                            webStellar ?
                              // <span><span className={'text-success'}>{parse_uri.uri.protocol}</span>:<span>{parse_uri.uri.pathname + parse_uri.uri.search}</span> {' '}</span>
                              // <a href={encodeUrparseStellarUri} target='_blank'rel='noopener noreferrer'>
                              //   <small className="uri-str"><b>{this.state.uri && this.state.uri.substr(0, 43)}...</b></small>
                              // </a>

                              <span><span className={'text-secondary'}>{encodeUri.toString().substr(0, 43)}</span> {' '}</span>
                              :
                              <span><span className={'text-success'}>{window.location.origin}</span><span>{window.location.pathname}</span> {' '}</span>
                          }

                          <CopyToClipboard text={webStellar ? encodeUri : window.location.href}
                                           onCopy={() => ToastStore.success(`Copied`)}>
                            <i className="fa fa-clone" aria-hidden="true" style={{cursor: 'pointer'}}></i>
                          </CopyToClipboard>
                        </small>
                        <QRCode
                          value={this.state.uri}
                          // logoWidth="60"
                          // logoHeight="60"
                          size="265"
                          quietZone="10"
                          fgColor="#217fe4"
                          qrStyle="dots"
                          // logoOpacity="0.9"
                          // logoImage="/img/logo/icon-transtparent-512.png"
                          style={{width: '100%'}}
                           />
                      </div>

                      {
                        (() => {
                          setTimeout(() => {
                            const qr_img = document.getElementById('react-qrcode-logo');
                            qr_img.setAttribute("style", "width: 100%;"); // .style = "width: 100%;"
                          }, 10)
                        })()
                      }

                      <br/>

                      <div className="text-secondary">
                        {/*<div>Asset: <a href={`/asset/${this.state.asset[0]}-${this.state.asset[1]}`}*/}
                        {/*               target='_blank'*/}
                        {/*               rel='noopener noreferrer'><b className="text-warning">{this.state.asset[0]}</b></a></div>*/}
                        <div>Amount: <b className="text-info">{numFormat(amount, 7)}</b> {' '}
                          <a href={`/trade/${asset[0]}-XLM/${asset[1]}/native`}
                             target='_blank'
                             rel='noopener noreferrer'><b className="text-warning">{asset[0]}</b></a> {' '}
                          {/*<CopyToClipboard text={amount}*/}
                          {/*                 onCopy={() => ToastStore.success(`Amount copied`)}>*/}
                          {/*  <i className="fa fa-clone" aria-hidden="true" style={{cursor: 'pointer'}}></i>*/}
                          {/*</CopyToClipboard>*/}
                        </div>
                        <div>Destination: <a href={`/account/${recipient}`}
                                           target='_blank'
                                           rel='noopener noreferrer'>{iconIdent(recipient, 'icont-ident-id')} <b>{shortAddr(recipient, 6)}</b></a> {' '}
                          <CopyToClipboard text={recipient}
                                           onCopy={() => ToastStore.success(`Address copied`)}>
                            <i className="fa fa-clone" aria-hidden="true" style={{cursor: 'pointer'}}></i>
                          </CopyToClipboard>
                        </div>
                        {memo ? <div><small>Memo: <span className="text-info">{memo}</span></small></div> : null}
                        {msg ? <div><small>Message: <span className="text-info">{msg}</span></small></div> : null}
                        {/*this.state.date ? <div><small>{formatDate(new Date())}</small></div> : null*/}
                        {/*<div><small>Origin Domain: <b>{this.state.domain}</b></small></div>*/}
                      </div>
                    </>
                  :
                    (this.state.loader ? (<Card><CardBody><div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div></CardBody></Card>) : 'Not found...' )
                }

                {
                  this.state.pay_show ?
                    <Card style={{paddingBottom: 0}}>

                      {this.state.loader ? <div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div> : null}

                      <br/>
                      <hr/>

                      <form className="material-form" onSubmit={handleSubmit(this.submitForm)}>

                        {
                          this.state.wallets ?
                           <div>
                             <Field
                                name="select_addr"
                                component={renderTextField}
                                select
                                label="Select Sender Account"
                                onChange={(e) => {
                                  this.changeSender(e)
                                }}
                              >
                                {
                                  this.state.select_addrs ? this.state.select_addrs.map((item, index) => {
                                    if (item.balance) {
                                      return (
                                        <MenuItem
                                          key={index}
                                          className={`material-form__option ${item && item.provider ?  'connect-provider' : ''}`}
                                          value={item.pk}>
                                          <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 8)}</b> <small>&nbsp;/ {item.title} / {item.balance} XLM</small> { getIconConnectProvider(item) }
                                        </MenuItem>
                                      )
                                    }
                                  }) : null
                                }
                              </Field>

                              {
                                this.state.select_addr ?
                                  <div style={{marginTop: -8}}>
                                    <div>
                                      <small>Relevant Balance: {' '}
                                        {
                                          this.state.trustline ?
                                            <>
                                              <b className="text-info">{numFormat(this.state.trustline.balance, 7)}</b> {' '}
                                              <b className="text-warning">{this.state.trustline.asset_code}</b>
                                            </>
                                          :
                                            <span className="text-danger">No Trustline to <b>{this.state.asset[0]}</b></span>
                                        }
                                      </small>
                                    </div>
                                    <div className="text-secondary" style={{fontSize: 11, marginTop: 4}}>
                                      <small>Available Native: <b className="text-info">{numFormat(this.state.available_native, 7)}</b> <b className="text-secondary">XLM</b> &nbsp;/&nbsp; <font>Reserve: <b className="text-info">{this.state.reserve_balance}</b> <b className="text-secondary">XLM</b></font></small></div>
                                  </div>
                                : null
                              }
                            </div>
                          : null
                        }

                        <br/>

                        {
                          this.state.path_result ?
                            <>
                              <div><small><b>Choose Path Payment:</b></small>
                                <button className="panel__btn refresh-bth" type="button" onClick={this.refreshPathPayments}><AutorenewIcon /></button>
                              </div><br/>
                              <div style={{marginBottom: 10}}>
                                {
                                  this.state.path_result.map(this.renderPath.bind(this))
                                }
                              </div>
                            </>
                          : null
                        }

                        {
                          this.state.path_result && this.state.select_asset ?
                            <div className="text-center">
                              <div style={{marginTop: 10}}>
                                <div><small>will be charged</small></div>
                                <b className="text-info">{numFormat(this.state.select_asset.source_amount, 7)}</b> <b className="text-warning">{this.state.select_asset.source_asset_type === 'native' ? 'XLM' : this.state.select_asset.source_asset_code}</b>
                                <div><i className="fa fa-arrow-down text-secondary"></i></div>
                              </div>
                            </div>
                          : null
                        }

                        <div className="text-center" style={{marginTop: 7}}>
                          <Button color="success"
                                  type="submit"
                                  disabled={this.state.disabled ? true : false}
                              >Send <b>{numFormat(this.state.amount, 7)} {this.state.asset[0]}</b></Button>
                        </div>
                      </form>
                    </Card>
                  :
                    this.state.uri ?
                      <div className='text-center'>
                        <br/><br/>
                        <Button color="primary"
                                disabled={this.state.is_auth ? true : false}
                                onClick={() => this.setState({ pay_show: true })}>
                          <i className="fa fa-check"></i> Pay <b>{numFormat(this.state.amount, 7)} {this.state.asset[0]}</b>
                        </Button>
                      </div>
                    : null
                }

                {
                  this.state.result_tx &&
                    <div className={'text-center'}><br/>
                      Transaction ID: <a href={`/transaction/${this.state.result_tx.id}`}
                                         target='_blank'
                                         rel='noopener noreferrer'>
                        <b>{shortAddr(this.state.result_tx.id, 4)}</b></a>
                    </div>
                }

              </CardBody>
            </Card>
          </Col>

        </Row>

        <Footer />

      </Container>
    );
  }
}



// export default translate('common')(Send);
export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(translate('common')(Pay));
