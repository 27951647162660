import React, { PureComponent } from 'react';
import { parseStellarUri, PayStellarUri } from '@stellarguard/stellar-uri';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import {
  shortAddr,
  numFormat,
  formatDate,
  get_loc_wallets,
  baseReserve,
  minBalance,
  getAuth,
  needDeauthAll,
  checkAuth,
  getTitle,
  showErrorMessage,
  getIconConnectProvider, checkConnectProvider, signConnectProvider, passPhrase
} from '../../../modules/index';
import {Button, Card, CardBody, Col, Container, Popover, PopoverBody, Row} from "reactstrap";
import {ToastContainer, ToastStore} from "react-toasts";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {QRCode} from "react-qrcode-logo";
import iconIdent from "../Account/components/iconIdent";
import LoadingIcon from "mdi-react/LoadingIcon";
import {Field} from "redux-form";
import MenuItem from "@material-ui/core/MenuItem";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import Footer from "../../Layout/footer/Footer";
import TextField from "@material-ui/core/TextField";
import { xdrParseClaimant } from '../../../modules/claim-condtions-xdr-parser'



const renderTextField = ({
                           input, label, meta: { touched, error }, children, select, type,
                         }) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

const formatBalanceId = (balance) => {
  return `${balance.substr(8, 4)}…${balance.substr(-4)}`
}


const OperationDescriptionView = ({op, source}) => {

  console.log('op: ', op)
  console.log('source: ', source)

  // return <div>on behalf of account </div>

  function SourceAccount() {
    if (!op.source) return null
    return <div>on behalf of account {op.source || source}</div>
  }

  let asset
  switch (op.type) {
    case 'createAccount':
      return <>
        <b>Create account</b> {op.destination} with starting
        balance {op.startingBalance}
        <SourceAccount/>
      </>
    case 'payment':
      return <>
        <b>Pay</b> {op.amount} {op.asset} to {op.destination}
        <SourceAccount/>
      </>
    case 'pathPaymentStrictReceive':
      return <>
        <b>Path payment</b> max {op.sendMax} {op.sendAsset}
        <span className="fa fa-space fa-random"/>
        {op.path.map((asset, i) => <span key={i}>
                    {asset}
                    <span className="fa fa-space fa-random"/>
                </span>)}
        {op.destAmount} {op.destAsset} to {op.destination}
        <SourceAccount/>
      </>
    case 'pathPaymentStrictSend':
      return <>
        <b>Path payment</b> {op.amount} {op.sendAsset}
        <span className="fa fa-space fa-random"/>
        {op.path.map((asset, i) => <span key={i}>
                    {asset}
                    <span className="fa fa-space fa-random"/>
                </span>)}
        min {op.destAmount} {op.destAsset} to {op.destination}
        <SourceAccount/>
      </>
    case 'manageSellOffer':
    case 'manageBuyOffer': {
      const direction = op.type === 'manageSellOffer' ? 'sell' : 'buy'
      let action,
        isCancelled = parseFloat(op.amount) === 0
      if (op.offerId && op.offerId !== '0') { //manage existing offer
        action = (isCancelled ? `Cancel offer ` : `Update ${direction} offer `)
      } else {
        action = `Create offer`
      }
      if (isCancelled) {
        return <>
          <b>{action}</b> #{op.offerId}
          <SourceAccount/>
        </>
      } else {
        return <>
          <b>{action} {op.offerId > 0 && ('#' + op.offerId)} {direction}</b>{' '}
          {op.amount} {op.selling} for {op.buying} {' '}
          at <b>{op.price}</b> {op.buying.code}/{op.selling.code}
          <SourceAccount/>
        </>
      }
    }
    case 'createPassiveSellOffer':
    case 'createPassiveBuyOffer': {
      const direction = op.type === 'manageSellOffer' ? 'sell' : 'buy'
      return <>
        <b>Create passive {direction} offer</b> {op.amount} {op.selling} for {' '}
        {op.buying} at <b>{op.price}</b> {op.buying.code}/{op.selling.code}
        <SourceAccount/>
      </>
    }
    case 'setOption':
    case 'setOptions':
      return <>
        <b>Set options</b>
        <SourceAccount/>
        {!!(op.setFlags && (op.setFlags & 1)) && <>
          <br/>Set trustline authorization required flag
        </>}
        {!!(op.setFlags && (op.setFlags & 2)) && <>
          <br/>Set trustline authorization revocable flag
        </>}
        {!!(op.clearFlags && (op.clearFlags & 1)) && <>
          <br/>Clear trustline authorization required flag
        </>}
        {!!(op.clearFlags && (op.clearFlags & 2)) && <>
          <br/>Clear trustline authorization revocable flag
        </>}
        {op.homeDomain !== undefined && <>
          <br/>Set home domain {op.homeDomain ?
          <a href={'http://' + op.homeDomain} target="_blank">{op.homeDomain}</a> :
          'not set'}
        </>}
        {op.inflationDest !== undefined && <>
          <br/>Set inflation destination to {op.inflationDest}
        </>}
        {op.lowThreshold !== undefined && <>
          <br/>Set low threshold to {op.lowThreshold}
        </>}
        {op.medThreshold !== undefined && <>
          <br/>Set medium threshold to {op.medThreshold}
        </>}
        {op.highThreshold !== undefined && <>
          <br/>Set high threshold to {op.highThreshold}</>}
        {!!op.signer && <>
          <br/>Add new signer {op.signer.ed25519PublicKey} with
          weight {op.signer.weight}
        </>}
        {op.masterWeight !== undefined && <>
          <br/>Set master key weight to {op.masterWeight}
        </>}
      </>
    case 'changeTrust':
      if (parseFloat(op.limit) > 0) {
        console.log('changeTrust op: ', op)
        return <>
          <b>Create trustline</b> to
          {/*{op.line} with*/}
          {/*limit {op.limit}*/}
          {/*<SourceAccount/>*/}
        </>
      }

      return <>
        <b>Remove trustline</b> to {op.line}
        <SourceAccount/>
      </>
    case 'allowTrust':
      asset = {code: op.assetCode, issuer: op.source || source}
      if (op.authorize) return <>
        <b>Allow trustline</b> {asset}/> for
        account {op.trustor}
        <SourceAccount/>
      </>
      return <>
        <b>Disallow trustline</b> {asset} for
        account {op.trustor}
        <SourceAccount/>
      </>
    case 'accountMerge':
      return <>
        <b>Merge account</b> {op.source || source} into
        account {op.destination}
        <SourceAccount/>
      </>
    case 'inflation':
      return <>
        <b>Run inflation</b>
        <SourceAccount/>
      </>
    case 'manageDatum':
    case 'manageData':
      if (!op.value) return <>
        <b>Delete data entry</b> "{op.name}"
        <SourceAccount/>
      </>
      return <>
        <b>Add data entry</b> "{op.name}"="{op.value}"
        <SourceAccount/>
      </>
    case 'bumpSequence':
      return <>
        <b>Bump account sequence</b> to {op.sequence}
        <SourceAccount/>
      </>
    case 'createClaimableBalance':
      return <>
        <b>Created claimable balance</b> {op.amount} {op.asset} {' '}
        for claimants {op.claimants.map(xdrParseClaimant).map((c, i) => <span key={i}>{i > 0 && ', '}
        {c.destination} with conditions <code>{JSON.stringify(c.predicate)}</code>
                </span>)}
        <SourceAccount/>
      </>
    case 'claimClaimableBalance':
      return <>
        <b>Claim balance</b> <code>{formatBalanceId(op.balanceId)}</code>
        <SourceAccount/>
      </>
    case 'beginSponsoringFutureReserves':
      return <>
        <b>Begin sponsoring future reserves</b> for {op.sponsoredId}
        <SourceAccount/>
      </>
    case 'endSponsoringFutureReserves':
      return <>
        <b>End sponsoring future reserves</b><SourceAccount/>
      </>
    case 'revokeAccountSponsorship':
      return <>
        <b>Revoke sponsorship</b> on account {op.account} <SourceAccount/>
      </>
    case 'revokeTrustlineSponsorship':
      return <>
        <b>Revoke sponsorship</b> on trustline {op.asset} for
        account {op.account} <SourceAccount/>
      </>
    case 'revokeOfferSponsorship':
      return <>
        <b>Revoke sponsorship</b> on offer {op.offerId} for account {op.account}
        <SourceAccount/>
      </>
    case 'revokeDataSponsorship':
      return <>
        <b>Revoke sponsorship</b> on data entry {op.name} for account {op.account}
        <SourceAccount/>
      </>
    case 'revokeClaimableBalanceSponsorship':
      return <>
        <b>Revoke sponsorship</b> on claimable balance {formatBalanceId(op.balanceId)}
        <SourceAccount/>
      </>
    case 'revokeSponsorshipSigner':
      return <>
        <b>Revoke sponsorship</b> on signer {op.signer} for
        account {op.accountId} <SourceAccount/>
      </>
  }
  throw new Error(`Not supported operation type: ${op.type}.`)
}





class Tx extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      uri: false,
      xdr: false,
      result_tx: false,
      popoverOpen: false,
      loader: false,
      tx_show: false,
      transaction: false,
    };
  }


  componentDidMount() {

    const uri = decodeURIComponent(this.props.match.params.id)
    const searchParams = new URLSearchParams(decodeURI(this.props.match.params.id));

    let count = 0, urlStr
    for (let p of searchParams) {
      if (!count) urlStr = p[0].substr(19)
      count++
    }

    const parse_uri = parseStellarUri(uri);
    const transaction = new StellarSdk.Transaction(urlStr, passPhrase());
    console.log('transaction: ', transaction)

    this.setState({
      uri: parse_uri,
      xdr: urlStr,
      transaction,
    })
  }


  render() {

    console.log('tx state: ', this.state)

    const {reset, handleSubmit } = this.props;

    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {/*<Row>
          <Col md={12}>
            <h3 className="page-title">Send Assets</h3>
          </Col>true
        </Row>*/}
        <Row>

          <Col md={{ size: 4, offset: 4 }}  className="align-self-center">
            <Card>
              <CardBody>

                <div className="card__title">
                  <h5 className="bold-text">Transaction {' '}
                    <i id="popover" onClick={this.togglePopover} className="fa fa-question-circle-o"></i> {' '}
                    <Popover
                      placement="top"
                      isOpen={this.state.popoverOpen}
                      target="popover"
                      trigger="legacy"
                      toggle={this.togglePopover}
                    >
                      <PopoverBody>
                        Supports {' '}
                        <a href="https://www.stellar.org/developers/guides/concepts/list-of-operations.html#path-payment-strict-send"
                           target='_blank' rel='noopener noreferrer'>Path Payment</a> and {' '}
                        <a href="https://github.com/stellar/stellar-protocol/blob/master/ecosystem/sep-0007.md"
                           target='_blank' rel='noopener noreferrer'>SEP-0007</a>.
                      </PopoverBody>
                    </Popover>
                  </h5>

                  <h5 className="subhead">Sign the transaction in the web, mobile app or browser extension</h5>
                </div>

                {
                  this.state.uri ?
                    <>
                      <div className="text-center">
                        {/*<small className={'qr-url text-secondary'}>*/}

                        {/*  <span><span className={'text-secondary'}>{this.state.uri.uri.href}</span> {' '}</span>*/}

                        {/*  <CopyToClipboard text={this.state.uri.uri.href}*/}
                        {/*                   onCopy={() => ToastStore.success(`Copied`)}>*/}
                        {/*    <i className="fa fa-clone" aria-hidden="true" style={{cursor: 'pointer'}}></i>*/}
                        {/*  </CopyToClipboard>*/}
                        {/*</small>*/}
                        <QRCode
                          value={this.state.uri.uri.href}
                          // logoWidth="60"
                          // logoHeight="60"
                          size="265"
                          quietZone="10"
                          fgColor="#217fe4"
                          qrStyle="dots"
                          // logoOpacity="0.9"
                          // logoImage="/img/logo/icon-transtparent-512.png"
                          style={{width: '100%'}}
                        />
                      </div>

                      {
                        setTimeout(() => {
                          const qr_img = document.getElementById('react-qrcode-logo');
                          qr_img.setAttribute("style", "width: 100%;"); // .style = "width: 100%;"
                        }, 100)
                        // (() => {
                        //   setTimeout(() => {
                        //     const qr_img = document.getElementById('react-qrcode-logo');
                        //     qr_img.setAttribute("style", "width: 100%;"); // .style = "width: 100%;"
                        //   }, 10)
                        // })()
                      }

                      <br/>

                      {
                        this.state.transaction && this.state.transaction.operations.map((item, index) =>
                          <OperationDescriptionView key={index} op={item} source={this.state.transaction.source}/>)
                        // tx.operations.length > 0 && <ul className="block-indent">
                        //   {tx.operations.map(op => <li key={op.id} className="appear">
                        //     <OperationDescriptionView op={op} source={tx.source_account}/>
                        //   </li>)}
                        // </ul>
                      }

                      {/*<div className="text-secondary">*/}
                      {/*  /!*<div>Asset: <a href={`/asset/${this.state.asset[0]}-${this.state.asset[1]}`}*!/*/}
                      {/*  /!*               target='_blank'*!/*/}
                      {/*  /!*               rel='noopener noreferrer'><b className="text-warning">{this.state.asset[0]}</b></a></div>*!/*/}
                      {/*  <div>Amount: <b className="text-info">{numFormat(amount, 7)}</b> {' '}*/}
                      {/*    <a href={`/trade/${asset[0]}-XLM/${asset[1]}/native`}*/}
                      {/*       target='_blank'*/}
                      {/*       rel='noopener noreferrer'><b className="text-warning">{asset[0]}</b></a> {' '}*/}
                      {/*    /!*<CopyToClipboard text={amount}*!/*/}
                      {/*    /!*                 onCopy={() => ToastStore.success(`Amount copied`)}>*!/*/}
                      {/*    /!*  <i className="fa fa-clone" aria-hidden="true" style={{cursor: 'pointer'}}></i>*!/*/}
                      {/*    /!*</CopyToClipboard>*!/*/}
                      {/*  </div>*/}
                      {/*  <div>Destination: <a href={`/account/${recipient}`}*/}
                      {/*                       target='_blank'*/}
                      {/*                       rel='noopener noreferrer'>{iconIdent(recipient, 'icont-ident-id')} <b>{shortAddr(recipient, 6)}</b></a> {' '}*/}
                      {/*    <CopyToClipboard text={recipient}*/}
                      {/*                     onCopy={() => ToastStore.success(`Address copied`)}>*/}
                      {/*      <i className="fa fa-clone" aria-hidden="true" style={{cursor: 'pointer'}}></i>*/}
                      {/*    </CopyToClipboard>*/}
                      {/*  </div>*/}
                      {/*  {memo ? <div><small>Memo: <span className="text-info">{memo}</span></small></div> : null}*/}
                      {/*  {msg ? <div><small>Message: <span className="text-info">{msg}</span></small></div> : null}*/}
                      {/*  /!*this.state.date ? <div><small>{formatDate(new Date())}</small></div> : null*!/*/}
                      {/*  /!*<div><small>Origin Domain: <b>{this.state.domain}</b></small></div>*!/*/}
                      {/*</div>*/}
                    </>
                    :
                    (this.state.loader ? (<Card><CardBody><div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div></CardBody></Card>) : 'Not found...' )
                }

                {
                  this.state.tx_show ?
                    <Card style={{paddingBottom: 0}}>

                      {this.state.loader ? <div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div> : null}

                      <br/>
                      <hr/>

                      <form className="material-form" onSubmit={handleSubmit(this.submitForm)}>

                        {
                          this.state.wallets ?
                            <div>
                              <Field
                                name="select_addr"
                                component={renderTextField}
                                select
                                label="Select Sender Account"
                                onChange={(e) => {
                                  this.changeSender(e)
                                }}
                              >
                                {
                                  // this.state.select_addrs ? this.state.select_addrs.map((item, index) => {
                                  //   if (item.balance) {
                                  //     return (
                                  //       <MenuItem
                                  //         key={index}
                                  //         className={`material-form__option ${item.provider && 'connect-provider'}`}
                                  //         value={item.pk}>
                                  //         <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 8)}</b> <small>&nbsp;/ {item.title} / {item.balance} XLM</small> { getIconConnectProvider(item) }
                                  //       </MenuItem>
                                  //     )
                                  //   }
                                  // }) : null
                                }
                              </Field>

                              {
                                this.state.select_addr ?
                                  <div style={{marginTop: -8}}>
                                    <div>
                                      <small>Relevant Balance: {' '}
                                        {/*{*/}
                                        {/*  this.state.trustline ?*/}
                                        {/*    <>*/}
                                        {/*      <b className="text-info">{numFormat(this.state.trustline.balance, 7)}</b> {' '}*/}
                                        {/*      <b className="text-warning">{this.state.trustline.asset_code}</b>*/}
                                        {/*    </>*/}
                                        {/*    :*/}
                                        {/*    <span className="text-danger">No Trustline to <b>{this.state.asset[0]}</b></span>*/}
                                        {/*}*/}
                                      </small>
                                    </div>
                                    {/*<div className="text-secondary" style={{fontSize: 11, marginTop: 4}}>*/}
                                    {/*  <small>Available Native: <b className="text-info">{numFormat(this.state.available_native, 7)}</b> <b className="text-secondary">XLM</b> &nbsp;/&nbsp; <font>Reserve: <b className="text-info">{this.state.reserve_balance}</b> <b className="text-secondary">XLM</b></font></small>*/}
                                    {/*</div>*/}
                                  </div>
                                  : null
                              }
                            </div>
                            : null
                        }

                        <br/>

                        {/*{*/}
                        {/*  this.state.path_result && this.state.select_asset ?*/}
                        {/*    <div className="text-center">*/}
                        {/*      <div style={{marginTop: 10}}>*/}
                        {/*        <div><small>will be charged</small></div>*/}
                        {/*        <b className="text-info">{numFormat(this.state.select_asset.source_amount, 7)}</b> <b className="text-warning">{this.state.select_asset.source_asset_type === 'native' ? 'XLM' : this.state.select_asset.source_asset_code}</b>*/}
                        {/*        <div><i className="fa fa-arrow-down text-secondary"></i></div>*/}
                        {/*      </div>*/}
                        {/*    </div>*/}
                        {/*    : null*/}
                        {/*}*/}

                        <div className="text-center" style={{marginTop: 7}}>
                          <Button color="success"
                                  type="submit"
                                  disabled={this.state.disabled ? true : false}
                                >Send
                          </Button>
                        </div>
                      </form>
                    </Card>
                    :
                    this.state.uri ?
                      <div className='text-center'>
                        <br/><br/>
                        <Button color="primary"
                                disabled={this.state.is_auth ? true : false}
                                onClick={() => this.setState({ pay_show: true })}>
                          <i className="fa fa-check"></i> Sign this transaction
                        </Button>
                      </div>
                      : null
                }

                {
                  this.state.result_tx &&
                  <div className={'text-center'}><br/>
                    Transaction ID: <a href={`/transaction/${this.state.result_tx.id}`}
                                       target='_blank'
                                       rel='noopener noreferrer'>
                      <b>{shortAddr(this.state.result_tx.id, 4)}</b></a>
                  </div>
                }

              </CardBody>
            </Card>
          </Col>

        </Row>

        <Footer />

      </Container>
    );
  }
}



export default Tx;
