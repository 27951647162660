import React from 'react';
import createStellarIdenticon from 'stellar-identicon-js';


export default (address, classNames) => {

    const canvas = createStellarIdenticon(address);
    const renderedIcon = canvas.toDataURL() 

    return <img src={renderedIcon} className={classNames} />
 }