import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarNotification from './TopbarNotification';
import TopbarSearch from './TopbarSearch';
import freighterApi from "@stellar/freighter-api";
import {ToastStore} from "react-toasts";
import swal from "sweetalert";
import TopbarMenuLink from "./TopbarMenuLink";
import {Collapse} from "reactstrap";
import { shortAddress, get_loc_wallets, setLocalWallets } from '../../../modules/index';
import { toggleAuthProvider } from '../../../redux/actions/customizerActions';
// import TopbarLanguage from './TopbarLanguage';



class Topbar extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      is_active: true,
      is_notifs: false,
      active_link: 'wallet',
      connect_provider: localStorage.getItem('connect_provider'),
      collapse: false,
      wallets: get_loc_wallets(),
    };
  }
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.checkNotifs();


    // function getPath() {
    //   return window.location.pathname;
    // }

    const setUrl = () => {

      // const pathname = getPath();
      const route = window.location.pathname.split('/')[1];

      var active = '';

      if (route === 'wallet') { active = 'wallet' }
      else if (route === 'assets') { active = 'assets' }
      else if (route === 'trade') { active = 'trade' }
      else if (route === 'swap') { active = 'swap' }
      else if (route === 'pools' || route === 'pool') { active = 'pools' }
      else if (route === 'send') { active = 'send' }
      else { active = '' }

      this.setState({
        active_link: active,
      })
    }

    setUrl();

    setInterval(() => {
      setUrl();
    }, 3000)

    // this.getConnectProvider()
  }

  toggle = (e) => {
    if (e) e.preventDefault()

    this.setState({ collapse: !this.state.collapse });
  };


  logoutProvider = () => {

    const { wallets } = this.state
    let connect_provider = localStorage.getItem('connect_provider')

    if (connect_provider && wallets) {
      connect_provider = JSON.parse(connect_provider)

      const filter_wallets = wallets.filter(item => item.pk !== connect_provider.account_id)

      if (filter_wallets.length) {
        setLocalWallets(filter_wallets)
        localStorage.removeItem('connect_provider')
      }

      this.props.dispatch(toggleAuthProvider(false));
      this.toggle()

      setTimeout(() => {
        swal({
          title: 'Disconnected!',
          text: `You have disabled browser extension`,
          icon: 'success',
        });
        // this.resetAllWallets()
      }, 500)
    }
    else {
      this.resetAllWallets()
    }
  }


  resetAllWallets = () => {
    localStorage.removeItem('connect_provider')
    localStorage.removeItem('wallets')
  }


  checkNotifs() {

    let nofiications = localStorage.getItem('notifications')

    if (nofiications) {
      this.setState({
        is_notifs: true,
      })
    }
  }


  linkActive(value) {
    // var links = document.querySelectorAll('.topbar__nav-link');
    // console.log('link: ', value)

    this.setState({
      active_link: value,
    })
  }


  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;

    let connect_provider = localStorage.getItem('connect_provider')
    if (connect_provider) {
      connect_provider = JSON.parse(connect_provider)
    }
    if (!connect_provider && this.props.isAuthProvider) {
      connect_provider = this.props.isAuthProvider
    }



    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <Link className="topbar__logo" to="/" />
          </div>

          <nav className="topbar__nav">

            <a className={`topbar__nav-link ${this.state.active_link === 'wallet' ? 'topbar-active' : null}`}
               href="/wallet"
               onClick={() => {this.linkActive('wallet')}}><b>Wallet</b></a>

            <Link className={`topbar__nav-link ${this.state.active_link === 'assets' && this.state.is_active ? 'topbar-active' : null}`}
               to="/assets"
               onClick={() => {this.linkActive('assets')}}><b>Assets</b></Link>

            <Link className={`topbar__nav-link ${this.state.active_link === 'trade' ? 'topbar-active' : null}`}
                to="/trade"
                onClick={() => {this.linkActive('trade')}}><b>Trade</b></Link>

            <Link className={`topbar__nav-link ${this.state.active_link === 'swap' ? 'topbar-active' : null}`}
                  to="/swap"
                  onClick={() => {this.linkActive('swap')}}><b>Swap</b></Link>

            <Link className={`topbar__nav-link ${this.state.active_link === 'pools' ? 'topbar-active' : null}`}
                  to="/pools"
                  onClick={() => {this.linkActive('pools')}}><b>AMM</b></Link>

            {/*<Link className={`topbar__nav-link ${this.state.active_link === 'receive' ? 'topbar-active' : null}`}*/}
            {/*    to="/receive"*/}
            {/*    onClick={() => {this.linkActive('send')}}><b>Receive</b></Link>*/}

            {/*<Link className={`topbar__nav-link ${this.state.active_link === 'send' ? 'topbar-active' : null}`}*/}
            {/*      to="/send"*/}
            {/*      onClick={() => {this.linkActive('send')}}><b>Send</b></Link>*/}
          </nav>

          <div className="topbar__right">
            {
              this.props.notifications.length || this.state.is_notifs ?
                <TopbarNotification />
              : null
            }
            <TopbarSearch />

            <span>
              {
                connect_provider ?
                <div>
                  <a href={'#'} onClick={this.toggle}>
                    <img className={'provider-icon'} src={`/img/icons/auth/${connect_provider.provider}.svg`} alt=""/>
                  </a>

                  {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}

                  <Collapse isOpen={this.state.collapse} className="topbar-provider">
                    <div className="topbar__menu provider-menu text-center">
                      <p>
                        <img className={'provider-icon provider-icon-big'}
                             style={{margin: '0 10px 0 0'}}
                             src={`/img/icons/auth/${connect_provider.provider}.svg`}
                             alt=""/>
                             <br/><br/>
                        <span>{shortAddress(connect_provider.account_id)}</span>
                      </p>
                      <p>Connected to the <b>{connect_provider.provider[0].toUpperCase() + connect_provider.provider.slice(1)}</b> extension</p>
                      <br/>
                      <p><a href="#" onClick={this.logoutProvider}><i className="fa fa-sign-out"></i> Log out</a></p>
                    </div>
                  </Collapse>
                </div>
                  : null
              }
            </span>

          </div>
        </div>
      </div>
    );
  }
}
// <TopbarLanguage />
// <TopbarMail new />
// <TopbarProfile />


const mapStateToProps = (state, ownProps) => {
  return {
    notifications: state.customizer.notifications,
    isAuthProvider: state.customizer.isAuthProvider
  }
}

export default connect(mapStateToProps)(Topbar);
