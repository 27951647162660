import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Badge, Table, Button } from 'reactstrap';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts'; // Tooltip
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import moment from 'moment';
import LoadingIcon from 'mdi-react/LoadingIcon';
import axios from 'axios';
import { GLOBE } from '../../../../modules/globeVars/index';
import PropTypes from 'prop-types';
import { numFormat, shortAddr} from "../../../../modules";
import SpinnerLoader from '../../../../modules/SpinnerLoader/index';
import { ToastContainer, ToastStore } from 'react-toasts';

import swal from "sweetalert";
import {CopyToClipboard} from "react-copy-to-clipboard";


let raw_assets = [], page_count = 0;


class TopAssets extends PureComponent {

  // Input Data:
  // - Asset(code/issuer)
  // - limit

  constructor(props) {
    super(props);

    this.state = {
      assets: [],
      assets_db: [],
      load_assets_db: false,
      load_assets: false,
      assets_length: 0,
      load_search_data: false,
      load_more: true,
      // xlm_price: [],
      load_xlm_price: false,
      loader: false,
    };
  }

  componentDidMount() {

    this.getTopAssets();
    // this.getPriceLocal();

    if (this.props.search_data) {

      this.setState({
        load_search_data: true,
      })
    }
  }


  loadMore() {
    this.setState({ load_more: false })

    page_count = (page_count+1);

    // setTimeout(() => this.getTopAssets(), 1000)
    this.getTopAssets()
  }


  getTopAssets() {

    if (this.props.search_data) {

      let url,
          asset_code = this.props.search_data.asset_code,
          asset_issuer = this.props.search_data.asset_issuer;

      if (asset_code && !asset_issuer) {
        url = `${GLOBE.API_URL}/get_assets_code_issuer/?asset_code=${asset_code}&asset_issuer=`;
      }
      else if (!asset_code && asset_issuer) {
        url = `${GLOBE.API_URL}/get_assets_code_issuer/?asset_issuer=${asset_issuer}&asset_code=`;
      }
      else if (asset_code && asset_issuer) {
        url = `${GLOBE.API_URL}/get_assets_code_issuer/?asset_code=${asset_code}&asset_issuer=${asset_issuer}`;
      }

      axios.post(url)
      .then(res => {
        console.log('getTopAssets res: ', res)

        if (res.data.length > 0) {
          // this.getAssetFromStellar(res.data);
          this.setState({
            assets_db: res.data,
            load_assets_db: true,
          })

          this.props.searchResult(true)
        }
        else {
          this.setState({ load_assets_db: true })
          ToastStore.info('No such Asset found');
          this.props.searchResult(false)
        }
      })
    }
    else {

      const formData = new FormData();
      formData.append('page', page_count)
      formData.append('limit', this.props.limit)


      axios.post(GLOBE.API_URL+`/get_market_assets`, formData)
      .then(res => {
        console.log('get_market_assets res', res)

        if (res.data.length > 0) {
          // this.getAssetFromStellar(res.data);

          // console.log('res.data', res.data)
          // console.log('this.state.assets_db', this.state.assets_db)

          this.setState({
            assets_db: this.state.assets_db.concat(res.data),
            load_assets_db: true,
          })

          if (page_count > 0) {
            this.setState({
              load_more: true,
            });
          }
        }
        else {
          this.setState({ load_assets: true })
        }
      })
    }
  }


  // getAssetFromStellar(res) {
  //
  //   if (this.state.load_search_data) {
  //     raw_assets = [];
  //   }
  //
  //   res.forEach((item, index) => {
  //
  //     Server.assets()
  //       .forIssuer(item.asset_issuer)
  //       .forCode(item.asset_code)
  //       .call()
  //       .then((resp) => {
  //
  //         // item.index = index;
  //         item.amount = resp.records[0].amount;
  //         item.amount_format = numFormat(item.amount, 0);
  //         item.holders = numFormat(resp.records[0].num_accounts, 0);
  //         item.toml = resp.records[0]._links.toml.href;
  //
  //         this.getTradesAsset(item, index, res);
  //       })
  //       .catch((err) => {
  //         console.error('err: ', err);
  //       })
  //   })
  //
  // }


  // getTradesAsset(item, index, arr_db) {

  //   var obj = {
  //       base: new StellarSdk.Asset.native(),
  //       counter: new StellarSdk.Asset(item.asset_code, item.asset_issuer),
  //       start_time: 0,
  //       end_time: 0,
  //       resolution: 86400000,
  //   }

  //   Server.tradeAggregation(obj.base, obj.counter, obj.start_time, obj.end_time, obj.resolution)
  //     .order('desc')
  //     .limit(200)
  //     .call()
  //     .then((result) => {

  //       const price_usd = this.state.xlm_price[0].price_usd;

  //       item.volume_xlm = result.records[0].base_volume;
  //       item.volume_usd = (item.volume_xlm * price_usd);
  //       item.price = Number((1 / result.records[0].close).toFixed(7));
  //       item.price_usd = (item.price * price_usd);
  //       item.price24 = Number((1 / result.records[1].close).toFixed(7));
  //       item.market_cap_xlm = (item.amount * item.price);
  //       item.market_cap_usd = (item.market_cap_xlm * price_usd);

  //       let val = this.change24(result);
  //       item.change24 = val.change24;
  //       item.change_24h_color = val.change_24h_color;
  //       item.chart7d = val.chart7d;

  //       this.getToml(item);

  //       raw_assets.push(item);

  //       if ((index+1) === arr_db.length) {

  //         setTimeout(() => {
  //           this.setState({
  //             assets: raw_assets,
  //             load_assets: true,
  //           });
  //         }, 500);

  //         if (page_count > 0) {
  //           this.setState({
  //             load_more: true,
  //           });
  //         }
  //       }
  //     })
  //     .catch(function(err) {
  //       console.error('err: ', err);
  //   });
  // }


  // change24(val) {

  //   var today = Number((val.records[0].close_r.D / val.records[0].close_r.N).toFixed(7));
  //   var yestoday = Number((val.records[1].close_r.D / val.records[1].close_r.N).toFixed(7));

  //    if (today > yestoday) {
  //      more();
  //    }
  //    else if (today === yestoday) {
  //      val.change24 = '0.00';
  //      val.change_24h_color = 'secondary';
  //    }
  //    else {
  //      less();
  //    }

  //    function more() {
  //      val.change24 = '+'+Number(today / yestoday * 100 - 100).toFixed(2);
  //      val.change_24h_color = 'success';
  //    }

  //    function less() {
  //      val.change24 = Number(100 - yestoday * 100 / today).toFixed(2);
  //      val.change_24h_color = 'danger';
  //    }

  //   val.chart7d = [];
  //   val.records.forEach((item, index) => {
  //     if (index+1 <= 7) {
  //       var price = Number((item.close_r.D / item.close_r.N).toFixed(7));
  //       if (val.records[0].close > item.close) {
  //         price = (price * 1.5)
  //       }
  //       else if (val.records[0].close < item.close) {
  //         price = (price / 1.5)
  //       }
  //       val.chart7d.unshift({ name: index, price: price });
  //     }
  //   })

  //   return val;
  // }


  // getToml(item) {

  //   const home_domain = item.toml.replace('https://', '').replace('/.well-known/stellar.toml', '');

  //   if (home_domain) {

  //     StellarSdk.StellarTomlResolver.resolve(home_domain)
  //       .then(assetToml => {

  //         assetToml.CURRENCIES.forEach((item_token, index) => {

  //           if (item_token.code === item.asset_code && item_token.issuer === item.asset_issuer) {

  //             item.toml = item_token;
  //             item.home_domain = home_domain;
  //             item.toml_full = assetToml;
  //           }
  //         })
  //       })
  //       .catch(error => {
  //         console.log('toml err', error);
  //     });
  //   }
  // }


  // renderAssets(item, index) {

  //   if (item.image === '' || item.image === 'undefined') {
  //     item.image = `${process.env.PUBLIC_URL}/img/digitalcoin.png`;
  //   }

  //   return (
  //     <tr key={index}>
  //       <td>{ index+1 }</td>
  //       <td>
  //         {/*<a href=""></a>*/}
  //         <Link className="text-secondary" to={`/asset/${item.asset_code}-${item.asset_issuer}`}><img className="asset-logo" src={item.image} alt="value" /><b>{item.asset_code}</b></Link>
  //       </td>
  //       <td><small>{item.toml.name}</small></td>
  //       {/*<td><a href={item.home_domain} target="_blank">{item.home_domain}</a></td>*/}
  //       <td>${numFormat(item.market_cap_usd)}</td>
  //       <td>${numFormat(item.volume_usd, 0)} / {numFormat(item.volume_xlm, 0)}</td>
  //       <td>{item.amount_format}</td>
  //       <td>{item.holders}</td>
  //       <td>${numFormat(item.price_usd, 7)} / {numFormat(item.price, 4)}</td>
  //       <td><Badge color={item.change_24h_color}>{item.change24}%</Badge></td>
  //       <td>
  //         <ResponsiveContainer height={24} className="dashboard__chart-container">
  //           <AreaChart data={item.chart7d} margin={{ top: 0, left: 0, bottom: 0 }}>
  //             <Area
  //               name={item.asset_code}
  //               type="monotone"
  //               dataKey="price"
  //               fill="#4ce1b6"
  //               stroke="#4ce1b6"
  //               fillOpacity={0.2}
  //             />
  //           </AreaChart>
  //         </ResponsiveContainer>
  //       </td>
  //     </tr>
  //   );
  // }

  // getImageSrc(image) {

  //   if (image === '' || image === 'undefined') {
  //     image = `${process.env.PUBLIC_URL}/img/digitalcoin.png`;
  //   }

  //   let img = document.createElement('img');
  //   img.src = image; // (*)

  //   img.onload = function() {
  //     // alert(`Изображение загружено`);
  //   };

  //   img.onerror = function() {
  //     image = `${process.env.PUBLIC_URL}/img/digitalcoin.png`;
  //   };

  //   return image
  // }


  changeTrust = (item) => {
    this.props.acceptAsset(item)
  }


  renderAssets = (item, index) => {
    // console.log('item: ', item)

    if (item.image === '' || item.image === 'undefined') {
      item.image = `${process.env.PUBLIC_URL}/img/digitalcoin.png`;
    }

    const plus = /\+/gi;
    const minus = /\-/gi;
    if (!item.change24.match(plus) && item.change_24h_color === 'success') {
      item.change24 = `+${item.change24}`
    }
    else if (!item.change24.match(minus) && item.change_24h_color === 'danger') {
      item.change24 = `-${item.change24}`
    }
    item.change24  = item.change24.replace(/\s+/g, '');


    let market_cap_usd = 0, volume_usd = 0, price_usd = 0;
    if (this.props.price) {
      market_cap_usd = ((item.supply * item.price) * this.props.price)
      volume_usd = (item.base_volume * this.props.price)
      price_usd = (item.price * this.props.price)
    }


    let chart7d = false;
    if (item.chart7d !== 'undefined' && item.chart7d !== '') {
      let isValidJSON = true;
      try { JSON.parse(item.chart7d) } catch { isValidJSON = false }
      chart7d = isValidJSON ? JSON.parse(item.chart7d) : false
    }

    const style = {
      btn: {
        fontSize: '10px',
        padding: '5px 12px',
        marginBottom: 0,
      }
    }

    return (
      <tr key={index}>
        {/*<td>{ index+1 }</td>*/}
        <td style={{whiteSpace: 'pre'}}>
          <a className="text-secondary" href={`/trade/${item.asset_code}-XLM/${item.asset_issuer}/native`}>
            <div>
              <img className="asset-logo" src={item.image} alt="value" />
              <b>{item.asset_code}</b>
              {/*<div><small>{shortAddress(item.asset_issuer)}</small></div>*/}
            </div>
          </a>
        </td>
        <td className="name_domain">
          <span className="name-asset">{item.name !== 'undefined' ? item.name : item.home_domain}<br/><small className="home_domain">{item.home_domain}</small></span>
        </td>
        {
          this.props.page !== 'home' ?
              <td>
                <Button color="secondary"
                        outline
                        size="sm"
                        style={style.btn}
                        onClick={() => this.changeTrust(item)}>+</Button>
              </td> : null
        }

        <td>
          <small>
            <Link to={`/account/${item.asset_issuer}`}
                         className={'text-secondary'}>{shortAddr(item.asset_issuer, 4)}</Link> &nbsp;&nbsp;
            <CopyToClipboard text={item.asset_issuer}
                             onCopy={() => {}}>
              <a href="#" onClick={(e) => {
                e.preventDefault()
                ToastStore.success('Copied!')
              }}><i className={'fa fa-clone'}></i></a>
            </CopyToClipboard>
          </small>
        </td>

        <td>${numFormat(market_cap_usd)}</td>
        <td>${numFormat(volume_usd)}</td>
        <td>{numFormat(item.supply)}</td>
        <td>{numFormat(item.holders)}</td>
        <td>{item.spread}%</td>
        <td>${numFormat(price_usd, 7)}</td>
        <td><Badge color={item.change_24h_color}>{item.change24}%</Badge></td>
        <td>
          {
            chart7d ?
              <ResponsiveContainer height={24} className="dashboard__chart-container">
                <AreaChart data={chart7d} margin={{ top: 0, left: 0, bottom: 0 }}>
                  <Area
                    name={item.asset_code}
                    type="monotone"
                    dataKey="price"
                    fill="#4ce1b6"
                    stroke="#4ce1b6"
                    fillOpacity={0.2}
                  />
                </AreaChart>
              </ResponsiveContainer>
            : null
          }
        </td>
      </tr>
    );
  }


  render() {

    // console.log('this.state: ', this.state)

    return (
      <div>

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {!this.state.load_assets_db ? <div className="panel__refresh"><LoadingIcon /></div> : ''}

        <Table responsive className="table--bordered" style={{fontSize: '12px'}}>
          <thead>
            <tr>
              {/*<th>#</th>*/}
              <th>Asset</th>
              <th>Name</th>
              {
                this.props.page !== 'home' ? <th>Trustline</th> : null
              }
              <th>Issuer</th>
              <th>Market Cap</th>
              <th>Vol 24h</th>
              <th>Supply</th>
              <th>Holders</th>
              <th>Spread</th>
              <th>Price</th>
              <th>Change 24h</th>
              <th>Price 7d</th>
            </tr>
          </thead>
          <tbody>

            {
              this.state.load_assets_db ?
                this.state.assets_db.map(this.renderAssets)
                : null
            }

          </tbody>
        </Table>
        <br/><br/>
        <div className="text-center">
          {
            window.location.pathname === '/assets' && this.state.load_assets_db && !this.state.load_search_data ?
              <Button color="secondary" onClick={() => this.loadMore()}>
                {
                  this.state.load_more ? 'Load more...' :
                  <SpinnerLoader />
                }
              </Button>
            : null
          }

          {
            window.location.pathname === '/home' || window.location.pathname === '/' && this.state.load_assets_db ?
              <Link className="btn btn-secondary" to="/assets">All Assets</Link>
            : null
          }
        </div>
      </div>
    );
  }
}


TopAssets.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate('common')(TopAssets);
