import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { ToastContainer, ToastStore } from 'react-toasts';



class SearchField extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      // 
    };

    this.assetForm = this.assetForm.bind(this);
  }

  componentDidMount() {
    if (this.props.asset) {
      this.props.change('asset_code', this.props.asset.asset_code)
    }
  }


  assetForm(values) {

    if (!values.asset_code && !values.asset_issuer) {
      ToastStore.warning('Enter Asset Code or Code Issuer, or both.');
    }
    else {

      let asset = {
        asset_code: '',
        asset_issue:  '',
      };

      
      if (values.asset_code) {
        asset.asset_code = values.asset_code;
      }
      if (values.asset_issuer) {
        asset.asset_issuer = values.asset_issuer;
      }

      // console.log('asset: ', asset)

      this.props.search_data(asset);
    } 
  }


  render() {
    const { handleSubmit, reset, t } = this.props;

    return (
      <div>
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} /> 

        <form className="form" onSubmit={handleSubmit(this.assetForm)}>

          <Col md={2}>
            <div className="form__form-group">
              <span className="form__form-group-label"><b>Asset Code</b></span>
              <div className="form__form-group-field">
                <Field
                  name="asset_code"
                  component="input"
                  type="search"
                  placeholder="Code"
                />
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="form__form-group">
              <span className="form__form-group-label"><b>Asset Issuer</b></span>
              <div className="form__form-group-field">
                <Field
                  name="asset_issuer"
                  component="input"
                  type="search"
                  placeholder="Issuer"
                />
              </div>
            </div>
          </Col>

          <Col md={2} style={{display: 'none'}}>
            <div className="form__form-group">
              <span className="form__form-group-label"></span>
              <div className="form__form-group-field">
                <Button color="secondary" type="submit" outline >Search</Button>
              </div>
            </div>
          </Col>
          
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'vertical_form_layout', // a unique identifier for this form
})(translate('common')(SearchField));
