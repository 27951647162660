import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, ButtonToolbar, Button } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import LoadingIcon from 'mdi-react/LoadingIcon';
import CreateAccount from './components/CreateAccount';
import RestoreAccount from './components/RestoreAccount';
import Footer from '../../Layout/footer/Footer';
// import moment from 'moment';


class Auth extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      //
    };
  }

  componentDidMount() {
    //
  }

  onRefresh = () => {
    this.setState({ refresh: true });
  };



  render() {

    return (
      <Container className="dashboard">
        {this.state.refresh ? <div className="panel__refresh"><LoadingIcon /></div> : ''}

        <Row>

          { this.props.match.params.id === 'create' ? <CreateAccount /> : null }

          { this.props.match.params.id === 'connect' ? <RestoreAccount /> : null }

        </Row>

        <div className='text-center'>
          {
            this.props.match.params.id === 'connect' ? <Link to='/auth/create'><i className="fa fa-plus"></i> Create Account</Link> : null
          }
          {
            this.props.match.params.id === 'create' ? <Link to='/auth/connect'><i className="fa fa-sign-in"></i> Connect Wallet</Link> : null
          }
        </div>

        <br/><br/>

        <Footer />

      </Container>
    );
  }
}


Auth.propTypes = {
  t: PropTypes.func.isRequired,
};


export default translate('common')(Auth);
