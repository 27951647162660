/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NotificationSystem from 'rc-notification';
import Topbar from './topbar/Topbar';
import TopbarWithNavigation from './topbar_with_navigation/TopbarWithNavigation';
import Sidebar from './sidebar/Sidebar';
import SidebarMobile from './topbar_with_navigation/sidebar_mobile/SidebarMobile';
import Customizer from './customizer/Customizer';
// import { BasicNotification } from '../../shared/components/Notification';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from '../../redux/actions/sidebarActions';
import { changeThemeToDark, changeThemeToLight } from '../../redux/actions/themeActions';
import { changeBorderRadius, toggleBoxShadow, toggleTopNavigation, toggleShowNotification, addNotification } from '../../redux/actions/customizerActions';
import { CustomizerProps, SidebarProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import { Server } from '../../modules/stellar/index';
import { shortAddr, numFormat, formatDate, get_loc_wallets, getNameAddr } from '../../modules/index';
import iconIdent from '../Pages/Account/components/iconIdent';


let notification = null;


const checkMyAccount = (address) => {
  const wallets = get_loc_wallets();

  let check = false;

  wallets.forEach((item, index) => {
    if (item.pk === address) {
      check = true;
    }
  })

  return check;
}


const getAsset = (item) => {

  return (
    <a href={(item.asset_type === 'native' ? '/asset/XLM-native' : /asset/+item.asset_code+'-'+item.asset_issuer)}>
      <b className="text-warning">{ item.asset_type === 'native' ? 'XLM' : item.asset_code }</b>
    </a>
  )
}


const shortAddress = (address) => {

  const name = getNameAddr(address);
  return (
    <a href={`/account/${address}`}>{iconIdent(address, 'icon-indent-sm')} <b>{shortAddr(address, 4)}</b> <small className="text-info">{name ? `[${name}]` : ''}</small></a>
  )
}


const getCounterAsset = (item) => {
  return (
    <a href={'/asset/'+(item.counter_asset_type === 'native' ? 'XLM-native' : item.counter_asset_code + '-' + item.counter_asset_issuer)}>
    <b className="text-warning">{ item.counter_asset_type === 'native' ? 'XLM' : item.counter_asset_code }</b>
    </a>
    )
}


const getBaseAsset = (item) => {
  return (
    <a href={'/asset/'+(item.base_asset_type === 'native' ? 'XLM-native' : item.base_asset_code + '-' + item.base_asset_issuer)}>
    <b className="text-warning">{ item.base_asset_type === 'native' ? 'XLM' : item.base_asset_code }</b>
    </a>
  )
}




class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
    customizer: CustomizerProps.isRequired,
    theme: ThemeProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      wallets: get_loc_wallets(),
      // notifications: [],
    };
  }

  componentDidMount() {

    this.recivedMoney(null);
    NotificationSystem.newInstance({}, n => notification = n);
    this.eventStream();
    this.setNavigator()
  }

  componentWillUnmount() {
    notification.destroy();
  }


  setNotification = (item, type) => {

    let notifications = localStorage.getItem('notifications');

    const pushItem = (notifications) => {

      if (type == 'trade') {
          var notification = {
              offer_id: item.offer_id,
              base_offer_id: item.base_offer_id,
              base_account: item.base_account,
              base_amount: item.base_amount,
              base_asset_type: item.base_asset_type,
              counter_offer_id: item.counter_offer_id,
              counter_account: item.counter_account,
              counter_amount: item.counter_amount,
              counter_asset_type: item.counter_asset_type,
              counter_asset_code: item.counter_asset_code,
              counter_asset_issuer: item.counter_asset_issuer,
              base_is_seller: item.base_is_seller,
              price: item.price,
              type: type,
          }
      }
      if (type == 'payment') {
          var notification = {
              created_at: item.created_at,
              transaction_hash: item.transaction_hash,
              asset_type: item.asset_type,
              asset_code: item.asset_code,
              asset_issuer: item.asset_issuer,
              from: item.from,
              to: item.to,
              amount: item.amount,
              type: type,
          }
      }


      notifications.push(notification);

      if (notifications.length > 200) {
          notifications.pop();
      }

      // this.setState({
      //     // notifications: this.state.notifications.concat(notification)
      //     notifications: [notification, ...this.state.notifications]
      // }, () => {
      //     if (this.state.notifications.length > 200) {
      //         this.setState({
      //             notifications: this.state.notifications.pop()
      //         })
      //     }
      // })

      localStorage.setItem('notifications', JSON.stringify(notifications));

      this.props.dispatch(addNotification(notifications));
    }


    if (!notifications) {
        pushItem([]);
    }
    else {
        pushItem(JSON.parse(notifications));
    }
  }


    showNotification = (item, type) => {

        let classElem = '';
        if (localStorage.getItem('theme') === 'theme-dark') {
            classElem = 'notification-dark'
        }

        const Content = () => (
            <div className={`notification notification--info ${classElem}`}>

                {
                    type === 'payment' ?
                        <div>
                            <h5 className="notification__title bold-text">Payment <b><font className="text-success">+{numFormat(item.amount, 7)}</font> {getAsset(item)}</b></h5>
                            <p className="notification__message">Your
                                &nbsp;{shortAddress(item.to)} Account received <b className="text-info">{numFormat(item.amount, 7)} {getAsset(item)}</b> from the
                                &nbsp;{shortAddress(item.from)} Account.
                            </p>
                            <p>
                                <small className="text-secondary">Transaction: <a href={'/transaction/'+item.transaction_hash}>{shortAddr(item.transaction_hash, 4)}</a></small>
                                <small className="text-secondary" style={{float: 'right'}}>{formatDate(item.created_at)}</small>
                            </p>
                        </div>
                        : null
                }

                {
                    type === 'trade' ?
                        <div>
                            <h5 className="notification__title bold-text">Trade</h5>
                            <p className="notification__message">
                                {
                                    item.base_is_seller ?
                                        <span>{checkMyAccount(item.counter_account) ? 'My Account' : ''} {shortAddress(item.counter_account)} <font className="text-danger">sold</font> <b className="text-info">{numFormat(item.counter_amount, 7)}</b> { getCounterAsset(item) } / <font className="text-success">bought</font> <b className="text-info">{numFormat(item.base_amount, 7)}</b> { getBaseAsset(item) } from {checkMyAccount(item.counter_account) ? 'My Account' : ''} { shortAddress(item.base_account) } </span>
                                        :
                                        <span>{checkMyAccount(item.base_account) ? 'My Account' : ''} {shortAddress(item.base_account)} <font className="text-success">bought</font> <b className="text-info">{numFormat(item.counter_amount, 7)}</b> { getCounterAsset(item) } / <font className="text-danger">sold</font> <b className="text-info">{numFormat(item.base_amount, 7)}</b> { getBaseAsset(item) } to {checkMyAccount(item.counter_account) ? 'My Account' : ''} {shortAddress(item.counter_account)}</span>
                                }
                            </p>
                            <p><small className="text-secondary">Offer: <a href={`/offer/${item.offer_id}`}><b>{item.offer_id}</b></a> / Price: <b className={item.base_is_seller ? 'text-success' : 'text-danger'}>{numFormat((item.price.d / item.price.n), 7)}</b> / {formatDate(item.ledger_close_time)}</small></p>
                        </div>
                        : null
                }
            </div>
        )

        const isShowNotification = localStorage.getItem('is-show-notif');

        if (isShowNotification > 0) {
            notification.notice({
                content: <Content/>,
                duration: 30,
                closable: true,
                style: { top: 0, left: 'calc(100vw - 100%)' },
                className: 'right-up',
                // onClose: () => {
                //     this.setState()
                // }
            });
        }

        this.setNotification(item, type);
    };



    recivedMoney = (accountId) => {

        if (accountId) {

            // Server.payments()
            // .forAccount(accountId)
            // .limit(1)
            // .order('desc')
            // .call()
            // .then((payment) => {
            //   return payment.records.length ? streamMoney(accountId, payment.records[0].paging_token) : 0;
            // })
            // .catch((err) => {
            //   console.log('err: ', err)
            // });

        }
        else {

            let wallets = get_loc_wallets();
            if (wallets) {
                wallets.forEach((item, index) => {
                    var accountId = item.pk;

                    Server.payments()
                        .forAccount(accountId)
                        .limit(1)
                        .order('desc')
                        .call()
                        .then((payment) => {
                            return payment.records.length ? this.streamMoney(accountId, payment.records[0].paging_token) : 0;
                        })
                        .catch((error) => {
                            console.log('error: ', error.response)
                        });
                })
            }
        }
    }


    streamMoney = (accountId, token) => {

        Server.payments()
            .forAccount(accountId)
            .limit(1)
            .cursor(token)
            .stream({
                onmessage: (payment) => {

                    if (payment.to !== accountId) {
                        return;
                    }

                    if (payment.asset_type === 'native') {
                        payment.asset_code = 'XLM';
                        payment.asset_issuer = 'native'
                    }

                    this.showNotification(payment, 'payment')
                    // this.setNotification(payment, 'payment');
                },
                onerror: (error) => {
                    console.error('Error in payment stream');
                }
            });
    }


  setNavigator() {

    var device_platrorm = {
        options: [],
        header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
        dataos: [
            { name: 'Windows', value: 'Win', version: 'NT' },
            { name: 'Macintosh', value: 'Mac', version: 'OS X' },
            { name: 'Android', value: 'Android', version: 'Android' },
            { name: 'iPhone', value: 'iPhone', version: 'OS' },
            { name: 'iPad', value: 'iPad', version: 'OS' },
        ],
        databrowser: [
            { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
            { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
            { name: 'Safari', value: 'Safari', version: 'Version' },
            { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
            { name: 'Opera', value: 'Opera', version: 'Opera' },
            { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
            { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
        ],
        init: function () {
            var agent = this.header.join(' '),
                os = this.matchItem(agent, this.dataos),
                browser = this.matchItem(agent, this.databrowser);

            return { os: os, browser: browser };
        },
        matchItem: function (string, data) {
            var i = 0,
                j = 0,
                html = '',
                regex,
                regexv,
                match,
                matches,
                version;

            for (i = 0; i < data.length; i += 1) {
                regex = new RegExp(data[i].value, 'i');
                match = regex.test(string);
                if (match) {
                    regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                    matches = string.match(regexv);
                    version = '';
                    if (matches) { if (matches[1]) { matches = matches[1]; } }
                    if (matches) {
                        matches = matches.split(/[._]+/);
                        for (j = 0; j < matches.length; j += 1) {
                            if (j === 0) {
                                version += matches[j] + '.';
                            } else {
                                version += matches[j];
                            }
                        }
                    } else {
                        version = '0';
                    }
                    return {
                        name: data[i].name,
                        version: parseFloat(version)
                    };
                }
            }
            return { name: 'unknown', version: 0 };
        }
    };

    var device_platrorm = device_platrorm.init();
    var platform = device_platrorm.os.name;

    if (navigator.registerProtocolHandler) {
      navigator.registerProtocolHandler('web+stellar', window.location.origin + '/sep-0007/%s', 'Scopuly - Stellar Wallet & SDEX')
    }
  }


  eventStream() {

    Server.trades()
      // .forAccount('GBT2RH3RELBKMXVPEKTR7TMEV6YFSIEDOK63SH2XOHM74KFU3PZF2BMY')
      .cursor('now')
      .stream({
        onmessage: (message) => {

          let is_base_account, is_counter_account;

          if (this.state.wallets) {
            this.state.wallets.forEach((item, index) => {

              if (message.base_account === item.pk) {
                is_base_account = true;
              }
              else if (message.counter_account === item.pk) {
                is_counter_account = true;
              }
            })
          }

          if (is_base_account && is_counter_account) {
            this.showNotification(message, 'trade');
            //   this.setNotification(message, 'trade');
          }
          else if (is_base_account) {
            this.showNotification(message, 'trade');
            //   this.setNotification(message, 'trade');
          }
          else if (is_counter_account) {
            this.showNotification(message, 'trade');
            //   this.setNotification(message, 'trade');
          }
        }
    });
  }



  changeSidebarVisibility = () => {
    this.props.dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    this.props.dispatch(changeMobileSidebarVisibility());
  };

  changeToDark = () => {
    this.props.dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    this.props.dispatch(changeThemeToLight());
  };

  toggleTopNavigation = () => {
    this.props.dispatch(toggleTopNavigation());
  };

  changeBorderRadius = () => {
    this.props.dispatch(changeBorderRadius());
  };

  toggleBoxShadow = () => {
    this.props.dispatch(toggleBoxShadow());
  };

  toggleShowNotification = () => {
    if (this.props.customizer.showNotification) {
      localStorage.setItem('is-show-notif', 0)
    } else {
      localStorage.setItem('is-show-notif', 1)
    }
    this.props.dispatch(toggleShowNotification());
  }


    // renderNotifications = () => {
    //     this.state.notifications.map((item, index) => {
    //         console.log('renderNotifications item: ', item)
    //         this.showNotification(item)
    //     })
    // }

  render() {

    const { customizer, sidebar, theme } = this.props;

    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebar.collapse,
      'layout--top-navigation': customizer.topNavigation,
    });

    // this.renderNotifications()

    return (
      <div className={layoutClass}>

        {/*<Customizer*/}
        {/*  customizer={customizer}*/}
        {/*  sidebar={sidebar}*/}
        {/*  theme={theme}*/}
        {/*  changeSidebarVisibility={this.changeSidebarVisibility}*/}
        {/*  toggleTopNavigation={this.toggleTopNavigation}*/}
        {/*  changeToDark={this.changeToDark}*/}
        {/*  changeToLight={this.changeToLight}*/}
        {/*  changeBorderRadius={this.changeBorderRadius}*/}
        {/*  toggleBoxShadow={this.toggleBoxShadow}*/}
        {/*  toggleShowNotification={this.toggleShowNotification}*/}
        {/*/>*/}

        {this.props.customizer.topNavigation ?
          <TopbarWithNavigation
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          /> :
          <Topbar
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
          />
        }
        {this.props.customizer.topNavigation ?
          <SidebarMobile
            sidebar={sidebar}
            changeToDark={this.changeToDark}
            changeToLight={this.changeToLight}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          /> :
          <Sidebar
            sidebar={sidebar}
            changeToDark={this.changeToDark}
            changeToLight={this.changeToLight}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          />
        }
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  customizer: state.customizer,
  sidebar: state.sidebar,
  theme: state.theme,
}))(Layout));
