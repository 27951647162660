import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import {Button, ButtonGroup} from 'reactstrap';
import { translate } from 'react-i18next';
import StellarSdk from 'stellar-sdk';
import PropTypes from 'prop-types';
import { Server } from '../../../../modules/stellar/index';
import { numFormat, get_loc_wallets } from '../../../../modules/index';
import axios from 'axios';
import {getPaymentRequests} from "../../../../endpoints/API";
import getPrice from "../../../App/GetPrice";
import {GLOBE} from "../../../../modules/globeVars";
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/core";


const beatLoader = css`
  display: inline-block;
  margin: 0 5px;
`


// var wallets = get_my_wallets();
let total_amount_usd = 0;


class Balances extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      balances: this.props.account.balances.reverse(),
      balances_formated: false,
      potencial_amount_xlm: 0,
      potencial_amount_fiat: 0,
      refresh: false,
      offers: [],
      claimable_balances: [],
      load_offers: false,
      my_wallet: false,
      price: 0,
      trades_item: {},
      rades_item_load: true,
      total_amount_usd: 0,
      total_amount_usd_is: false,
      invoices: false,
      // wallets: get_loc_wallets(),
    };
  }


  countPools = 0


  componentDidMount() {
    this.formatBalances()
    this.getOffers(this.props.account.id);
    this.getClaimableBalances(this.props.account.id);
    this.getWallets();
    // this.getTrades();

    getPaymentRequests(this.props.account.id, 50)
      .then(result => {
        // console.log('result: ', result)

        if (result.data !== 'not_found') {
          this.setState({
            invoices: result.data,
          })

          this.props.allInvoices && this.props.allInvoices(result.data)
        }
      })
  }


  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.state.total_amount_usd_is !== nextState.total_amount_usd_is) {
  //     return true;
  //   }
  //   return false;
  // }



  formatBalances = () => {

    this.state.balances.map( async (item, index) => {

      if (item.asset_type === 'liquidity_pool_shares') {

        const formData = new FormData();
        formData.append('pool_id', item.liquidity_pool_id)

        const pool_db = await axios.post(`${GLOBE.API_URL}/get_liquidity_pool`, formData).catch(function (err) {
          console.error(`Failed to load db: ${err}`)
        })

        // console.log('pool_db: ', pool_db)

        if (!pool_db) { return }

        item.pool = pool_db.data

        this.countPools = (this.countPools + 1)
      }


      if (this.state.balances.length === index+1) {
        this.setState({
          balances: this.state.balances,
          balances_formated: true,
        })
      }
    })

  }


  getWallets() {
    const wallets = this.props.wallets;

    if (wallets) {
      wallets.forEach((wallet, index) => {
        if (wallet.pk === this.props.account.id) {
          wallet.id = index;

          this.setState({
            my_wallet: true,
          })
        }
      });
    }
  }


  change24(val) {

    var today = Number((val.records[0].close_r.d / val.records[0].close_r.n).toFixed(7));
    var yestoday = Number((val.records[1].close_r.d / val.records[1].close_r.n).toFixed(7));

     if (today > yestoday) {
       more();
     }
     else if (today === yestoday) {
       val.change24 = '0.00';
       val.change_24h_color = 'secondary';
     }
     else {
       less();
     }

     function more() {
       val.change24 = '+'+Number(today / yestoday * 100 - 100).toFixed(2);
       val.change_24h_color = 'text-success';
     }

     function less() {
       val.change24 = Number(100 - yestoday * 100 / today).toFixed(2);
       val.change_24h_color = 'text-danger';
     }

    return val;
  }


  // renderBalance(elem, index) {

  //   let url = `/asset/${elem.asset_code}-${elem.asset_issuer}`;
  //   if (elem.asset_type === 'native') {
  //     elem.asset_code = 'XLM';
  //     url = `/asset/native`;
  //   }

  //   return (
  //     <span key={index}>
  //       <font className="asset-amount">{elem.balance}</font>
  //       <Link className="asset-code text-warning" to={ url }>
  //         <b>{elem.asset_code}</b>
  //       </Link>
  //     </span>
  //   )
  // }


  getClaimableBalances = async (address) => {
    // console.log('address: ', address)

    const { records } = await Server
      .claimableBalances()
      .claimant(address)
      .limit(200)
      .call()

    this.setState({
      claimable_balances: records,
    })
  }


  getOffers(account_id) {

    Server.offers().forAccount(account_id)
      .order('desc')
      .call()
      .then((offerResult) => {

        this.setState({
          offers: offerResult.records,
          load_offers: true,
        })
      })
      .catch(function (err) {
        console.error(err);
      })
  }



  getTrades(item, index) {

    if (item.asset_type === 'liquidity_pool_shares') {
      // alert(1)
    }

    if (item.asset_type === 'native') {
      total_amount_usd = (item.balance * this.props.price)

      if (this.state.balances.length === 1) {

        this.setState({
          total_amount_usd_is: true,
          total_amount_usd,
        }, () => {
          total_amount_usd = 0
        })
      }
    }

    else if (item.asset_type !== 'liquidity_pool_shares') { // asset

      const base = new StellarSdk.Asset.native();
      const counter = new StellarSdk.Asset(item.asset_code, item.asset_issuer);
      const startTime = 0;
      const endTime = 0;
      const resolution = 86400000;
      const offset = 0;

      if (!this.state.total_amount_usd_is) {

        Server.tradeAggregation(base, counter, startTime, endTime, resolution, offset)
          .order('desc')
          .limit(1)
          .call()
          .then(tradesRes => {
            // console.log('tradesRes: ', tradesRes)

            let amount_usd = 0, price_xlm = 0, amount_xlm = 0, amount_xlm_usd = 0;

            price_xlm = (tradesRes.records[0].close_r.d / tradesRes.records[0].close_r.n);
            amount_xlm = (item.balance * price_xlm);
            amount_usd = (amount_xlm * this.props.price);

            total_amount_usd = (total_amount_usd + amount_usd);

            if ((this.state.balances.length) === (index + 1 + this.countPools)) {

              this.setState({
                total_amount_usd_is: true,
                total_amount_usd,
              }, () => {
                total_amount_usd = 0
              })
            }
          })
          .catch((err) => {
            console.log('err trades count', err);
          })
      }
    }
  }



  renderBalances = (limit) => {

    let balances = this.state.balances.map( (item, index) => {

      let url

      if (index < limit) {
        url = `/trade/${item.asset_code}-XLM/${item.asset_issuer}/native`;

        if (item.asset_type === 'native') {
          item.asset_code = 'XLM';
          url = `/native`;
        }
        // else {
        //   this.getTrades(item, index);
        // }

        if (window.location.pathname === '/account/'+this.props.account.id) {
          this.getTrades(item, index);
        }

        let asset_a = {
          image: '/img/digitalcoin.png'
        }
        let asset_b = {
          image: '/img/digitalcoin.png'
        }

        if (item.asset_type === 'liquidity_pool_shares' && item.pool) {

          url = `/pool/${item.liquidity_pool_id}`;

          console.log('item.pool: ', item.pool)

          if (item.pool && item.pool !== 'not_found') {
            asset_a = item.pool.assets.filter(asset => asset.asset_code === item.pool.pool[0].code_a && asset.asset_issuer === item.pool.pool[0].issuer_a)[0]
            asset_b = item.pool.assets.filter(asset => asset.asset_code === item.pool.pool[0].code_b && asset.asset_issuer === item.pool.pool[0].issuer_b)[0]
          }

          if (!asset_a) {
            asset_a = {
              asset_code: 'XLM',
              asset_issuer: 'native',
              name: 'Stellar',
              image: '/img/logo/stellar-logo.png',
              home_domain: 'stellar.org',
            }
          }
        }


        return (
          <span key={index} className="chips_item">
            <Link className="text-warning" to={ url }>
              {
                item.pool ?
                  <span className={'wr-balance-poll-item'}>
                    <span className={'balance-pool-item'}>
                      <span className={'pool-share'}><img src={asset_a.image} alt="" style={{width: asset_a.asset_issuer === 'native' ? 25 : 23}}/></span>
                      <span className={'pool-share'}><img src={asset_b.image} alt="" style={{width: 23}}/></span>
                    </span>
                  </span>
                  :
                  <b>{item.asset_code}</b>
              }
              <span className="asset-amount">{' '} <b className="text-info">{numFormat(item.balance, 7)}</b> {item.pool && ' pool shares'}</span>
            </Link> {' '}
            {/*<span className="asset-amount"><b className="text-info">{numFormat(item.balance, 7)}</b> {item.pool && ' pool shares'}</span>*/}
              {/*<small className={elem.change_24h_color}>{elem.change24}</small>*/}
          </span>
        )
      }
    })

    return balances;
  }



  render() {

    // console.log('B state: ', this.state)
    // console.log('props: ', this.props)

    const { total_amount_usd_is, balances_formated } = this.state

    let more_balances = false,
        balances = false,
        limit = this.props.limit;

    if (this.state.balances) {

      balances = this.state.balances;
    //   console.log('balances: ', balances)

      if (balances.length > this.props.limit) {
        more_balances = (balances.length - this.props.limit)
      } else {
        limit = balances.length;
      }
    }

    return (
      <div>

        {
          this.state.claimable_balances.length && window.location.pathname === '/wallet' ?
            <div>
              <a className={'pending-payment btn btn-secondary'} href={`/pending-payments/${this.props.account.id}`}>
                <i className={'fa fa-clock-o'}></i> Airdrop <b>{this.state.claimable_balances.length}{this.state.claimable_balances.length === 200 && '+'}</b>
              </a>
            </div>
          : null
        }

        <p style={{margin: '-11px 0 15px 0'}}>

          {
            balances_formated ?
              this.renderBalances(limit)
            : null
          }

          { more_balances ? <Link to={ `/account/${this.props.account.id}` }>... +{more_balances} balances</Link> : '' }
        </p>

        <p>
          <ButtonGroup className="balancess-data">
            {
              this.state.my_wallet ?
                <Link to={`/balances/${this.props.account.id}`}
                      className="btn btn-outline-secondary btn-sm"
                      style={{marginBottom: '0'}}>Trustlines: <b>{balances.length-1}</b></Link>
                : null
            }
            {
              this.state.invoices ?
                <Link to={`/invoices/${this.props.account.id}`}
                      className="btn btn-outline-secondary btn-sm"
                      style={{marginBottom: '0'}}>Invoices: <b>{this.state.invoices.length}</b></Link>
                : null
            }
            {
              this.state.load_offers && this.state.offers.length > 0 ?
                <Link to={'/offers/'+this.props.account.id}
                      className="btn btn-outline-secondary btn-sm"
                      style={{marginBottom: '0'}}>Offers: <b>{this.state.offers.length}</b></Link>
                : null
            }
          </ButtonGroup>
          <br/>

          {/*&nbsp;&nbsp;&nbsp;*/}
          {
            window.location.pathname === '/account/'+this.props.account.id ?
              <div className={'total-usd'}>Total <b className="text-secondary">USD</b> {' '}
                {
                  !this.state.total_amount_usd_is ?
                    <BeatLoader
                      css={beatLoader}
                      color={"#70bbfd"}
                      loading={true}
                      size={7}
                    />
                  :
                  <span>&#8776; <b className="text-secondary">{numFormat(this.state.total_amount_usd, 2)}</b></span>
                }
              </div>
            : null
          }
        </p>
      </div>
    );
  }
}


export default translate('common')(Balances);
