import React, { PureComponent } from 'react';
import {Field, reduxForm} from 'redux-form';
import { translate } from 'react-i18next';
import { ToastContainer, ToastStore } from 'react-toasts';
import {
  get_loc_wallets,
  getAsset,
  itemType,
  numFormat,
  shortAddress,
  shortAddr,
  getBasePrice, formatCount, formatDate, getIconConnectProvider, getTitle
} from '../../../modules/index';
import LoadingIcon from "mdi-react/LoadingIcon";
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Container,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane, ButtonToolbar, ButtonGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal
} from 'reactstrap';
import {Link, Redirect} from 'react-router-dom';
import {CopyToClipboard} from "react-copy-to-clipboard";
// import {Redirect} from "react-router-dom";
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import { liquidityPools } from '../../../endpoints/StellarAPI'
import axios from "axios";
import {GLOBE} from "../../../modules/globeVars";
import Footer from "../../Layout/footer/Footer";
import ReactApexChart from 'react-apexcharts'
import RingLoader from "react-spinners/RingLoader";
import swal from "sweetalert";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import iconIdent from "../Account/components/iconIdent";
import SpinnerLoader from "../../../modules/SpinnerLoader";
import DepositWithdraw from "./components/DepositWithdraw";


const renderTextField = ({
                           input, label, meta: { touched, error }, children, select, type, value,
                         }) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);



class LiquidityPools extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      wallets: [],
      base_price: false,
      select_account: false,
      pools: [],
      assets: [],
      loading: false,
      cursor: '',
      pools_db: [],
      limit: 200,
      asset_code: '',
      options: {},
      series: [],
      activeTab: '1',
      volume30d: [],
      all_liquidity: 0,
      all_volume: 0,
      all_apr: 0,
      all_o_n: 0,
      series_liquidity: [],
      series_volume: [],
      series_fee: [],
      series_apr: [],
      series_o_n: [],
      options_liquidity: {},
      options_volume: {},
      options_fee: {},
      options_apr: {},
      options_o_n: {},
      scan_cnt: 0,
      save_cnt: 0,
      // dropdownOpen: false,
      filter: 'liquidity',
      filter_pool_type: 'all',
      all_pools: [],
      more_btn: false,
      load_more: true,
      modal: false,
      asset_a: {
        image: '/img/digitalcoin.png',
        name: 'Asset Name',
        home_domain: 'asset.domain'
      },
      asset_b: {
        image: '/img/digitalcoin.png',
        name: 'Asset Name',
        home_domain: 'asset.domain'
      },
      rewards_aqua: false,
    };
  }

  // cursor = ''
  page = 0
  limit = 30
  get_pool_limit = 6
  doneInterval = 1000;
  typingTimer = 0


  componentDidMount = () => {

    getTitle(`Liquidity Pools on Stellar`);

    // this.scanLiquidityPools()

    this.getXLMPrice()
    this.getAllLiquidityValue()

    this.getAquaRewards()

    const asset_code = localStorage.getItem('lq_code')
    // console.log('asset_code: ', asset_code)

    if (asset_code) {
      this.props.change('asset_code', asset_code)

      this.setState({
        asset_code
      }, () => this.getPoolsDB())
      return
    }
    this.getPoolsDB()
  }


  getAquaRewards = async () => {

    let response = await fetch('https://voting-tracker.aqua.network/api/voting-rewards/');
    if (response.ok) {
      let json = await response.json();
      console.log('getAquaRewards json: ', json)
      this.setState({ rewards_aqua: json })
    } else {
      console.log("Error Rewards: " + response.status);
    }
  }


  testHolders = () => {

  }


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  // toggleDropdown = () => {
  //   this.setState(prevState => ({
  //     dropdownOpen: !prevState.dropdownOpen
  //   }));
  // }


  getAllLiquidityValue = () => {

    axios.post(`${GLOBE.API_URL}/get_pools_stats`).then(result => {
      // console.log('get_pools_stats: ', result)

      let all_liquidity = 0, all_volume = 0, all_fee = 0

      const all_volume_30d = Array.from({length: 30}, (item, index) => ({ liquidity: 0, volume: 0, fee: 0}))

      if (result.data.pools.length) {

        const formatFees = (day) => {
          const fee = (day.volume * 0.3 / day.liquidity * 356 / (365/24)).toFixed(2)
          return Number(isNaN(fee) ? 0 : fee)
        }

        const average = (nums) => {
          return nums.reduce((a, b) => (a + b)) / nums.length;
        }

        result.data.pools.reverse().map((item, index) => {
          // console.log('pool item: ', item)

          if (item.volume30d && item.volume30d !== '0') {

            const volume30d = JSON.parse(item.volume30d)
            // console.log('volume30d: ', volume30d)

            if (volume30d.length === 30) {

              volume30d.map((day, index) => {

                const liquidity = Number(day.liquidity)
                const volume = Number(day.volume)
                const fee = formatFees(day)

                all_volume_30d[index].liquidity = (all_volume_30d[index].liquidity + liquidity)
                all_volume_30d[index].volume = (all_volume_30d[index].volume + volume)
                all_volume_30d[index].fee = fee
                all_volume_30d[index].time = day.time
              })
            }
          }
        })


        all_volume_30d.map(item => all_liquidity = (all_liquidity + item.liquidity))
        all_volume_30d.map(item => all_volume = (all_volume + item.volume))
        // all_volume_30d.forEach(item => all_fee = (all_fee + formatFees(item)))
        all_volume_30d.map(item => all_fee = (all_fee + (item.volume / (0.3 * 1000))))

        const getTime = (item) => {
          let time = new Date(item.time * 1000)
          time.setDate(time.getDate() + 1)
          time = new Date(time.getTime()).toDateString()
          return time
        }

        const liquidity = all_volume_30d.map(item => item.liquidity.toFixed(0)) //
        const volume = all_volume_30d.map(item => item.volume.toFixed(0))
        const labels = all_volume_30d.map(item => getTime(item))// new Date(item.time * 1000).toDateString())
        // const fees = all_volume_30d.map(item => formatFees(item))
        const fees = all_volume_30d.map(item => (item.volume / (0.3 * 1000)).toFixed(2))
        const aprs = all_volume_30d.map(item => Number((item.volume * 0.3 / item.liquidity * 365).toFixed(2)))
        const o_n = all_volume_30d.map(item => Number(((item.volume * 0.3 / item.liquidity * 365) / 365).toFixed(2)))
        const all_apr = average(aprs)
        const all_o_n = average(o_n)

        const series_liquidity = [

          {
            name: 'Volume',
            type: 'column',
            data: volume
          },
          {
            name: 'Liquidity',
            type: 'area', // column
            data: liquidity
          },
        ]

        const series_volume = [
          {
            name: 'Volume',
            type: 'column', // column
            data: volume
          },
        ]

        const series_fee = [
          {
            name: 'Fee',
            type: 'column', // column
            data: fees
          },
        ]

        const series_apr = [
          {
            name: 'APR',
            type: 'area', // column
            data: aprs
          },
          {
            name: 'O/N',
            type: 'column', // column
            data: o_n
          },
        ]

        // const series_o_n = [
        //   {
        //     name: 'O/N',
        //     type: 'area', // column
        //     data: o_n
        //   },
        // ]


        this.setState({
          series_liquidity,
          options_liquidity: this.setOptions(series_liquidity, labels),

          series_volume,
          options_volume: this.setOptions(series_volume, labels),

          series_fee,
          options_fee: this.setOptions(series_fee, labels, 2),

          series_apr,
          options_apr: this.setOptions(series_apr, labels, 2),

          // series_o_n,
          // options_o_n: this.setOptions(series_o_n, labels, 2),

          all_liquidity, all_volume, all_fee, labels, all_apr, all_o_n,
        })
      }
    });
  }


  setOptions = (series, labels, decimels = 0) => {

    const theme_loc = localStorage.getItem('theme')
    const theme = theme_loc === 'theme-dark' ? 'dark' : 'light'

    const options = {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
        toolbar: {
          show: false,
        }
      },
      colors:['#03A9F4', '#13D8AA'],
      stroke: {
        width: [0, 2, 5],
        curve: 'smooth'
      },
      plotOptions: {
        bar: {
          columnWidth: '50%'
        }
      },

      grid: {
        show: true,
        borderColor: '#64677726', //#2a2a30
        row: {
          colors: undefined,
          opacity: 0.5
        },
        column: {
          colors: undefined,
          opacity: 0.5
        },
      },

      legend: { show: false },

      theme: {
        mode: theme,
        palette: 'palette1',
        monochrome: {
          enabled: false,
          color: '#255aee',
          // shadeTo: 'light',
          shadeIntensity: 0.65
        },
      },

      fill: {
        opacity: [0.85, 0.25, 1],
        // type: "gradient",
        gradient: {
          inverseColors: false,
          // shade: 'light',
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }

      },
      labels: labels // ['01/01/2003', '02/01/2003', ...]
      ,
      markers: {
        size: 0
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        title: {
          text: '',
          style: {
            color: '#a5a5a5',
          },
        },
        min: 0,
      },
      tooltip: {
        shared: true,
        intersect: false,
        fillSeriesColor: false,
        theme: true,
        y: {
          formatter: function (y, x) {

            if (typeof y !== "undefined") {

              if (series[0].name === 'APR') {
                return `${numFormat(y, decimels)}%`
              }
              else {
                return `$${numFormat(y, decimels)}`
              }
            }
            return y;

          }
        }
      },

    }

    return options
  }



  scanLiquidityPools = () => {

    const local_cursor = localStorage.getItem('cursor')
    const cursor = local_cursor ? local_cursor : ''
    this.setState({ cursor },
      () => this.getLiquidityPools())
  }


  getPoolsDB = () => {

    const { asset_code } = this.state

    const formData = new FormData();
    formData.append('limit', this.limit)
    formData.append('page', this.page)
    formData.append('filter', this.state.filter)
    formData.append('code', this.state.asset_code)

    axios.post(`${GLOBE.API_URL}/get_liquidity_pools`, formData).then(result => {
      // console.log('getPoolsDB result: ', result)

      if (result.data !== 'not_found') {
        // this.getAssetsDB(result.data)

        let pools = result.data.pools
        const assets = result.data.assets

        if (pools.length && assets.length) {

          pools = pools.map(item => {

            let asset_a = assets.filter(asset => asset.asset_code === item.code_a && asset.asset_issuer === item.issuer_a)[0]
            let asset_b = assets.filter(asset => asset.asset_code === item.code_b && asset.asset_issuer === item.issuer_b)[0]

            if (!asset_a) {
              asset_a = {
                asset_code: 'XLM',
                asset_issuer: 'native',
                name: 'Stellar',
                image: '/img/logo/stellar-logo.png',
                home_domain: 'stellar.org',
              }
            }

            item.asset_a = asset_a
            item.asset_b = asset_b
            item.volume30d = item.volume30d && item.volume30d !== 0 && item.volume30d !== 'undefined' ? JSON.parse(item.volume30d) : []

            return item
          })

          this.setState({
            assets: asset_code ? assets : this.state.assets.concat(assets),
            pools: asset_code ? pools : this.state.pools.concat(pools),
            more_btn: pools.length >= this.limit ? true : false,
            load_more: true,
          })
        }
      }
    });
  }


  // getAssetsDB = (pools_db) => {
  //
  //   const asset_codes = []
  //   const asset_issuers = []
  //
  //   pools_db.pools.map(item => {
  //
  //     if (item.code_a !== 'XLM' && item.issuer_a !== 'native') {
  //       if (!asset_codes.some(code => code === item.code_a)) {
  //         asset_codes.push(item.code_a)
  //       }
  //       if (!asset_codes.some(code => code === item.code_b)) {
  //         asset_codes.push(item.code_b)
  //       }
  //     }
  //   })
  //
  //   pools_db.pools.map(item => {
  //
  //     if (item.code_a !== 'XLM' && item.issuer_a !== 'native') {
  //       if (!asset_issuers.some(issuer => issuer === item.issuer_a)) {
  //         asset_issuers.push(item.issuer_a)
  //       }
  //       if (!asset_issuers.some(issuer => issuer === item.issuer_b)) {
  //         asset_issuers.push(item.issuer_b)
  //       }
  //     }
  //   })
  //
  //   const formData = new FormData();
  //
  //   formData.append('asset_codes', asset_codes)
  //   formData.append('asset_issuers', asset_issuers)
  //
  //   axios.post(`${GLOBE.API_URL}/get_pool_assets`, formData).then(result => {
  //     console.log('get_pool_assets result: ', result)
  //
  //     if (result.data.assets.length) {
  //       const assets = result.data.assets
  //
  //       const pools = pools_db.pools.map(item => {
  //         let asset_a = assets.filter(asset => asset.asset_code === item.code_a && asset.asset_issuer === item.issuer_a)[0]
  //         let asset_b = assets.filter(asset => asset.asset_code === item.code_b && asset.asset_issuer === item.issuer_b)[0]
  //
  //         if (!asset_a) {
  //           asset_a = {
  //             asset_code: 'XLM',
  //             asset_issuer: 'native',
  //             name: 'Stellar',
  //             image: '/img/logo/stellar-logo.png',
  //             home_domain: 'stellar.org',
  //           }
  //         }
  //
  //         item.asset_a = asset_a
  //         item.asset_b = asset_b
  //         item.volume30d = item.volume30d && item.volume30d !== 0 ? JSON.parse(item.volume30d) : []
  //
  //         return item
  //       })
  //
  //       console.log('pools: ', pools)
  //
  //       this.setState({ assets, pools })
  //     }
  //   });
  //
  // }


  getXLMPrice = async () => {

    const stellar = await getBasePrice()

    this.setState({
      stellar,
      base_price: stellar.tickers[0].last
    })
  }


  getPriceAsset = (asset) => {
    // console.log('asset: ', asset)

    return Server
      .trades()
      .order('desc')
      .limit(1)
      .forAssetPair(
        new StellarSdk.Asset.native(),
        new StellarSdk.Asset(asset[0], asset[1]),
      )
      .call()
  }

  pools_length = 0


  getVolume = (item, index) => {

    let assetA = item.reserves[0].asset
    let assetB = item.reserves[1].asset
    assetA = (assetA !== 'native' ? assetA.split(':') : 'native')
    assetB = (assetB !== 'native' ? assetB.split(':') : 'native')

    let issuer_a = assetA !== 'native' ? assetA[1] : 'native'
    let issuer_b = assetB !== 'native' ? assetB[1] : 'native'

    let vol_xlm_a, vol_xlm_b, price_a, price_b, amount_a, amount_b

    // amount_a = item.reserves[0].amount
    // amount_b = item.reserves[1].amount

    axios.get(`https://amm-api.stellarx.com/api/pools/30d-statistic/?pool_string=${item.reserves[0].asset}/${item.reserves[1].asset}`)
      .then(result => {

        this.setState({
          scan_cnt: this.state.scan_cnt + 1,
        })

        if (result.data && Object.keys(result.data).length > 0) {

          const pool_stats = result.data.reverse()
          // console.log('amm pool_stats: ', pool_stats, 'save data...')


          let vol_24h = (pool_stats[0].volume).toFixed(7)

          let vol_7d = 0;
          pool_stats.map((item, index) => index <= 6 ? vol_7d += item.volume : 0 )

          const formatFees = () => {
            const fee = (pool_stats[0].volume * 0.3 / pool_stats[0].liquidity * 356 / (365/24)).toFixed(2)
            return Number(isNaN(fee) ? 0 : fee)
          }
          const fee_24h = formatFees() // .toFixed(0)
          const apr = (vol_24h * 0.3 / pool_stats[0].liquidity * 356).toFixed(2)

          const save = {
            amount_a: item.reserves[0].amount,
            code_a: assetA === 'native' ? 'XLM' : assetA[0],
            issuer_a: issuer_a,
            amount_b: item.reserves[1].amount,
            code_b: assetB === 'native' ? 'XLM' : assetB[0],
            issuer_b: issuer_b,
            shares: item.total_shares,
            trustlines: item.total_trustlines,
            total_vol_usd: (pool_stats[0].liquidity).toFixed(7),
            vol_24h,
            vol_7d,
            fee_24h,
            apr,
          }

          // return

          const formData = new FormData();
          formData.append('pool_id', item.id)
          formData.append('volume30d', JSON.stringify(pool_stats))
          formData.append('amount_a', save.amount_a)
          formData.append('code_a', save.code_a)
          formData.append('issuer_a', save.issuer_a)
          formData.append('amount_b', save.amount_b)
          formData.append('code_b', save.code_b)
          formData.append('issuer_b', save.issuer_b)
          formData.append('shares', save.shares)
          formData.append('trustlines', save.trustlines)
          formData.append('total_vol_usd', save.total_vol_usd)
          formData.append('vol_24h', save.vol_24h)
          formData.append('vol_7d', save.vol_7d)
          formData.append('fee_24h', save.fee_24h)
          formData.append('apr', save.apr)

          axios.post(`${GLOBE.API_URL}/update_pool`, formData).then(result => {
            // console.log('update_pool result: ', result)
            // console.log('pool_id: ', item.id)

            this.setState({
              save_cnt: this.state.save_cnt + 1,
            })

            // this.getNextPool(index)
          });
        }
        else {
          console.log('Not found...')
          // this.getNextPool(index)

          const formData = new FormData();
          formData.append('pool_id', item.id)
          formData.append('amount_a', item.reserves[0].amount)
          formData.append('code_a', 'native' ? 'XLM' : assetA[0])
          formData.append('issuer_a', issuer_a)
          formData.append('amount_b', item.reserves[1].amount)
          formData.append('code_b', assetB === 'native' ? 'XLM' : assetB[0])
          formData.append('issuer_b', issuer_b)
          formData.append('shares', item.total_shares)
          formData.append('trustlines', item.total_trustlines)

          axios.post(`${GLOBE.API_URL}/update_pool`, formData).then(result => {
            // console.log('pool_id zero result: ', result)
            // console.log('pool_id result: ', item.id)
          });
        }
      });
  }


  getNextPool = (index) => {
    // if (index+1 === this.get_pool_limit-1) {
      this.getLiquidityPools()
    // }
  }



  getLiquidityPools = () => {

    Server
      .liquidityPools()
      // .limit(this.state.limit)
      .limit(this.get_pool_limit)
      .order('desc')
      .cursor(this.state.cursor)
      .call()
      .then( (result) => {
        // console.log('liquidityPools result: ', result);

        this.pools_length = result.records.length
        // console.log('length: ', this.pools_length);

        if (result && result.records.length) {

          const cursor = result.records[this.pools_length-1].paging_token

          this.setState({
            // pools: [...this.state.pools, ...result.records],
            cursor,
          })

          localStorage.setItem('cursor', cursor)

          this.getLiquidityPools()

          result.records.map((item, index) => {
            setTimeout(() => this.getVolume(item, index), 3000)
          })

          return

          result.records.map(async (item, index) => {

            let assetA = item.reserves[0].asset
            let assetB = item.reserves[1].asset
            assetA = (assetA !== 'native' ? assetA.split(':') : 'native')
            assetB = (assetB !== 'native' ? assetB.split(':') : 'native')

            let issuer_a = assetA !== 'native' ? assetA[1] : 'native'
            let issuer_b = assetB !== 'native' ? assetB[1] : 'native'

            let vol_xlm_a, vol_xlm_b, price_a, price_b, amount_a, amount_b

            amount_a = item.reserves[0].amount
            amount_b = item.reserves[1].amount

            const checkFillPair = () => {
              if (vol_xlm_a && vol_xlm_b) {
                setObj()
              }
            }

            const setObj = () => {

              const total_vol_xlm = (Number(vol_xlm_a) + Number(vol_xlm_b))
              const total_vol_usd = (total_vol_xlm * this.state.base_price)

              const pool = {
                pool_id: item.id,
                amount_a: item.reserves[0].amount,
                code_a: assetA === 'native' ? 'XLM' : assetA[0],
                issuer_a: issuer_a,
                amount_b: item.reserves[0].amount,
                code_b: assetB === 'native' ? 'XLM' : assetB[0],
                issuer_b: issuer_b,
                shares: item.total_shares,
                trustlines: item.total_trustlines,
                fee_bp: item.fee_bp,
                vol_xlm_a,
                vol_xlm_b,
                total_vol_xlm,
                total_vol_usd,
              }

              console.log('pool: ', pool)

              this.saveData(pool, index)
            }

            // return


            if (assetA !== 'native') {
              const price = await this.getPriceAsset(assetA).then(result => result.records[0])

              if (price) {
                price_a = (price.price.d / price.price.n).toFixed(7)
                vol_xlm_a = (amount_a * price_a).toFixed(7)

                checkFillPair()
              }
            }
            else {
              vol_xlm_a = amount_a
              checkFillPair()
            }


            if (assetB !== 'native') {
              const price = await this.getPriceAsset(assetB).then(result => result.records[0])

              if (price) {
                price_b = (price.price.d / price.price.n).toFixed(7)
                vol_xlm_b = (amount_b * price_b).toFixed(7)

                checkFillPair()
              }
            }
            else {
              vol_xlm_b = amount_b
              checkFillPair()
            }

          })
        }
      })
      .catch( (err) => {
        console.error(err);
      });
  }


  saveData = (item, index) => {

    console.log('saveData item: ', item)
    return

    const formData = new FormData();

    formData.append('pool_id', item.pool_id)
    formData.append('amount_a', item.amount_a)
    formData.append('code_a', item.code_a)
    formData.append('issuer_a', item.issuer_a)
    formData.append('amount_b', item.amount_b)
    formData.append('code_b', item.code_b)
    formData.append('issuer_b', item.issuer_b)
    formData.append('shares', item.shares)
    formData.append('trustlines', item.trustlines)
    formData.append('fee_bp', item.fee_bp)
    formData.append('vol_xlm_a', item.vol_xlm_a)
    formData.append('vol_xlm_b', item.vol_xlm_b)
    formData.append('total_vol_xlm', item.total_vol_xlm)
    formData.append('total_vol_usd', item.total_vol_usd)

    axios.post(`${GLOBE.API_URL}/set_pool`, formData).then(result => {

      if (result.data && index+1 === this.state.limit) {
        console.log('NEXT LOAD POOLS...')

        this.setState({ pools: [] },
          () => this.getLiquidityPools()
        )
      }
    });
  }


  searchPool = (values) => {
    // console.log('values: ', values)

    const asset_code = values.asset_code
    localStorage.setItem('lq_code', asset_code)

    this.setState({
      asset_code,
    },
      () => this.getPoolsDB()
    )
  }


  clearCode = (e) => {
    e.preventDefault()
    localStorage.removeItem('lq_code')
    this.props.change('asset_code', '')

    this.setState({
      asset_code: '',
      pools: [],
    },
      () => this.getPoolsDB()
    )
  }


  filterPools = (value) => {
    // console.log('value: ', value)

    let filter = '';
    let count = 0
    for (const key of Object.keys(value)) {
      filter = (filter + (Object.keys(value).length-1 > count ? value[key] : ''))
      count++
    }

    this.setState({
        filter,
        pools: [],
      },
      () => this.getPoolsDB()
    )
  }


  changeSearchCode = (value) => {

    let asset_code = '', count = 0;
    for (const key of Object.keys(value)) {
      asset_code = (asset_code + (Object.keys(value).length-1 > count ? value[key] : ''))
      count++
    }

    if (!asset_code) {
      this.setState({
          asset_code: '',
          pools: [],
        },
        () => this.getPoolsDB()
      )
    }
    else {

      this.setState({ asset_code, pools: [] }, () => {
        if (asset_code.length > 1) {
          setTimeout(() => {
            this.getPoolsDB()
            localStorage.setItem('lq_code', asset_code)
          }, 1000)
        }
      })
    }
  }


  checkWatchlist = (pool_id) => {

    let local_favorites = localStorage.getItem('favorites');

    if (local_favorites) {
      local_favorites = JSON.parse(local_favorites);

      return local_favorites.pools && local_favorites.pools.some((item) => item === pool_id)
    }

    return false
  }


  toggleWatchlist = (e, item) => {
    e.preventDefault()

    const pool = item.pool_id

    let local_favorites = localStorage.getItem('favorites');
    // console.log('local_favorites: ', local_favorites)

    if (!local_favorites) {

      let favorites = {
        addresses: [],
        assets: [],
        offers: [],
        pools: [],
      }

      favorites.pools.unshift(pool);
      favorites = JSON.stringify(favorites);
      localStorage.setItem('favorites', favorites);

      ToastStore.success('Pool added to Watchlist');
      // this.setWachAssetDB('set', pool);
    }
    else { // isset local

      local_favorites = JSON.parse(local_favorites);

      let isset_pool = false;
      if (Array.isArray(local_favorites.pools)) {
        isset_pool = local_favorites.pools.some((item) => item === pool);
      }
      else {
        local_favorites.pools = [];
      }

      if (!isset_pool) {
        local_favorites.pools.unshift(pool);
        local_favorites = JSON.stringify(local_favorites);
        localStorage.setItem('favorites', local_favorites);

        ToastStore.success('Pool added to Watchlist!');
        // this.setWachAssetDB('set', pool);
      }
      else {

        local_favorites.pools.forEach((item, index) => {

          if (item === pool) {
            local_favorites.pools.splice(index, 1);
            local_favorites = JSON.stringify(local_favorites);
            localStorage.setItem('favorites', local_favorites);

            ToastStore.info('Pool removed from Watchlist');
            // this.setWachAssetDB('remove', asset);
          }
        });
      }
    }
  }


  filterWatchlist = (type) => {

    const { all_pools, pools } = this.state

    if (type === 'all') {
      this.setState({
        pools: all_pools.length ? all_pools : pools,
        filter_pool_type: type,
      })
    }
    if (type === 'watch') {

      let local_favorites = localStorage.getItem('favorites');

      if (local_favorites) {
        local_favorites = JSON.parse(local_favorites);

        const pools = this.state.pools.filter((item) => {
          return local_favorites.pools.some(pool => pool === item.pool_id)
        })

        this.setState({
          all_pools: this.state.pools,
          pools,
          filter_pool_type: type,
        })
      }
    }
  }


  loadMorePools = () => {
    this.page = this.page+1
    this.setState({ load_more: false, })
    this.getPoolsDB()
  }


  selectAccount = (select_account) => {
    this.setState({ select_account })
  }


  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  }

  setWallets = (wallets) => {
    this.setState({ wallets })
  }


  // renderPool = (item, index) => {
  //   // console.log('item: ', item)
  //
  //   const stellar_logo = '/img/logo/stellar-logo.png'
  //   const default_logo = '/img/digitalcoin.png'
  //   const asset_a = item.issuer_a !== 'native' ? `${item.code_a}:${item.issuer_a}` : 'native'
  //   const asset_b = item.issuer_b !== 'native' ? `${item.code_b}:${item.issuer_b}` : 'native'
  //   const image_a = item.issuer_a !== 'native' ? !item.asset_a ? default_logo : item.asset_a.image : stellar_logo
  //   const image_b = item.issuer_b !== 'native' ? !item.asset_b ? default_logo : item.asset_b.image : stellar_logo
  //
  //   return (
  //     <tr key={index}>
  //       <td>{index+1}</td>
  //       <td>{getAsset(asset_a, image_a)} <b className="text-info">{numFormat(item.amount_a, 7)}</b> / <img src={image_b} className={'asset-icon-sm'} alt="" /> {getAsset(asset_b)} <b className="text-info">{numFormat(item.amount_b, 7)}</b></td>
  //       <td><b className="text-info">${numFormat(item.total_vol_usd, 2)}</b></td>
  //       <td><b className="text-info">${numFormat(item.vol_24h)}</b></td>
  //       <td><b className="text-info">${numFormat(item.fee_24h, 2)}</b></td>
  //       <td><b className="text-success">{item.apr}%</b></td>
  //       <td><b className="text-info">{numFormat(item.shares)}</b></td>
  //       <td><b className="text-info">{item.trustlines}</b></td>
  //       {/*<td>{itemType(item.type)}</td>*/}
  //       <td><Link to={`/pool/${item.pool_id}`}><b>{shortAddr(item.pool_id, 4)}</b></Link></td>
  //     </tr>
  //   )
  // }


  renderPoolItem = (item, index) => {

    console.log('renderPoolItem item: ', item)

    const stellar_logo = '/img/logo/stellar-logo.png'
    const default_logo = '/img/digitalcoin.png'
    const asset_a = item.issuer_a !== 'native' ? `${item.code_a}:${item.issuer_a}` : 'native'
    const asset_b = item.issuer_b !== 'native' ? `${item.code_b}:${item.issuer_b}` : 'native'
    const image_a = item.issuer_a !== 'native' ? !item.asset_a ? default_logo : item.asset_a.image : stellar_logo
    const image_b = item.issuer_b !== 'native' ? !item.asset_b ? default_logo : item.asset_b.image : stellar_logo
    const domain_b = !item.asset_b || !item.asset_b.home_domain ? 'domain not found' : item.asset_b.home_domain

    const is_watchlist = this.checkWatchlist(item.pool_id)
    let rewards
    rewards = this.state.rewards_aqua.filter(pair =>  pair.asset1 === (pair.asset1 === 'native' ? item.issuer_a : `${item.asset_a.asset_code}:${item.asset_a.asset_issuer}`) && pair.asset2 === `${item.asset_b.asset_code}:${item.asset_b.asset_issuer}`)
    const percent_amm = rewards.length ? (rewards[0].sdex_reward_value / item.total_vol_usd * 365 ).toFixed(2) : 0

    console.log('rewards: ', rewards)
    console.log('percent_amm: ', percent_amm)

    return (
      <Card className={'liquidity-item'} key={index}>
        <CardBody style={{padding: '20px 25px 17px'}}>

          <small className={'pool-counter'}>{index+1}</small>

          <div className={'star-icon'}>
            <a href="#" onClick={(e) => this.toggleWatchlist(e, item)}><i className={`fa fa-star-o text-${is_watchlist ? 'warning' : 'secondary' }`}></i></a>
          </div>

          <Row>
            <Col xs={12} md={3} className={'item'}>

              <div>

                <Link to={`/pool/${item.pool_id}`}>
                  <div className={`icon-asset-pair ${asset_a === 'native' && 'native-item'}`}>
                    <img src={image_a} alt=""/>
                  </div>
                  <div className={'icon-asset-pair ml-47'}>
                    <img src={image_b} alt=""/>
                  </div>

                  <div className={'ml-100'}>
                  <span>
                    <span className={'fs-16 text-primary'}><b>{item.code_a}</b> / <b>{item.code_b}</b></span>
                    <div className={'text-secondary'} style={{marginTop: -4}}>
                      <small className={'overflow'}>
                        <span>{item.asset_a.home_domain}</span> / {' '}
                        <span>{domain_b}</span>
                      </small>
                    </div>
                  </span>
                  </div>
                </Link>
              </div>
            </Col>
            <Col xs={4} md={1} className={'item text-center hide-xs'}>
              <p className={'text-secondary'}>
                {/*<small className={'overflow'}>{item.asset_a.home_domain}</small>*/}
                {/*<small className={'overflow'}>{domain_b}</small>*/}

                <small className={'overflow'}>{item.asset_a && item.asset_a.name !== '' && item.asset_a.name !== 'undefined' && item.asset_a.name !== '...' ? item.asset_a.name : item.code_a}</small>
                <small className={'overflow'}>{item.asset_b && item.asset_b.name !== '' && item.asset_b.name !== 'undefined' && item.asset_b.name !== '...' ? item.asset_b.name : item.code_b}</small>
              </p>
            </Col>
            <Col xs={4} md={1} className={'item text-center'}><small className={'text-secondary'}>Liquidity</small><h4><b className={'text-info fs-16'}>${item.total_vol_usd > 1 ?formatCount(item.total_vol_usd, true, 2) : '0.00'}</b></h4></Col>
            <Col xs={4} md={1} className={'item text-center'}><small className={'text-secondary'}>Vol 24H</small><h4><b className={'text-info fs-16'}>${item.vol_24h > 1 ? formatCount(item.vol_24h, true, 2) : '0.00'}</b></h4></Col>
            <Col xs={4} md={1} className={'item text-center'}><small className={'text-secondary'}>Fee 24H</small><h4><b className={'text-info fs-16'}>${numFormat(item.fee_24h, 2)}</b></h4></Col>
            <Col xs={4} md={1} className={'item text-center'}>
              <small className={'text-secondary'}>APR</small>
              <h4><b className={'text-success fs-16'}>{item.apr}%</b></h4>
              {
                rewards.length ?
                  <div className={'text-secondary rewards text-left'}><small className={'label-rewards'}><b>+{numFormat(percent_amm)}% AQUA</b></small></div>
                : null
              }
            </Col>
            <Col xs={4} md={1} className={'item text-center'}><small className={'text-secondary'}>Shares</small><h4><b className={'text-info fs-16'}>{item.shares > 1 ? formatCount(item.shares, true, 2) : numFormat(item.shares, 2)}</b></h4></Col>
            <Col xs={4} md={1} className={'item text-center'}><small className={'text-secondary'}>Trustlines</small><h4><b className={'text-info fs-16'}>{formatCount(item.trustlines, true, 2)}</b></h4></Col>
            {/*<Col xs={4} md={1} className={'item'}><p className={'text-secondary'}>Pool</p><span><b>xxxx...xxxx</b></span></Col>*/}
            <Col xs={9} md={2} className={'item'}>
              <div className={'text-right liquid-btn'}><Link to={`/pool/${item.pool_id}`} className={'btn btn-outline-primary m-0'}>Add Liquidity</Link></div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }


  renderModal = () => {

    const { modal, manage_pool_type, asset_a, asset_b, pool_db, pool } = this.state;

    let classElem = '', theme = 'theme-light';
    if (localStorage.getItem('theme') === 'theme-dark') {
      classElem = ''
      theme = 'theme-dark'
    }

    return (
      <Modal
        isOpen={modal} //
        toggle={this.toggleModal}
        className={`${theme} modal-dialog--primary modal-dialog--header modal-dialog--swap`}
      >
        <div>
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggleModal} />
            <h4 className="bold-text  modal__title">
              Deposit Liquidity
              {/*<span><span className={'pool-square-sm'}><img src={asset_a.image} alt="" style={{width: 22}}/></span> <span className={'pool-square-sm'}><img src={asset_b.image} alt="" style={{width: 22}}/></span></span>*/}
            </h4>
          </div>
          <div className={`modal__body ${classElem}`}>

            <DepositWithdraw setWallets={this.setWallets}
                             selectAccount={this.selectAccount}
                             manage_pool_type={'create'}
                             type={'create'}
                             {...this.state} />

          </div>
        </div>
      </Modal>
    )
  }




  render() {
    console.log('this.state: ', this.state)
    // console.log('this.props.match.params.id: ', this.props.match.params.id)

    const { handleSubmit } = this.props
    const { asset_code, activeTab, all_liquidity, all_volume, all_fee, options_liquidity, options_volume, options_fee, series_liquidity,
      series_volume, series_fee, all_o_n, all_apr, filter, filter_pool_type, base_price } = this.state


    return (
      <Container className="dashboard liquidity">
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {this.renderModal()}

        <Row>
          <Col md={12}>
            <h3 className="page-title">Liquidity Pools

              {
                base_price ?
                  <span style={{float: 'right'}}>
                    <a href="#"
                       onClick={this.toggleModal}
                       className={'btn btn-secondary btn-sm'}
                      // disabled={base_price ? false : true}
                       style={{padding: '5px 14px'}}>
                      <i className={'fa fa-plus'}></i>
                    </a>
                  </span>
                : true
              }

            </h3>
            <h3 className="page-subhead subhead">Make money by providing liquidity. Add assets to the pool and Stellar Auto Market Maker (AMM) will do everything for you.</h3>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <div className="card__title" style={{marginBottom: 10}}>
                  <h5 className="subhead fs-13">Liquidity
                    {/*{this.state.scan_cnt} / <b>{this.state.save_cnt}</b>*/}
                    <small className={'right-pair'}>All Liquidity Pools</small>
                  </h5>
                  <h3 className="bold-text">{ all_liquidity > 0 ? <span>${formatCount(all_liquidity, true, 2)}</span> : '0.00' }</h3>
                  <h5 className="subhead" style={{marginTop: -2}}>Statistics for the last 30 days</h5>
                  {/*{formatDate(new Date())}*/}
                </div>

                {
                  series_liquidity.length ?
                    <ReactApexChart options={this.state.options_liquidity} series={this.state.series_liquidity} type="line" height={320} />
                    : null
                }

              </CardBody>
            </Card>
          </Col>

          {/*<Col md={4}>*/}
          {/*  <Card>*/}
          {/*    <CardBody>*/}
          {/*      <div className="card__title" style={{marginBottom: 10}}>*/}
          {/*        <h5 className="subhead">Fees*/}
          {/*          <small className={'right-pair'}>All Liquidity Pools</small>*/}
          {/*        </h5>*/}
          {/*        <h3 className="bold-text">{ all_fee > 0 ? <span>${formatCount(Number(all_fee).toFixed(7), true, 2)}</span> : '0.00' }</h3>*/}
          {/*        <h5 className="subhead" style={{marginTop: -2}}>Statistics for the last 30 days</h5>*/}
          {/*        /!*{formatDate(new Date())}*!/*/}
          {/*      </div>*/}

          {/*      {*/}
          {/*        series_liquidity.length ?*/}
          {/*          <ReactApexChart options={this.state.options_fee} series={this.state.series_fee} type="line" height={320} />*/}
          {/*          : null*/}
          {/*      }*/}

          {/*    </CardBody>*/}
          {/*  </Card>*/}
          {/*</Col>*/}

          <Col md={4}>
            <Card>
              <CardBody>
                <div className="card__title" style={{marginBottom: 10}}>
                  <h5 className="subhead">Volume 24h
                    <small className={'right-pair'}>All Liquidity Pools</small>
                  </h5>
                  <h4 className="bold-text">{ all_volume > 0 ? <span>${formatCount(all_volume, true, 2)}</span> : '0.00' }</h4>
                  <h5 className="subhead" style={{marginTop: -2}}>Volume over the last 30 days</h5>
                </div>

                {
                  series_liquidity.length ?
                    <ReactApexChart options={this.state.options_volume} series={this.state.series_volume} type="line" height={220} />
                    : null
                }

              </CardBody>
            </Card>
          </Col>

          <Col md={4}>
            <Card>
              <CardBody>
                <div className="card__title" style={{marginBottom: 10}}>
                  <h5 className="subhead">Fees
                    <small className={'right-pair'}>All Liquidity Pools</small>
                  </h5>
                  <h4 className="bold-text"> { all_fee > 0 ? <span>${formatCount(Number(all_fee).toFixed(7), true, 2)}</span> : '0.00' } </h4>
                  <h5 className="subhead" style={{marginTop: -2}}>Fees in the last 30 days</h5>
                </div>

                {
                  series_liquidity.length ?
                    <ReactApexChart options={this.state.options_fee} series={this.state.series_fee} type="line" height={220} />
                    : null
                }

              </CardBody>
            </Card>
          </Col>

          <Col md={4}>
            <Card>
              <CardBody>
                <div className="card__title" style={{marginBottom: 10}}>
                  <h5 className="subhead">APR
                    <small className={'right-pair'}>All Liquidity Pools</small>
                  </h5>
                  <h4 className="bold-text text-success"> { all_fee > 0 ? <span>{formatCount(Number(all_apr).toFixed(7), true, 2)}%</span> : '0.00' } </h4>
                  <h5 className="subhead" style={{marginTop: -2}}>Average over the last 30 days</h5>
                </div>

                {
                  series_liquidity.length ?
                    <ReactApexChart options={this.state.options_apr} series={this.state.series_apr} type="line" height={220} />
                    : null
                }

              </CardBody>
            </Card>
          </Col>

        </Row>

        <Row>
          <Col md={12}>
           <Card>
             <CardBody>
               {/*<Row>*/}
               {/*  <Col md={3} md={{size: 2, offset: 8}}>*/}

               {/*    <ButtonToolbar>*/}
               {/*      <ButtonGroup className="btn-group--icons">*/}
               {/*        <Button onClick={() => this.filterWatchlist('all')}*/}
               {/*                color={filter_pool_type === 'all' ? 'primary' : 'secondary'}>*/}
               {/*          <i className="fa fa-database"></i> All Pools</Button>*/}
               {/*        <Button onClick={() => this.filterWatchlist('watch')}*/}
               {/*                color={filter_pool_type === 'watch' ? 'primary' : 'secondary'}>*/}
               {/*          <i className="fa fa-star-o"></i> Watchlist</Button>*/}
               {/*      </ButtonGroup>*/}
               {/*    </ButtonToolbar>*/}
               {/*  </Col>*/}
               {/*</Row>*/}

               <Row className={'filter-row'}>

                 <Col md={{size: 3}}>
                   <ButtonToolbar className={'toolbar-filter'}>
                     <ButtonGroup className="btn-group--icons">
                       <Button onClick={() => this.filterWatchlist('all')}
                               color={filter_pool_type === 'all' ? 'primary' : 'secondary'}>
                         <i className="fa fa-database"></i> All Pools</Button>
                       <Button onClick={() => this.filterWatchlist('watch')}
                               color={filter_pool_type === 'watch' ? 'primary' : 'secondary'}>
                         <i className="fa fa-star-o"></i> Watchlist</Button>
                     </ButtonGroup>
                     {
                       base_price ?
                         <ButtonGroup className="btn-group--icons">
                           <Button onClick={this.toggleModal}
                                   color={'secondary'}><i className="fa fa-plus"></i></Button>
                         </ButtonGroup>
                       : true
                     }
                   </ButtonToolbar>
                 </Col>

                 <Col md={{size: 2, offset: 5}}>
                   <form className="material-form" onSubmit={handleSubmit(this.searchPool)}>
                     <div>
                       <Field
                         name="asset_code"
                         component={renderTextField}
                         type="text"
                         label="Asset Code"
                         value={asset_code}
                         onChange={this.changeSearchCode}
                       />
                       <span className={'material-field-icon'}>
                         {
                           asset_code ?
                             <a href="#" onClick={(e) => this.clearCode(e)}>
                               <i className={'fa fa-close'}></i>
                             </a>
                           :
                             <i className={'fa fa-search'}></i>
                         }
                       </span>
                     </div>
                   </form>
                 </Col>

                 <Col md={{size: 2}}>
                   <form className="material-form filter-pools">
                     <div>
                       <Field
                         name="select_addr"
                         component={renderTextField}
                         select
                         label="Liquidity"
                         onChange={(e) => {
                           this.filterPools(e)
                         }} >
                         <MenuItem
                           className={`material-form__option`}
                           value={'liquidity'}>
                           <p>Liquidity</p>
                         </MenuItem>
                         <MenuItem
                           className={`material-form__option`}
                           value={'vol_24h'}>
                           <p>Vol 24H</p>
                         </MenuItem>
                         <MenuItem
                           className={`material-form__option`}
                           value={'vol_7d'}>
                           <p>Vol 7D</p>
                         </MenuItem>
                         <MenuItem
                           className={`material-form__option`}
                           value={'fee_24h'}>
                           <p>Fee 24H</p>
                         </MenuItem>
                         <MenuItem
                           className={`material-form__option`}
                           value={'apr'}>
                           <p>APR</p>
                         </MenuItem>
                         <MenuItem
                           className={`material-form__option`}
                           value={'shares'}>
                           <p>Shares</p>
                         </MenuItem>
                         <MenuItem
                           className={`material-form__option`}
                           value={'trustlines'}>
                           <p>Trustlines</p>
                         </MenuItem>
                       </Field>
                     </div>
                   </form>
                 </Col>

                 {/*<Col md={{size: 1}}>*/}
                 {/*  <div>*/}
                 {/*    <Button color={'primary'} size={'sm'}><i className={'fa fa-plus'}></i></Button>*/}
                 {/*  </div>*/}
                 {/*</Col>*/}

               </Row>

             </CardBody>
           </Card>
          </Col>
        </Row>

        {
          asset_code || filter ?
            <p style={{margin: '-26px 0px 26px 16px'}}>
              <small><i className="fa fa-filter"></i> Filter: {asset_code && <span><b className={'text-info'}>{asset_code}</b> /</span>} {filter && <b className={'text-info'}>{filter}</b>}</small>
            </p>
          :
            null
        }

        <div>
          { !this.state.pools.length ? <Card><CardBody><div className="panel__refresh" style={{height: '68%'}}><LoadingIcon /></div></CardBody></Card> : null }
          {
            this.state.pools.length && this.state.rewards_aqua ?
              this.state.pools.map(this.renderPoolItem)
            : null
          }
        </div>

        <br/>
        <div className="text-center">
          {
            this.state.more_btn ?
              <Button color="secondary" onClick={this.loadMorePools}>
                {
                  this.state.load_more ? 'Load more...' :
                    <SpinnerLoader />
                }
              </Button>
              : null
          }
        </div>

        {/*<Row>*/}
        {/*  <Col md={12}>*/}
        {/*    <Card>*/}
        {/*      <CardBody>*/}

        {/*        { !this.state.pools.length ? <div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div> : null }*/}


        {/*        <div className="tabs tabs--bordered-bottom">*/}
        {/*          <div className="tabs__wrap">*/}
        {/*            <Nav tabs>*/}
        {/*              <NavItem>*/}
        {/*                <NavLink*/}
        {/*                  className={classnames({ active: activeTab === '1' })}*/}
        {/*                  onClick={() => this.toggle('1')}*/}
        {/*                >*/}
        {/*                  <b>All Pools</b>*/}
        {/*                </NavLink>*/}
        {/*              </NavItem>*/}
        {/*              <NavItem>*/}
        {/*                <NavLink*/}
        {/*                  className={classnames({ active: activeTab === '2' })}*/}
        {/*                  onClick={() => this.toggle('2')}*/}
        {/*                >*/}
        {/*                  <b>Watchlist</b>*/}
        {/*                </NavLink>*/}
        {/*              </NavItem>*/}
        {/*            </Nav>*/}


        {/*            <TabContent activeTab={activeTab}>*/}
        {/*              <TabPane tabId="1">*/}
        {/*                <form className="form" onSubmit={handleSubmit(this.searchPool)}>*/}

        {/*                  <Col md={2}>*/}
        {/*                    <div className="form__form-group">*/}
        {/*                      <span className="form__form-group-label"><b>Asset Code</b></span>*/}
        {/*                      <div className="form__form-group-field">*/}
        {/*                        <Field*/}
        {/*                          style={{borderRadius: 5}}*/}
        {/*                          name="asset_code"*/}
        {/*                          component="input"*/}
        {/*                          type="search"*/}
        {/*                          placeholder="Code"*/}
        {/*                        />*/}

        {/*                        {*/}
        {/*                          search_code &&*/}
        {/*                          <div className="form__form-group-icon clear-icon">*/}
        {/*                            <a href="#" onClick={this.clearCode}><i className={'fa fa-close text-secondary'}></i></a>*/}
        {/*                          </div>*/}
        {/*                        }*/}

        {/*                      </div>*/}
        {/*                    </div>*/}
        {/*                  </Col>*/}

        {/*                </form>*/}

        {/*                <Table striped responsive>*/}
        {/*                  <thead>*/}
        {/*                  <tr>*/}
        {/*                    <th>#</th>*/}
        {/*                    <th>Pair</th>*/}
        {/*                    <th>Liquidity</th>*/}
        {/*                    <th>Vol 24H</th>*/}
        {/*                    <th>Fee 24H</th>*/}
        {/*                    <th>APR</th>*/}
        {/*                    <th>Shares</th>*/}
        {/*                    <th>Members</th>*/}
        {/*                    /!*<th>Type</th>*!/*/}
        {/*                    <th>Add</th>*/}
        {/*                  </tr>*/}
        {/*                  </thead>*/}
        {/*                  <tbody>*/}

        {/*                  {*/}
        {/*                    this.state.pools.length ? this.state.pools.map(this.renderPool) : null*/}
        {/*                  }*/}

        {/*                  </tbody>*/}
        {/*                </Table>*/}
        {/*              </TabPane>*/}

        {/*              <TabPane tabId="2">*/}
        {/*                🌟 Soon...*/}
        {/*              </TabPane>*/}

        {/*            </TabContent>*/}
        {/*          </div>*/}
        {/*        </div>*/}

        {/*      </CardBody>*/}
        {/*    </Card>*/}
        {/*  </Col>*/}
        {/*</Row>*/}

        <Footer />

      </Container>
    )
  }
}



export default reduxForm({
  form: 'LiquidityPools',
})(translate('common')(LiquidityPools));
