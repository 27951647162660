import React, { PureComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Table, Badge, Button, ButtonGroup, ButtonToolbar, Modal, Card, CardBody } from 'reactstrap';
import { translate } from 'react-i18next';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../../modules/stellar/index';
// import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { shortAddr, numFormat, get_loc_wallets, minBalance, baseReserve, getAuth, needDeauthAll, setPairToLocal, checkAuth } from '../../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from 'sweetalert';
import { GLOBE } from '../../../../modules/globeVars/index';
import axios from 'axios';
import RequiredSigners from '../../Auth/components/RequiredSigners';
import iconIdent from '../../Account/components/iconIdent';


const renderTextField = ({
  input, label, meta: { touched, error }, children, select, type,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);


class AssetInfo extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      modal: false,
      wallets: get_loc_wallets(),
      signer: '',
      trade_type: '',
      trade_account: {},
      load_trade_account: false,
      trades: this.props.state.trades,
      last_price: (this.props.state.trades.length > 0 ? numFormat(this.props.state.trades[0].close_r.d / this.props.state.trades[0].close_r.n, 7) : 0),

      amount_buy_change: false,
      price_buy_change: false,
      total_buy_change: false,

      amount_sell_change: false,
      price_sell_change: false,
      total_sell_change: false,

      native_balance: 0,
      counter_balance: 0,
      reserve_balance: 0,

      favorites_asset: false,
      star_color: '#b1c3c8',
      locked: false,
      trust_asset: false,
      is_auth: false,
      type_set_tx: false,
      need_add_signers: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.addSigners = this.addSigners.bind(this);
  }


  componentDidMount() {
    this.checkWatchlist();

    const checkAcc = () => {
      this.checkAccount(this.props.state.asset_db.asset_issuer);
    }

    if (this.props.state.asset_db.length) {
      checkAcc();
    }
    else if (this.props.state.asset_db.asset_issuer) {
      checkAcc();
    }
  }


  toggleModal() {
    this.setState({
      modal: !this.state.modal,
    });
  }


  addSigners(value) {
    console.log('value: ', value)

    this.setState({
      transaction: value,
      need_add_signers: false,
    })

    if (this.state.type_set_tx == 'offer') {
      this.setOfferTx();
    }
    else if (this.state.type_set_tx == 'trust') {
      this.setTrustTx();
    }
  }


  checkAccount(account_id) {
    Server.loadAccount(account_id)
      .then((account) => {

        this.getLockStatus(account);
      })
      .catch(function (err) {
        console.log('error: ', err);
    })
  }



  getLockStatus(account) {
    let med = account.thresholds.med_threshold;
    let signers_weight = 0;

    account.signers.forEach(function (item) {
      signers_weight = (item.weight + signers_weight);
    });


    if (signers_weight === 0 ) { // false emission
        this.setState({locked: true})
    }
    else if (signers_weight < med ) { // false emission
        this.setState({locked: true})
    }
    else { // true emission
        this.setState({locked: false})
    }
  }



  showModalBtn(type) {
    let wallets = this.state.wallets;

    let bid_price = 0, ask_price = 0;
    if (this.props.state.orderbook && this.props.state.orderbook.bids.length && this.props.state.orderbook.asks.length) {
      bid_price = (this.props.state.orderbook.bids[0].price_r.d / this.props.state.orderbook.bids[0].price_r.n).toFixed(7);
      ask_price = (this.props.state.orderbook.asks[0].price_r.d / this.props.state.orderbook.asks[0].price_r.n).toFixed(7);
    }

    this.setState({
      price_buy_change: Number(bid_price),
      price_sell_change: Number(ask_price),
      bid_price: Number(bid_price),
      ask_price: Number(ask_price),
    })

    this.props.change('buy_price', Number(bid_price));
    this.props.change('sell_price', Number(ask_price));

    this.setState({
      trade_type: type
    })

    this.toggleModal();

    // if (wallets) {
    //   // this.setState({
    //   //   wallets: wallets,
    //   // })
    //   this.toggleModal();
    // }
    // else {
    //   swal({
    //     title: "To trade, you must authorize your Address.",
    //     icon: "info",
    //   })
    // }
  }


  changeAddress(value) {

    let address = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        address = address + value[key];
      }
    }

    this.getWallets(address);

    this.getAccount(address)
  }


  getAccount(account_id) {

    this.setState({
      load_trade_account: false,
      trust_asset: false,
    });

    this.setState({ loader: true });

    Server.loadAccount(account_id)
      .then((account) => {
        let reserve = ((account.subentry_count * baseReserve()) + minBalance());

        this.setState({
          loader: false,
          load_trade_account: true,
          trade_account: account,
          reserve_balance: reserve,
        });

        account.balances.forEach((item, index) => {
          if (item.asset_type === 'native') {
            this.setState({
              native_balance: item.balance,
            });
          }
          if (item.asset_code === this.props.state.asset.asset_code && item.asset_issuer === this.props.state.asset.asset_issuer) {
            this.setState({
              counter_balance: item.balance,
              trust_asset: true,
            });
          }
        })

        if (!this.state.trust_asset) {

          this.alertNoTrustline(account_id);
        }
      })
      .catch((err) => {
        console.log('error: ', err);

        this.setState({ loader: false });

        // $this.nextLoadAccount();
    })
  }


  alertNoTrustline(account_id) {

    swal({
      title: `No Trustline for ${this.props.state.asset.asset_code}`,
      text: `There is no Trustline for the ${this.props.state.asset.asset_code} token on the selected Address. Want to install Trustline right now?`,
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
    .then((confirm) => {
      if (confirm) {
        this.setTrustline(account_id);
      }
    })
  }


  setTrustline(account_id) {

    getAuth()
      .then((value) => {
        if (value === 'accept') {
          this.setTrustTx()
        }
        else if (value === 'need_deauth_all') {
          needDeauthAll();
        }
      });
  }


  setTrustTx() {

    const account_id = this.state.trade_account.account_id;

    this.setState({ loader: true })

    Server.loadAccount(account_id)
      .then((sourceAccount) => {

        const submitTx = (num) => {
          return Server.submitTransaction(this.state.transaction);
        }

        if (!this.state.transaction) {

          this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: StellarSdk.BASE_FEE,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
          .addOperation(StellarSdk.Operation.changeTrust({
              asset: new StellarSdk.Asset(this.props.state.asset.asset_code, this.props.state.asset.asset_issuer),
          }))
          .setTimeout(100)
          .build();

          this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));

          const master_weight = sourceAccount.signers.reverse()[0].weight;

          if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {
            // alert(sourceAccount.thresholds.med_threshold)

            this.setState({
              // transaction: this.state.transaction,
              account: sourceAccount,
              need_add_signers: true,
              btn_name: 'Set Trustline',
              btn_icon: 'cube',
              type_tx: 'med',
              type_set_tx: 'trust'
            })

            return false;
          }
          else {
            return submitTx();
          }
        }
        else {
          return submitTx();
        }
      })
      .then((result) => {
        if (result) {
            this.setState({ loader: false })
        // this.toggleModal();

        swal({
          title: `Added new balance ${this.props.state.asset.asset_code}!`,
          text: `Trustline to ${this.props.state.asset.asset_code} token has been successfully installed. Now ${this.props.state.asset.asset_code} token is available on your balance.`,
          icon: "success",
          confirm: true,
        })
        .then(confirm => {
          if (confirm) {
            this.getAccount(account_id);
          }
        });
        }
      })
      .catch((error) => {
        console.error('Something went wrong!', error);

        this.setState({ loader: false })
        ToastStore.error('Transaction error');
    });
  }


  priceChange(value) {
    let price = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        price = price + value[key];
      }
    }

    if (this.state.trade_type === 'Buy') {
      this.setState({
        price_buy_change: Number(price),
      })
    }
    if (this.state.trade_type === 'Sell') {
      this.setState({
        price_sell_change: Number(price),
      })
    }

    if (this.state.amount_buy_change) {
      let total = (price * this.state.amount_buy_change).toFixed(7)
      this.setState({
        total_buy_change: Number(total),
      })

      this.props.change('buy_total_counter', Number(total))
    }
  }


  amountChange(value) {
    let amount = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        amount = amount + value[key];
      }
    }

    if (this.state.trade_type === 'Buy') {
      this.setState({
        amount_buy_change: Number(amount),
      })

      if (this.state.price_buy_change) {
        let total = (amount * this.state.price_buy_change).toFixed(7)
        this.setState({
          total_buy_change: Number(total),
        })

        this.props.change('buy_total_counter', Number(total))
      }
    }
    if (this.state.trade_type === 'Sell') {
      this.setState({
        amount_sell_change: Number(amount),
      })

      if (this.state.price_sell_change) {
        let total = (amount * this.state.price_sell_change).toFixed(7)
        this.setState({
          total_sell_change: Number(total),
        })

        this.props.change('sell_total_counter', Number(total))
      }
    }
  }


  totalChange(value) {
    let total = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        total = total + value[key];
      }
    }

    if (this.state.trade_type === 'Buy') {
      this.setState({
        total_buy_change: Number(total),
      })

      if (this.state.price_buy_change) {
        let amount = (total / this.state.price_buy_change).toFixed(7)
        this.setState({
          amount_buy_change: Number(amount),
        })

        this.props.change('buy_amount', Number(amount))
      }
    }
    if (this.state.trade_type === 'Sell') {
      this.setState({
        total_sell_change: Number(total),
      })

      if (this.state.price_sell_change) {
        let amount = (total / this.state.price_sell_change).toFixed(7)
        this.setState({
          amount_sell_change: Number(amount),
        })

        this.props.change('sell_amount', Number(amount))
      }
    }
  }


  submitForm(values) {

    if (!values.select_address) {
      ToastStore.warning('Select Trading Address');
    }
    else if (!this.state.trust_asset) {
      this.alertNoTrustline(values.select_address);
    }
    else {

      if (this.state.trade_type === 'Buy') {
        if (!values.buy_price) {
          ToastStore.warning('Enter your price');
        }
        else if (!values.buy_amount) {
          ToastStore.warning('Enter Asset amount');
        }
        else if (!values.buy_total_counter) {
          ToastStore.warning('Not total amount');
        }
        else {

          swal({
            title: "Confirmation!",
            text: `Create an Order to buy ${this.props.state.asset.asset_code} right now?`,
            icon: "info",
            buttons: {
              cancel: true,
              confirm: true,
            },
          })
          .then((confirm) => {
            if (confirm)
              this.setOffer(values, this.state.trade_type);
          })
        }
      }

      if (this.state.trade_type === 'Sell') {
        if (!values.sell_price) {
          ToastStore.warning('Enter your price');
        }
        else if (!values.sell_amount) {
          ToastStore.warning('Enter Asset amount');
        }
        else if (!values.sell_total_counter) {
          ToastStore.warning('Not total amount');
        }
        else {

          swal({
            title: "Confirmation",
            text: `Create an Order to sell ${this.props.state.asset.asset_code} right now?`,
            icon: "info",
            buttons: {
              cancel: true,
              confirm: true,
            },
          })
          .then((confirm) => {
            if (confirm)
              this.setOffer(values, this.state.trade_type);
          })
        }
      }
    }
  }


  setOffer(values, type_offer) {
    const $this = this;

    // this.getWallets(values.select_address);

    getAuth()
      .then((value) => {
        if (value === 'accept') {
          acceptSendTX();
        }
        else if (value === 'need_deauth_all') {
          needDeauthAll();
        }
      });

    const acceptSendTX = () => {

      const asset_code = $this.props.state.asset.asset_code;
      const asset_issuer = $this.props.state.asset.asset_issuer;

      var asset = {};

      if (type_offer === 'Buy') {
        asset.buying = new StellarSdk.Asset(asset_code, asset_issuer);
        asset.selling = StellarSdk.Asset.native();
        asset.amount = values.buy_total_counter;
        asset.price = Number(1 / values.buy_price);

        var buy_asset = {
          asset_code: asset_code,
          asset_issuer:asset_issuer
        }
        var sell_asset = {
          asset_code: 'XLM',
          asset_issuer: 'native'
        }
      }
      if (type_offer === 'Sell') {
        asset.buying = StellarSdk.Asset.native();
        asset.selling = new StellarSdk.Asset(asset_code, asset_issuer);
        asset.amount = values.sell_amount;
        asset.price = values.sell_price;

        var sell_asset = {
          asset_code: asset_code,
          asset_issuer:asset_issuer
        }
        var buy_asset = {
          asset_code: 'XLM',
          asset_issuer: 'native'
        }
      }

      this.setState({
        selling: asset.selling,
        buying: asset.buying,
        amount: asset.amount,
        price: asset.price,
        buy_asset: buy_asset,
        sell_asset: sell_asset,
      })

      this.setOfferTx();
    }
  }


  setOfferTx() {

    console.log('setOfferTx this.state: ', this.state);

    this.setState({ loader: true });

    Server.loadAccount(this.state.trade_account.account_id)
      .then((sourceAccount) => {
        // console.log('sourceAccount: ', sourceAccount);

        const submitTx = (num) => {
          return Server.submitTransaction(this.state.transaction);
        }

        if (!this.state.transaction) {

          this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: StellarSdk.BASE_FEE,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
          .addOperation(StellarSdk.Operation.manageOffer({
              selling: this.state.selling,
              buying: this.state.buying,
              amount: String(this.state.amount),
              price: String(this.state.price)
          }))
          .build();

          this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));

          const master_weight = sourceAccount.signers.reverse()[0].weight;

          if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {

            // this.toggleModal();

            this.setState({
              account: sourceAccount,
              need_add_signers: true,
              btn_name: 'Trade Asset',
              btn_icon: 'exchange',
              type_tx: 'med',
              type_set_tx: 'offer'
            })
          }
          else {
            return submitTx();
          }
        }
        else {
          return submitTx();
        }
      })
      .then((result) => {

        this.setState({ loader: false });

        if (result) {

          let pair = {
            buy_asset: this.state.buy_asset,
            sell_asset: this.state.sell_asset,
            address: this.state.signer[0].pk,
          }

          setPairToLocal(pair);

          swal({
            title: "Offer added!",
            text: "Your exchange Offer has been successfully added.",
            icon: "success",
          })
          // .then((value) => {
          //   this.toggleModal();
          // })

          this.setState({ transaction: false });
        }
      })
      .catch((error) => {
        console.error('Something went wrong!', error);

        this.setState({
          loader: false,
          transaction: false,
        })
        ToastStore.error('Transaction error');
    });
  }


  getWallets(address) {
    var wallets = this.state.wallets;
    if (wallets) {
      var signer = wallets.filter((item) => {
        return item.pk === address;
      });

      this.setState({
        // wallets: wallets,
        signer: signer,
      })
    }
  }



  checkWatchlist() {

    let asset = {
      asset_code: this.props.state.asset.asset_code,
      asset_issuer: this.props.state.asset.asset_issuer,
    }

    let local_favorites = localStorage.getItem('favorites');

    if (local_favorites) {

      local_favorites = JSON.parse(local_favorites);

      if (local_favorites.assets.length) {
        let isset_asset = false;
        local_favorites.assets.forEach((item, index) => {
          if (item.asset_code === asset.asset_code && item.asset_issuer === asset.asset_issuer) {
            isset_asset = true;

            if (isset_asset) {
              this.setState({
                star_color: '#ffc200'
              });
            }
          }
        });
      }
    }
  }


  toggleWatchlist() {

    let asset = {
      asset_code: this.props.state.asset.asset_code,
      asset_issuer: this.props.state.asset.asset_issuer,
    }

    let local_favorites = localStorage.getItem('favorites');

    if (!local_favorites) { // not local

      let favorites = {
        addresses: [],
        assets: [],
        offers: [],
      }

      favorites.assets.unshift(asset);
      favorites = JSON.stringify(favorites);
      localStorage.setItem('favorites', favorites);

      this.setState({
        favorites_asset: true,
        star_color: '#ffc200'
      });

      ToastStore.success('Asset added to Watchlist!');

      this.setWachAssetDB('set', asset);
    }

    else { // isset local

      local_favorites = JSON.parse(local_favorites);

      let isset_asset = false;
      if (local_favorites.assets.length) {
        local_favorites.assets.forEach((item, index) => {
          if (item.asset_code === asset.asset_code && item.asset_issuer === asset.asset_issuer) {
            isset_asset = true;
          }
        });
      }
      else {
        local_favorites.assets = [];
      }

      if (!isset_asset) {

        local_favorites.assets.unshift(asset);
        local_favorites = JSON.stringify(local_favorites);
        localStorage.setItem('favorites', local_favorites);

        this.setState({
          favorites_asset: true,
          star_color: '#ffc200'
        });

        ToastStore.success('Asset added to Watchlist!');

        this.setWachAssetDB('set', asset);
      }
      else {

        local_favorites.assets.forEach((item, index) => {
          if (item.asset_code === asset.asset_code && item.asset_issuer === asset.asset_issuer) {

            local_favorites.assets.splice(index, 1);

            local_favorites = JSON.stringify(local_favorites);
            localStorage.setItem('favorites', local_favorites);
            this.setState({
              favorites_asset: false,
              star_color: '#b1c3c8'
            });

            ToastStore.info('Asset removed from Watchlist');

            this.setWachAssetDB('remove', asset);
          }
        });
      }
    }
  }



  setWachAssetDB(type, asset) {

    let auth_user = localStorage.getItem('auth_user');
    if (auth_user) {

      const formData = new FormData();
      formData.append('user_id', JSON.parse(auth_user).user_id)
      formData.append('asset_code', asset.asset_code)
      formData.append('asset_issuer', asset.asset_issuer)
      formData.append('type', type)

      axios.post(GLOBE.API_URL+`/watch_asset`, formData)
        .then(response => {
          // console.log('response: ', response)
      });
    }
  }


  checkAuthAddr() {
    checkAuth().then((value) => {
      if (value) {
        this.setState({
          is_auth: true
        })
      }
    })
  }



  renderTradeAddress() {
    return (
        <div>
          <div>
            <Field
              name="select_address"
              component={renderTextField}
              select
              label="Select Trading Address"
              onChange={(value) => {
                this.changeAddress(value)
              }}
            >
              {
                this.state.wallets
                ? this.state.wallets.map((item, index) => {
                    return (
                      <MenuItem key={index} className="material-form__option" value={item.pk}><b className="text-secondary">{shortAddr(item.pk, 10)}</b> <small>&nbsp;/ {item.title}</small></MenuItem>
                    );
                  })
                : null
              }
            </Field>
          </div>

          {
            this.state.load_trade_account ?
              <div>
                <h5 className="subhead">Balances: <b className="text-info">{numFormat(this.state.native_balance, 7)} <font className="text-warning">XLM</font></b> &nbsp; /
                  &nbsp; {this.state.trust_asset ? <b className="text-info">{numFormat(this.state.counter_balance, 7)} <font className="text-warning">{this.props.state.asset.asset_code}</font></b> : null}
                  <span className="float-right">Reserve: <b className="text-info">{this.state.reserve_balance} <font className="text-warning">XLM</font></b></span>
                </h5>
                <br/>
              </div>
            : null
          }
        </div>
    )
  }


  renderModal() {

    const {reset, handleSubmit } = this.props;

    let classElem = '';
    if (localStorage.getItem('theme') === 'theme-dark') {
      classElem = 'notification-dark'
    }

    return (
      <Modal
        isOpen={this.state.modal}
        toggle={this.toggleModal}
        className={`modal-dialog--${this.state.trade_type === 'Buy' ? 'success' : 'warning'} modal-dialog--header ${classElem}`}
      >
        <div>
          { this.state.loader ? <div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div> : null }

          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggleModal} />
            <h4 className="bold-text  modal__title">{this.state.trade_type} <b>{this.props.state.asset.asset_code}</b></h4>
          </div>
          <div className="modal__body">

            <div className="card__title">
              <h5 className="subhead">
                ASK: <b className="text-success">{numFormat(this.state.ask_price, 7)} <span className="text-warning">XLM</span></b> &nbsp; / &nbsp;
                BID: <b className="text-danger">{numFormat(this.state.bid_price, 7)} <span className="text-warning">XLM</span></b> &nbsp; / &nbsp;
                SPREAD: <b className="text-secondary">{numFormat(this.state.bid_price - this.state.ask_price, 7)} <span className="text-warning">XLM</span></b>
              </h5>
            </div>

            {
              this.state.trade_type === 'Buy' ?
                <div>
                  <form className="material-form"
                        onSubmit={handleSubmit(this.submitForm.bind(this))}
                        onClick={this.checkAuthAddr.bind(this)}>

                    { this.renderTradeAddress() }

                    <div>
                      <Field
                        name="buy_price"
                        component={renderTextField}
                        label={`Price ${this.props.state.asset.asset_code}/XLM`}
                        className="m-form"
                        onChange={this.priceChange.bind(this)}
                      />
                    </div>

                    <div>
                      <Field
                        name="buy_amount"
                        component={renderTextField}
                        label={`Amount buy ${this.props.state.asset.asset_code}`}
                        onChange={this.amountChange.bind(this)}
                      />
                    </div>


                    <div>
                      <Field
                        name="buy_total_counter"
                        component={renderTextField}
                        label="Total sell XLM"
                        onChange={this.totalChange.bind(this)}
                      />
                    </div>

                    <ButtonToolbar className="form__button-toolbar float-right">
                      <Button type="button" onClick={reset}>Reset</Button>
                      <Button color="success" type="submit">Buy <b>{numFormat(this.state.amount_buy_change, 7)} {this.props.state.asset.asset_code}</b></Button>
                    </ButtonToolbar>
                  </form>
                </div>
              : null
            }


            {
              this.state.trade_type === 'Sell' ?
                <div>
                  <form className="material-form"
                        onSubmit={handleSubmit(this.submitForm.bind(this))}
                        onClick={this.checkAuthAddr.bind(this)}>

                    { this.renderTradeAddress() }

                    <div>
                      <Field
                        name="sell_price"
                        component={renderTextField}
                        label={`Price ${this.props.state.asset.asset_code}/XLM`}
                        className="m-form"
                        onChange={this.priceChange.bind(this)}
                      />
                    </div>

                    <div>
                      <Field
                        name="sell_amount"
                        component={renderTextField}
                        label={`Amount sell ${this.props.state.asset.asset_code}`}
                        onChange={this.amountChange.bind(this)}
                      />
                    </div>

                    <div>
                      <Field
                        name="sell_total_counter"
                        component={renderTextField}
                        label="Total buy XLM"
                        onChange={this.totalChange.bind(this)}
                      />
                    </div>

                    <ButtonToolbar className="form__button-toolbar float-right">
                      <Button type="button" onClick={reset}>Reset</Button>
                      <Button color="warning" type="submit">Sell <b>{numFormat(this.state.amount_sell_change, 7)} {this.props.state.asset.asset_code}</b></Button>
                    </ButtonToolbar>
                  </form>
                </div>
              : null
            }

          </div>
        </div>
      </Modal>
    )
  }


  render() {

    console.log('Info this.props: ', this.props)

    const default_logo = `${process.env.PUBLIC_URL}/img/digitalcoin.png`;
    let market_cap = false;
    if (this.props.state.price && this.props.state.load_trades) {

      // var asset_price_xlm = (this.props.state.trades[0].close_r.D / this.props.state.trades[0].close_r.N);
      var asset_price_xlm = (1 / this.props.state.trades[0].close);
      var market_cap_xlm = (this.props.state.asset.amount * asset_price_xlm);
      var market_cap_usd = (market_cap_xlm * this.props.state.price);
      // var asset_price_usd = (this.props.state.trades[0].base_volume * this.props.state.xlm_price[0].price_usd);
      var vol_24_usd = (this.props.state.trades[0].base_volume * this.props.state.price);
      var last_price_usd = (asset_price_xlm * this.props.state.price)

      market_cap = numFormat(this.props.state.asset.amount * ((this.props.state.trades[0].close_r.d / this.props.state.trades[0].close_r.n) * this.props.state.price), 0);
    }
    const issuer = this.props.state.asset.asset_issuer;

    if (this.state.is_auth) {
      return <Redirect to='/wallet' />
    }

    return (
      <div>

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {
          this.state.need_add_signers && this.state.transaction ?
            <RequiredSigners account={this.state.trade_account}
                             add_signers={this.addSigners}
                             transaction={this.state.transaction}
                             btn_name={this.state.btn_name}
                             icon={this.state.btn_icon}
                             type_tx={this.state.type_tx} />
          : null
        }

        { this.renderModal() }

        <div className="profile__activity" style={{padding: '0 0 20px'}}>
          <div className="profile__activity-avatar">
            <img className="icon-asset" src={ this.props.state.toml.image ? this.props.state.toml.image : default_logo } alt="" />
          </div>
          <div>
            <h3 className="profile__activity-code">{this.props.state.asset.asset_code}
              <span className="profile__activity-date">&nbsp; {this.props.state.toml.name}</span>
            </h3>
            <Link className="text-secondary"
                  to={`/account/${this.props.state.asset.asset_issuer}`}
                  style={{fontSize: 12}}>{iconIdent(issuer, 'icon-indent-sm')}{' '}
              {shortAddr(issuer, 10)}&nbsp;&nbsp;
              <span>
                {this.state.locked ? <i className="fa fa-lock text-success"></i> : <i className="fa fa-unlock-alt text-info"></i>}
              </span>
            </Link>
          </div>
          <a className="icon-favorit" onClick={() => this.toggleWatchlist()}>
            <span className="sidebar__link-icon lnr lnr-star"
                  style={{color: this.state.star_color, fontSize: '16px'}}/>
          </a>
        </div>

        <Table className="table--bordered asset-info" responsive>
          <tbody>
            <tr>
              <td>PRICE <small>(USD/XLM)</small></td>
              <td>
                <small>${this.props.state.load_trades ? numFormat(last_price_usd, 7) : '...'}</small> /&nbsp;
                <b className="text-info">{this.props.state.load_trades ? numFormat(asset_price_xlm, 7) : '...'}</b>
              </td>
            </tr>
            <tr>
              <td>CHANGE 24</td>
              <td><b className="text-info"><Badge color={this.props.state.load_trades ? this.props.state.change_24h_color : 'secondary' }>{this.props.state.load_trades ? this.props.state.change24 : '...'}%</Badge></b></td>
            </tr>
            <tr>
              <td>VOLUME 24 <small>(USD/XLM)</small></td>
              {/*<td><b className="text-info">{this.props.state.load_trades ? numFormat(this.props.state.trades[0].base_volume, 0) : '...'}</b> / <b>$1,765,365</b></td>*/}
              <td>
                <small>${this.props.state.load_trades ? numFormat(vol_24_usd, 0) : '...'}</small> /&nbsp;
                <b className="text-info">{this.props.state.load_trades ? numFormat(this.props.state.trades[0].base_volume, 0) : '...'}</b>
              </td>
            </tr>
            <tr>
              <td>VOLUME 24 <small>({this.props.state.asset.asset_code})</small></td>
              <td>
                <b className="text-info">{this.props.state.load_trades ? numFormat(this.props.state.trades[0].counter_volume, 7) : '...'}</b>
              </td>
            </tr>
            <tr>
              <td>SUPPLY</td>
              <td><b className="text-info">{numFormat(this.props.state.asset.amount, 0)}</b></td>
            </tr>
            <tr>
              <td>MARKET CAP</td>
              <td><b className="text-info">${market_cap ? market_cap : '...' }</b></td>
            </tr>
            <tr>
              <td>HOLDERS</td>
              <td><b className="text-info">{numFormat(this.props.state.asset.num_accounts, 0)}</b></td>
            </tr>
            {/*<tr>
              <td>TOTAL VOLUME <small>(XLM)</small></td>
              <td><b className="text-info">{numFormat(this.props.state.asset_db.overall_trade_volume, 0)}</b></td>
            </tr>
            <tr>
              <td>TRADES COUNT</td>
              <td><b className="text-info">{numFormat(this.props.state.asset_db.total_trades_count, 0)}</b></td>
            </tr>*/}
            {/*<tr>
              <td>RATING</td>
              <td><b className="text-info">{this.props.asset_db ? numFormat(this.props.asset_db.all_rating, 1) : 0}</b></td>
            </tr>*/}
            <tr>
              <td>ISSUER URL</td>
              <td>
                {
                  this.props.state.load_toml ? <b><a className="text-info" href={`https://${this.props.state.home_domain}`} target='_blank' rel='noopener noreferrer'>{this.props.state.home_domain}</a></b> : 'Get domain...'
                }

              </td>
            </tr>
          </tbody>
        </Table>

        <div>
          <br />

            {/*{ this.props.state.load_orderbook ?*/}
            {/*  */}
            {/* : null}*/}

          <ButtonGroup className="btn-group--justified">
            <Link to={`/trade/${this.props.state.asset.asset_code}-XLM/${this.props.state.asset.asset_issuer}/native`} class={'btn btn-secondary'}>Trade {this.props.state.asset.asset_code}</Link>
            {/*<Button color="secondary"*/}
            {/*        outline onClick={() => { this.showModalBtn('Buy') }}*/}
            {/*        style={{background: '#00ff0b1c'}}>BUY</Button>*/}
            {/*<Button color="secondary"*/}
            {/*        outline onClick={() => { this.showModalBtn('Sell') }}*/}
            {/*        style={{background: '#ff490012'}}>SELL</Button>*/}
          </ButtonGroup>
        </div>

      </div>
    )
  }
}


AssetInfo.propTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'floating_labels_form',
})(translate('common')(AssetInfo));
