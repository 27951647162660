import React, { PureComponent } from 'react';
import {Card, CardBody, Row, Col, Badge, Table, Button, Container, ButtonToolbar} from 'reactstrap';
import { translate } from 'react-i18next';
import LoadingIcon from 'mdi-react/LoadingIcon';
import {numFormat, shortAddr, shortAddress} from "../../../modules";
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from "sweetalert";
import {Field, reduxForm} from "redux-form";
import { getAccount } from "../../../endpoints/StellarAPI";



class MonitorBalances extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      wallets: false,
      loading: false,
      balancesXLM: 0,
      balancesToken: 0,
      addresses: false,
      accounts: [],
      asset: {},
    };
  }

  componentDidMount() {
    this.getAccounts()
  }


  getAccounts = () => {

    const addresses = localStorage.getItem('monitor-addresses')
    const asset = localStorage.getItem('monitor-asset')

    if (addresses && asset) {

      const parseAddrs = JSON.parse(addresses)
      const parseAsset = JSON.parse(asset)

      this.setState({
        addresses: parseAddrs,
        asset: parseAsset,
        accounts: [],
        balancesXLM: 0,
        balancesToken: 0,
      })

      this.props.change('addresses', parseAddrs.join('\n'))
      this.props.change('code', parseAsset.code)
      this.props.change('issuer', parseAsset.issuer)

      parseAddrs.map((item, index) => {
        this.getOneAccount(item, index)
      })
    }
  }


  getOneAccount = (address, index) => {

    this.setState({ loading: true })

    getAccount(address)
      .then(result => {
        // console.log('getAccount result: ', result)

        const xlm = result.balances.filter(item => item.asset_type === 'native')
        const asset = result.balances.filter(item => item.asset_code === this.state.asset.code && item.asset_issuer === this.state.asset.issuer )

        const account = {
          address: result.account_id,
          balance_xlm: Number(xlm[0].balance),
          balance_token: Number(asset[0].balance),
        }

        this.setState({
          balancesXLM: this.state.balancesXLM + Number(xlm[0].balance),
          balancesToken: this.state.balancesToken + Number(asset[0].balance),
          accounts: this.state.accounts.concat(account),
          loading: false,
        })

    })
  }


  submitForm = (values) => {
    const addresses = values.addresses.split('\n')
    const asset = {
      code: values.code,
      issuer: values.issuer,
    }

    localStorage.setItem('monitor-addresses', JSON.stringify(addresses))
    localStorage.setItem('monitor-asset', JSON.stringify(asset))

    this.setState({ addresses, asset })

    this.getAccounts()
  }


  renderAccounts = (item, index) => {
    return (
      <tr key={index}>
        <td>{ index+1 }</td>
        <td>{shortAddress(item.address)}</td>
        <td><b className={'text-info'}>{numFormat(item.balance_xlm, 7)}</b></td>
        <td><b className={'text-info'}>{numFormat(item.balance_token, 7)}</b></td>
      </tr>
    );
  }


  reset = (reset) => {
    reset()
    localStorage.removeItem('monitor-addresses')
    localStorage.removeItem('monitor-asset')
    this.setState({
      addresses: false,
      asset: {},
    })
  }



  render() {

    // console.log('this.state: ', this.state)

    const {reset, handleSubmit} = this.props;


    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Row>
          <Col md={{size:12}}>
            <h3 className="page-title">Monitor Balances</h3>
          </Col>
        </Row>

        <div>

          <Card>
            <CardBody>

              <Row>
                <Col md={{ size: 8, offset: 2 }} >
                  <br/>
                  <form className="form form--horizontal" onSubmit={handleSubmit(this.submitForm)}>

                    <div className="form__form-group">
                      <span className="form__form-group-label">Asset Code</span>
                      <div className="form__form-group-field">
                        <Field
                          name="code"
                          component="input"
                          type="text"
                          placeholder="Asset Code"
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">Asset Issuer</span>
                      <div className="form__form-group-field">
                        <Field
                          name="issuer"
                          component="input"
                          type="text"
                          placeholder="Asset Issuer"
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">Addresses</span>
                      <div className="form__form-group-field">
                        <Field
                          name="addresses"
                          component="textarea"
                          type="text"
                          placeholder="Each address is on a new line"
                          // onChange={(value) => {
                          //   this.changeInput(value)
                          // }}
                        />
                      </div>
                    </div>


                    <ButtonToolbar className="form__button-toolbar float-right">
                      <Button type="button" onClick={() => this.reset(reset)}>Reset</Button>
                      <Button color="primary" type="submit">Save</Button>
                    </ButtonToolbar>
                  </form>

                </Col>
              </Row>
            </CardBody>
          </Card>

        </div>


        <Row>
          <Col md={{size:12}}>
            {
              this.state.accounts.length ?
                <h3 className="page-title">
                  Accounts: <b className={'text-info'}>{this.state.accounts.length}</b> <br/>
                  XLM: <b className={'text-info'}>{numFormat(this.state.balancesXLM, 4)}</b> <br/>
                  {this.state.asset.code}: <b className={'text-info'}>{numFormat(this.state.balancesToken, 4)}</b>
                </h3>
              : null
            }
          </Col>
        </Row>


        <Row>
          <Col md={12}>
            <Card>
              <CardBody>

                { this.state.loading ? <div className="panel__refresh" style={{height: '78%'}}><LoadingIcon /></div> : '' }

                <Table responsive className="table--bordered">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Account</th>
                    <th>Balance XLM</th>
                    <th>Balance {this.state.asset.code}</th>
                  </tr>
                  </thead>
                  <tbody>

                  {
                    this.state.accounts.length ?
                      this.state.accounts.map(this.renderAccounts)
                      : null
                  }

                  </tbody>
                </Table>

              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
    );
  }
}



export default reduxForm({
  form: 'MonitorBalances', // a unique identifier for this form
})(translate('common')(MonitorBalances));
