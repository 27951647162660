import React, { PureComponent } from 'react';
import { Table } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { shortAddr, numFormat, getNameAddr } from '../../../../../modules/index';
import moment from 'moment';



class Transactions extends PureComponent {

	constructor(props) {
	  super(props);
	  this.state = {
	    // activeTab: '1',
	  };
	}



	formatDate(time) {
	  return moment(time).format("DD MMMM YYYY HH:mm:ss");
	}

	shortAddress(address) {
		const name = getNameAddr(address);
	  return (
	    <Link to={`/account/${address}`}><b>{shortAddr(address, 4)}</b> <small className="text-info">{name ? `[${name}]` : ''}</small></Link>
	  )
	}


	renderItem(item, index) {
		return (
		  <tr key={index}>
		  	<td><b><Link to={`/transaction/${item.id}`}>{ shortAddr(item.id, 6) }</Link></b></td>
		  	<td>{ this.formatDate(item.created_at) }</td>
		  	<td><b className="text-info">{ item.operation_count }</b></td>
		    <td><b className="text-info">{(item.fee_charged / 1000000)} <Link to={'/asset/XLM-native'} className="text-warning">XLM</Link></b></td>
		  </tr>
		)
	}


	render() {

		const items = this.props.items;

		return(
			<Table striped responsive>
			  <thead>
			    <tr>
			      <th>Hash</th>
			      <th>Date</th>
			      <th>Operations</th>
			      <th>Fee paid</th>
			    </tr>
			  </thead>
			  <tbody>

			  	{ items.map(this.renderItem.bind(this)) }

			  </tbody>
			</Table>
		)
	}
}

Transactions.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate('common')(Transactions);
