import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const scopIcon = `${process.env.PUBLIC_URL}/img/icons/scop.png`;

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    this.props.onClick();
  };

  render() {
    return (
      <div className="sidebar__content">

        <ul className="sidebar__block">
          <SidebarLink
            title="Home"
            icon="home"
            route="/"
            onClick={this.hideSidebar} />
        </ul>
        <ul className="sidebar__block">
          <a href="/wallet" rel='noopener noreferrer'>
            <li className="sidebar__link">
              <span className="sidebar__link-icon lnr lnr-briefcase"></span><p className="sidebar__link-title">Wallet</p>
            </li>
          </a>
          {/*<SidebarLink*/}
          {/*  title="Wallet"*/}
          {/*  icon="briefcase"*/}
          {/*  route="/wallet"*/}
          {/*  onClick={this.hideSidebar} />*/}
          <SidebarLink
            title="Assets"
            icon="layers"
            route="/assets"
            onClick={this.hideSidebar} />
          <SidebarLink
            title="Trade"
            icon="chart-bars"
            route="/trade"
            onClick={this.hideSidebar} />
          <SidebarLink
            title="Swap"
            icon="sync"
            route="/swap"
            onClick={this.hideSidebar} />
          <SidebarLink
            title="Liquidity Pools"
            icon="database"
            route="/pools"
            onClick={this.hideSidebar} />

          <a href="/payments" rel='noopener noreferrer'>
            <li className="sidebar__link">
              <span className="sidebar__link-icon lnr lnr-location"></span><p className="sidebar__link-title">Payments</p>
            </li>
          </a>

          <SidebarLink
            title="Receive"
            icon="frame-expand"
            route="/receive"
            onClick={this.hideSidebar} />
          <SidebarLink
            title="Claimable Balance"
            icon="clock"
            route="/claimable-balance"
            onClick={this.hideSidebar} />
          <SidebarLink
            title="Buy/Sell Crypto"
            icon="store"
            route="/fiat"
            newLink={true}
            onClick={this.hideSidebar} />
          {/*<SidebarLink
            title="IEO Launchpad"
            icon="rocket"
            route=""
            onClick={this.hideSidebar} />*/}
        </ul>

        <ul className="sidebar__block">
          <SidebarLink
            title="Block explorer"
            icon="magnifier"
            route="/explorer"
            onClick={this.hideSidebar} />
          <SidebarLink
            title="Create Token"
            icon="file-add"
            route="/create-token"
            onClick={this.hideSidebar} />
          <SidebarLink
            title="Contacts"
            icon="book"
            route="/contacts"
            onClick={this.hideSidebar} />

          <SidebarLink
            title="Watchlist"
            icon="pushpin"
            route="/watchlist"
            onClick={this.hideSidebar} />
          {/*<SidebarLink*/}
          {/*  title="Sign XDR"*/}
          {/*  icon="code"*/}
          {/*  route="/xdr"*/}
          {/*  onClick={this.hideSidebar} />*/}

          {/*<SidebarLink
            title="Airdrops"
            icon="rocket"
            route="/airdrops"
            onClick={this.hideSidebar} />*/}
          <SidebarLink
            title="Settings"
            icon="cog"
            route="/settings"
            onClick={this.hideSidebar} />
          <SidebarLink
            title="Help"
            icon="question-circle"
            route="/support"
            onClick={this.hideSidebar} />
        </ul>

        <ul className="sidebar__block">

          {/*<SidebarLink
            title="Guide"
            icon="tag"
            route="https://scopuly.zendesk.com/hc/en-us/sections/360000344438-FAQ"
            onClick={this.hideSidebar} />*/}

          {/*<a href="https://scopuly.zendesk.com/hc/en-us/sections/360000344438-FAQ" target='_blank' rel='noopener noreferrer'>
            <li className="sidebar__link">
              <span className="sidebar__link-icon lnr lnr-graduation-hat"></span><p className="sidebar__link-title">Guide</p>
            </li>
          </a>*/}

          {/*<SidebarLink*/}
          {/*  title="About"*/}
          {/*  icon="flag"*/}
          {/*  route="https://about.scopuly.com/"*/}
          {/*  target={'_blank'}*/}
          {/*  onClick={this.hideSidebar} />*/}

          {/*<SidebarLink
            title="SCOP"
            icon="layers"
            route="http://scop.scopuly.com/"
            onClick={this.hideSidebar} />*/}

          <a href="https://about.scopuly.com/" target='_blank' rel='noopener noreferrer'>
            <li className="sidebar__link">
              <span className="sidebar__link-icon lnr lnr-flag"></span> <p className="sidebar__link-title">About</p>
            </li>
          </a>

          <a href="https://scop.scopuly.com/" target='_blank' rel='noopener noreferrer'>
            <li className="sidebar__link">
              <span className="sidebar__link-icon lnr"><img className={'sidebar-img-icon'} src={scopIcon} alt=""/></span><p className="sidebar__link-title">SCOP</p>
            </li>
          </a>

        </ul>
        {/*<ul className="sidebar__block">
          <SidebarLink
            title="Logout"
            icon="exit"
            route="/suppots"
            onClick={this.hideSidebar} />
        </ul>*/}

      </div>
    );
  }
}

export default SidebarContent;
