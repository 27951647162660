import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Table } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Server } from '../../../../modules/stellar/index';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { shortAddr } from '../../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';


class TableOps extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      operations: [],
    };
  }

  componentDidMount() {
    this.getOperations();
  }

  getOperations() {

    var $this = this;

    Server.operations()
    .limit(10)
    .order('desc')
    .call()
    .then(function(res){
      $this.streamOperations(res.records[0].paging_token);
      $this.setState({operations: res.records});
    });

  }

  streamOperations(token) {
    var $this = this;
    var ops = [];
    var op = Server.operations().order('asc').limit(10).cursor(token);

    op.stream({
      onmessage: function(data) {
        ops.unshift(data);
        if(ops.length === 10) {
          $this.setState({operations: ops});
          // if(!$this.state.loadOp) {
          //   $this.setState({loadOp: true});
          // }
          ops = [];
        }

      }
    });
  }

  renderOperations(item, index) {
    return (
      <tr key={index}>
        <td>
          <Link to={`/operation/${item.id}`} ><b>{ shortAddr(item.id, 4) }</b></Link>
        </td>
        <td>
          <Link to={`/transaction/${item.transaction_hash}`} ><b>{ shortAddr(item.transaction_hash, 4) }</b></Link>
        </td>
        <td> { item.type } </td>
        <td> { moment(item.created_at).format("DD MMMM YYYY HH:mm:ss") } </td>
      </tr>
      );
  }


  render() {

    return (
      <Col md={12} lg={12} xl={6}>
        <Card>
          <CardBody>
            {
              !this.state.operations.length ?
                <div className="panel__refresh"><LoadingIcon /></div>
              : null
            }
            <div className="card__title">
              <h5 className="bold-text">Last 10 Stellar Operations</h5>
              {/*<h5 className="subhead">Use default table with property <span className="red-text">striped</span></h5>*/}
            </div>
            <Table striped responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>TX Hash</th>
                  <th>Type</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody style={{fontSize: 13}}>
                {
                  this.state.operations.map(this.renderOperations)
                }
                {/*<tr>
                  <td>1</td>
                  <td>Maria</td>
                  <td>Morisson</td>
                  <td>@dragon</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Bobby</td>
                  <td>Brown</td>
                  <td>@boboby</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Alexander</td>
                  <td>Medinberg</td>
                  <td>@medimedi</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Vera</td>
                  <td>Lori</td>
                  <td>@lori</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Maria</td>
                  <td>Morisson</td>
                  <td>@dragon</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Bobby</td>
                  <td>Brown</td>
                  <td>@boboby</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Alexander</td>
                  <td>Medinberg</td>
                  <td>@medimedi</td>
                </tr>*/}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    );
  }
}


TableOps.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate('common')(TableOps);
