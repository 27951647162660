import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Server } from '../../../../modules/stellar/index';
import moment from 'moment';
import { shortAddr } from '../../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';


class TableTxs extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
    };
  }

  componentDidMount() {
    this.getTransactions();
  }


  getTransactions() {
    let $this = this;
    Server.transactions()
    .limit(10)
    .order('desc')
    .call()
    .then(function(res){
      $this.streamTransactions(res.records[0].paging_token);
      $this.setState({transactions: res.records});
    });
  }

  streamTransactions(token) {
    var $this = this;
    var txs = [];
    var ts = Server.transactions().order('asc').cursor(token);

    ts.stream({
      onmessage: function(data) {
        txs.unshift(data);
        if(txs.length === 10) {
          $this.setState({transactions: txs});
          // if(!$this.state.loadTx) {
          //   $this.setState({loadTx: true});
          // }
          txs = [];
        }
      }
    });
  }


  renderTransactions(item, index) {

    return (
      <tr key={index}>
        <td>
          <Link to={`/transaction/${item.hash}`} ><b>{ shortAddr(item.hash, 4) }</b></Link>
        </td>
        <td>
          <Link to={`/account/${item.source_account}`} ><b>{ shortAddr(item.source_account, 4) }</b></Link>
        </td>
        <td> { item.operation_count } </td>
        <td> { moment(item.created_at).format("DD MMMM YYYY HH:mm:ss") } </td>
      </tr>
      );
  }

  render() {

    return (
      <Col md={12} lg={12} xl={6}>
        <Card>
          <CardBody>
            {
              !this.state.transactions.length ?
                <div className="panel__refresh"><LoadingIcon /></div>
              : null
            }
            <div className="card__title">
              <h5 className="bold-text">Last 10 Stellar Transactions</h5>
              {/*<h5 className="subhead">Use default table with property <span className="red-text">striped</span></h5>*/}
            </div>
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Hash</th>
                  <th>Account</th>
                  <th>Operations</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody style={{fontSize: 13}}>
                {
                  this.state.transactions.map(this.renderTransactions)
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    );
  }
}


TableTxs.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate('common')(TableTxs);
