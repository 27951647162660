import React, { PureComponent } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Modal,
  UncontrolledTooltip,
  TabPane, TabContent, Nav, NavItem, NavLink
} from 'reactstrap';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  getIconConnectProvider,
  getTitle,
  numFormat,
  shortAddr,
  get_loc_wallets,
  baseReserve,
  minBalance,
  getAsset,
  showErrorMessage,
  getSigner,
  itemType,
  formatDate,
  shortAddress,
  checkConnectProvider, signConnectProvider
} from '../../../modules/index';
import Footer from '../../Layout/footer/Footer';
import {ToastContainer, ToastStore} from "react-toasts";
import {Field, reduxForm} from "redux-form";
import MenuItem from "@material-ui/core/MenuItem";
import iconIdent from "../Account/components/iconIdent";
import TextField from "@material-ui/core/TextField";
import Collapse from '../../../shared/components/Collapse';
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/core";
import LoadingIcon from "mdi-react/LoadingIcon";
import {getMarketAssets} from "../../../endpoints/API";
import PropagateLoader from 'react-spinners/PropagateLoader'
import classnames from "classnames";
import {GLOBE} from "../../../modules/globeVars";
import axios from "axios";
import StellarSdk from 'stellar-sdk';
import {Server} from "../../../modules/stellar";
import { strictReceivePaths, strictSendPaths, getOrderbook, getOperationForTransaction } from '../../../endpoints/StellarAPI'
import swal from "sweetalert";


const loaderStyle = css`
  display: inline-block;
  position: absolute;
  width: 40px;
  top: 0;
  left: 5px;
`;

const  loaderPrice = css`
  margin-bottom: -25px;
`

const loaderAccounts = css`
  text-align: center;
  display: inline-block;
`


const renderTextField = ({
                           input, label, meta: { touched, error }, children, select, type,
                         }) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);




class Swap extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      wallets: get_loc_wallets(),
      wallets_loaded: false,
      trader_balances: [],
      select_account: false,
      select_address: '',
      show_assets: false,
      select_asset_type: '',
      activeTabAssets: '1',
      search_asset_code: '',
      assets_db: [],
      base_asset: {
        asset_code: 'XLM',
        asset_issuer: 'native',
        name: 'Stellar',
        image: '/img/logo/stellar-logo-gray.png',
        home_domain: 'stellar.org',
      },
      counter_asset: {
        asset_code: 'USDC',
        asset_issuer: 'GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN',
        name: 'USDC Coin',
        image: 'https://www.centre.io/images/usdc/usdc-icon-86074d9d49.png',
        home_domain: 'centre.io',
      },
      native_asset: {
        asset_code: 'XLM',
        asset_issuer: 'native',
        name: 'Stellar',
        image: '/img/logo/stellar-logo.png',
        home_domain: 'stellar.org',
      },
      base_balance: 0,
      counter_balance: 0,
      base_accept: 0,
      counter_accept: 0,
      destination: '',
      memo: '',
      path: [],
      path_loading: false,
      path_send_loading: false,
      path_receive_loading: false,
      min_amount: 0,
      max_amount: 0,
      orderbook_pair: false,
      current_price: 0,
      base_asset_code: '',
      counter_asset_code: '',
      price_impact: 0,
      loader: false,
      hash: '',
      base_amount: 0,
      counter_amount: 0,
      class01: 'secondary',
      class02: 'secondary',
      operation: false,
      source_amount: 0,
    }
  }

  wallets_arr = []
  page_count = 0;
  doneTypingInterval = 1000;
  typingTimer = 0


  componentDidMount() {
    getTitle(`Swap Assets on Stellar`);
    this.getAssetsDB(0)
    this.getAllAccounts()

    // this.getAccount(this.state.wallets[0], 'one')
  }


  getAllAccounts = () => {

    if (this.state.wallets) {
      this.state.wallets.forEach((item, index) => {
        this.getAccount(item);
      })
    }
  }


  getUrl() {
    const pathArray = window.location.pathname.split('/');

    if (pathArray.length >= 5 || pathArray[3] !== '') {
      this.setStateFromUrl(pathArray)
    }
  }


  setStateFromUrl(pathArray) {

    const splitPair = pathArray[2].split('-');

    const pair = {
      base_code: splitPair[0],
      counter_code: splitPair[1],
    }

    const issuers = {
      base_issuer: pathArray[3],
      counter_issuer: pathArray[4],
    }

    const counter_asset = {
      asset_code: pair.counter_code,
      asset_issuer: issuers.counter_issuer,
    }
    const base_asset = {
      asset_code: pair.base_code,
      asset_issuer: issuers.base_issuer,
    }

    this.setStatePair(counter_asset, base_asset)
    // this.getOrderbookPrice(base_asset, counter_asset)
  }


  setStatePair(counter_asset, base_asset) {

    this.setState({
      base_asset: (base_asset.asset_issuer === 'native') ? this.state.native_asset : this.state.assets_db.filter(item => item.asset_code === base_asset.asset_code && item.asset_issuer === base_asset.asset_issuer)[0],
      counter_asset: (counter_asset.asset_issuer === 'native') ? this.state.native_asset : this.state.assets_db.filter(item => item.asset_code === counter_asset.asset_code && item.asset_issuer === counter_asset.asset_issuer)[0],
      base_asset_code: base_asset.asset_code,
      counter_asset_code: counter_asset.asset_code,
    },
      () => this.getPricePair())

    const title = `${counter_asset.asset_code}-${base_asset.asset_code} swap on Stellar`
    getTitle(title);
    window.document.querySelector('meta[property="og:title"]').setAttribute("content", title);

    // ...
  }


  getAccount(item, type) {

    Server.loadAccount(!type ? item.pk : item)
      .then((account) => {
        // console.log('account: ', account)

        let sponsored = account.num_sponsored;
        let sponsoring = account.num_sponsoring;
        const sponsorReserve = (Number(sponsored) + Number(sponsoring))

        item.account = account;
        item.balance = account.balances[account.balances.length-1].balance
        item.reserve  = ((account.subentry_count + sponsorReserve) * baseReserve()) + minBalance()
        item.avaliable = (item.balance - item.reserve).toFixed(2);

        if (type === 'one') {

          const { base_asset, counter_asset } = this.state

          account.balances.map(balance => {

            if (balance.asset_type === 'native') {
              balance.asset_code = 'XLM'
              balance.asset_issuer = 'native'
            }
            if (base_asset.asset_code === balance.asset_code && base_asset.asset_issuer === balance.asset_issuer) {
              this.setState({ base_balance: balance.balance })
            }
            if (counter_asset.asset_code === balance.asset_code && counter_asset.asset_issuer === balance.asset_issuer) {
              this.setState({ counter_balance: balance.balance })
            }
          })

          const wallets = this.state.wallets.map(wallet => {
            if (wallet.pk === item.pk) {
              wallet = item
            }
            return wallet
          })

          this.setState({ wallets: [] }, () => this.setState({ wallets }))

          return
        }

        this.wallets_arr.push(item);

        if (this.state.wallets.length === this.wallets_arr.length) {
          newState();
        }
      })
      .catch((err) => {
        console.error(err);

        this.wallets_arr.push(item)
        if (this.state.wallets.length === this.wallets_arr.length) {
          newState();
        }
      })

    const newState = () => {

      this.setState({
        wallets: this.wallets_arr,
        wallets_loaded: true,
      }, () => {
        if (this.state.select_account) {
          this.selectAccount(this.state.select_address)
        }
      })
    }
  }



  getAssetsDB = (page) => {

    let limit = 80;
    if (!page) {
      var page = 0;
    }

    getMarketAssets(page, limit)
      .then(result => {
        // console.log('getMarketAssets result: ', result)
        // const counter_asset = result.data[0];

        if (result.data && result.data.length) {
          this.setState({
            assets_db: this.state.assets_db.concat(result.data),
          },
            () => this.getPricePair())

          if (this.props.match.params.id) {
            this.getUrl()
          }
        }
      })
      .catch(error => {
        console.log('error: ', error)
      })
  }


  selectAccount = (address) => {

    const { base_asset, counter_asset } = this.state

    this.setState({
      base_balance: 0,
      counter_balance: 0,
      select_address: address,
    })

    const select_account = this.state.wallets.filter((item) => {
      return item && address === item.pk;
    })[0];

    if (!select_account.account) return

    const account = select_account.account;

    account.balances.forEach((item, index) => {
      // console.log('item: ', item)

      if (item.asset_type === 'native') {
        item.asset_code = 'XLM';
        item.asset_issuer = 'native';

        const sponsored = account.num_sponsored;
        const sponsoring = account.num_sponsoring;
        const sponsorReserve = (Number(sponsored) + Number(sponsoring))
        item.reserve  = (((account.subentry_count + sponsorReserve) * baseReserve()) + minBalance())
        item.avaliable = (item.balance - item.reserve).toFixed(2);

        this.setState({
          balance_native: item.balance,
          balance_reserve: item.reserve,
        })
      }

      if (base_asset.asset_code === item.asset_code && base_asset.asset_issuer === item.asset_issuer) {
        this.setState({ base_balance: item.balance })
      }
      if (counter_asset.asset_code === item.asset_code && counter_asset.asset_issuer === item.asset_issuer) {
        this.setState({ counter_balance: item.balance })
      }
    })

    this.setState({
      select_account: select_account[0],
      loading: true,
      base_accept: account.balances.some(item => base_asset.asset_code === item.asset_code && base_asset.asset_issuer === item.asset_issuer),
      counter_accept: account.balances.some(item => counter_asset.asset_code === item.asset_code && counter_asset.asset_issuer === item.asset_issuer),
    },
    () => {
      // ...
    })
  }


  toggleAssets = (tab) => {
    if (this.state.activeTabAssets !== tab) {
      this.setState({
        activeTabAssets: tab,
      });
    }
  };

  changeTrader = (value) => {

    let address = '';
    for (const key of Object.keys(value)) {
      if (key < 56) {
        address = (address + value[key])
      }
      else {
        this.selectAccount(address)
      }
    }
  }


  getPriceImpact = () => {

    // const price_impact = (this.state.counter_amount * this.state.min_amount / 1000).toFixed(2)
    // console.log('price_impact: ', price_impact)
    // this.setState({ price_impact })
  }

  changeBaseAmount = (value) => {

    this.props.change('counter_amount', '')
    const source_amount = Number(value.target.value).toFixed(7);

    if (source_amount > 0) {
      this.setState({ source_amount })
      // this.setBaseAmount(source_amount)
    }
  }

  changeCounterAmount = (value) => {
    this.props.change('base_amount', '')
    const counter_amount = Number(value.target.value).toFixed(7);

    if (counter_amount > 0) {
      this.setState({ counter_amount })
      // this.setCounterAmount(counter_amount)
    }
  }


  setBaseAmount = (amount) => {

    this.props.change('counter_amount', '')

    const source_asset = (this.state.base_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native()
      : new StellarSdk.Asset(this.state.base_asset.asset_code, this.state.base_asset.asset_issuer)
    const destination_asset = (this.state.counter_asset.asset_issuer === 'native') ? [new StellarSdk.Asset.native()] : [new StellarSdk.Asset(this.state.counter_asset.asset_code, this.state.counter_asset.asset_issuer)]

    this.setState({
      base_amount: amount,
      counter_amount: 0,
      path_send_loading: true,
      path_loading: true,
      min_amount: 0,
      max_amount: 0,
      select_asset_type: 'base_asset',
      path: [],
    })

    strictSendPaths(source_asset, amount, destination_asset).then(result => {
      // console.log('strictSendPaths result: ', result)

      if (result && result.records.length) {
        const min_amount = result.records[result.records.length-1].destination_amount
        const counter_amount = result.records[0].destination_amount

        this.props.change('counter_amount', counter_amount)

        this.setState({
          counter_amount,
          min_amount,
          path: result.records[0].path,
          path_send_loading: false,
          path_loading: false,
        },
        () =>  this.getPriceImpact())
      }
    }).catch(error => {
      console.log('error: ', error)
      this.setState({
        path_send_loading: false,
        path_loading: false,
      })
    })
  }


  setCounterAmount = (amount) => {

    this.props.change('base_amount', '')

    this.setState({
      counter_amount: amount,
      base_amount: 0,
      path_receive_loading: true,
      path_loading: true,
      min_amount: 0,
      max_amount: 0,
      select_asset_type: 'counter_asset',
      path: [],
    })

    const source_asset = [(this.state.base_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native()
      : new StellarSdk.Asset(this.state.base_asset.asset_code, this.state.base_asset.asset_issuer)]
    const destination_asset = (this.state.counter_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native() : new StellarSdk.Asset(this.state.counter_asset.asset_code, this.state.counter_asset.asset_issuer)

    strictReceivePaths(source_asset, destination_asset, amount.toString()).then(result => {
      // console.log('strictReceivePaths result: ', result)

      if (result && result.records.length) {
        const base_amount = Number(result.records[0].source_amount)
        const max_amount = result.records[result.records.length-1].source_amount

        this.props.change('base_amount', base_amount)

        this.setState({
          base_amount,
          max_amount,
          path: result.records[0].path,
          path_receive_loading: false,
          path_loading: false,
        },
        () =>  this.getPriceImpact())
      }
    }).catch(error => {
      console.log('error: ', error)
      this.setState({
        path_receive_loading: false,
        path_loading: false,
      })
    })
  }

  changeSelectBaseAsset = (asset) => {
    this.setState({
      show_assets: !this.state.show_assets,
      select_asset_type: 'base_asset',
      min_amount: 0,
      max_amount: 0,
      class01: 'secondary',
      class02: 'secondary',
    })
  }

  changeSelectCounterAsset = (asset) => {
    this.setState({
      show_assets: !this.state.show_assets,
      select_asset_type: 'counter_asset',
      min_amount: 0,
      max_amount: 0,
      class01: 'secondary',
      class02: 'secondary',
    })
  }


  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  }

  toggleSelectAssets = () => {
    this.setState({ show_assets: !this.state.show_assets })
  }

  searchAssetForm = (values) => {
    this.searchAsset(values.search_asset)
  }

  searchAssetChange = (value) => {
    this.setState({
      search_asset_code: value.target.value
    })
    this.searchAsset(value.target.value)
  }


  searchAssetClick = () => {
    // this.searchAsset(this.state.search_asset_code)
  }


  searchAsset = (assetCode) => {
    // console.log('searchAsset: ', assetCode)

    if (!assetCode || assetCode === '') {
      this.setState({
        assets_db: [],
        stellar_assets: [],
      })
      this.getAssetsDB(0)
      ToastStore.warning('Enter the name of the Asset');
    }
    else {

      const url = `${GLOBE.API_URL}/get_assets_code_issuer/?asset_code=${assetCode}&asset_issuer=`;
      axios.post(url)
        .then(res => {
          // console.log('res: ', res)

          if (res.data.length > 0) {
            this.setState({
              assets_db: res.data,
            })
          }
          else {
            ToastStore.info('Asset not found');
          }
        })
    }
  }


  loadMoreAssetsDB = () => {
    this.page_count+=1;
    this.getAssetsDB(this.page_count);
  }


  changeUrl = () => {
    const url = `${this.state.base_asset.asset_code}-${this.state.counter_asset.asset_code}/${this.state.base_asset.asset_issuer}/${this.state.counter_asset.asset_issuer}`
    this.props.history.push({
      pathname: `/swap/${url}`,
    });
  }


  clickItemAsset = (e, item) => {

    e.preventDefault()
    // console.log('item: ', item)

    const reversePairMatch = () => {
      this.reversePair()
      this.toggleSelectAssets()
    }

    if (item === 'native' && this.state.base_asset.asset_issuer === item) { //  || this.state.counter_asset.asset_issuer === item
      // ToastStore.warning('This asset has already been selected');
      reversePairMatch()
      return
    }
    if (this.state.base_asset.asset_code === item.asset_code && this.state.base_asset.asset_issuer === item.asset_issuer) {
      // ToastStore.warning('This asset has already been selected');
      reversePairMatch()
      return
    }
    if (this.state.counter_asset.asset_code === item.asset_code && this.state.counter_asset.asset_issuer === item.asset_issuer) {
      // ToastStore.warning('This asset has already been selected');
      reversePairMatch()
      return
    }


    if (this.state.select_asset_type === 'base_asset') {
      this.setState({
        base_asset: item,
        counter_amount: 0,
      }, () => {
        this.changeUrl()
        this.state.base_amount && this.setBaseAmount(this.state.base_amount)
      })
    }
    if (this.state.select_asset_type === 'counter_asset') {
      this.setState({
        counter_asset: item,
        base_amount: 0,
      }, () => {
        this.changeUrl()
        this.state.counter_amount && this.setCounterAmount(this.state.counter_amount)
      })
    }

    this.toggleSelectAssets()

    setTimeout(() => {

      // this.getOrderbookPrice()
      this.getPricePair()

      if (this.state.select_address) {
        this.selectAccount(this.state.select_address)
      }
    }, 100)
  }


  getPricePair = () => {

    this.setState({
      current_price: false,
      // path_receive_loading: true,
      path_loading: true,
      min_amount: 0,
    })

    const source_amount = 1;
    const source_asset = (this.state.base_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native() : new StellarSdk.Asset(this.state.base_asset.asset_code, this.state.base_asset.asset_issuer)
    const destination_asset = (this.state.counter_asset.asset_issuer === 'native') ? [new StellarSdk.Asset.native()] : [new StellarSdk.Asset(this.state.counter_asset.asset_code, this.state.counter_asset.asset_issuer)]

    strictSendPaths(source_asset, source_amount, destination_asset).then(result => {
      // console.log('strictSendPaths result: ', result)

      if (result && result.records.length) {
        const current_price = (result.records[0].source_amount / result.records[0].destination_amount).toFixed(7)

        this.setState({
            start_path_found: result.records,
            current_price,
            base_asset_code: this.state.base_asset.asset_code,
            counter_asset_code: this.state.counter_asset.asset_code,
          },
          // () =>  this.getPriceImpact()
        )
      }
    })
  }


  // getOrderbookPrice = () => {
  //
  //   const base_asset = (this.state.base_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native() : new StellarSdk.Asset(this.state.base_asset.asset_code, this.state.base_asset.asset_issuer)
  //   const counter_asset = (this.state.counter_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native() : new StellarSdk.Asset(this.state.counter_asset.asset_code, this.state.counter_asset.asset_issuer)
  //
  //   getOrderbook(base_asset, counter_asset, 1).then(result => {
  //     console.log('getOrderbook result: ', result)
  //
  //     const current_price = result.bids.length ? (result.bids[0].price_r.d / result.bids[0].price_r.n).toFixed(7) : false
  //
  //     this.setState({
  //       orderbook_pair: result,
  //       current_price,
  //       base_asset_code: this.state.base_asset.asset_code,
  //       counter_asset_code: this.state.counter_asset.asset_code,
  //     })
  //   })
  // }



  changeDestination = (value) => {
    // console.log('changeDestination value: ', value.target.value)

    if (StellarSdk.StrKey.isValidEd25519PublicKey(value.target.value)) { // stellar valid
      this.setState({ destination: value.target.value })
    }
    else {
      ToastStore.warning('Invalid Stellar address');
    }
  }


  changeMemo = (value) => {
    // console.log('changeMemo value: ', value.target.value)

    let memo = value.target.value

    if (memo.length > 28) {
      memo = memo.substr(0, 28)

      setTimeout(() => {
        this.props.change('memo', memo)
      }, 200)
    }
    else {
      this.setState({ memo })
    }
  }


  clickSwap = () => {

    // console.log('clickSwap state: ', this.state)

    let { select_asset_type, base_amount, base_asset, counter_amount, counter_asset, select_address, min_amount, max_amount, path, base_accept, counter_accept, destination } = this.state

    if (!select_address) {
      ToastStore.warning('Account not selected');
      return
    }
    if (!base_amount || !counter_amount) {
      ToastStore.warning('No amount selected');
      return
    }

    const confirmAddTrustline = (asset, type) => {

      const message = `<div>The selected account does not have a Trustline to <b class="text-warning">${asset.asset_code}</b>. <br> Add Trustline for <b class="text-warning">${asset.asset_code}</b> Now?</div>`
      const element = document.createElement("span");
      element.innerHTML = message;

      swal({
        title: "Confirm Trustline",
        content: element,
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
        .then((confirm) => {
          if (confirm) {
            if (type === 'base_asset') {
              this.setTrustline(base_asset, type)
            }
            if (type === 'counter_asset') {
              this.setTrustline(counter_asset, type)
            }
          }
        })
    }

    if (!base_accept) {
      confirmAddTrustline(base_asset, 'base_asset')
      return
    }
    if (!counter_accept) {
      confirmAddTrustline(counter_asset, 'counter_asset')
      return
    }

    let sendAsset, sendMax, sendAmount, destMin, destAsset, destAmount;

    sendAsset = (base_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native() : new StellarSdk.Asset(base_asset.asset_code, base_asset.asset_issuer)
    destAsset = (counter_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native() : new StellarSdk.Asset(counter_asset.asset_code, counter_asset.asset_issuer)
    destination = (destination ? destination : select_address)
    path = path.map(item => {
      if (item.asset_type === 'native') {
        return new StellarSdk.Asset.native()
      }
      else {
        return new StellarSdk.Asset(item.asset_code, item.asset_issuer)
      }
    })

    // PathPaymentStrictSend
    if (select_asset_type === 'base_asset') {
      sendAmount = base_amount.toString()
      destMin = min_amount.toString() // min_amount
    }

    // PathPaymentStrictReceive
    if (select_asset_type === 'counter_asset') {
      sendMax = max_amount.toString()
      destAmount = counter_amount.toString()
    }


    const message = `<div>Swap <b class="text-info">${numFormat(select_asset_type === 'base_asset' ? base_amount : max_amount, 7)}</b> <b class="text-warning">${this.state.base_asset.asset_code}</b> to <b class="text-info">${numFormat(counter_amount, 7)}</b> <b class="text-warning">${this.state.counter_asset.asset_code}</b> right now?</div>`;
    const element = document.createElement("span");
    element.innerHTML = message;

    swal({
      title: "Confirm",
      content: element,
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then((confirm) => {
        if (confirm) {
          this.setPathPayment(sendAsset, sendMax, sendAmount, destMin, destination, destAsset, destAmount, path, select_asset_type)
        }
      })
  }



  setTrustline = (asset, type) => {

    const { select_address } = this.state

    this.setState({ loader: true })

    Server.loadAccount(select_address)
      .then((sourceAccount) => {

        var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee: StellarSdk.BASE_FEE,
          networkPassphrase: StellarSdk.Networks.PUBLIC
        })
          .addOperation(StellarSdk.Operation.changeTrust({
            asset: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
          }))
          .setTimeout(100)
          .build();

        if (checkConnectProvider(select_address)) {
          return signConnectProvider(transaction, select_address).then(result => {
            return Server.submitTransaction(result);
          })
        }
        else {
          // transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));
          if (!getSigner(select_address).sk || !window.atob(getSigner(select_address).sk)) return
          const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(select_address).sk))
          transaction.sign(signer);

          return Server.submitTransaction(transaction);
        }

        // if (!getSigner(address).sk || !window.atob(getSigner(address).sk)) return
        // const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(address).sk))
        //
        // transaction.sign(signer);
        //
        // return Server.submitTransaction(transaction);
      })
      .then((result) => {

        if (result) {
          this.setState({ loader: false })

          if (type === 'base_asset') {
            this.setState({ base_accept: true })
          }
          if (type === 'counter_asset') {
            this.setState({ counter_accept: true })
          }

          swal({
            title: `Added Trustline!`,
            text: `Trustline to ${asset.asset_code} has been successfully added. Now ${asset.asset_code} is available on your balance.`,
            icon: "success",
          })

          this.getAccount(this.state.select_account, 'one')
        }
      })
      .catch((error) => {
        console.error('Something went wrong!', error);

        showErrorMessage(error, 'change_trust')

        this.setState({ loader: false })
        ToastStore.error('Transaction error');
      });
  }



  setPathPayment = (sendAsset, sendMax, sendAmount, destMin, destination, destAsset, destAmount, path, type) => {

    const { select_address } = this.state

    this.setState({ loader: true })

    Server.loadAccount(select_address)
      .then((sourceAccount) => {

        let transaction

        if (type === 'base_asset') {
          transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: StellarSdk.BASE_FEE,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
            .addOperation(StellarSdk.Operation.pathPaymentStrictSend({ sendAsset, sendAmount, destination, destAsset, destMin, path }))
            .addMemo(StellarSdk.Memo.text(this.state.memo ? this.state.memo : ''))
            .setTimeout(100)
            .build();
        }

        if (type === 'counter_asset') {
          transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: StellarSdk.BASE_FEE,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
            .addOperation(StellarSdk.Operation.pathPaymentStrictReceive({ sendAsset, sendMax, destination, destAsset, destAmount, path }))
            .addMemo(StellarSdk.Memo.text(this.state.memo ? this.state.memo : ''))
            .setTimeout(100)
            .build();
        }

        if (checkConnectProvider(select_address)) {
          return signConnectProvider(transaction, select_address).then(result => {
            return Server.submitTransaction(result);
          })
        }
        else {

          const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(select_address).sk))
          transaction.sign(signer);

          return Server.submitTransaction(transaction);
        }
      })
      .then((result) => {

        if (result) {

          this.setState({
            loader: false,
            hash: result.hash,
            operation: false,
          })

          const message = `<b class="text-warning">${this.state.base_asset.asset_code}</b> to <b class="text-warning">${this.state.counter_asset.asset_code}</b><br><br><small>hash: <a href="/transaction/${result.hash}" target="_blank"><b>${shortAddr(result.hash, 4)}</b></a></small>`
          const element = document.createElement("span");
          element.innerHTML = message;

          swal({
            title: `Successful Swap!`,
            content: element,
            icon: "success",
          })

          this.getOperation(result.hash)
          this.getAccount(this.state.select_account, 'one')

          // Save to DB ...
        }
      })
      .catch((error) => {
        console.error('Error! ', error);

        this.setState({ loader: false })
        showErrorMessage(error, 'path_payment')
      });
  }


  reversePair = (e) => {
    e && e.preventDefault()

    const { base_balance, counter_balance, base_asset, counter_asset, current_price, base_amount, counter_amount } = this.state

    this.setState({
      base_asset: counter_asset,
      counter_asset: base_asset,
      base_balance: counter_balance,
      counter_balance: base_balance,
      min_amount: 0,
      max_amount: 0,
      current_price: (1 / current_price).toFixed(7),
      base_asset_code: counter_asset.asset_code,
      counter_asset_code: base_asset.asset_code,
    },
      () => {

        this.changeUrl()

        if (base_amount) {

          this.setState({
            counter_amount: base_amount,
            select_asset_type: 'counter_asset',
            min_amount: counter_amount,
          },
            () => {
              this.props.change('counter_amount', Number(base_amount))
              this.setCounterAmount(base_amount)
            })

        }
      })
  }


  reversePrice = (e) => {
    e.preventDefault()

    const base_price = (this.state.start_path_found[0].destination_amount)
    const counter_price = (this.state.start_path_found[0].source_amount / this.state.start_path_found[0].destination_amount).toFixed(7)

    this.setState({
      current_price: (this.state.counter_asset.asset_code === this.state.counter_asset_code ? base_price : counter_price),
      base_asset_code: this.state.counter_asset_code,
      counter_asset_code: this.state.base_asset_code,
      class01: 'secondary',
      class02: 'secondary',
    })
  }


  setSlippage = (value) => {

    const { select_asset_type, base_amount, min_amount, counter_amount } = this.state


    if (value === 0.1) {
      this.setState({ class01: 'primary', class02: 'secondary' })
    }
    if (value === 0.5) {
      this.setState({ class01: 'secondary', class02: 'primary' })
    }
    if (value === 'custom') {
      this.setState({ class01: 'secondary', class02: 'secondary' })
    }

    if (select_asset_type === 'base_asset' && value !== 'custom') {
      this.setState({
        min_amount: (Number(min_amount) - (Number(min_amount) * value / 100)).toFixed(7)
      })
    }
    if (select_asset_type === 'counter_asset') {
      this.setState({
        max_amount: (Number(base_amount) + (Number(base_amount) * value / 100)).toFixed(7)
      })
    }
  }


  refreshPair = (e) => {
    e.preventDefault()

    this.setState({
      class01: 'secondary',
      class02: 'secondary',
    })

    if (this.state.select_asset_type === 'base_asset') {
      this.setState({
        counter_amount: 0,
      })
      this.setBaseAmount(this.state.base_amount)
    }
    if (this.state.select_asset_type === 'counter_asset') {
      this.setState({
        base_amount: 0,
      })
      this.setCounterAmount(this.state.counter_amount)
    }
  }


  getOperation = (hash) => {

    getOperationForTransaction(hash, 1).then(result => {
      if (result) {
        this.setState({
          operation: result.records[0],
        })
      }
    }).catch(error => {
        console.log('error: ', error)
      })
  }


  renderFirstAsset = (item) => {
    return <Link to={item.source_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.source_asset_code}-XLM/${item.source_asset_issuer}/native`} className="text-warning"> <b>{item.source_asset_type === 'native' ? 'XLM' : item.source_asset_code}</b></Link>
  }


  closeLastSwap = () => {
    this.setState({ hash: false })
  }


  clearAdvanced = (e, type) => {
    e.preventDefault()

    if (type === 'destination') {
      this.setState({ destination: false })
      this.props.change('destination', '')
    }
    if (type === 'memo') {
      this.setState({ memo: false })
      this.props.change('memo', '')
    }
  }




  keyUpInput = (type) => {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => this.doneTyping(type), this.doneTypingInterval)
  }

  keyDownInput = () => {
    clearTimeout(this.typingTimer);
  }

  doneTyping = (type) => {
    const { source_amount, counter_amount } = this.state

    if (type === 'base') {
      this.setBaseAmount(source_amount)
    }
    if (type === 'counter') {
      this.setCounterAmount(counter_amount)
    }
  }



  render() {

    console.log('this.state: ', this.state)

    const { handleSubmit } = this.props;
    const { base_asset, counter_asset, base_amount, counter_amount, native_asset, base_balance,
      counter_balance, select_asset_type, base_accept, counter_accept, select_address,
      operation,
    } = this.state

    return (
      <Container className="dashboard swap">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {/*{ this.renderModal() }*/}

        {/*<Row>*/}
        {/*  <Col md={{size:12}}>*/}
        {/*    <h3 className="page-title"><i className={'fa fa-exchange'}></i> Swap</h3>
            <h4 className="subhead" style={{marginBottom: '18px'}}>
              Swap Swap Swap Swap Swap Swap. Reach out to <b>Swap</b>.
            </h4>
        {/*  </Col>*/}
        {/*</Row>*/}


        <Row>
          <Col md={{size: 4, offset: 4}}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Asset Swap</h5>
                </div>

                { this.state.loader ? <div className="panel__refresh" style={{height: '95%'}}><LoadingIcon /></div> : null }

                {
                  this.state.wallets && !this.state.wallets_loaded ?
                    <div className={'text-center'}>
                      <PropagateLoader
                        css={loaderAccounts}
                        color={'#80808014'}
                        loading={true}
                        size={9}
                      />
                    </div>
                    : null
                }

                <form className="material-form">
                  <div>
                    {
                      this.state.wallets ?

                        <Field
                          name="select_addr"
                          component={renderTextField}
                          select
                          label="Select Account"
                          onChange={(e) => {
                            this.changeTrader(e)
                          }} >
                          {
                            this.state.wallets.length ? this.state.wallets.map((item, index) => {

                              if (item) {
                                return (
                                  <MenuItem
                                    key={index}
                                    style={{fontSize: '12px'}}
                                    className={`material-form__option ${item.provider ? 'connect-provider' : ''}`}
                                    value={item.pk}>
                                    <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 4)}</b> <small>&nbsp;/ {item.title} / <b>{item.balance ? numFormat(item.balance, 7) : null} XLM</b></small> { getIconConnectProvider(item) }
                                  </MenuItem>
                                )
                              }
                            }) : null
                          }
                        </Field>
                        : null
                    }
                  </div>
                </form>

                <br/>

                {
                  this.state.show_assets ?
                    <div>
                      <div className="card__title" style={{marginBottom: 19}}>
                        <h5 className="bold-text">
                          <a href={'#'} onClick={this.toggleSelectAssets} className="panel__btn" type="button">
                            <i className="fa fa-arrow-left"></i>
                          </a> &nbsp;
                          Select Asset
                        </h5>
                      </div>
                      {/*<div className="panel__btns">*/}
                      {/*  <div className={'panel__btn'} style={{marginTop: -3}}>*/}
                      {/*    <a href="#" onClick={this.toggleSelectAssets} className=" text-secondary">*/}
                      {/*      <i className="fa fa-close"></i>*/}
                      {/*    </a>*/}
                      {/*  </div>*/}
                      {/*</div>*/}

                      <div className="tabs tabs--justify tabs--bordered-bottom">
                        <div className="tabs__wrap">
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.activeTabAssets === '1' })}
                                onClick={() => {
                                  this.toggleAssets('1');
                                }}
                              >
                                <b>ALL ASSETS</b>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.activeTabAssets === '2' })}
                                onClick={() => {
                                  this.toggleAssets('2');
                                }}
                              >
                                <b>MY ASSETS</b>
                              </NavLink>
                            </NavItem>
                          </Nav>

                          <TabContent activeTab={this.state.activeTabAssets} style={{height: 470}}>
                            <TabPane tabId="1">

                              <form className="form modal-form" onSubmit={handleSubmit(this.searchAssetForm)}>
                                <div className="form__form-group">
                                  <div className="form__form-group-field">
                                    <Field
                                      name="search_asset"
                                      component="input"
                                      type="text"
                                      placeholder="Asset Code"
                                      onChange={this.searchAssetChange}
                                    />
                                    <button
                                      type="button"
                                      className={`form__form-group-button`}
                                      onClick={this.searchAssetClick}
                                    ><i className="fa fa-search"></i></button>
                                  </div>
                                </div>
                              </form>

                              {/*{*/}
                              {/*  this.state.select_asset_type === 'base_asset' && this.state.base_asset.asset_issuer !== 'native' ?*/}
                              {/*    <a href={`#`} onClick={(e) => this.clickItemAsset(e, 'native')}>*/}
                              {/*      <Card style={{paddingBottom: 6}}>*/}
                              {/*        <CardBody className="item-asset text-secondary">*/}
                              {/*          <div className="wr-asset-item-icon">*/}
                              {/*            <img className="asset-item-icon" src={this.state.native_asset.image} />*/}
                              {/*          </div>*/}
                              {/*          <b className="text-info" style={{fontSize: 15}}>{this.state.native_asset.asset_code}</b> <small><b>{this.state.native_asset.name}</b> / <span className={'text-info'}>{this.state.native_asset.home_domain}</span></small> <span style={{float: 'right'}}> <small className={`text-${this.state.changeColor}`}><b>{this.state.change > 0 ? '+' : ''}{numFormat(this.state.change, 2)}%</b></small></span>*/}
                              {/*        </CardBody>*/}
                              {/*      </Card>*/}
                              {/*    </a>*/}
                              {/*    : null*/}
                              {/*}*/}

                              {/*<div style={{height: 476}}>*/}
                              {/*             */}
                              {/*</div>       */}

                              <a href={`#`} onClick={(e) => this.clickItemAsset(e, native_asset)}>
                                <Card style={{paddingBottom: 6}}>
                                  <CardBody className="item-asset text-secondary">
                                    <div className="wr-asset-item-icon">
                                      <img className="asset-item-icon" src={native_asset.image} />
                                    </div>
                                    <b className="text-info" style={{fontSize: 17}}>{native_asset.asset_code}</b> <small><b>{native_asset.name}</b> / <span>{native_asset.home_domain}</span></small>
                                    {/*<span style={{float: 'right'}}> <small className={`text-${this.state.changeColor}`}><b>{this.state.change > 0 ? '+' : ''}{numFormat(this.state.change, 2)}%</b></small></span>*/}
                                    <div>
                                      <span className="text-secondary"><small><i className={'fa fa-trophy'}></i> <b>native</b></small></span>
                                      <span style={{float: 'right'}}><small><b></b></small></span>
                                    </div>
                                  </CardBody>
                                </Card>
                              </a>


                              {
                                this.state.select_asset_type === 'base_asset' ?
                                  this.state.assets_db.length ?
                                    this.state.assets_db.map((item, index) => {

                                      return (
                                        <a key={index}
                                           href={'#'}
                                           onClick={(e) => this.clickItemAsset(e, item)}
                                        >
                                          <Card style={{paddingBottom: 6}}>
                                            <CardBody className="item-asset text-secondary">
                                              <div className="wr-asset-item-icon">
                                                <img className="asset-item-icon" src={item.image === 'undefined' || item.image === '' ? '/img/digitalcoin.png' : item.image} />
                                              </div>
                                              <b className={'text-info'} style={{fontSize: 17}}>{item.asset_code}</b> <small><b>{item.name}</b> / <span>{item.home_domain}</span></small>
                                              <div style={{marginTop: -4}}>
                                                <span className="text-secondary"><small><i className={'fa fa-bar-chart'}></i> <b>{numFormat(item.base_volume)}</b> XLM</small> &nbsp;&nbsp; <small><i className={'fa fa-user'}></i> <b>{numFormat(item.holders)}</b></small> &nbsp;&nbsp; <small><i className={'fa fa-trophy'}></i> <b>{item.expert_rating}</b></small></span>
                                                <span style={{float: 'right'}}><small className={`text-${item.change_24h_color}`}><b>{item.change24}%</b></small></span>
                                              </div>
                                            </CardBody>
                                          </Card>
                                        </a>
                                      )
                                    })
                                    : <div className="panel__refresh"><LoadingIcon /></div>
                                  : null
                              }


                              {
                                this.state.select_asset_type === 'counter_asset' ?
                                  this.state.assets_db.length ?
                                    this.state.assets_db.map((item, index) => {
                                      // console.log('item: ', item)

                                      return (
                                        <a key={index}
                                           href={'#'}
                                           onClick={(e) => this.clickItemAsset(e, item)}
                                        >
                                          <Card style={{paddingBottom: 6}}>
                                            <CardBody className="item-asset text-secondary">
                                              <div className="wr-asset-item-icon">
                                                <img className="asset-item-icon" src={item.image === 'undefined' || item.image === '' ? '/img/digitalcoin.png' : item.image} />
                                              </div>
                                              <b className="text-info" style={{fontSize: 17}}>{item.asset_code}</b> <small><b>{item.name}</b> / <span className={'text-info'}>{item.home_domain}</span></small>
                                              <div>
                                                <span className="text-secondary"><small><i className={'fa fa-bar-chart'}></i> <b className={'text-info'}>{numFormat(item.base_volume)}</b> XLM</small> &nbsp;&nbsp; <small><i className={'fa fa-users'}></i> <b className={'text-info'}>{numFormat(item.holders)}</b></small> &nbsp;&nbsp; <small><i className={'fa fa-trophy'}></i> <b className={'text-info'}>{item.expert_rating}</b></small></span>
                                                <span style={{float: 'right'}}><small className={`text-${item.change_24h_color}`}><b>{item.change24}%</b></small></span>
                                              </div>
                                            </CardBody>
                                          </Card>
                                        </a>
                                      )
                                    })
                                    : <div className="panel__refresh"><LoadingIcon /></div>
                                  : null
                              }

                              {
                                !this.state.search_asset_code ?
                                  <div className="text-center">
                                    <Button
                                      size="sm"
                                      style={{margin: '13px 0 0'}}
                                      onClick={() => this.loadMoreAssetsDB()}>Load more</Button>
                                  </div>
                                  : null
                              }
                            </TabPane>

                            <TabPane tabId="2">
                              {
                                this.state.account ?
                                  this.state.account.balances.map((item, index) => {
                                    return (
                                      <div key={index}>
                                        {
                                          item.asset_type != 'native' ?
                                            <a href={'#'}
                                               onClick={(e) => this.clickItemAsset(e, item)}
                                            >
                                              {/*<a href={`/trade/${item.asset_code}-${this.state.base_asset.asset_code}/${item.asset_issuer}/${this.state.base_asset.asset_issuer}`}*/}
                                              {/*>*/}
                                              <Card style={{paddingBottom: 6}}>
                                                <CardBody className="item-asset text-secondary">
                                                  <b className="text-warning" style={{fontSize: 14}}>{item.asset_code}</b> <small>(<b className="text-info">{numFormat(item.balance, 7)}</b>)</small> <span style={{float: 'right'}}><small> <font className="text-danger">selling</font> <b className="text-info">{numFormat(item.selling_liabilities, 7)}</b> / <font className="text-success">buying</font> <b className="text-info">{numFormat(item.buying_liabilities, 7)}</b></small></span>
                                                </CardBody>
                                              </Card>
                                            </a>
                                            :
                                            null
                                        }
                                      </div>
                                    )
                                  })
                                  :
                                  <p>Select Account to view all Balances (Assets)</p>
                              }
                            </TabPane>
                          </TabContent>
                        </div>
                      </div>

                    </div>

                  : // next condition

                    <div>

                      <form className={'form'}>
                        <div className="form__form-group swap-item">
                          <span className="form__form-group-label">From { select_asset_type === 'counter_asset' ? <small>(estimate)</small> : (base_amount ? <small>(max)</small> : null) }</span> {' '}
                          <span style={{position: "relative"}}>
                            <BeatLoader
                              css={loaderStyle}
                              color={"#70bbfd"}
                              loading={this.state.path_receive_loading}
                              size={8}
                            />
                          </span>
                          <small className={'balance-label text-secondary'}>
                            {
                              select_address ?
                                base_accept  ?
                                  <span>
                                    {/*<i className={'fa fa-check-circle-o text-success'}></i> {' '}*/}
                                    <b className={'text-info'}>{numFormat(base_balance, 7)}</b> { base_asset.asset_code }
                                  </span>
                                :
                                  <span><i className={'fa fa-minus-circle text-secondary'}></i> No Trustline</span>
                              :
                                <span>Account not selected</span>
                            }
                          </small>
                          <div className="form__form-group-field">
                            <Field
                              name="base_amount"
                              className={'input-swap'}
                              component="input"
                              type="text"
                              placeholder="0.00"
                              onChange={this.changeBaseAmount}
                              onKeyUp={() => this.keyUpInput('base')}
                              onKeyDown={this.keyDownInput}
                              disabled={this.state.path_receive_loading}
                            />
                            <div className="form__form-group-icon select-asset-icon">
                              <a href="#"
                                 className={'text-secondary'}
                                 onClick={this.changeSelectBaseAsset}>
                                <div>
                                  <img src={base_asset.image}
                                       className={'icon-asset'}
                                       alt={base_asset.name}
                                  />
                                  <b className={'swap-asset-code'}>{base_asset.asset_code}</b>
                                  <div className={'swap-home-domain'}>{base_asset.home_domain}</div>
                                </div>
                                <div className={'arrow'}><i className="fa fa-angle-down"></i></div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className={'reverse-assets'}>
                        <a href="#" onClick={this.reversePair} className={'text-secondary'}><i className="fa fa-arrow-down"></i></a>
                        {
                          base_amount && counter_amount ?
                            <span>
                              &nbsp;&nbsp;
                              <a href="#"
                                 onClick={(e) => this.refreshPair(e)}
                                 style={{color: '#6c757d61'}}><i className="fa fa-refresh"></i></a>
                            </span>
                          : null
                        }
                      </div>

                      <form className="form" onSubmit={handleSubmit}>

                        <div className="form__form-group swap-item">
                          <span className="form__form-group-label">To { select_asset_type === 'base_asset' ? <small>(estimate)</small> : (counter_amount ? <small>(max)</small> : null) } </span> {' '}
                          <span style={{position: "relative"}}>
                            <BeatLoader
                              css={loaderStyle}
                              color={"#70bbfd"}
                              loading={this.state.path_send_loading}
                              size={8}
                            />
                          </span>

                          <small className={'balance-label text-secondary'}>
                            {
                              select_address ?
                                counter_accept ?
                                  <span>
                                    {/*<i className={'fa fa-check-circle-o text-success'}></i> {' '}*/}
                                    <b className={'text-info'}>{numFormat(counter_balance, 7)}</b> { counter_asset.asset_code }
                                  </span>
                                :
                                  <span><i className={'fa fa-minus-circle text-secondary'}></i> No Trustline</span>
                              :
                                <span>Account not selected</span>
                            }
                          </small>

                          <div className="form__form-group-field">
                            <Field
                              name="counter_amount"
                              className={'input-swap'}
                              component="input"
                              type="text"
                              placeholder="0.00"
                              onChange={this.changeCounterAmount}
                              onKeyUp={() => this.keyUpInput('counter')}
                              onKeyDown={this.keyDownInput}
                              disabled={this.state.path_send_loading}
                            />
                            <div className="form__form-group-icon select-asset-icon">
                              <a href="#"
                                 className={'text-secondary'}
                                 onClick={this.changeSelectCounterAsset}>
                                <div>
                                  <img src={counter_asset.image}
                                       className={'icon-asset'}
                                       alt={counter_asset.name}
                                  />
                                  <b className={'swap-asset-code'}>{counter_asset.asset_code}</b>
                                  <div className={'swap-home-domain'}>{counter_asset.home_domain}</div>
                                </div>
                                <div className={'arrow'}><i className="fa fa-angle-down"></i></div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>


                      {
                        this.state.current_price ? //
                          <div className={'text-right current-price'}>
                            <small className={'text-secondary'}>1 {this.state.counter_asset_code} = <b>{this.state.current_price}</b> {this.state.base_asset_code} &nbsp;
                              <a href="#" onClick={this.reversePrice}> <i className="fa fa-refresh text-secondary"></i></a>
                            </small>
                          </div>
                        :
                          <div className={'text-right current-price'}>
                            <BeatLoader
                              css={loaderPrice}
                              color={"#70bbfd"}
                              loading={!this.state.current_price}
                              size={8}
                            />
                          </div>

                      }


                      <small>
                        <Collapse title="Advanced" type={'small'}>

                          <Collapse title="Other Destination" type={'small'}>
                            <form className={'form'}>
                              <div className="form__form-group">
                                <div className="form__form-group-field">
                                  <Field
                                    name="destination"
                                    component="input"
                                    type="text"
                                    placeholder="Destination address"
                                    className={'br-5'}
                                    onBlur={this.changeDestination}
                                  />
                                  <div className="clear-icon">
                                    <a href="#" onClick={(e) => this.clearAdvanced(e, 'destination')}><i className={'fa fa-close'}></i></a>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </Collapse>

                          <Collapse title={`Memo ${this.state.memo ? `(${this.state.memo.length}/28)` : ''}`} type={'small'}>
                            <form className={'form'}>
                              <div className="form__form-group">
                                <div className="form__form-group-field">
                                  <Field
                                    name="memo"
                                    component="input"
                                    type="text"
                                    placeholder="Memo"
                                    className={'br-5'}
                                    onChange={this.changeMemo}
                                  />
                                  <div className="clear-icon">
                                    <a href="#" onClick={(e) => this.clearAdvanced(e, 'memo')}><i className={'fa fa-close'}></i></a>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </Collapse>

                        </Collapse>
                      </small>

                      <ButtonToolbar className="form__button-toolbar" style={{width: '100%'}}>
                        {
                          this.state.wallets ?
                            <Button onClick={this.clickSwap}
                                    color="primary"
                                    size={'lg'}
                                    // disabled={this.state.path_loading}
                                    style={{width: '100%'}}>Swap</Button>
                            :
                            <Link to={'/auth/connect'}
                                  className={'btn btn-secondary btn-lg'}
                                  style={{width: '100%'}}>Connect Wallet</Link>
                        }
                      </ButtonToolbar>


                      {
                        this.state.base_amount || this.state.counter_amount ?
                          <Row>
                            <Col md={{size: 12}}>

                              <div className={'stats-block'}>

                                <p>Swap type <a href={null}  id="swap-type"><i className="fa fa-question-circle-o text-secondary"></i></a>
                                  <UncontrolledTooltip placement="top" target="swap-type">
                                    {
                                      select_asset_type === 'base_asset' ?
                                        <span>A Path Payment Strict Send allows a user to specify the amount of the asset to send. The <b><i>amount received</i></b> will vary based on offers in the order books.</span>
                                        :
                                        <span> A Path Payment Strict Receive allows a user to specify the amount of the asset received. The <b><i>amount sent</i></b> varies based on offers in the order books.</span>
                                    }
                                  </UncontrolledTooltip>
                                  <span className={'swap-data'}>
                                    <span>{select_asset_type === 'base_asset' ? 'Strict Send' : 'Strict Receive'}</span>
                                  </span>
                                </p>

                                <p>Fee <a href={null}  id="swap-type"><i className="fa fa-question-circle-o text-secondary"></i></a>
                                  <UncontrolledTooltip placement="top" target="swap-type">
                                    <span>To prevent ledger spam and maintain the efficiency of the network, Stellar requires small transaction
                                      fees and minimum balances on accounts. Transaction fees are also used to prioritize transactions when
                                      the network enters surge pricing mode.</span>
                                  </UncontrolledTooltip>
                                  <span className={'swap-data'}>
                                    <small>≈ $0.0001</small>
                                  </span>
                                </p>

                                <p>{select_asset_type === 'base_asset' ? 'Minimum received' : 'Maximum send'}   <a href={null} id="min-receive"><i className="fa fa-question-circle-o text-secondary"></i></a>
                                  <UncontrolledTooltip placement="top" target="min-receive">
                                    This is the minimum amount of asset you`ll receive after a swap.
                                  </UncontrolledTooltip>
                                  {
                                    this.state.min_amount || this.state.max_amount ?
                                      <span className={'swap-data'}><b>{(select_asset_type === 'base_asset' ? numFormat(this.state.min_amount, 7) : numFormat(this.state.max_amount, 7)) }</b> {' '}
                                        {/*{ select_asset_type === 'base_asset' ? counter_asset.asset_code : base_asset.asset_code }*/}
                                      </span>
                                    :
                                      <div className={'swap-data'}>
                                        <BeatLoader
                                          color={"#70bbfd"}
                                          loading={true}
                                          size={8}
                                        />
                                      </div>
                                  }
                                </p>
                                {/*<p>Price impact <a href="#" id="price-impact"><i className="fa fa-question-circle-o text-secondary"></i></a>*/}
                                {/*  <UncontrolledTooltip placement="top" target="price-impact">*/}
                                {/*    The percentage difference between the last order price of a pair of assets and the price a which your order will be completed at.*/}
                                {/*  </UncontrolledTooltip>*/}
                                {/*  {*/}
                                {/*    this.state.price_impact ?*/}
                                {/*      <div className={'swap-data success-impact'}>{this.state.price_impact}%</div>*/}
                                {/*    :*/}
                                {/*      <div className={'swap-data'}>*/}
                                {/*        <BeatLoader*/}
                                {/*          color={"#70bbfd"}*/}
                                {/*          loading={true}*/}
                                {/*          size={8}*/}
                                {/*        />*/}
                                {/*      </div>*/}
                                {/*  }*/}
                                {/*</p>*/}

                                <p>Slippage tolerance <a href={null} id="slippage"><i className="fa fa-question-circle-o text-secondary"></i></a>
                                  <UncontrolledTooltip placement="top" target="slippage">
                                    Defines the minimum amount of assets your want to receive after a swap. Higher percentages will reduce the
                                    minimum amount off assets you`ll receive from the swap.
                                  </UncontrolledTooltip>
                                  <span className={'swap-data'}>
                                    <button onClick={() => this.setSlippage(0.1)}
                                            type="button"
                                            className={`slippage-tolerance btn btn-${this.state.class01} btn-sm`}><small>0.1%</small></button>
                                    <button onClick={() => this.setSlippage(0.5)}
                                            type="button"
                                            className={`slippage-tolerance btn btn-${this.state.class02} btn-sm`}><small>0.5%</small></button>
                                    <input onClick={() => this.setSlippage('custom')}
                                           className={'custom-percent'}
                                           name={'custom_slippage'}
                                           onChange={(e) => this.setSlippage(e.target.value)}
                                           type="text"
                                           placeholder={'custom %'}/>
                                  </span>
                                </p>

                                <p>
                                  Path <a href={null} id="path-swap"><i className="fa fa-question-circle-o text-secondary"></i></a>
                                  <UncontrolledTooltip placement="top" target="path-swap">
                                    The path is the best possible way to swap assets based on the order books and the liquidity rates on the Stellar network.
                                  </UncontrolledTooltip>
                                  <span className={'swap-data'} style={{marginTop: -18}}>
                                    {/*path*/}

                                    {
                                      !this.state.path_loading ?
                                        <span>
                                          <span><b className={'text-secondary'}>{base_asset.asset_code}</b> <i className="fa fa-angle-right" ></i></span> {' '}
                                          {
                                            this.state.path.length ?
                                              this.state.path.map((item, index) => {
                                                return (
                                                  <span key={index}><a href={item.asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.asset_code}-XLM/${item.asset_issuer}/native`} className="text-secondary" target={'_blank'}><b>{item.asset_type === 'native' ? 'XLM' : item.asset_code}</b></a> <i className="fa fa-angle-right"></i> </span>
                                                )
                                              })
                                              : null
                                          }
                                          <span><b className={'text-secondary'}>{counter_asset.asset_code}</b></span>
                                        </span>
                                      :
                                        <div className={'swap-data'}>
                                          <BeatLoader
                                            color={"#70bbfd"}
                                            loading={true}
                                            size={8}
                                          />
                                        </div>
                                    }
                                  </span>
                                </p>
                              </div>
                            </Col>
                          </Row>
                        : null
                      }

                    </div>
                }

              </CardBody>
            </Card>

          </Col>

          <Col md={{size: 4, offset: 4}}>
            {
              this.state.hash ?
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Last Swap</h5>
                    </div>
                    <div className="panel__btns">
                      <button className="panel__btn text-secondary" type="button" onClick={this.closeLastSwap}>
                        <i className={'fa fa-close'}></i>
                      </button>
                    </div>
                    <div className={'stats-block'}>

                      <p>
                        <span>Transaction:</span>
                        <span className={'swap-data'}>
                          <b><a href={`/transaction/${this.state.hash}`}>{ shortAddr(this.state.hash, 4) }</a></b>
                        </span>
                      </p>

                      { !operation ? <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> : null }

                      {
                        operation ?
                          <span>
                            <p>
                              <span>Operation:</span>
                              <span className={'swap-data'}>
                                <b><a href={`/operation/${operation.id}`}>{ shortAddr(operation.id, 4) }</a></b>
                              </span>
                            </p>

                            <p>
                              <span>Type:</span>
                              <span className={'swap-data'}>
                                <b className="text-info">{ itemType(operation.type) }</b>
                              </span>
                            </p>
                            <p>
                              <span>Date:</span>
                              <span className={'swap-data'}>
                                <small>{formatDate(operation.created_at)}</small>
                              </span>
                            </p>
                            <p>
                              <span>{shortAddress(operation.from)} sent:</span>
                              <span className={'swap-data'}>
                                <b className="text-info">{ numFormat(operation.source_amount, 7) }</b> {this.renderFirstAsset(operation)}
                              </span>
                            </p>
                            <p>
                              <span>{shortAddress(operation.to)} received:</span>
                              <span className={'swap-data'}>
                                <b className="text-info">{ numFormat(operation.amount, 7) }</b> { getAsset(operation) }
                              </span>
                            </p>
                            <p>
                              <span>Path:</span>
                              <span className={'swap-data'}>
                                { this.renderFirstAsset(operation) } <i className="fa fa-angle-right"></i> {' '}
                                {
                                  operation.path.map((pathItem, index) => {
                                    return (
                                      <b key={ index }> {getAsset(pathItem)} { operation.path.length > (index+1) ? <span> <i className="fa fa-angle-right"></i> </span> : '' }</b>
                                    )
                                  })
                                }
                                {' '} <i className="fa fa-angle-right"></i> {getAsset(operation)} {' '}
                              </span>
                            </p>
                          </span>
                        : null
                      }

                    </div>
                  </CardBody>
                </Card>
                : null
            }
          </Col>
        </Row>


        <Footer />

      </Container>
    )
  }
}



export default reduxForm({
  form: 'Swap', // a unique identifier for this form
})(translate('common')(Swap));
