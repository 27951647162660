import React, { PureComponent } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import StellarSdk from 'stellar-sdk';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { ToastContainer, ToastStore } from 'react-toasts';
import Footer from '../../Layout/footer/Footer';
import { getTitle, searchProcess } from '../../../modules/index';
import LoadingIcon from "mdi-react/LoadingIcon";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
} from 'reactstrap';



class Search extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      activeTab: '1',
      search_value: false,
      loading: false,
    };
  }

  componentDidMount() {  

    const title = 'Block Explorer for Stellar Network';
    const description = 'The powerful and detailed Block Explorer for Stellar allows you to receive and analyze all possible combinations of data and transactions from the blockchain.';
    getTitle(title, description);
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };


  parseUrl() {
    let part = this.props.match.params.id.split('-');
    let asset = {
      asset_code: part[0],
      asset_issuer: part[1],
    }
    return asset;
  }


  handleSearchClick = () => {
    this.startSearch(this.state.search_value)
  }


  searchChange = (value) => {
    this.setState({
      search_value: value.target.value
    })
  }


  submitSearchForm = (values) => {
    this.startSearch(values.search)
  }


  startSearch = (value) => {

    if (!value) {
      ToastStore.warning('Paste value');
    }
    else {
      this.setState({loading: true})
      searchProcess(value)
    }
  }



  render() {

    const { handleSubmit, t } = this.props;

    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} /> 

        <Row>
          <Col md={{size:8, offset:2}}>
            <h3 className="page-title">Block Explorer</h3>
            <h4 className="subhead" style={{marginBottom: '18px'}}>
              Search and detailed data analytics from <a href="https://stellar.org" target='_blank' rel='noopener noreferrer'>Stellar</a>
            </h4>
          </Col>
        </Row>

        <Row>
          <Col md={{size:8, offset:2}}>
            <Card>
              <CardBody>

                <div className={'text-center text-secondary'}>
                  <i className="fa fa-search search-logo-wr">
                    <img src="/img/logo/stellar-logo-gray.png" alt="Stellar" className={'search-stellar-logo'}/>
                  </i>

                </div>
                <br/><br/><br/>

                {
                  this.state.loading ?
                    <div className="panel__refresh"><LoadingIcon /></div> : null
                }

                <form className="form" onSubmit={handleSubmit(this.submitSearchForm)}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Enter your search query</span>
                    <div className="form__form-group-field">
                      <Field
                        name="search"
                        component="input"
                        type="text"
                        placeholder="Address, Asset, Transaction, Operation, Offer, Federation"
                        onChange={this.searchChange}
                      />
                      <button
                        type="button"
                        className={`form__form-group-button`}
                        style={{width: '90px'}}
                        onClick={this.handleSearchClick}
                      ><i className="fa fa-search" aria-hidden="true"></i></button>
                    </div>
                  </div>
                </form>
                <br/>
                <p>Scopuly Block Explorer allows you to find any combination of data from Stellar Blockckain in a simple, understandable and readable form.</p>
                <p><small>Here you can search for full information by: <b>Addresses</b>, <b>Assets</b>, <b>Transactions</b>, <b>Operations</b>, <b>Offers</b> and <b>Federation name</b>.</small></p>
                <br/>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Footer />

      </Container>
    )
  }
}

Search.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'vertical_form_layout', // a unique identifier for this form
})(translate('common')(Search));
