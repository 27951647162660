import React, { PureComponent } from 'react';
import {
  Col, Container, Row, Card, CardBody, UncontrolledCarousel, Button, ButtonToolbar, Nav,
  NavItem, NavLink, TabContent, TabPane, Table
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar';
// import axios from 'axios';
// import { GLOBE } from '../../../modules/globeVars/index';
// import LoadingIcon from 'mdi-react/LoadingIcon';
// import moment from 'moment';
import Footer from '../../Layout/footer/Footer';
import {
  get_loc_wallets, getTitle, numFormat, getSigner, getClaimantStatus, shortAddr,
  getIconConnectProvider, checkAuth, baseReserve, minBalance, formatDate, showErrorMessage, shortAddress, getAsset,
} from '../../../modules';
import {getPendingPayments} from '../../../endpoints/StellarAPI'
import { isScamer, setClaimant } from '../../../endpoints/API'
import WrapClaimBalances from "./WrapClaimBalances";
import {Field, reduxForm} from "redux-form";
import MenuItem from "@material-ui/core/MenuItem";
import iconIdent from "../Account/components/iconIdent";
import TextField from "@material-ui/core/TextField";
import DatePicker from "react-datepicker";
import classnames from "classnames";
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
// import Payments from "../Account/components/Effects/Payments";
// import Trades from "../Account/components/Effects/Trades";
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from "sweetalert";
import PacmanLoader from "react-spinners/PacmanLoader";
import BarLoader from "react-spinners/BarLoader";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import { massiveReClaims } from './components/massiveReClaims'

const override = css`
  border-color: #418fba;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 8px;
`;

const override_loader = css`
  border-color: #418fba;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 8px;
`;



const renderTextField = ({
                           input, label, meta: { touched, error }, children, select, type,
                         }) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);




class CreateClaimableBalance extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      duration_sec: 60, // 86400/24h *** 172800/48h/2d ***
      sent_claimants: 0,
      sent_amount: 0,
      sponsor: '',
      sponsor_claimants: [],
      loading: false,
      loading_submite: false,
      next: true,
      sponsor_balances: false,
      is_auth: false,
      claim_amount: 0.1,
      select_asset: {
        code: '',
        issuer: ''
      },
      wallets: get_loc_wallets(),
      loading_accounts: false,
      load_select_assets: true,
      select_addr: '',
      select_start_time: new Date(),
      select_end_time: '', // new Date('10/30/2021')
      start_time: '',
      lasts_time: '',
      activeTab: '1',
      claim_all: false,
      returned_balances: 0,
      returned_amount: 0,
      disabled_claim: false,
      disabled_start: false,
      claim_process: false,
      airdrop_process: false,
      steamTrades: '',
      streamPayments: '',
      streamEffects: '',
      reserve_balance: 0,
      checking_addresses: 0,
      arrayClaimants: [],
      previous_set: [],
      memo: '',
      show_claimants: false,
      sent_percent: 0,
      hash: false,
    };
  }

  claimCounter = 0
  stream = true
  reClaimants = []
  sponsor_claimants_length = 0
  scop = {
    code: 'SCOP',
    issuer: 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ',
  }
  items_slider = [{
    src: `${process.env.PUBLIC_URL}/img/slider/5.png`,
    altText: 'Create Claimable Balances',
  }];
  wallets_arr = [];
  opLimit = 10
  reclaim_limit = 50
  mass_claim = false
  loadClaimArr = false
  checking_addresses = 0
  reserveClaimants = []



  componentDidMount() {

    const title = 'Create Claimable Balance on Stellar';
    const description = 'Create Claimable on Stellar';
    const address = this.props.match.params.id

    getTitle(title, description);

    // this.claimableBalances()
    // this.getSponsorClaimants(true, true) // false / true
    // this.effectsStream()
    // this.streamTrades()
    // this.streamPayments()

    this.getLocalData()

    if (address) {
      this.props.change('select_addr', address)
      this.setState({ sponsor: address })
      this.getSponsorClaimants(true)
    }

    if (this.state.wallets) {
      this.state.wallets.forEach((item, index) => {
        this.getAccount(item, index);
      })
    }
  }


  getLocalData = () => {

    let set_claim = localStorage.getItem('set_claim')
    set_claim = set_claim ? JSON.parse(set_claim) : false
    console.log('set_claim: ', set_claim)

    if (set_claim) {

      this.setState({
        claim_amount: set_claim.claim_amount,
        memo: set_claim.memo,
        select_asset: set_claim.select_asset,
        select_end_time: new Date(set_claim.select_end_time),
      }, () => {

        this.props.change('memo', set_claim.memo)
        this.props.change('amount', set_claim.claim_amount)
        this.props.change('select_asset', `${set_claim.select_asset.code}-${set_claim.select_asset.issuer}`)
        // this.props.change('End', set_claim.select_end_time)
      })
    }
  }


  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };


  streamActiveAddresses = () => {
    this.streamTrades()
    this.streamPayments()
    this.effectsStream()

    // setTimeout(() => {
    //   this.effectsStream()
    //
    //   // setTimeout(() => {
    //   //   this.effectsStream()
    //   // }, 10000)
    //
    // }, 10000)
  }


  effectsStream = () => {

    const streamEffects = Server
      .effects()
      .cursor("now")
      .stream({ onmessage: resp => {
        // console.log('effectsStream: ', resp.type);

          if (resp.type !== 'trade' || resp.type !== 'account_debited' || resp.type !== 'account_credited' ||
            resp.type !== 'account_removed' || resp.type !== 'signer_created' || resp.type !== 'account_created' ||
            resp.type !== 'claimable_balance_claimant_created' || resp.type !== 'claimable_balance_sponsorship_created' ||
            resp.type !== 'claimable_balance_sponsorship_removed' || resp.type !== 'claimable_balance_created') {
            if (this.state.arrayClaimants.length < this.opLimit) {
              this.checkClaimant(resp.account)
            }
          }

        // if (resp.type === 'liquidity_pool_created' ||
        //     resp.type === 'liquidity_pool_removed' ||
        //     resp.type === 'liquidity_pool_revoked' ||
        //     resp.type === 'liquidity_pool_deposited' ||
        //     resp.type === 'liquidity_pool_withdraw') {
        //
        //   // alert(1)
        //
        //   console.log('effectsStream: ', resp);
        // }
      }});

    this.setState({ streamEffects })
  }


  streamTrades = () => {

    const steamTrades = Server
      .trades()
      .cursor("now")
      .stream({ onmessage: trade => {
          // console.log('trade: ', trade)
        if (this.state.arrayClaimants.length < this.opLimit) {

          if (trade.base_account) { this.checkClaimant(trade.base_account) }
          if (trade.counter_account !== trade.base_account) { this.checkClaimant(trade.counter_account) }
        }
      }});

    this.setState({ steamTrades })
  }


  streamPayments = () => {

    const streamPayments = Server
      .payments()
      .cursor("now")
      .stream({ onmessage: payment => {
          // console.log('streamPayment: ', payment)
          if (this.state.arrayClaimants.length < this.opLimit) {
            if (payment.from) { this.checkClaimant(payment.from) }
            if (payment.to !== payment.from) { this.checkClaimant(payment.to) }
          }
        }});

    this.setState({ streamPayments })
  }



  getSponsorAccount = () => {

    Server
      .loadAccount(this.state.sponsor)
      .then( (account) => {
        console.log(account);

        const balance_native = account.balances[account.balances.length-1].balance

        if (this.state.select_asset.issuer) {

          const balance_token = account.balances.filter(item => item.asset_type !== 'native' && item.asset_code === this.state.select_asset.code && item.asset_issuer === this.state.select_asset.issuer)[0].balance
          let sponsored = account.num_sponsored;
          let sponsoring = account.num_sponsoring;
          const sponsorReserve = (Number(sponsored) + Number(sponsoring))
          const reserve_balance  = (((account.subentry_count + sponsorReserve) * baseReserve()) + minBalance())
          const available_native = (balance_native - reserve_balance).toFixed(7);

          this.setState({
            balance_native,
            available_native,
            balance_token,
            reserve_balance,
          })
        }
      })
      .catch(function (err) {
        console.error(err);
      });
  }



  checkClaimant = async (claimant) => {
    // console.log('claimant: ', claimant)

    if (!claimant) return

    this.checking_addresses = (this.checking_addresses + 1)

    const is_prevous = this.state.previous_set.some(item => item === claimant)
    if (is_prevous) {
      // console.log('IS PREVOUS: ', is_prevous)
      return
    }

    const isUnicClaimant = () => {
      const match = this.state.arrayClaimants.some(item => item === claimant)
      if (match) {
        // console.log('NOT UNIC: ', match)
        return false
      }
      return true
    }
    if (!isUnicClaimant()) return

    let scamers = localStorage.getItem('scamers')
    if (scamers) {
      scamers = JSON.parse(scamers)

      const is_scamer = scamers.some(item => item === shortAddr(claimant, 4))
      if (is_scamer) {
        // console.log('REMOVED TRUST: ', scamers)
        return
      }
    }
    else {
      scamers = []
    }


    const account = await Server.loadAccount(claimant).catch(function (err) {
      console.error(`Failed to load ${claimant}: ${err}`)
    })
    if (!account) { return }

    if (account.balances.length > 2) {

      const isTrustline = account.balances.some(item => item.asset_type !== 'native' && item.asset_code === this.state.select_asset.code && item.asset_issuer === this.state.select_asset.issuer)
      // console.log(`Trustline ${this.state.select_asset.code}: `, isTrustline)
      if (isTrustline) {
        return
      }

      getPendingPayments(claimant, 200, 'desc').then(result => {

        const asset = `${this.state.select_asset.code}:${this.state.select_asset.issuer}`
        const save_asset = `${this.state.select_asset.code}:${shortAddr(this.state.select_asset.issuer)}`

        if (result.records) {
          const claimantAsset = result.records.some(item => item.asset === asset)
          console.log(`is claimantAsset: `, claimantAsset)

          if (!claimantAsset) {

            isScamer(claimant, save_asset, 'get').then(result => {
              // console.log('scamer get result: ', result)

              if (result.data === 'not_found') {
                getEffects()
              }
            })

            const getEffects = () => {

              Server.effects()
                .forAccount(claimant)
                .limit(200)
                .order('desc')
                .call()
                .then(result => {

                  if (result.records.length < 50) { // result.records.reverse()[0].type === 'account_created'
                    // console.log('X NEW ACCOUNT X')
                    this.setState({ checking_addresses: this.checking_addresses })
                    return;
                  }

                  // if (result.records.some(item => item.type === 'account_created')) {
                  //   this.setState({ checking_addresses: this.checking_addresses })
                  //   return;
                  // }

                  // if (!result.records.some(item => item.type === 'trade' || item.type === 'account_debited'
                  //   || item.type === 'trustline_created' || item.type === 'liquidity_pool_trade')) {
                  //   this.setState({ checking_addresses: this.checking_addresses })
                  //   return;
                  // }

                  const isRemove = result.records.some(item => item.type === 'account_removed')
                  if (isRemove) return;

                  const isRemoveAsset = result.records.some(item => {

                    if (item.type === 'trustline_removed') {
                      if (item.asset_code === this.state.select_asset.code) {
                        // console.log(`${this.state.select_asset.code} REMOVED: `, item)

                        const scamersLength = scamers.unshift(shortAddr(claimant, 4))

                        if (scamersLength > 100) scamers.pop()

                        localStorage.setItem('scamers', JSON.stringify(scamers))
                        return true
                      }
                    }
                    return false
                  })

                  // console.log('isRemoveAsset: ', isRemoveAsset)

                  if (isRemoveAsset) {
                    isScamer(claimant, save_asset, 'set').then((result => {
                      console.log('scamer set result: ', result)
                    }))
                    return;
                  }

                  if (this.state.arrayClaimants.length < this.opLimit) {

                    if (!isUnicClaimant()) return

                    this.setState({
                        arrayClaimants: this.state.arrayClaimants.concat(claimant),
                        checking_addresses: this.checking_addresses,
                      },
                      () => {
                        // console.log('Claimants: ', this.state.arrayClaimants)

                        if (this.state.arrayClaimants.length === this.opLimit) {
                          console.log('=== CLAIMANTS READY ===')

                          this.createClaimableBalances()
                        }
                      })
                  }
                })
                .catch( (err) => {
                  console.error(`Failed to load transactions: ${err}`)
                })
            }

            // getEffects()
          }
        }
      })
    }
  }


  createClaimableBalances = async () => {

    const { sponsor, arrayClaimants, select_asset, claim_amount } = this.state

    if (!getSigner(sponsor).sk || !window.atob(getSigner(sponsor).sk)) return

    const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(sponsor).sk))

    const select_start_time = new Date(this.state.select_start_time);
    const select_end_time = new Date(this.state.select_end_time);
    const seconds = Math.trunc((select_end_time - select_start_time) / 1000)
    const end_time = StellarSdk.Claimant.predicateBeforeRelativeTime(seconds.toString());
    const soon = Math.ceil((Date.now() / 1000) + seconds); // .now() is in ms
    const canReclaim = StellarSdk.Claimant.predicateNot(StellarSdk.Claimant.predicateBeforeAbsoluteTime(soon.toString()));

    const claimantsMap = arrayClaimants.length < this.opLimit ? this.reserveClaimants : arrayClaimants

    const claimants = claimantsMap.map(claimant => {

      const claimants = [
        new StellarSdk.Claimant(claimant, end_time),
        new StellarSdk.Claimant(sponsor, canReclaim)
      ]

      const item = StellarSdk.Operation.createClaimableBalance({
        claimants: claimants,
        asset: new StellarSdk.Asset(select_asset.code, select_asset.issuer),
        amount: String(claim_amount),
      })

      return item
    })

    console.log('loadAccount: ', sponsor)

    this.setState({
      previous_set: arrayClaimants,
      arrayClaimants: [],
      loading_submite: true,
    })

    this.reserveClaimants = arrayClaimants

    const setClaim = async (claimants) => {

      const sponsorAccount = await Server.loadAccount(sponsor).catch(function (err) {
        console.error(`Failed to load sponsor ${sponsor}: ${err}`)
      })
      if (!sponsorAccount) { return }


      let tx = new StellarSdk.TransactionBuilder(sponsorAccount, {fee: StellarSdk.BASE_FEE})
        .addOperation(claimants[0])
        .addOperation(claimants[1])
        .addOperation(claimants[2])
        .addOperation(claimants[3])
        .addOperation(claimants[4])
        .addOperation(claimants[5])
        .addOperation(claimants[6])
        .addOperation(claimants[7])
        .addOperation(claimants[8])
        .addOperation(claimants[9])
        // .addOperation(claimants[10])
        // .addOperation(claimants[11])
        // .addOperation(claimants[12])
        // .addOperation(claimants[13])
        // .addOperation(claimants[14])
        // .addOperation(claimants[15])
        // .addOperation(claimants[16])
        // .addOperation(claimants[17])
        // .addOperation(claimants[18])
        // .addOperation(claimants[19])
        // .addOperation(claimants[20])
        // .addOperation(claimants[21])
        // .addOperation(claimants[22])
        // .addOperation(claimants[23])
        // .addOperation(claimants[24])
        // .addOperation(claimants[25])
        // .addOperation(claimants[26])
        // .addOperation(claimants[27])
        // .addOperation(claimants[28])
        // .addOperation(claimants[29])
        // .addOperation(claimants[30])
        // .addOperation(claimants[31])
        // .addOperation(claimants[32])
        // .addOperation(claimants[33])
        // .addOperation(claimants[34])
        // .addOperation(claimants[35])
        // .addOperation(claimants[36])
        // .addOperation(claimants[37])
        // .addOperation(claimants[38])
        // .addOperation(claimants[39])
        // .addOperation(claimants[40])
        // .addOperation(claimants[41])
        // .addOperation(claimants[42])
        // .addOperation(claimants[43])
        // .addOperation(claimants[44])
        // .addOperation(claimants[45])
        // .addOperation(claimants[46])
        // .addOperation(claimants[47])
        // .addOperation(claimants[48])
        // .addOperation(claimants[49])
        // .addOperation(claimants[50])
        // .addOperation(claimants[51])
        // .addOperation(claimants[52])
        // .addOperation(claimants[53])
        // .addOperation(claimants[54])
        // .addOperation(claimants[55])
        // .addOperation(claimants[56])
        // .addOperation(claimants[57])
        // .addOperation(claimants[58])
        // .addOperation(claimants[59])
        .setNetworkPassphrase(StellarSdk.Networks.PUBLIC)
        .addMemo(StellarSdk.Memo.text(this.state.memo)) // Scopuly Airdrop 🔥
        .setTimeout(180)
        .build();

      tx.sign(signer);

      console.log('|----------------------------/')
      console.log('tx: ', tx)
      console.log('|----------------------------/')

      // return

      Server.submitTransaction(tx).then(txResponse => {
        console.log("CLAIMABLE BALANCES CREATED! : ", txResponse.hash);
        ToastStore.success(`Create ${this.opLimit} claimable balances!`);

        const new_sent_claimants = (this.state.sent_claimants + this.opLimit)

        this.setState({
          sent_claimants: new_sent_claimants,
          sent_percent: (new_sent_claimants / this.state.checking_addresses * 100),
          sent_amount: (new_sent_claimants * this.state.claim_amount),
          loading_submite: false,
          hash: txResponse.hash,
        })

        // this.resetClaims()
        this.getLastsTime()
        this.getSponsorAccount()

        const asset = `${select_asset.code}:${shortAddr(select_asset.issuer)}`

        setClaimant(this.state.previous_set, asset).then(result => {
          console.log('setClaimant result: ', result)
        })

        // let txResult = StellarSdk.xdr.TransactionResult.fromXDR(txResponse.result_xdr, "base64");
        // let results = txResult.result().results();
        // let operationResult = results[0].value().createClaimableBalanceResult();
        // let balanceId = operationResult.balanceId().toXDR("hex");
        // console.log("Balance ID: ", balanceId);

        // Method 3: Account B could alternatively do something like:
        // let balances = await server
        //   .claimableBalances()
        //   .claimant(B.publicKey())
        //   .limit(1)       // there may be many in general
        //   .order("desc")  // so always get the latest one
        //   .call()
        //   .catch(function(err) {
        //     console.error(`Claimable balance retrieval failed: ${err}`)
        //   });
        // if (!balances) { return; }
        //
        // balanceId = balances.records[0].id;
        // console.log("Balance ID (3):", balanceId);
        //
      }).catch( (error) => {
        showErrorMessage(error, 'claim')
        this.setState({ loading_submite: false })
        setClaim(claimants)

        this.resetClaims()
        // this.stream = false
        // this.claimableBalances(claimants)
      });
    }

    setClaim(claimants)
  }


  resetClaims = () => {
    // this.claimants = []
    // this.setState({claimants: []})
    // this.count = 0
    this.stream = true
  }


  getSponsorClaimants = (is_loading = true) => {

    this.setState({ loading: is_loading })

    getPendingPayments(this.state.sponsor, 200, 'asc')
      .then(result => {
        console.log('getPendingPayments result: ', result)

        this.setState({ loading: false })
        this.claimCounter = 0

        const balances = result.records

        if (balances.length) {

          this.setState({sponsor_claimants: []},
            () => {
            this.setState({
              sponsor_claimants: [{
                account: this.state.sponsor,
                payments: balances,
              }],
            })
          })
        }
      })
      .catch(error => {
        console.log('error: ', error)
        this.setState({ loading: false })
      })
  }


  checkBalancesLength = () => {
    console.log('this.claimCounter: ', this.claimCounter)
    console.log('sponsor_claimants_length: ', this.sponsor_claimants_length)

    if (this.claimCounter > this.sponsor_claimants_length) {
      this.setState({ next: false })
      return false
    }
    return true
  }


  nextIteration = () => {

    if (!this.checkBalancesLength()) {
      alert('STOP!')
      return
    }

    if (this.mass_claim) {
      // this.claimCounter = (this.claimCounter + this.reclaim_limit) - 1
      // this.claimCounter = (this.claimCounter + this.reclaim_limit)
    }
    else {
      this.claimCounter++
    }

    this.claimBalance(this.state.sponsor_claimants[0].payments[this.claimCounter])
  }




  claimBalance = async (item) => {
    this.mass_claim = true
    // console.log('item: ', item)

    const { select_asset, sponsor_claimants, sponsor } = this.state
    const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(this.state.sponsor).sk))
    const claimants_length = this.state.sponsor_claimants[0].payments.length

    console.log('claimants_length: ', claimants_length)

    if (claimants_length >= this.reclaim_limit) {


      const claimants = sponsor_claimants[0].payments
      const amountItem = claimants[0].amount
      const filterClaimants = []
      const filterClaimants2 = []

      claimants.map((item, index) => {
        // console.log('item.claimants: ', item)

        const splitAsset = item.asset.split(':')
        const sponsorClaimant = item.claimants.filter(item => item.destination === this.state.sponsor)[0]
        const status = getClaimantStatus(item, sponsorClaimant).status

        if (status === 'Expired' && select_asset.code === splitAsset[0] && select_asset.issuer === splitAsset[1] && item.sponsor === this.state.sponsor) { // && item.destination === this.state.sponsor

          const claimBalance = StellarSdk.Operation.claimClaimableBalance({ balanceId: item.id });

          // console.log('this.loadClaimArr: ', this.loadClaimArr)
          // console.log('this.claimCounter: ', this.claimCounter)
          // console.log('index: ', index)

          if (!this.loadClaimArr && !this.claimCounter) {
            // if (index < this.reclaim_limit) {
            filterClaimants.push(claimBalance)
            filterClaimants2.push(sponsorClaimant)
            // }
          }
          else if (index > this.claimCounter) { // from Limit
            // if (claimants.length < this.reclaim_limit) {
            filterClaimants.push(claimBalance)
            filterClaimants2.push(sponsorClaimant)
            // }
          }
        }
      })

      console.log('filterClaimants: ', filterClaimants)
      console.log('filterClaimants2: ', filterClaimants2)

      if (filterClaimants.length < this.reclaim_limit) {
        this.setState({
          claim_process: false,
          disabled_start: false,
        })
        swal({
          title: "Done!",
          text: `Available claims returned.`,
          icon: "success",
        })
        return
      }

      if (!filterClaimants.length) {
        ToastStore.warning('No claim for return yet');
        return
      }

      this.setState({
        checking_addresses: this.claimCounter || this.reclaim_limit,
      })

      this.getSponsorClaimants(false)

      // return

      massiveReClaims(sponsor, filterClaimants, signer, this.reclaim_limit, this.loadClaimArr).then(result => { // (...claimants)
        console.log('massiveReClaims result: ', result)

        if (!result) {
          // this.getSponsorClaimants(false)
          this.loadClaimArr = false
          this.claimCounter = 0
          // this.claimCounter = this.claimCounter + this.reclaim_limit
          this.nextIteration()
          return
        }

        if (result !== 'failed_to_load_sponsor' && result.successful) {

          ToastStore.success('Claim returned!');

          this.claimCounter = this.claimCounter + this.reclaim_limit
          this.loadClaimArr = true
          this.nextIteration()

          // this.getSponsorClaimants(false)
          this.getLastsTime()
          this.getSponsorAccount()

          this.setState({
            returned_balances: (this.state.returned_balances + this.reclaim_limit),
            returned_amount: (Number(this.state.returned_amount) + Number(amountItem * this.claimCounter)),
          })
        }
      })
      return
    }

    if (!this.state.claim_process) {
      return
    }

    if (!item) {
      ToastStore.info('Return of claims completed');
      return
    }

    const asset = item.asset.split(':')

    if (asset[0] === this.state.select_asset.code && asset[1] === this.state.select_asset.issuer) {

      const status = getClaimantStatus(item, item.claimants.filter(item => item.destination === this.state.sponsor)[0]).status
      console.log('status: ', status)

      if (status !== 'Expired') {
        this.nextIteration()
        return
      }
    }
    else {
      this.nextIteration()
      return
    }


    const sponsorAccount = await Server.loadAccount(this.state.sponsor).catch(function (err) {
      console.error(`Failed to load sponsor ${this.state.sponsor}: ${err}`)
    })
    if (!sponsorAccount) { return }

    let claimBalance = StellarSdk.Operation.claimClaimableBalance({ balanceId: item.id });

    let tx = new StellarSdk.TransactionBuilder(sponsorAccount, {fee: StellarSdk.BASE_FEE})
      .addOperation(claimBalance)
      .setNetworkPassphrase(StellarSdk.Networks.PUBLIC)
      .setTimeout(180)
      .build();

    tx.sign(signer);

    console.log('tx sign: ', tx)

    const resClaim = await Server.submitTransaction(tx).catch( (error) => {
      console.error(`Tx submission failed: ${error}`)

      showErrorMessage(error, 'claim')
      ToastStore.error(`Tx submission failed: ${error}`);
    });

    console.log('resClaim: ', resClaim)

    if (resClaim) {

      ToastStore.success('Claim returned!');

      this.getSponsorClaimants(false)
      this.getLastsTime()

      this.setState({
        returned_balances: (this.state.returned_balances + 1),
        returned_amount: (Number(this.state.returned_amount) + Number(item.amount)),
      })
    }

    this.nextIteration()
  }


  checkAuthAddr = () => {
    checkAuth().then((value) => {
      if (value) {
        this.setState({ is_auth: true })
      }
    })
  }


  selectAccount = (addr) => {

    this.props.history.push({
      pathname: `/create-claimable-balance/${addr}`,
    });

    let select_account = this.state.wallets.filter((item) => {
      return addr === item.pk;
    });

    const account = select_account[0].account;
    const balances = account.balances;

    balances.forEach((item, index) => {
      // console.log('item: ', item)

      if (item.asset_type === 'native') {
        item.asset_code = 'XLM';
        item.asset_issuer = 'native';

        const sponsored = account.num_sponsored;
        const sponsoring = account.num_sponsoring;
        const sponsorReserve = (Number(sponsored) + Number(sponsoring))
        item.reserve  = (((account.subentry_count + sponsorReserve) * baseReserve()) + minBalance())
        item.avaliable = (item.balance - item.reserve).toFixed(2);

        this.setState({
          balance_native: item.balance,
          reserve_balance: item.reserve,
        })
      }
      else {
        item.token = item.balance;
      }
    })

    this.setState({
        sponsor_balances: balances.reverse(),
        sponsor_claimants: [],
        sponsor: addr,
        loading: true,
      },
      () => {
        this.getSponsorClaimants(true)
      })
  }


  changeSender = (value) => {

    let addr = '';
    for (const key of Object.keys(value)) {
      if (key < 56) {
        addr = (addr+value[key])
      }
      else {
        this.selectAccount(addr)
      }
    }
  }


  changeSelectAsset = (value) => {

    let asset = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        asset = asset + value[key];
      }
    }

    asset = asset.split('-');

    this.setState({
      select_asset: {
        code: asset[0],
        issuer: asset[1],
      },
      balance_token: this.state.sponsor_balances.filter(item => item.asset_code === asset[0])[0].balance
    });

  }


  changeMemo = (value) => {
    let memo = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        memo = memo + value[key];
      }
    }

    if (memo.length > 28) {
      memo = memo.substr(0, 28)

      setTimeout(() => {
        this.props.change('memo', memo)
      }, 200)
    }
    else {
      this.setState({ memo })
    }
  }


  changeSelectAmount = (value) => {
    var amonut = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        amonut = amonut + value[key];
      }
    }

    const claim_amount = numFormat(Number(amonut), 7)

    this.setState({ claim_amount })
  }


  getAccount(item, index) {

    // var item = item;
    // item.id = index;

    Server.loadAccount(item.pk)
      .then((account) => {
        console.log('account: ', account)

        item.account = account;
        item.balance = account.balances[account.balances.length-1].balance

        let sponsored = this.state.account.num_sponsored;
        let sponsoring = this.state.account.num_sponsoring;
        const sponsorReserve = (Number(sponsored) + Number(sponsoring))
        item.reserve  = (((account.subentry_count + sponsorReserve) * baseReserve()) + minBalance())
        item.avaliable = (item.balance - item.reserve).toFixed(2);

        this.wallets_arr.push(item);

        if (this.state.wallets.length === this.wallets_arr.length) {
          newState();
        }
      })
      .catch((err) => {
        console.error(err);

        this.wallets_arr.push(item)

        if (this.state.wallets.length === this.wallets_arr.length) {
          newState();
        }
      })

    const newState = () => {

      this.setState({
        wallets: this.wallets_arr,
        loading_accounts: false
      }, () => {
        if (this.props.match.params.id) {
          this.selectAccount(this.props.match.params.id)
        }
      })
    }
  }


  changeStartDate = (date) => {
    console.log('changeStartDate: ', date)
    this.setState({ select_start_time: date })
  }


  changeEndDate = (date) => {
    console.log('changeEndDate: ', date)
    this.setState({ select_end_time: date })
  }


  submitFormAddresses = (values) => {
    console.log('values: ', values)
  }

  // changeMemo = (memo) => {
  //   console.log('changeMemo: ', memo)
  //   this.setState({ memo: memo })
  // }


  startAirdrop = (type) => {

    if (type === 'stop') {

      this.state.steamTrades()
      this.state.streamPayments()
      this.state.streamEffects()

      this.setState({
        disabled_claim: false,
        airdrop_process: false,
      })

      ToastStore.info('Airdrop stopped');

      return
    }


    if (!this.state.sponsor) {
      ToastStore.warning('Select distributor address');
    }
    else if (!this.state.select_asset.issuer) {
      ToastStore.warning('Select asset for airdrop');
    }
    else if (!this.state.claim_amount) {
      ToastStore.warning('Enter the amount of the claim');
    }
    else if (!this.state.select_end_time) {
      ToastStore.warning('Select the expiration date of the claim');
    }
    else {
      swal({
        title: "Confirm!",
        text: `Launch the airdrop now?`,
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
        .then((confirm) => {
          if (confirm) {

            this.streamActiveAddresses()

            this.setState({
              claim_all: false ,
              start_time: String(new Date()),
              disabled_claim: true,
              airdrop_process: true,
            },
              () => {
              this.getLastsTime()
                const set_claim = {
                  claim_amount: this.state.claim_amount,
                  select_end_time: this.state.select_end_time,
                  memo: this.state.memo,
                  select_asset: this.state.select_asset,
                }
              localStorage.setItem('set_claim', JSON.stringify(set_claim))
            })
          }
        })
    }
  }


  claimAll = (typeEvent) => {

    const {sponsor_claimants} = this.state

    console.log('typeEvent: ', typeEvent)

    if (typeEvent === 'start') {

      if (!sponsor_claimants.length) {
        ToastStore.warning('No claims balances');
        return
      }

      const  claimants = sponsor_claimants[0].payments;
      console.log('claimants: ', claimants)

      if (!this.state.sponsor) {
        ToastStore.warning('Select distributor address');
        return
      }
      else if (!this.state.select_asset.issuer) {
        ToastStore.warning('Select asset for airdrop');
        return
      }

      swal({
        title: "Confirm!",
        text: `Return claimable balances now?`,
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
        .then((confirm) => {
          if (confirm) {

            this.setState({
                start_time: String(new Date()),
                claim_all: true,
                disabled_start: true,
                claim_process: true,
              },
              () => {
                this.claimBalance(claimants[this.claimCounter])
                this.getLastsTime()

                this.sponsor_claimants_length = this.state.sponsor_claimants[0].payments.length
              }
            )
          }
        })
    }
    else {
      this.setState({
        claim_process: false,
        disabled_start: false,
      })
    }
  }


  getLastsTime = () => {

    var dtc = new Date();
    var dtmb = new Date(this.state.start_time);
    var qminutes = Math.floor((dtc-dtmb)/1000/60);
    var hours = Math.floor(qminutes/60);
    // qminutes-=qhours*60;

    // const hours = new Date(dtc - dtmb).getHours()
    const minutes = new Date(dtc - dtmb).getMinutes()
    const seconds = new Date(dtc - dtmb).getSeconds()
    const lasts_time = `${hours > 9 ? hours : `0${hours}` }:${minutes > 9 ? minutes : `0${minutes}` }:${seconds > 9 ? seconds : `0${seconds}` }`

    this.setState({ lasts_time })
  }


  changeShowClaimants = (e) => {
    if (e.target) {
      this.setState({ show_claimants: e.target.checked })
    }
  }




  render() {
    // console.log('state: ', this.state)
    // console.log('props: ', this.props)

    const {reset, handleSubmit} = this.props;


    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Row>
          <Col md={{size:12}}>
            <h3 className="page-title"><i className={'fa fa-gift'}></i> Create Claimable Balance</h3>
            <h4 className="subhead" style={{marginBottom: '18px'}}>
              Manage your airdrops to Stellar. <b>Give</b> or <b>Claim</b> Gifts for your community. Reach out to <b>every Stellar user</b>.
            </h4>
          </Col>
        </Row>


        <Row>
          <Col md={5}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Distributor Address</h5>
                  {/*<h5 className="subhead">Create Claimable Balance</h5>*/}
                </div>

                <form className="material-form" onClick={this.checkAuthAddr}>
                  <div>
                    <Field
                      name="select_addr"
                      component={renderTextField}
                      select
                      label="Select Distributor Address"
                      onChange={(e) => {
                        this.changeSender(e)
                      }}
                    >
                      {
                        this.state.wallets.length ? this.state.wallets.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              style={{fontSize: '12px'}}
                              className={`material-form__option ${item && item.provider ?  'connect-provider' : ''}`}
                              value={item.pk}>
                              <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 8)}</b> <small>&nbsp;/ {item.title} / <b>{item.balance ? item.balance : null} XLM</b></small> { getIconConnectProvider(item) }
                            </MenuItem>
                          )
                        }) : null
                      }
                    </Field>
                  </div>

                  <Row>
                    <Col md={8}>
                      <Field
                        name="select_asset"
                        component={renderTextField}
                        select
                        label="Select Asset"
                        onChange={(e) => {
                          this.changeSelectAsset(e)
                        }}
                      >
                        {
                          this.state.sponsor_balances.length ? this.state.sponsor_balances.map((item, index) => {
                            return (
                              <MenuItem
                                key={index}
                                className="material-form__option"
                                value={`${item.asset_code}-${item.asset_issuer}`}><b className="text-secondary">{item.asset_code}</b> &nbsp;({item.asset_code === 'XLM' ? numFormat(item.avaliable, 7) : numFormat(item.balance, 7)})</MenuItem>
                            )
                          }) : null
                        }
                      </Field>
                    </Col>
                    <Col md={4}>
                        <Field
                          name="amount"
                          component={renderTextField}
                          label={`Claim amount`}
                          onChange={(e) => {
                            this.changeSelectAmount(e)
                          }}
                        />
                    </Col>
                  </Row>


                  <Row>

                    <Col md={4}>
                      <div className="datepicker-wrapper">
                        <p><small>Start Claim</small></p>
                        <DatePicker
                          className="date-picker"
                          name="Start"
                          dateFormat="MM/dd/yyyy"
                          selected={this.state.select_start_time}
                          onChange={this.changeStartDate}
                          isClearable={false} // !this.state.select_start_time ? false : true
                          label={'Current time'}
                          disabled={true}
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="datepicker-wrapper">
                        <p><small>End Claim</small></p>
                        <DatePicker
                          className="date-picker"
                          name="End"
                          dateFormat="MM/dd/yyyy"
                          selected={this.state.select_end_time}
                          onChange={this.changeEndDate}
                          isClearable={!this.state.select_end_time ? false : true}
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <Field
                        name="memo"
                        component={renderTextField}
                        label={`Memo ${this.state.memo && `(${this.state.memo.length}/28)`}`}
                        onChange={this.changeMemo}
                      />
                    </Col>
                  </Row>

                  <br/>

                  <h5 className="subhead"><b className="text-warning">XLM</b>: <b className="text-info">{numFormat(this.state.balance_native, 7)}</b> &nbsp; / &nbsp;
                    <b className="text-warning">{this.state.select_asset.code}</b>: <b className="text-info">{numFormat(this.state.balance_token, 7)}</b>
                    <span className="float-right">Reserve: <b className="text-info">{numFormat(this.state.reserve_balance, 7)} <font className="text-warning">XLM</font></b></span>
                  </h5>

                </form>

              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody>
                {/*<div className="card__title">*/}
                {/*  <h5 className="bold-text">Airdrop recipients</h5>*/}
                {/*  <h5 className="subhead">Create Claimable Balance</h5>*/}
                {/*</div>*/}

                <div className="tabs tabs--bordered-bottom" style={{marginTop: -11}}>
                  <div className="tabs__wrap">
                    <Nav tabs style={{fontSize: 13}}>
                      <NavItem >
                        <NavLink
                          style={{paddingLeft: 0}}
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => this.toggleTab('1')}
                        >
                          <b>ACTIVE ADDRESSES</b>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => this.toggleTab('2')}
                        >
                          <b>PASTE ADDRESSES</b>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>

                      <TabPane tabId="1">
                        <p>
                          The selection of addresses occurs automatically in random order from the Stellar transaction stream.
                        </p><br/>

                        <Row>
                          <Col md={6}>

                            <h5>All Assets</h5><br/>

                            <form className="form" onSubmit={handleSubmit}>
                              <div className="form__form-group form__form-group-field">
                                <Field
                                  name={`stream-trades`}
                                  component={renderCheckBoxField}
                                  label={'Trades'}
                                  defaultChecked={true}
                                  disabled={true}
                                  className={'colored-click'}
                                />
                              </div>
                              <div className="form__form-group form__form-group-field">
                                <Field
                                  name={`stream-payments`}
                                  component={renderCheckBoxField}
                                  label={'Payments'}
                                  defaultChecked={true}
                                  disabled={true}
                                  className={'colored-click'}
                                />
                              </div>

                              <div className="form__form-group form__form-group-field">
                                <Field
                                  name={`stream-claimable`}
                                  component={renderCheckBoxField}
                                  label={'Claimable Balances'}
                                  defaultChecked={false}
                                  disabled={true}
                                  className={'colored-click'}
                                />
                              </div>

                              <div className="form__form-group form__form-group-field">
                                <Field
                                  name={`stream-liquidity`}
                                  component={renderCheckBoxField}
                                  label={'Liquidity Pools'}
                                  defaultChecked={false}
                                  disabled={true}
                                  className={'colored-click'}
                                />
                              </div>

                            </form>
                          </Col>
                          <Col md={6}>

                            <h5>Add filter</h5><br/>

                            <form className="form" onSubmit={handleSubmit}>
                              <div className="form__form-group form__form-group-field">
                                <Field
                                  name={`add-asset`}
                                  component={renderCheckBoxField}
                                  label={`Add ${this.state.select_asset.code}`}
                                  defaultChecked={false}
                                  disabled={false}
                                  className={'colored-click'}
                                />
                              </div>
                              <div className="form__form-group form__form-group-field">
                                <Field
                                  name={`exclude-asset`}
                                  component={renderCheckBoxField}
                                  label={`Exclude ${this.state.select_asset.code}`}
                                  defaultChecked={false}
                                  disabled={false}
                                  className={'colored-click'}
                                />
                              </div>
                              <div className="form__form-group form__form-group-field">
                                <Field
                                  name={`only-asset`}
                                  component={renderCheckBoxField}
                                  label={`Only ${this.state.select_asset.code}`}
                                  defaultChecked={false}
                                  disabled={false}
                                  className={'colored-click'}
                                />
                              </div>
                              <div className="form__form-group form__form-group-field">
                                <Field
                                  name={`show_claimants`}
                                  component={renderCheckBoxField}
                                  label={`Show Claimants`}
                                  defaultChecked={false}
                                  disabled={false}
                                  className={'colored-click'}
                                  onChange={this.changeShowClaimants}
                                />
                              </div>
                            </form>
                          </Col>
                        </Row>

                      </TabPane>

                      <TabPane tabId="2">
                        <form className="form" onSubmit={handleSubmit(this.submitFormAddresses)}>

                          <div className="form__form-group">
                            <span className="form__form-group-label">Addresses</span>
                            <div className="form__form-group-field">
                              <Field
                                name="addresses"
                                component="textarea"
                                type="text"
                                placeholder="Each address is on a new line"
                              />
                            </div>
                          </div>

                          <ButtonToolbar className="form__button-toolbar float-right">
                            <Button size="sm" type="button" onClick={reset}>Reset</Button>
                            <Button size="sm" color="primary" type="submit">Add</Button>
                          </ButtonToolbar>
                        </form>
                      </TabPane>

                    </TabContent>

                  </div>
                </div>

              </CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <CardBody style={{ position: 'relative' }}>
                <div className="card__title">
                  <h5 className="bold-text">Distribute & Claim</h5>
                  {/*<h5 className="subhead">Create Claimable Balance</h5>*/}
                </div>

                <div className={'stats-claimants'}>
                  <p>Checked addresses: <b className={'text-info'}>{numFormat(this.state.checking_addresses)}</b></p>
                  {
                    !this.state.claim_all ?
                      <p>
                        <p>Claimants Sent: {' '}
                          {
                            this.state.airdrop_process ?
                              <span style={{position: 'relative'}}>
                                <BarLoader
                                  css={override_loader}
                                  color={"#70bbfd"}
                                  loading={true}
                                  width={65}
                                  height={4}
                                />
                              </span>
                            : null
                          }
                          <b className={'text-info'}><small className={'text-secondary'}>{this.state.arrayClaimants.length} / {numFormat(this.state.sent_percent, 2)}% / </small> {numFormat(this.state.sent_claimants)}</b>
                        </p>
                        <p>Sent: {this.state.select_asset.code}: <b className={'text-info'}>{numFormat(this.state.sent_amount, 7)}</b></p>
                      </p>
                    :
                      <p>
                        <p>Returned balances: {' '}
                          {
                            this.state.claim_process ?
                              <span style={{position: 'relative'}}>
                                <PacmanLoader
                                  css={override}
                                  size={8}
                                  color={"#70bbfd"}
                                  loading={true}
                                />
                              </span>
                            : null
                          }

                        <b className={'text-info'}>{numFormat(this.state.returned_balances)}</b></p>
                        <p>Refunded {this.state.select_asset.code}: <b className={'text-info'}>{numFormat(this.state.returned_amount, 7)}</b></p>
                      </p>
                  }
                  <p>Start Time: <b className={'text-info'}>{this.state.start_time ? formatDate(this.state.start_time) : 'not set'}</b></p>
                  <p>Lasts: <b className={'text-info'}>{this.state.lasts_time ? this.state.lasts_time : 'not set'}</b></p>
                </div>

                <br/>

                <div style={{ position: 'absolute', bottom: 30 }}>

                  <Button
                    color="primary"
                    className="table-btn"
                    outline={this.state.airdrop_process ? true : false}
                    disabled={this.state.disabled_start ? true : false}
                    onClick={() => this.startAirdrop(this.state.airdrop_process ? 'stop' : 'start')}>
                      {this.state.airdrop_process ? 'Stop' : 'Start' } Airdrop
                  </Button>

                  <Button
                    color="success"
                    className="table-btn"
                    outline={this.state.claim_process ? true : false}
                    disabled={this.state.disabled_claim ? true : false}
                    onClick={() => this.claimAll(this.state.claim_process ? 'stop' : 'start')}>
                     {this.state.claim_process ? 'Stop Claim' : 'Claim All' }
                  </Button>

                </div>

              </CardBody>
            </Card>
          </Col>
        </Row>



        {
          this.state.airdrop_process ?
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Filtered addresses (<small><b>{numFormat(this.state.checking_addresses)}</b> / </small> <b className={'text-info'}>{numFormat(this.state.sent_claimants)}</b> <small> / <b>{this.state.arrayClaimants.length}</b> / <b className={'text-secondary'}>{this.opLimit}</b></small>)</h5>
                    </div>
                    {
                      this.state.arrayClaimants.length ?
                      this.state.arrayClaimants.map((item, index) => {
                        return (
                          <span key={index}><small className={'text-secondary'}>{index+1}.</small> {shortAddress(item, '_blank')} &nbsp;&nbsp;</span>
                        )
                      })
                        : <p>Candidates awaited...</p>
                    }

                    {
                      this.state.loading_submite && <p>Sending transaction...</p>
                    }
                    {
                      this.state.hash && <p>Last hash: <Link to={`/transaction/${this.state.hash}`} target={'_blank'}><b>{shortAddr(this.state.hash, 4)}</b></Link></p>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          : null
        }


        {
          this.state.show_claimants ?
            <WrapClaimBalances
              accounts={this.state.sponsor_claimants}
              loading={this.state.loading}
              params_id={this.state.sponsor}
              type_url={'create'}
            />
          : null
        }


        <Footer />

      </Container>
    );
  }
}



export default reduxForm({
  form: 'CreateClaimableBalance', // a unique identifier for this form
})(translate('common')(CreateClaimableBalance));
