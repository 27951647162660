import React from 'react';
import {Col, Row, Card, CardBody, Button} from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {numFormat} from "../../../modules";


const stellar_logo = `${process.env.PUBLIC_URL}/img/logo/stellar-logo-gray.png`;
const android_icon = `${process.env.PUBLIC_URL}/img/icons/google-store-logo.svg`;
const appstore_icon = `${process.env.PUBLIC_URL}/img/icons/app-store-logo.svg`;
const mac_icon = `${process.env.PUBLIC_URL}/img/icons/mac-store-logo.png`;
const windows_icon = `${process.env.PUBLIC_URL}/img/icons/windows-store-logo.png`;
const bomb = `${process.env.PUBLIC_URL}/img/banners/bomb.png`;

const date = new Date();
const show_banner = false;

const style = {
  title: {fontSize: 22, marginBottom: '5px'},
  features: {fontSize: 24, marginBottom: '5px'}, // color: '#c567ff',
  button: {margin: '7px 10px 10px'},
  ieo: {
    // position: 'absolute', right: '-126px', top: 0, color: '#fdb271'
    float: 'right'
  },
  scop: {color: 'orange'},
  img: {width: '7%', marginTop: '-2px', opacity: 0.5},
  bold: {color: '#71bbfd'}
}

const Banner = () => {
  return (
    <Row className="wr-row">
      <Col md={12} className="trade-col right-last">
        <Card>
          <CardBody style={{padding: 10}}>
            {/*<Link to={'/trade/SCOP-XLM/GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ/native'}>*/}
            {/*  */}
            {/*</Link>*/}
            <div style={{
              backgroundImage: 'url(/img/banners/banner-bg.png)',
              backgroundSize: 'cover',
              backgroundPosition: 'bottom'}}>

              <Row style={{padding: '10px 20px', color: 'white'}}>
                <Col md={5} className={'text-center'}>
                  <div style={style.title}><b>SCOP is a Scopuly Ecosistem token</b></div>
                  <a href={'http://scop.scopuly.com/'}
                     target={'_blank'}
                     color="primary"
                     className={'btn btn-outline-primary'}
                     style={style.button}><b>Learn more</b></a>
                  <a href={'/trade/SCOP-XLM/GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ/native'}
                     color="primary"
                     className={'btn btn-primary'}
                     style={style.button}><b>Buy SCOP</b></a>
                </Col>
                <Col md={7}>
                  <div>
                    <div style={style.features}>
                      <b style={style.scop}>
                        <Link to={'/trade/SCOP-XLM/GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ/native'}>SCOP</Link></b> features:
                      <div style={style.ieo}><img style={style.img} src={bomb} alt="scopuly ieo"/> Listing on exchanges soon</div>
                    </div>
                  </div>

                  <Row>
                    <Col md={4}>
                      <div>* Access to the <b style={style.bold}>crypto card</b> service</div>
                      <div>* Access to the <b style={style.bold}>staking</b> service</div>
                    </Col>
                    <Col md={4}>
                      <div>* Payments for <b style={style.bold}>staking</b></div>
                      <div>* Up to <b style={style.bold}>50%</b> discount on fees</div>
                    </Col>
                    <Col md={4}>
                      <div>* <b style={style.bold}>Rewards</b> and loyalty points</div>
                      <div>* Participation in <b style={style.bold}>IDO</b> projects</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}



const Footer = () => (
  <div>
    {
      show_banner &&
        <Banner />
    }

    <Row className="wr-row">
      <Col md={12} className="trade-col right-last">
        <div className="footer-card text-secondary">
          <Card>
            <CardBody>
              <Row >
                <Col md={5} className="footer-back">
                  <h4>
                    <img src={stellar_logo} className="stellar-logo-sm" />  Powered by Stellar {' '}
                    <small style={{fontSize: '11px'}} className="text-secondary">| Decentralized Finance</small>
                  </h4>

                  <p><small>Scopuly - Stellar Wallet & SDEX.</small></p>
                  <p><small>Stellar is an open network for storing, moving, and converting money.</small></p>
                  {/*<p><small>Scopuly is the perfect way to buy, store, invest, trade, pay, and transfer crypto assets.</small></p>*/}
                  <p><small>Simple, secure and powerful wallet for the Stellar network. <br/> With Scopuly,
                    people can safely and cross-platform use all of Stellar's features.
                  </small></p>
                  <p><small>Not custodian. Only <b>you control</b> your funds.</small></p>

                  {/*<p><small>Scopuly is a Wallet, SDEX, IEO and Block Explorer for Stellar Network. Stellar is an open network for storing and moving money. Scopuly is the perfect way to buy, decentrally trade, pay and transfer crypto and invest in promising startups.</small></p>*/}
                  {/*<p><small>Scopuly does not store the user's secret keys and, accordingly, does not have access to wallets recovery. Scopuly is not responsible for the safety of the user's secret keys.</small></p>*/}
                  <br/>
                  {/*<div><small><b>Donations</b>: GD3TBZ3X3TJN7RE7L5SFWI2TBPO42DXOVFJOUNFWZFVOJWUY4YYTNWGP</small></div>*/}
                </Col>


                <Col md={2} className="mt-15">
                  <div className="footer-lable"><b>Services</b></div>
                  <small>
                    <span><a href="/wallet" className="text-secondary">Stellar Wallet</a></span><br/>
                    <span><Link to="/trade" className="text-secondary">Trade Assets</Link></span><br/>
                    <span><Link to="/swap" className="text-secondary">Swap Assets</Link></span><br/>
                    <span><Link to="/send" className="text-secondary">QR Payments</Link></span><br/>
                    <span><Link to="/claimable-balance" className="text-secondary">Claimable Balance</Link></span><br/>
                    <span><Link to="/fiat" className="text-secondary">Buy/Sell Crypto</Link></span><br/>
                    <span><Link to="/create-token" className="text-secondary">Create Token</Link></span><br/>
                    {/*<span><Link to="#" className="text-secondary">Launchpad</Link></span><br/>*/}
                    <span><Link to="/explorer" className="text-secondary">Block Explorer</Link></span>
                  </small>
                </Col>




                <Col md={2} className="mt-15">
                  <div className="footer-lable"><b>Company</b></div>
                  <small>
                    <span><a href="https://about.scopuly.com/"
                             className="text-secondary"
                             target='_blank'
                             rel='noopener noreferrer'><i className="fa"></i>About Scopuly</a></span><br/>

                    <span><a href="/support"
                             className="text-secondary"
                             target='_blank'
                             rel='noopener noreferrer'><i className="fa"></i>Help & Support</a></span><br/>

                    <span><a href="https://scopuly.com/docs/whitepaper.pdf"
                             className="text-secondary"
                             target='_blank'
                             rel='noopener noreferrer'><i className="fa"></i>Whitepaper</a></span><br/>

                    <span><a href="https://scopuly.com/docs/onepager.pdf"
                              className="text-secondary"
                              target='_blank'
                              rel='noopener noreferrer'><i className="fa"></i>Onepager</a></span><br/>

                    <span><a href="https://scop.scopuly.com/"
                               className="text-secondary"
                               target='_blank'
                               rel='noopener noreferrer'><i className="fa"></i>SCOP Token</a></span><br/>

                    <span><a href="https://about.scopuly.com/brand.html"
                             className="text-secondary"
                             target='_blank'
                             rel='noopener noreferrer'><i className="fa"></i>Brand Page</a></span><br/>

                    <span><a href="https://about.scopuly.com/terms.html"
                               className="text-secondary"
                                target='_blank'
                               rel='noopener noreferrer'><i className="fa"></i>Terms of Service</a></span><br/>

                    <span><a href="https://about.scopuly.com/privacy.html"
                             className="text-secondary"
                             target='_blank'
                             rel='noopener noreferrer'><i className="fa"></i>Privacy Policy</a></span><br/>

                </small>
              </Col>

                <Col md={2} className="mt-15">
                  <div className="footer-lable"><b>Community</b></div>
                  <small>
                  <span><a href="https://t.me/scopuly"
                           className="text-secondary"
                           target='_blank'
                           rel='noopener noreferrer'><i className="fa fa-telegram"></i> Telegram</a></span><br/>

                    <span><a href="https://twitter.com/scopuly"
                             className="text-secondary"
                             target='_blank'
                             rel='noopener noreferrer'><i className="fa fa-twitter"></i> Twitter</a></span><br/>

                    <span><a href="https://scopulyplatform.medium.com/"
                             className="text-secondary"
                             target='_blank'
                             rel='noopener noreferrer'><i className="fa fa-medium"></i> Medium</a></span><br/>

                    <span><a href="https://www.youtube.com/channel/UC-b8hH2c3ZnegEJhw4K9JOw"
                             className="text-secondary"
                             target='_blank'
                             rel='noopener noreferrer'><i className="fa fa-youtube"></i> YouTube</a></span><br/>

                    <span><a href="https://galactictalk.org/u/Scopuly"
                             className="text-secondary"
                             target='_blank'
                             rel='noopener noreferrer'>
                    <img src="/img/logo/galactic.png"
                         alt="galactic talk"
                         style={{
                           width: '16px',
                           filter: 'grayscale(100%)',
                           margin: '0 -2px 0 -3px'}}/> GalacticTalk</a></span><br/>

                    {/*<span><a href="https://www.facebook.com/scopuly/"*/}
                    {/*         className="text-secondary"*/}
                    {/*         target='_blank'*/}
                    {/*         rel='noopener noreferrer'><i className="fa fa-facebook"></i> Facebook</a></span><br/>*/}

                    <span><a href="https://reddit.com/r/Scopuly"
                             className="text-secondary"
                             target='_blank'
                             rel='noopener noreferrer'><i className="fa fa-reddit"></i> Reddit</a></span>
                  </small>
                </Col>


                <Col md={1} className="mt-15" >
                  <div className="footer-lable"><b>Apps</b></div>
                  <small>


                 <span className="icon-apps"><a href="https://play.google.com/store/apps/details?id=com.sdex.app"
                                                className="text-secondary"
                                                target='_blank'
                                                rel='noopener noreferrer'><img src={android_icon} alt='Android | Scopuly | Stellar Wallet'/></a></span><br/>

                    <span className="icon-apps"><a href="https://apps.apple.com/us/app/id1383402218#?platform=iphone"
                                                   className="text-secondary"
                                                   target='_blank'
                                                   rel='noopener noreferrer'><img src={appstore_icon} alt='iPhone | Scopuly | Stellar Wallet'/></a></span><br/>


                    <span className="icon-apps"><a href="https://github.com/Scopuly/scopuly/releases/download/v2.0.4/Scopuly-macOS.dmg"
                                                   className="text-secondary"
                                                   ><img src={mac_icon} alt='MacOS | Scopuly | Stellar Wallet'/></a></span><br/>


                    <span className="icon-apps"><a href="https://github.com/Scopuly/scopuly/releases"
                                                   className="text-secondary"
                                                   target='_blank'
                                                   rel='noopener noreferrer'
                                                   ><img src={windows_icon} alt='Windows | Scopuly | Stellar Wallet'/></a></span><br/>


                    {/*<span><a href="https://github.com/Scopuly/scopuly/releases/download/v2.0.4/Scopuly-macOS.dmg"*/}
                    {/*         className="text-secondary"*/}
                    {/*         // target='_blank'*/}
                    {/*         rel='noopener noreferrer'><i className="fa fa-apple"></i> MacOS</a></span><br/>*/}

                    {/*<span><a href="https://github.com/Scopuly/scopuly/releases"*/}
                    {/*         className="text-secondary"*/}
                    {/*         target='_blank'*/}
                    {/*         rel='noopener noreferrer'><i className="fa fa-windows"></i> Windows</a></span><br/>*/}

                    <span><a href="https://github.com/Scopuly"
                             className="text-secondary"
                             target='_blank'
                             rel='noopener noreferrer'><i className="fa fa-github"></i> GitHub</a></span><br/>
                  </small>
                </Col>

                {/*<Col md={12}>
                <small>
                  <br/>
                  <font>info@scopuly.com</font>
                </small>
              </Col>*/}

                <Col md={12}>
                  <small>
                    <br/>
                    <span className="text-secondary">
                     <span style={{fontFamily: 'Joy Kim'}}>Scopuly</span> @ {date.getFullYear()}
                    </span>
                    <span className="float-right">
                  <a href="mailto:info@scopuly.com" className="text-secondary" style={{marginRight: '20px'}}>info@scopuly.com</a>
                    <a href="/docs/PRIVACY_EN.pdf" className="text-secondary" style={{marginRight: '20px'}}>Privacy Policy</a>
                    <a href="/docs/TERMS_EN.pdf" className="text-secondary">Terms of Service</a>
                  </span>
                  </small>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Col>
    </Row>
  </div>
);


export default Footer;
