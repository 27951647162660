import React, { PureComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { translate } from 'react-i18next';
import {Button, Card, CardBody, Col, Container, Row, Table} from "reactstrap";
import {
  checkConnectProvider,
  formatDate,
  get_loc_wallets,
  getAsset, getAuth, needDeauthAll,
  numFormat, shortAddr,
  shortAddress, showErrorMessage,
  signConnectProvider, baseReserve, getClaimantStatus,
} from "../../../modules";
import LoadingIcon from "mdi-react/LoadingIcon";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ToastContainer, ToastStore} from "react-toasts";
import {Server} from "../../../modules/stellar";
import StellarSdk from "stellar-sdk";
import swal from "sweetalert";
import RequiredSigners from "../Auth/components/RequiredSigners";



class ClaimableBalances extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      wallets: get_loc_wallets(),
      is_my_address: false,
      loading: false,
      transaction: false,
      need_add_signers: false,
      btn_name: '',
      btn_icon: '',
      type_tx: '',
      type_set_tx: '',
      select_index: 0,
      claimable_balances: this.props.claimable_balances,
    };
  }


  componentDidMount() {
    this.setState({
      is_my_address: this.state.wallets ? this.state.wallets.some(item => item.pk === this.props.account) : false
    })
  }


  addSigners = (value) => {

    this.setState({
      transaction: value,
      need_add_signers: false,
    })

    this.setSendTx();
  }


  getSigner(account) {
    if (this.state.wallets) {
      return this.state.wallets.filter((item) => {
        return item.pk === account;
      })[0];
    }
  }


  getPayment = async(item, account) => {

    const signer = this.getSigner(account)

    this.setState({ loading: true })
    ToastStore.info('Loading an account...');


    // Claim Claimable Balance
    await Server.loadAccount(account)
      .then(async (sourceAccount) => {
        // console.log('sourceAccount: ', sourceAccount)

        this.setState({ account: sourceAccount })
        // await account.claimableBalances() :(

        const trust = sourceAccount.balances.some(balance => balance.asset_code === item.asset.split(':')[0])
        if (!trust) {
          this.noTrustlineMessage(item, account)
        }
        else {

          const submitTx = (num) => {
            ToastStore.info('Sending a transaction...');
            return Server.submitTransaction(this.state.transaction);
          }

          if (!this.state.transaction) {

            this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: StellarSdk.BASE_FEE,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
              .addOperation(StellarSdk.Operation.claimClaimableBalance({
                balanceId: item.id
              }))
              .setTimeout(0)
              .build()

            if (checkConnectProvider(account)) {

              return signConnectProvider(this.state.transaction, account).then(result => {
                this.state.transaction = result
                return submitTx();
              })
            }
            else {

              this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(signer.sk)));

              const master_weight = sourceAccount.signers.reverse()[0].weight;

              if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {

                this.setState({
                  account: sourceAccount,
                  need_add_signers: true,
                  btn_name: 'Claim',
                  btn_icon: 'clock-o',
                  type_tx: 'med',
                  type_set_tx: 'claim',
                })

                return false;
              }
              else {
                return submitTx();
              }
            }
          }
          else {
            submitTx()
          }
        }
      })
      .then((result) => {
        this.setState({
          loading: false,
          transaction: false,
        })

        if (result) {

          const message = `Received <b class="text-info">${numFormat(item.amount, 7)}</b> <b class="text-warning">${item.asset.split(':')[0]}</b><br><br><small>hash: <a href="/transaction/${result.hash}"><b>${shortAddr(result.hash, 4)}</b></a></small>`
          const element = document.createElement("span");
          element.innerHTML = message;

          swal({
            title: `Asset received!`,
            content: element,
            icon: "success",
            buttons: {
              confirm: true,
            },
          })
            .then(confirm => {
              if (confirm) {

                const claimable_balances = this.state.claimable_balances.filter(payment => payment.id !== item.id)
                this.setState({ claimable_balances })
              }
            });
        }
      })
      .catch(error => {
        console.log('error: ', error)
        this.setState({ loading: false })
        showErrorMessage(error, 'claim')
      })

  }


  noTrustlineMessage = (item, account) => {

    const message = `Add <b class="text-warning">${item.asset.split(':')[0]}</b> to your balance right now?`
    const element = document.createElement("span");
    element.innerHTML = message;

    swal({
      title: `No Trustline`,
      content: element,
      icon: "warning",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then(confirm => {
        if (confirm) {
          this.setTrustline(item, account)
        }
      });
  }


  setTrustline = (item, account) => {

    getAuth()
      .then((value) => {
        if (value === 'accept') {
          setTrustTx(item, account)
        }
        else if (value === 'need_deauth_all') {
          needDeauthAll();
        }
      });


    const setTrustTx = (item, account) => {

      const signer = this.getSigner(account)
      const split_asset = item.asset.split(':')

      this.setState({ loading: true })
      ToastStore.info('Loading an account...');

      Server.loadAccount(account)
        .then((sourceAccount) => {

          const submitTx = (num) => {
            ToastStore.info('Sending a transaction...');
            return Server.submitTransaction(this.state.transaction);
          }

          if (!this.state.transaction) {

            this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: StellarSdk.BASE_FEE,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
              .addOperation(StellarSdk.Operation.changeTrust({
                asset: new StellarSdk.Asset(split_asset[0], split_asset[1]),
              }))
              .setTimeout(100)
              .build();


            if (checkConnectProvider(account)) {

              return signConnectProvider(this.state.transaction, account).then(result => {
                this.state.transaction = result
                return submitTx();
              })
            }
            else {

              this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(signer.sk)));

              const master_weight = sourceAccount.signers.reverse()[0].weight;

              if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {

                this.setState({
                  // transaction: this.state.transaction,
                  account: sourceAccount,
                  need_add_signers: true,
                  btn_name: 'Accept',
                  btn_icon: 'check',
                  type_tx: 'med',
                  type_set_tx: 'change_trust',
                })

                return false;
              }
              else {
                return submitTx();
              }
            }
          }
          else {
            return submitTx();
          }
        })
        .then((result) => {
          if (result) {
            // console.log('setTrustTx result: ', result)
            if (result) {
              ToastStore.success('Trustline added!');
              this.setState({ transaction: false })
              this.getPayment(item, account)
            }
          }
        })
        .catch((error) => {
          this.setState({ loading: false })
          ToastStore.error('Transaction error...');
          showErrorMessage(error, 'change_trust')
        });
    }
  }


  handleClick = (item, account, index) => {
    // console.log('item: ', item)

    this.setState({ select_index: index })

    const message = `Accept and claim <b class="text-warning">${item.asset.split(':')[0]}</b> right now? <br><br><small class="text-secondary">You must have at least 0.5 XLM on your balance.</small>`
    const element = document.createElement("span");
    element.innerHTML = message;

    swal({
      title: "Accept and Claim",
      content: element,
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then((confirm) => {
        if (confirm) {
          this.getPayment(item, account)
        }
      })
  }


  renderPayment = (item, index, account) => {
    // console.log('renderPayment item: ', item)

    const claimant = item.claimants.filter(item => item.destination === account)[0]

    if (claimant) {

      let isSponsor = (item.sponsor === claimant.destination)
      const status = getClaimantStatus(item, claimant).status

      return (
        <tr key={index}>
          <td>{index+1}</td>
          {
            this.state.wallets && this.state.is_my_address ?  // this.state.is_my_address
              <td>
                <Button
                  color="success"
                  size="sm"
                  className="table-btn"
                  // disabled={status === 'Upcoming' ? true : false}
                  onClick={() => this.handleClick(item, account, index)}>Claim</Button>
              </td> : null
          }
          <td><b className={'text-info'}>{numFormat(item.amount, 7)}</b> {getAsset(item.asset)}</td>
          {/*<td>{getAsset(item.asset)}</td>*/}
          <td>{shortAddress(item.sponsor)} {isSponsor ? <small>{item.claimants.length-1} / {((item.claimants.length-1) * baseReserve())} XLM</small> : null}</td>
          {/*<td>{shortAddress(claimant.destination)}</td>*/}
          <td>
            {(status === 'Pending') ? <small className={'pending-status'}><b>{status}</b></small> : (status === 'Expired') ? <small className={'expired-status'}><b>{status}</b></small> : <small className={'upcoming-status'}><b>{status}</b></small>}
            {' '} {isSponsor && status === 'Expired' ? <small className={'pending-status'}><b>Pending</b></small> : null}
          </td>
          <td><small><b className={'text-info'}>{claimant.predicate.and ? formatDate(claimant.predicate.and[0].not ? claimant.predicate.and[0].not.abs_before : claimant.predicate.and[0].abs_before) : 'Not set'}</b></small></td>
          <td><small><b className={'text-danger'}>
            {(claimant.predicate.and) ?
              (claimant.predicate.and[1].not ? formatDate(claimant.predicate.and[1].not.abs_before) : formatDate(claimant.predicate.and[1].abs_before)) :
              (claimant.predicate.abs_before) ? formatDate(claimant.predicate.abs_before) :
              (claimant.predicate.not)? formatDate(claimant.predicate.not.abs_before) : 'Not set' }</b></small>
          </td>
          <td><small>{formatDate(item.last_modified_time)}</small></td>
          <td><small>{shortAddr(item.id, 4)}</small></td>
        </tr>
      )
    }
  }



  render() {

    // console.log('CB state: ', this.state)
    // console.log('CB props: ', this.props)

    return (
      <div>
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {
          this.state.need_add_signers && this.state.transaction ?
            <RequiredSigners account={this.state.account}
                             add_signers={this.addSigners}
                             transaction={this.state.transaction}
                             btn_name={this.state.btn_name}
                             icon={this.state.btn_icon}
                             type_tx={'low'} />
            : null
        }

        { this.state.loading && <div className="panel__refresh" style={{height: '93%'}}><LoadingIcon /></div> }

        <Table striped responsive>
          <thead>
          <tr>
            <th>#</th>
            { this.state.wallets && this.state.is_my_address ? <th>Claim</th> : null }
            <th>Amount</th>
            {/*<th>Asset</th>*/}
            <th>Sponsor</th>
            {/*<th>Destination</th>*/}
            <th>Status</th>
            <th>Start Time</th>
            <th>End Time</th>
            {/*<th>Hash</th>*/}
            <th>Created</th>
            <th>Balance</th>
          </tr>
          </thead>
          <tbody>

          { this.state.claimable_balances.map((payment, index) => this.renderPayment(payment, index, this.props.account)) }

          </tbody>
        </Table>
        {
          !this.state.is_my_address ?
            <div>
              <br/>
              <small
                style={{float: 'right'}}
                className={'text-secondary'}><i className={'fa fa-info-o'}></i> Is this <Link to={`/account/${this.props.account}`}>{shortAddr(this.props.account, 4)}</Link> address yours? You can <Link to={'/auth/connect'}><b>connect</b></Link> this wallet and claim your claimable balances.</small>
            </div>
          : null
        }
      </div>
    );
  }
}


export default translate('common')(ClaimableBalances);
