import React, { PureComponent } from 'react';
import { Table } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { shortAddr, numFormat, getNameAddr, shortAddress, itemType, formatDate } from '../../../../../modules/index';
import moment from 'moment';
import iconIdent from '../iconIdent';



class Trades extends PureComponent {

	constructor(props) {
	  super(props);
	  this.state = {
	    // activeTab: '1',
	  };
	}


	// getNameWatch(address) {

	// 	let local_favorites = localStorage.getItem('favorites');

	// 	if (local_favorites) {
	// 		local_favorites = JSON.parse(local_favorites);

	// 		let name = false;
	// 		local_favorites.addresses.forEach((item, index) => {

	// 		  if (item.address === address) {
	// 		    name = item.name;
	// 		  }
	// 		});

	// 		// my wallets
	// 		if (!name) {
	// 			const wallets = get_loc_wallets();

	// 			if (wallets) {

	// 				wallets.forEach((item, index) => {
	// 				  if (item.pk === address) {
	// 				    name = item.title;
	// 				  }
	// 				});
	// 			}
	// 		}

	// 		return name;
	// 	}
	// }



	formatDate(time) {
	  return moment(time).format("DD MMMM YYYY HH:mm:ss");
	}

	shortAddress(address) {
		if (!address) return
		const name = getNameAddr(address);
	  return (
	    <a href={`/account/${address}`}>{iconIdent(address, 'icon-indent')} <b>{shortAddr(address, 4)}</b> <small className="text-info">{name ? `[${name}]` : ''}</small></a>
	  )
	}

	getCounterAsset(item) {
		return (
			<a href={(item.counter_asset_type === 'native' ? '/asset/XLM-native' : '/trade/'+item.counter_asset_code + '-XLM/' + item.counter_asset_issuer+'/native')}>
			<b className="text-warning">{ item.counter_asset_type === 'native' ? 'XLM' : item.counter_asset_code }</b>
			</a>
			)
	}

	getBaseAsset(item) {
		return (
			<a href={(item.base_asset_type === 'native' ? '/asset/XLM-native' : '/trade/'+item.base_asset_code+'-XLM/'+item.base_asset_issuer+'/native')}>
			<b className="text-warning">{ item.base_asset_type === 'native' ? 'XLM' : item.base_asset_code }</b>
			</a>
			)
	}



	renderItem(item, index) {
		const operation_id = item.id.split('-')[0];
		const liquidity_pool_id = item.base_liquidity_pool_id ? item.base_liquidity_pool_id : item.counter_liquidity_pool_id

		// console.log('-----------------')
		// console.log('item: ', item)
		// console.log('trade_type: ', item.trade_type)
		// console.log('base_account: ', item.base_account)
		// console.log('counter_account: ', item.counter_account)

		return (
			<tr key={index} style={{fontSize: 13}}>
				<td>{index+1}</td>
				<td>
					{/*{
						item.base_is_seller ?
						<div> {this.shortAddress(item.counter_account)} sold <b className="text-secondary">{numFormat(item.counter_amount, 7)}</b> { this.getCounterAsset(item) } / bought <b className="text-secondary">{numFormat(item.base_amount, 7)}</b> { this.getBaseAsset(item) } from { this.shortAddress(item.base_account) } </div> :
						<div> {this.shortAddress(item.counter_account)} bought <b className="text-secondary">{numFormat(item.counter_amount, 7)}</b> { this.getCounterAsset(item) } / sold <b className="text-secondary">{numFormat(item.base_amount, 7)}</b> { this.getBaseAsset(item) } to { this.shortAddress(item.base_account) } </div>
					}*/}
					{/*{
						item.base_is_seller ?
						<div> {this.shortAddress(item.counter_account)} sold <b className="text-secondary">{numFormat(item.counter_amount, 7)}</b> { this.getCounterAsset(item) } / bought <b className="text-secondary">{numFormat(item.base_amount, 7)}</b> { this.getBaseAsset(item) } from { this.shortAddress(item.base_account) } </div> :
						<div> {this.shortAddress(item.counter_account)} sold <b className="text-secondary">{numFormat(item.counter_amount, 7)}</b> { this.getCounterAsset(item) } / bought <b className="text-secondary">{numFormat(item.base_amount, 7)}</b> { this.getBaseAsset(item) } to { this.shortAddress(item.base_account) } </div>
					}*/}
					{
						item.base_is_seller ?
							<div style={{fontSize: 13}}>
								{shortAddress(item.counter_account)} <span className="text-danger">sold</span> <b className="text-info">{numFormat(item.counter_amount, 7)}</b> { this.getCounterAsset(item) } / <span className="text-success">bought</span> <b className="text-info">{numFormat(item.base_amount, 7)}</b> { this.getBaseAsset(item) } from {item.trade_type === 'orderbook' ? <span> {shortAddress(item.base_account)}</span> :
								<Link to={`/pool/${liquidity_pool_id}`}>
									<b>{iconIdent(liquidity_pool_id, 'icon-indent')} LIQUIDITY POOL</b>
								</Link> }
							</div>
						:
							<div style={{fontSize: 13}}>
								{shortAddress(item.base_account)} <span className="text-success">bought</span> <b className="text-info">{numFormat(item.counter_amount, 7)}</b> { this.getCounterAsset(item) } / <span className="text-danger">sold</span> <b className="text-info">{numFormat(item.base_amount, 7)}</b> { this.getBaseAsset(item) } to {item.trade_type === 'orderbook' ? <span> {shortAddress(item.counter_account)}</span> :
								<Link to={`/pool/${liquidity_pool_id}`}>
									<b>{iconIdent(liquidity_pool_id, 'icon-indent')} LIQUIDITY POOL</b>
								</Link> }
							</div>
					}
				</td>
				<td> <b className={item.base_is_seller ? 'text-success' : 'text-danger'}>{numFormat((item.price.d / item.price.n), 7)}</b></td>
				<td className={item.base_is_seller ? 'text-success' : 'text-danger'}>{item.base_is_seller ? 'buy' : 'sell'}</td>
				<td>{item.trade_type}</td>
				<td>{formatDate(item.ledger_close_time, 'short')}</td>
				<td>{ item.trade_type === 'orderbook' ? <span><a href={`/offer/${item.base_offer_id}`}><b>{shortAddr(item.base_offer_id, 3)}</b></a> / <a href={`/offer/${item.counter_offer_id}`}><b>{shortAddr(item.counter_offer_id, 3)}</b></a></span> : <span><a
					href={`/pool/${liquidity_pool_id}`}><b>{shortAddr(liquidity_pool_id, 3)}</b></a> / <a href={`/offer/${item.counter_offer_id ? item.counter_offer_id : item.base_offer_id}`}><b>{shortAddr(item.counter_offer_id ? item.counter_offer_id : item.base_offer_id, 3)}</b></a></span> }</td>
				<td><a href={`/operation/${operation_id}`}><b>{shortAddr(operation_id, 4)}</b></a></td>
			</tr>
		);
	}


	render() {

		const items = this.props.items;

		return(
			<Table striped responsive>
			  <thead>
			    <tr>
						<th>#</th>
						<th>Details</th>
						<th>Price</th>
						<th>Type</th>
						<th>Trade Type</th>
						<th>Date</th>
						<th>Offer</th>
						<th>Operation</th>
			    </tr>
			  </thead>
			  <tbody>

			  	{ items.map(this.renderItem.bind(this)) }

			  </tbody>
			</Table>
		)
	}
}

Trades.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate('common')(Trades);
