import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Button, ButtonGroup, Table } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ToastContainer, ToastStore } from 'react-toasts';
import { shortAddr, getTitle } from '../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import axios from 'axios';
import { GLOBE } from '../../../modules/globeVars/index';
import Footer from '../../Layout/footer/Footer';



class Favorites extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      favorites: false,
      auth_user: localStorage.getItem('auth_user'),
    };
  }

  componentDidMount() {  
    getTitle(`Watchlist`);

    if (this.state.auth_user) {
      this.getWatchlist();
    }
    else {

      this.setState({
        favorites: JSON.parse(localStorage.getItem('favorites')),
      })
    }
  }


  removeItem(type, index, item) {

    let local_favorites = localStorage.getItem('favorites');
    local_favorites = JSON.parse(local_favorites);

    if (type === 'address') {
      local_favorites.addresses.splice(index, 1);
      ToastStore.success('Address removed from Watchlist'); 

      this.setWachAddressDB('remove', {
        address: item,
        name: ''
      })
    }
    if (type === 'asset') {
      local_favorites.assets.splice(index, 1);
      ToastStore.success('Asset removed from Watchlist'); 

      this.setWachAssetDB('remove', item)
    }

    this.setState({
      favorites: local_favorites
    });

    local_favorites = JSON.stringify(local_favorites);
    localStorage.setItem('favorites', local_favorites);
  }



  setWachAddressDB(type, item) {

    let auth_user = localStorage.getItem('auth_user');
    if (auth_user) {

      const formData = new FormData();
      formData.append('user_id', JSON.parse(auth_user).user_id)
      formData.append('address', item.address)
      formData.append('name', item.name)
      formData.append('type', type)

      axios.post(GLOBE.API_URL+`/watch_address`, formData)
        .then(response => {
          // console.log('response: ', response)
      });
    }
  }


  setWachAssetDB(type, asset) {

    let auth_user = localStorage.getItem('auth_user');
    if (auth_user) {

      const formData = new FormData();
      formData.append('user_id', JSON.parse(auth_user).user_id)
      formData.append('asset_code', asset.asset_code)
      formData.append('asset_issuer', asset.asset_issuer)
      formData.append('type', type)

      axios.post(GLOBE.API_URL+`/watch_asset`, formData)
        .then(response => {
          // console.log('response: ', response)
      });
    }
  }



  getWatchlist() {

    let auth_user = localStorage.getItem('auth_user');
    if (auth_user) {

      const formData = new FormData();
      formData.append('user_id', JSON.parse(auth_user).user_id)

      axios.post(GLOBE.API_URL+`/get_watchlist`, formData)
        .then(response => {
          // console.log('response: ', response)

          this.setState({
            favorites: response.data,
          })

          let local_favorites = JSON.stringify(response.data);
          localStorage.setItem('favorites', local_favorites);
      });
    }
  }



  renderAddress(item, index) {

    return (
      <tr key={index}>
        <td>{index+1}</td>
        <td><a href={`/account/${item.address}`}><b>{shortAddr(item.address, 6)}</b></a></td>
        <td>{item.name}</td>
        <td><Button color="secondary" 
                    size="sm" outline 
                    className="table-btn"
                    onClick={() => this.removeItem('address', index, item.address)}>
              <span className="lnr lnr-trash" />
            </Button>
        </td>
      </tr>
    )
  }


  renderAsset(item, index) {
    return (
      <tr key={index}>
        <td>{index+1}</td>
        <td><a href={`/trade/${item.asset_code}-XLM/${item.asset_issuer}/native`}><b className="text-warning">{item.asset_code}</b></a></td>
        <td><a href={`/account/${item.asset_issuer}`}><b>{shortAddr(item.asset_issuer, 4)}</b></a></td>
        <td>
          <Button color="secondary" 
                    size="sm" outline 
                    className="table-btn"
                    onClick={() => this.removeItem('asset', index, item)}>
              <span className="lnr lnr-trash" />
          </Button>
        </td>
      </tr>
    )
  }



  render() {

    return (
        <Container>

          <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} /> 

          <Row>
            <Col md={12}>
              <h3 className="page-title">Watchlist</h3>
              {/*<h4 className="subhead subhead-b" style={{marginBottom: '18px'}}>fasdfasfasdf</h4>*/}
              {
                this.state.auth_user ?
                  <h4 className="subhead" style={{marginBottom: '18px'}}>
                    Your Watchlist is tied to this account: <b>{JSON.parse(this.state.auth_user).email}</b>
                  </h4>
                : 
                  <div>
                    <p>
                      <small>
                        <i className="fa fa-info"></i> {' '}
                        Now your Watchlist is temporarily stored in the local memory of your browser. 
                        This is not the most reliable way to store your data. For reliable data storage 
                        Your Watchlist - Log in with your account.
                      </small>
                    </p>
                    <h4 className="subhead" style={{marginBottom: '18px'}}>
                      <Link to="login" ><i className="fa fa-sign-in"></i> Login</Link>
                    </h4>
                  </div>
              }
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Addresses</h5>
                    <h5 className="subhead">Watching Addresses</h5>
                  </div>

                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Address</th>
                        <th>Name</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>

                      {/*{
                        this.state.favorites.addresses.length > 0 ? 
                          this.state.favorites.addresses.map(this.renderAddress.bind(this))
                        : null
                      }*/}

                      {
                        this.state.favorites ? 
                          this.state.favorites.addresses.length > 0 ? 
                            this.state.favorites.addresses.map(this.renderAddress.bind(this))
                          : null
                        : 
                          <p>Nothing added to favorites yet</p>
                      }

                    </tbody>
                  </Table>
                </CardBody>
              </Card>   
            </Col>
            <Col md={6}>
              <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Assets</h5>
                      <h5 className="subhead">Watching Assets</h5>
                    </div>

                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Asset Code</th>
                          <th>Asset Issuer</th>
                          <th>Remove</th>
                        </tr>
                      </thead>
                      <tbody>

                        {
                          this.state.favorites ? 
                            this.state.favorites.assets.length > 0 ? 
                              this.state.favorites.assets.map(this.renderAsset.bind(this))
                            : null
                          : 
                            <p>Nothing added to favorites yet</p>
                        }

                      </tbody>
                    </Table>
                  </CardBody>
                </Card> 
            </Col>
            {/*<Col md={4}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Offers</h5>
                  </div>
                </CardBody>
              </Card> 
            </Col>*/}
          </Row>

          <Footer />
          
        </Container>
    );
  }
}


Favorites.propTypes = {
  t: PropTypes.func.isRequired,
};


export default translate('common')(Favorites);

