import React, { PureComponent } from 'react';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import { ToastContainer, ToastStore } from 'react-toasts';
import {get_loc_wallets, shortAddress} from '../../../modules/index';
import LoadingIcon from "mdi-react/LoadingIcon";
import {Col, Row, Card, CardBody, Button,} from 'reactstrap';
import {CopyToClipboard} from "react-copy-to-clipboard";
import ClaimableBalances from "./ClaimableBalances";



class WrapClaimBalances extends PureComponent {

  constructor() {
    super();
    this.state = {
      //
    };
  }

  // wallets = get_loc_wallets()


  componentDidMount = () => {
    //...
  }



  render() {
    // console.log('this.state: ', this.state)
    // console.log('this.props: ', this.props)


    return (
      <div>
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Row>
          {
            this.props.accounts.length ? this.props.accounts.map((item, index) => {

              if (item.payments.length) {
                return (
                  <Col md={{size:12}} key={index}>
                    <Card>
                      <CardBody>

                        { this.props.loading && <div className="panel__refresh" style={{height: '93%'}}><LoadingIcon /></div> }

                        <div className="card__title">
                          <h5 className="bold-text">Account: {shortAddress(item.account)} {' '}
                            <CopyToClipboard text={item.account} onCopy={() => ToastStore.success(`Copied`)}>
                              <i className="fa fa-clone" style={{cursor: 'pointer'}}></i>
                            </CopyToClipboard> {' '} &nbsp;&nbsp;&nbsp;
                            { !this.props.params_id ?
                              <span><a href={`/claimable-balance/${item.account}`} target={'_blank'}><i className={'fa fa-external-link'}></i></a></span>
                              : null
                            }
                            <b className={'float-right'}>(<span className={'text-info'}>{item.payments.length}</span>)</b>
                          </h5>
                        </div>


                        <ClaimableBalances
                          account={item.account}
                          claimable_balances={item.payments}
                        />

                      </CardBody>
                    </Card>
                  </Col>
                )
              }
              else if (this.props.params_id) {
                return <Col md={{size:12}}><Card><CardBody><p className={'text-center'}>No claimable balances found on this account</p></CardBody></Card></Col>
              }

            })
            :
              this.props.type_url === 'claimable' ?
                <Col md={{size:12}}><Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card></Col>
            :
              this.props.type_url === 'create' && this.props.loading ?
                <Col md={{size:12}}><Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card></Col>
            : null
          }
        </Row>
      </div>
    )
  }
}



export default reduxForm({
  form: 'WrapClaimBalances', // a unique identifier for this form
})(translate('common')(WrapClaimBalances));
