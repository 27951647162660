import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Table, Button, ButtonGroup, ButtonToolbar } from 'reactstrap';
import { translate } from 'react-i18next';
import { Field, reduxForm, change} from 'redux-form';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import { ToastContainer, ToastStore } from 'react-toasts';
// import axios from 'axios';
import LoadingIcon from 'mdi-react/LoadingIcon';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
// import Highcharts from 'highcharts'
// import HighchartsReact from 'highcharts-react-official'
// import moment from 'moment';
import { get_loc_wallets, baseReserve, shortAddr } from '../../../modules/index';
const  passType = 'Ghd6#_d7S';



let total_count = 0, sell_count = 0;


class Dashboard extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      wallets: get_loc_wallets(),
      loader: false,
      addressess: [],
      amount_max: 0.001,
      interval: 15000,
      orderbook: false,
      loc_asset: localStorage.getItem('loc_asset'),
    };
  }

  componentDidMount() {
    this.checkAt();
  }



  checkAt() {
    let at = localStorage.getItem('at');
    if (!at) {
      let result = prompt('Enter Password');
      if (result !== passType) {
        window.location = window.location.origin + '/home';
        return;
      }
      else {
        localStorage.setItem('at', 'true');
      }
    }
    else {

      if (this.state.loc_asset) {
        this.getAssetData();
      }
    }
  }


  getAssetData() {
    this.checkAddressess();
    this.getOrderbook();
    this.checkLocalAsset();
  }


  checkAddressess() {

    let local_addressess = localStorage.getItem('buying_addressess');
    let amount_max = localStorage.getItem('amount_max');
    let interval = localStorage.getItem('interval');

    if (amount_max) {
      this.props.change('amount_max', amount_max)
    }
    if (interval) {
      this.props.change('interval', interval)
    }

    if (local_addressess) {
      local_addressess = JSON.parse(local_addressess);
      // console.log('local_addressess: ', local_addressess)

      local_addressess.forEach((item, index) => {
        // console.log('item: ', item)

        if (index === 0) {
          this.props.change('address_1', item)
        }
        if (index === 1) {
          this.props.change('address_2', item)
        }
        if (index === 2) {
          this.props.change('address_3', item)
        }
        if (index === 3) {
          this.props.change('address_4', item)
        }
        if (index === 4) {
          this.props.change('address_5', item)
        }
        if (index === 5) {
          this.props.change('address_6', item)
        }
        if (index === 6) {
          this.props.change('address_7', item)
        }
        if (index === 7) {
          this.props.change('address_8', item)
        }
        if (index === 8) {
          this.props.change('address_9', item)
        }
        if (index === 9) {
          this.props.change('address_10', item)
        }
        if (index === 10) {
          this.props.change('address_11', item)
        }
        if (index === 11) {
          this.props.change('address_12', item)
        }
        if (index === 12) {
          this.props.change('address_13', item)
        }
        if (index === 13) {
          this.props.change('address_14', item)
        }
        if (index === 14) {
          this.props.change('address_15', item)
        }
        if (index === 15) {
          this.props.change('address_16', item)
        }
        if (index === 16) {
          this.props.change('address_17', item)
        }
        if (index === 17) {
          this.props.change('address_18', item)
        }
        if (index === 18) {
          this.props.change('address_19', item)
        }
        if (index === 19) {
          this.props.change('address_20', item)
        }
      })
    }
  }



  startTradesSubmit(values) {
    // console.log('values: ', values)

    if (!values.radio) { ToastStore.warning('Select Asset') }
    else if (!values.address_1) { ToastStore.warning('Enter Address 1') }
    // else if (!values.address_2) { ToastStore.warning('Enter Address 2') }
    // else if (!values.address_3) ToastStore.warning('Enter Address 3');
    // else if (!values.address_4) ToastStore.warning('Enter Address 4');
    // else if (!values.address_5) ToastStore.warning('Enter Address 5');
    else {

      let addressess = [];
      Object.keys(values).map((item, index) => {
        if (item !== 'radio' && item !== 'interval' && item !== 'amount_max') {
          addressess.push(values[item]);
        }
      })

      let interval = (Number(values.interval) * 1000);

      this.setState({
        addressess: addressess,
        amount_max: values.amount_max,
        interval: interval,
      })

      localStorage.setItem('buying_addressess', JSON.stringify(addressess))
      localStorage.setItem('amount_max', values.amount_max)
      localStorage.setItem('interval', values.interval)

      setTimeout(() => {
        this.setOffer(addressess);
      }, 100)
    }
  }



  getOrderbook() {

    const asset = JSON.parse(this.state.loc_asset);

    Server.orderbook(new StellarSdk.Asset.native(), new StellarSdk.Asset(asset.asset_code, asset.asset_issuer))
      .limit(5)
      .call()
      .then((result) => {
        // console.log('getOrderbook result: ', result)

        if (result) {
          this.setState({
            orderbook: result,
          })

          ToastStore.info(`You can run the bot!`);
        }

      })
      .catch((err) => {
        console.log(err);
    })
  }



  setOffer(addressess) {

    let count = 0;

    const asset = JSON.parse(this.state.loc_asset);
    // console.log('asset: ', asset)


    const randomAmount = (max) => {
      var min = 0;
      var random = (Math.random() * (+max - +min) + +min).toFixed(6);
      return random;
    }


    const offer = (item) => {
      // console.log('offer address: ', item)

      let price = '0.9';
      let type = 'Buy'

      const signer = this.state.wallets.filter((item2) => {
        return item2.pk === item;
      })

      if (sell_count >= 3) {
        price   = (this.state.orderbook.asks[3].price_r.d / this.state.orderbook.asks[3].price_r.n).toFixed(7);
        type = 'Sell'
        sell_count = 0;
      }


      // if (count+1 < addressess.length) {

        this.setState({ loader: true });

        Server.loadAccount(item)
          .then((sourceAccount) => {
            // console.log('sourceAccount ', sourceAccount)
            // console.log('type ', type)

            var transaction;

            if (type == 'Sell') {
              transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: StellarSdk.BASE_FEE,
                networkPassphrase: StellarSdk.Networks.PUBLIC
              })
              .addOperation(StellarSdk.Operation.manageSellOffer({
                  selling: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                  buying: new StellarSdk.Asset.native(),
                  amount: String(randomAmount(this.state.amount_max)),
                  price: price,
              }))
              .addOperation(StellarSdk.Operation.manageSellOffer({
                selling: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buying: new StellarSdk.Asset.native(),
                amount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .addOperation(StellarSdk.Operation.manageSellOffer({
                selling: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buying: new StellarSdk.Asset.native(),
                amount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .addOperation(StellarSdk.Operation.manageSellOffer({
                selling: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buying: new StellarSdk.Asset.native(),
                amount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .addOperation(StellarSdk.Operation.manageSellOffer({
                selling: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buying: new StellarSdk.Asset.native(),
                amount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .addOperation(StellarSdk.Operation.manageSellOffer({
                selling: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buying: new StellarSdk.Asset.native(),
                amount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .setTimeout(100)
              .build();
            }

            if (type == 'Buy')  {

              transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: StellarSdk.BASE_FEE,
                networkPassphrase: StellarSdk.Networks.PUBLIC
              })
              .addOperation(StellarSdk.Operation.manageBuyOffer({
                  selling: new StellarSdk.Asset.native(),
                  buying: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                  buyAmount: String(randomAmount(this.state.amount_max)),
                  price: price,
              }))
              .addOperation(StellarSdk.Operation.manageBuyOffer({
                selling: new StellarSdk.Asset.native(),
                buying: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buyAmount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .addOperation(StellarSdk.Operation.manageBuyOffer({
                selling: new StellarSdk.Asset.native(),
                buying: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buyAmount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .addOperation(StellarSdk.Operation.manageBuyOffer({
                selling: new StellarSdk.Asset.native(),
                buying: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buyAmount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .addOperation(StellarSdk.Operation.manageBuyOffer({
                selling: new StellarSdk.Asset.native(),
                buying: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buyAmount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .addOperation(StellarSdk.Operation.manageBuyOffer({
                selling: new StellarSdk.Asset.native(),
                buying: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buyAmount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .setTimeout(100)
              .build();
            }

            transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(signer[0].sk)));
            return Server.submitTransaction(transaction);
          })
          .then((result) => {
            console.log(`result offer: `, result)

            total_count++;
            sell_count++;

            // ToastStore.success(`${type} Success - count: ${total_count} ${shortAddr(item, 4)}`);

            this.setState({ loader: false });

            setTimeout(() => {
              // console.log(`setTimeout ->`)

              nextOffer();

            }, this.state.interval);



            const nextOffer = () => {

              // console.log(`nextOffer`)
              // console.log(`count: `, count)
              // console.log(`addressess[]: `, addressess[0])

              if (count+1 === addressess.length) {

                setTimeout(() => {
                  count = 0;
                  // console.log(`setTimeout -> next round`)

                  // this.setOffer(this.state.addressess);

                  offer(addressess[0]);

                }, this.state.interval);
              }
              else {
                // console.log(`next offer`)

                count++;
                let address = addressess[count];
                // console.log(`address: `, address)

                offer(address);
              }
            }

          })
          .catch((error) => {
            console.log('error tx: ', error);

            // this.setState({ loader: false });

            this.setOffer(this.state.addressess);
            // count++;
            // console.log('offer count: ', count);

            // offer(addressess[0])
        });
      // }
      // else if (count+1 === addressess.length) {

      //   // count = 0;
      //   // setTimeout(() => {
      //   //   // console.log('----------> new cicle')
      //   //   // console.log('this.state.interval: ', this.state.interval)

      //   //   this.setOffer(this.state.addressess);
      //   // }, this.state.interval);
      // }

      //--------
    };



    // if (addressess.length > count) {
    //   offer(addressess[count]);
    // }
    // else {

    //   this.setOffer(addressess);
    // }

    // console.log('next cicle: ', count)
    // console.log('addressess[count]: ', addressess[count])

    offer(addressess[count]);
  }


  checkLocalAsset() {
    if (!this.state.loc_asset) {
      const asset = {
        asset_code: 'SKY',
        asset_issuer: 'GB2WB73G5IWRXEUTJANAIF52JFTDXOXORAP4S5HZRW23TZG2ONDVZMVA',
      }
      // console.log('asset: ', asset)
    }
    else {

      const asset = JSON.parse(this.state.loc_asset);
      // console.log('loc_asset: ', asset)
    }
  }


  changeAsset(value) {
    // console.log('value: ', value)

    if (value[0] === '1') {
      var asset = {
        asset_code: 'SKY',
        asset_issuer: 'GB2WB73G5IWRXEUTJANAIF52JFTDXOXORAP4S5HZRW23TZG2ONDVZMVA',
      }
    }
    if (value[0] === '2') {
      var asset = {
        asset_code: 'SCOP',
        asset_issuer: 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ',
      }
    }

    // console.log('asset: ', asset)

    var asset = JSON.stringify(asset);

    localStorage.setItem('loc_asset', asset)

    this.setState({
      loc_asset: asset,
    })

    this.getAssetData();
  }




  render() {

    const { handleSubmit, reset, t } = this.props;

    // console.log('state: ', this.state)


    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Row>
          <Col md={12}>
            <h3 className="page-title">Dashboard</h3>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card>

              {/*{ this.state.loader ? <div className="panel__refresh"><LoadingIcon /></div> : '' }*/}

              <CardBody>

                <div className="card__title">
                  <h5 className="bold-text">Auto Trades </h5>
                  {/*<h5 className="subhead">Addresses I added to my contact list</h5>*/}
                </div>

                <form className="form form--horizontal" onSubmit={handleSubmit(this.startTradesSubmit.bind(this))}>

                  <div>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="radio"
                          component={renderRadioButtonField}
                          label="SKY"
                          radioValue="1"
                          defaultChecked={this.state.loc_asset &&  JSON.parse(this.state.loc_asset).asset_code === 'SKY' ? true : null}
                          onChange={(value) => this.changeAsset(value)}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="radio"
                          component={renderRadioButtonField}
                          label="SCOP"
                          radioValue="2"
                          defaultChecked={this.state.loc_asset && JSON.parse(this.state.loc_asset).asset_code === 'SCOP' ? true : null}
                          onChange={(value) => this.changeAsset(value)}
                        />
                      </div>
                    </div>
                  </div>

                  <hr/>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 1</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_1"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 2</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_2"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 3</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_3"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 4</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_4"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 5</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_5"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 6</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_6"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 7</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_7"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 8</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_8"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 9</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_9"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 10</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_10"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 11</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_11"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 12</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_12"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 13</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_13"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 14</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_14"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 15</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_15"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 16</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_16"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 17</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_17"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 18</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_18"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 19</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_19"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 20</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_20"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Options</span>
                    <Row>
                      <Col md={6} >
                        <div className="form__form-group-field">
                          <Field
                            name="amount_max"
                            component="input"
                            placeholder="Amont XLM (max)"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group-field">
                          <Field
                            name="interval"
                            component="input"
                            placeholder="Interval (sec)"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/*<div className="form__form-group">
                    <Row>
                      <Col md={6}>
                        <span className="form__form-group-label">Options</span>
                        <div className="form__form-group-field">
                          <Field
                            name="amount_max"
                            component="input"
                            type="number"
                            placeholder="Amont SKY (max)"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group-field">
                          <Field
                            name="interval"
                            component="input"
                            type="number"
                            placeholder="Interval (minutes)"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>*/}


                  <ButtonToolbar className="form__button-toolbar">
                    <Button type="button" onClick={reset}>Reset</Button>
                    {
                      this.state.orderbook ?
                        <Button color="primary" type="submit" >Start Trading</Button>
                      :
                        <Button color="primary" type="submit" disabled >Start Trading</Button>
                    }

                  </ButtonToolbar>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
    );
  }
}


Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'horizontal_form', // a unique identifier for this form
})(translate('common')(Dashboard));
