import React, { PureComponent } from 'react';
import { Table } from 'reactstrap';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import iconIdent from '../../Account/components/iconIdent';
import { numFormat, formatDate, getAsset } from '../../../../modules/index';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ToastStore} from "react-toasts";



class Holders extends PureComponent {

	constructor(props) {
	  super(props);
	  this.state = {
	    // activeTab: '1',
	  };
	}


	renderItem(item, index) {
		const { asset } = this.props
		const balance = item.balance.slice(0, -7)
		const supply = 10000000000;
		const percent = numFormat((balance / supply * 100), 3)

		return (
			<tr key={index}>
				<td>{item.position}</td>
        <td>
					<Link to={`/account/${item.account}`}>{iconIdent(item.account, 'icon-indent-sm')} <b>{item.account}</b></Link> &emsp;
					<CopyToClipboard text={item.account} onCopy={() => {}}>
						<i className="fa fa-clone"
							 color="secondary"
							 style={{cursor: 'pointer', fontSize: 14}}
							 onClick={() => ToastStore.success('Copied!')}></i>
					</CopyToClipboard>
        </td>
        <td> <b className="text-info">{numFormat(balance, 7)}</b> <b className={'text-warning'}>{asset.asset_code}</b></td>
        <td><b className='text-secondary'>{ percent }%</b></td>
      </tr>
		)
	}


	render() {
		const items = this.props.items;

		return (
      <Table striped responsive>
			  <thead>
			    <tr>
						<th>#</th>
			    	<th>Account</th>
		        <th>Balance</th>
		        <th>Share</th>
			    </tr>
			  </thead>
			  <tbody>

          { items.map(this.renderItem.bind(this)) }

			  </tbody>
			</Table>
		)
	}
}


export default translate('common')(Holders);
