import React, { PureComponent } from 'react';
// import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { numFormat } from '../../../../modules/index';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
// import LoadingIcon from 'mdi-react/LoadingIcon';



class AssetChart extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      // theme: localStorage.getItem('theme'),
      activeTab: '1',
      theme: 'theme-light',
        // xlm_price: false,
      count: 0,
      trade_agregation: this.props.trade_agregation
    };
  }

  componentDidMount() {
    this.setAssetData();
    // this.getXlmPrice();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.count !== nextState.count) {
      return true;
    }
    return false;

    // if (this.state.trade_agregation !== nextState.trade_agregation) {
    //   return true;
    // }
    // return false;
  }


  toggle = (tab) => {

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };


    // getXlmPrice() {
    //
    //     const price = JSON.parse(localStorage.getItem('price'));
    //
    //     this.setState({
    //         xlm_price: price,
    //     });
    // }



  setAssetData() {

    let buy_asset = this.props.buy_asset;
    let sell_asset = this.props.sell_asset;

    let asset_code,
        asset_code1,
        asset_issuer1,
        asset_code2,
        asset_issuer2;

    if (buy_asset.asset_code === 'XLM') {
      asset_code = 'XLM';

      asset_code1 = sell_asset.asset_code;
      asset_issuer1 = sell_asset.asset_issuee;

      asset_code2 = 'XLM';
      asset_issuer2 = 'native';
    }
    else if (sell_asset.asset_code === 'XLM') {
      asset_code = 'XLM';

      asset_code1 = buy_asset.asset_code;
      asset_issuer1 = buy_asset.asset_issuer;

      asset_code2 = 'XLM';
      asset_issuer2 = 'native';
    }
    else {
      asset_code1 = buy_asset.asset_code;
      asset_issuer1 = buy_asset.asset_issuer;

      asset_code2 = sell_asset.asset_code;
      asset_issuer2 = sell_asset.asset_issuer;
    }


    this.setState({
      asset_code,
      asset_code1,
      asset_issuer1,
      asset_code2,
      asset_issuer2,
      count: this.state.count++
    })
  }



  getArea() {

    // console.log('getArea this.props: ', this.props)

    var spline1 = [],
        volume1 = [],
        trades1 = [];

    this.props.trade_agregation.forEach((item, index) => {

        let price_asset_xlm = Number((item.close_r.d / item.close_r.n));
        let volume = Number(item.base_volume);
        let price = price_asset_xlm;

        // if (this.state.xlm_price) {
        //     const price_xlm_usd = this.state.xlm_price.crypto_price.xlm_usd;
        //     console.log('price_xlm_usd: ', price_xlm_usd)
        //
        //     price = (price_asset_xlm * price_xlm_usd);
        //     volume = (volume * price_xlm_usd);
        // }

        // console.log('price USD: ', price)
        // console.log('volume USD: ', volume)


        spline1.unshift([
            Number(item.timestamp),
            price
        ])

        volume1.unshift([
            Number(item.timestamp),
            volume
        ])

      trades1.unshift([
        Number(item.timestamp),
        Number(item.trade_count)
      ])
    })


    const optionsArea = {
      chart:{
          zoomType: 'x',
          height: '400px',
          backgroundColor:'transparent',
      },
      rangeSelector: {
          selected: 1
      },
      yAxis: [{ // Primary yAxis
          title: {
              text: `Price (${this.state.asset_code2})`,
          },
          labels: {
              style: {
                  color: Highcharts.getOptions().colors[1]
              }
          },
      },
      { // Secondary yAxis
          title: {
              style: {
                  color: Highcharts.getOptions().colors[0]
              }
          },
          labels: {
              // format: 'Test {value}',
              color: '#666666',
              style: {
                  color: '#bd9efbcc'
              }
          },
          opposite: false,
        }
      ],
      tooltip: {
          shared: true
      },
      responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal'
                },
                yAxis: {
                    labels: {
                        align: 'left',
                        x: 0,
                        y: -5
                    },
                    title: {
                        text: null
                    }
                },
                subtitle: {
                    text: null
                },
                credits: {
                    enabled: false
                }
            }
        }]
    },
      series:
      [
        {
          name: 'Price',
          type: 'areaspline',
          color: '#4ce1b6',
          threshold: null,
          data: spline1,
          tooltip: {
              valueSuffix: ' '+this.state.asset_code2,
              valueDecimals: 7
          },
          fillColor: {
              linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1
              },
              stops: [
                  [0, '#4ce1b63b'],
                  [1, Highcharts.Color('#4ce1b6').setOpacity(0).get('rgba')]
              ]
          }
        },
        {
          name: 'Volume',
          type: 'column',
          color: '#bd9efbcc', // #c88ffa, #cca8f, #70bbfd, #bd9efbcc
          yAxis: 1,
          data: volume1,
          tooltip: {
              valueSuffix: ' '+this.state.asset_code2,
              valueDecimals: 0
          }
        },
        {
          name: 'Trade count',
          type: 'spline',
          color: '#ff7901c2',
          yAxis: 1,
          data: trades1,
          tooltip: {
              // valueSuffix: ''
          }
        }
      ]
    }

    this.setTheme()

    return optionsArea;
  }



  getCandleStick() {

    let groupingUnits = [[
            'week',                         // unit name
            [1]                             // allowed multiples
        ], [
            'month',
            [1, 2, 3, 4, 6]
        ]],
        i = 0,
        ohlc = [],
        volume = [],
        trades = [];

    this.props.trade_agregation.forEach((item, index) => {

      let open = (item.open_r.d / item.open_r.n);
      let close = (item.close_r.d / item.close_r.n);
      let low = (item.low_r.d / item.low_r.n);
      let high = (item.high_r.d / item.high_r.n);

      ohlc.unshift([
          Number(item.timestamp), // the date
          open, // open
          high, // high
          low, // low
          close // close
      ]);

      volume.unshift([
          Number(item.timestamp), // the date
          Number(item.base_volume)// the volume
      ]);

      trades.unshift([
        Number(item.timestamp),
        Number(item.trade_count)
      ])
    })

    // this.setDarkTheme();

    let optionsCandlestick = {
      chart:{
          backgroundColor:'transparent',
      },
      rangeSelector: {
          selected: 1
      },

      title: {
          // text: 'AAPL Historical'
      },

      yAxis: [{
          labels: {
              align: 'right',
              x: -3
          },
          title: {
              text: 'Price'
          },
          height: '60%',
          lineWidth: 2,
          resize: {
              enabled: true
          }
      }, {
          labels: {
              align: 'right',
              x: -3
          },
          title: {
              text: 'Volume'
          },
          top: '65%',
          height: '35%',
          offset: 0,
          lineWidth: 2
      }],

      tooltip: {
          split: true
      },
      plotOptions: {
       candlestick: {
              // color: '#fb7d89',
              upColor: '#47d47a'
          }
      },
      responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal'
                },
                yAxis: {
                    labels: {
                        align: 'left',
                        x: 0,
                        y: -5
                    },
                    title: {
                        text: null
                    }
                },
                subtitle: {
                    text: null
                },
                credits: {
                    enabled: false
                }
            }
        }]
      },
      series: [{
          type: 'candlestick',
          name: 'Price XLM',
          data: ohlc,
          dataGrouping: {
              units: groupingUnits
          }
      },
      {
        type: 'column',
        name: 'Volume XLM',
        data: volume,
        yAxis: 1,
        dataGrouping: {
            units: groupingUnits
        },
        tooltip: {
            valueDecimals: 0
        },
        color: '#bd9efbcc',
      },
      {
        name: 'Trade count',
        type: 'spline',
        color: '#ff7901c2',
        yAxis: 1,
        data: trades,
        tooltip: {
            // valueSuffix: ''
        }
      }]
    }


    Highcharts.theme = {
        colors: ['#f45b5b', '#8085e9', '#8d4654', '#7798BF', '#aaeeee',
            '#ff0066', '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
    };
    // // Apply the theme
    Highcharts.setOptions(Highcharts.theme);

    // this.setTheme()

    return optionsCandlestick;
  }




  getDepthChart() {

    // console.log('getDepthChart orderbook: ', this.props.orderbook)

    let bids = [],
        asks = [],
        bidsDepth = 0,
        asksDepth = 0;

    if (this.props.orderbook.bids && this.props.orderbook.asks) {

      this.props.orderbook.bids.forEach((item, index) => {

        let price = Number(item.price_r.d / item.price_r.n);
        let volume = Number(price * item.amount);
        bidsDepth += volume;

        bids.push([
          price,
          bidsDepth,
          bidsDepth
        ])
      })

      this.props.orderbook.asks.forEach((item, index) => {

        let price = Number(item.price_r.d / item.price_r.n);
        let volume = Number(item.amount);
        asksDepth += volume;

        asks.unshift([
          price,
          asksDepth,
          bidsDepth
        ])
      })
    }

    // this.setDarkTheme();

    const optionsDepth = {

      chart: {
          type: 'area',
          zoomType: 'xy',
          backgroundColor:'transparent',
      },
      title: {
          // text: 'ETH-BTC Market Depth'
      },
      xAxis: {
          minPadding: 0,
          maxPadding: 0,
          plotLines: [{
              color: '#888',
              // value: 0.1523,
              width: 1,
              label: {
                  // text: 'Actual price',
                  rotation: 90
              }
          }],
          title: {
              text: 'Price'
          }
      },
      yAxis: [{
          lineWidth: 1,
          gridLineWidth: 1,
          title: null,
          tickWidth: 1,
          tickLength: 5,
          tickPosition: 'inside',
          labels: {
              align: 'left',
              x: 8
          }
      }, {
          opposite: true,
          linkedTo: 0,
          lineWidth: 1,
          gridLineWidth: 0,
          title: null,
          tickWidth: 1,
          tickLength: 5,
          tickPosition: 'inside',
          // labels: {
          //     align: 'right',
          //     x: -8
          // }
      }],
      legend: {
          enabled: false
      },
      plotOptions: {
          area: {
              fillOpacity: 0.2,
              lineWidth: 1,
              step: 'center'
          }
      },
      tooltip: {
          headerFormat: `<span style="font-size=10px;">Volume XLM</span><br/>`,
          valueDecimals: 0
      },
      responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal'
                },
                yAxis: {
                    labels: {
                        align: 'left',
                        x: 0,
                        y: -5
                    },
                    title: {
                        text: null
                    }
                },
                subtitle: {
                    text: null
                },
                credits: {
                    enabled: false
                }
            }
        }]
      },
      series: [{
          name: 'Bids',
          data: bids,
          color: '#fc5857',
      }, {
          name: 'Asks',
          data: asks,
          color: '#03a7a8',
      }]
    }

    // this.setTheme()

    return optionsDepth;
  }


  setTheme() {
    const theme = localStorage.getItem('theme');
    if (theme === 'theme-dark') {
      this.themeDark()
    }
  }


  themeDark() {

      Highcharts.theme = {
          colors: ['#2b908f', '#90ee7e', '#f45b5b', '#7798BF', '#aaeeee', '#ff0066',
              '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
          chart: {
              backgroundColor: {
                  linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
                  stops: [
                      [0, '#2a2a2b'],
                      [1, '#3e3e40']
                  ]
              },
              style: {
                  fontFamily: '\'Unica One\', sans-serif'
              },
              plotBorderColor: '#606063'
          },
          title: {
              style: {
                  color: '#E0E0E3',
                  textTransform: 'uppercase',
                  fontSize: '20px'
              }
          },
          subtitle: {
              style: {
                  color: '#E0E0E3',
                  textTransform: 'uppercase'
              }
          },
          xAxis: {
              gridLineColor: '#707073',
              labels: {
                  style: {
                      color: '#E0E0E3'
                  }
              },
              lineColor: '#707073',
              minorGridLineColor: '#505053',
              tickColor: '#707073',
              title: {
                  style: {
                      color: '#A0A0A3'

                  }
              }
          },
          yAxis: {
              gridLineColor: '#707073',
              labels: {
                  style: {
                      color: '#E0E0E3'
                  }
              },
              lineColor: '#707073',
              minorGridLineColor: '#505053',
              tickColor: '#707073',
              tickWidth: 1,
              title: {
                  style: {
                      color: '#A0A0A3'
                  }
              }
          },
          tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.85)',
              style: {
                  color: '#F0F0F0'
              }
          },
          plotOptions: {
              series: {
                  dataLabels: {
                      color: '#B0B0B3'
                  },
                  marker: {
                      lineColor: '#333'
                  }
              },
              boxplot: {
                  fillColor: '#505053'
              },
              candlestick: {
                  lineColor: 'white'
              },
              errorbar: {
                  color: 'white'
              }
          },
          legend: {
              itemStyle: {
                  color: '#E0E0E3'
              },
              itemHoverStyle: {
                  color: '#FFF'
              },
              itemHiddenStyle: {
                  color: '#606063'
              }
          },
          credits: {
              style: {
                  color: '#666'
              }
          },
          labels: {
              style: {
                  color: '#707073'
              }
          },

          drilldown: {
              activeAxisLabelStyle: {
                  color: '#F0F0F3'
              },
              activeDataLabelStyle: {
                  color: '#F0F0F3'
              }
          },

          navigation: {
              buttonOptions: {
                  symbolStroke: '#DDDDDD',
                  theme: {
                      fill: '#505053'
                  }
              }
          },

          // scroll charts
          rangeSelector: {
              buttonTheme: {
                  fill: '#505053',
                  stroke: '#000000',
                  style: {
                      color: '#CCC'
                  },
                  states: {
                      hover: {
                          fill: '#707073',
                          stroke: '#000000',
                          style: {
                              color: 'white'
                          }
                      },
                      select: {
                          fill: '#000003',
                          stroke: '#000000',
                          style: {
                              color: 'white'
                          }
                      }
                  }
              },
              inputBoxBorderColor: '#505053',
              inputStyle: {
                  backgroundColor: '#333',
                  color: 'silver'
              },
              labelStyle: {
                  color: 'silver'
              }
          },

          navigator: {
              handles: {
                  backgroundColor: '#666',
                  borderColor: '#AAA'
              },
              outlineColor: '#CCC',
              maskFill: 'rgba(255,255,255,0.1)',
              series: {
                  color: '#7798BF',
                  lineColor: '#A6C7ED'
              },
              xAxis: {
                  gridLineColor: '#505053'
              }
          },

          scrollbar: {
              barBackgroundColor: '#808083',
              barBorderColor: '#808083',
              buttonArrowColor: '#CCC',
              buttonBackgroundColor: '#606063',
              buttonBorderColor: '#606063',
              rifleColor: '#FFF',
              trackBackgroundColor: '#404043',
              trackBorderColor: '#404043'
          },

          // special colors for some of the
          legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
          background2: '#505053',
          dataLabelsColor: '#B0B0B3',
          textColor: '#C0C0C0',
          contrastTextColor: '#F0F0F3',
          maskColor: 'rgba(255,255,255,0.3)'
      }

      Highcharts.setOptions(Highcharts.theme);
  }



  render() {

    // console.log('AC state: ', this.state)
    // console.log('AC props: ', this.props)


    let trade_agregation = this.props.trade_agregation;
    let orderbook = this.props.orderbook;

    let price = 'not price';
    if (trade_agregation.length > 0) {
      price = (trade_agregation[0].close_r.d / trade_agregation[0].close_r.n);
    }

    let bids_price = 0, asks_price = 0, spread = 0;

    if (orderbook.bids && orderbook.bids.length > 0) {
      bids_price = (orderbook.bids[0].price_r.d / orderbook.bids[0].price_r.n);
    }
    if (orderbook.asks && orderbook.asks.length > 0) {
      asks_price = (orderbook.asks[0].price_r.d / orderbook.asks[0].price_r.n);
    }

    spread = (bids_price - asks_price).toFixed(7);


    return (
      <div>

        {/*{ this.state.loader ? <div className="panel__refresh"><LoadingIcon /></div> : null }*/}

        <div className="card__title" style={{marginBottom: '0px'}}>
          {
            this.props.point == 'trade' ?
              null
            :
              <h5 className="bold-text">
                <font>ASSET: </font>&nbsp;
                <Link to={`/asset/${this.state.asset_code1}-${this.state.asset_issuer1}`} className="text-warning" href="#"><b>{this.state.asset_code1}</b></Link>&nbsp;/&nbsp;
                <Link to={`/asset/${this.state.asset_code2}-${this.state.asset_issuer2}`}><b className="text-warning">{this.state.asset_code2}</b></Link>&nbsp;
                <b className="text-info">{numFormat(price, 7)}</b>
                {
                  this.props.asset_db ?
                    <span style={{float: 'right'}}>
                      <font className="text-secondary">Trust Rating:</font> {' '}
                      <font className="text-info" style={{fontSize: 18}}>{numFormat(this.props.asset_db.all_rating, 1)}</font>
                      {/*<br/><font className="rating-info">Rating is calculated from various statistics</font>*/}
                    </span>
                  : null
                }
              </h5>
          }
          <h5 className="subhead">
            ASK: <b className="text-success">{numFormat(asks_price, 7)}</b> /
            BID: <b className="text-danger">{numFormat(bids_price, 7)}</b> /
            SPREAD: <b className="text-secondary">{numFormat(spread, 7)}</b>{' '}
            <small>({numFormat(100 / (bids_price / spread), 2)}%)</small>
            {/*{
              this.props.asset_db ?
                <span style={{float: 'right'}} className="text-secondary">Provided by {' '}
                  <a href="https://stellarport.io/exchange" target='_blank' rel='noopener noreferrer'>StellarPort</a> and {' '}
                  <a href="https://stellar.expert" target='_blank' rel='noopener noreferrer'>StellarExpert</a>
                </span>
              : null
            }*/}
          </h5>
        </div>



        <div className="tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => {
                    this.toggle('1');
                  }}
                >
                  <b>Area Spline</b>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => {
                    this.toggle('2');
                  }}
                >
                  <b>Candle Stick</b>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => {
                    this.toggle('3');
                  }}
                >
                  <b>Market Depth</b>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">

                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={this.getArea()}
                  />

              </TabPane>
              <TabPane tabId="2">

                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={this.getCandleStick()}
                  />

              </TabPane>
              <TabPane tabId="3">

                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={this.getDepthChart()}
                    className="depth-bl"
                  />

              </TabPane>
            </TabContent>
          </div>
        </div>


        {/*<p style={{margin: '20px 0 0 20px'}}>
          * View, issue and secure decentralized trading of any Stellar Assets without trading fees.
        </p>*/}

      </div>
    )
  }
}


// AssetChart.propTypes = {
//   t: PropTypes.func.isRequired,
// };

// const mapStateToProps = (state, ownProps) => {
//   console.log('ownProps: ', ownProps)
//   console.log('state: ', state)

//   return {
//     theme: state.theme
//   }
// }

export default (AssetChart); // connect(mapStateToProps)
