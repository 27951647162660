import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, UncontrolledCarousel } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import axios from 'axios';
import { GLOBE } from '../../../modules/globeVars/index';
import each from 'lodash/each';
import LoadingIcon from 'mdi-react/LoadingIcon';
import ABTestingAnalytics from './components/ABTestingAnalytics';
import StellarCap from './components/StellarCap';
import TableTxs from './components/TableTxs';
import TableOps from './components/TableOps';
import TopAssets from './components/TopAssets';
// import Highcharts from 'highcharts'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment';
import Footer from '../../Layout/footer/Footer';
import {getTitle, numFormat, } from '../../../modules/index';
import Top4Assets from './components/Top4Assets';
import getPrice from '../../App/GetPrice';
import {getMarketAssets } from '../../../endpoints/API'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 7
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3
  }
};


const items_slider = [
  {
    src: `${process.env.PUBLIC_URL}/img/slider/1.png`,
    // header: 'Multi-Account Stellar Wallet',
    altText: 'Multi-Account Stellar Wallet',
    // caption: 'Simple and secure wallet for your Stellar Assets'
  },
  {
    src: `${process.env.PUBLIC_URL}/img/slider/2.png`,
    // header: 'Multicurrency Transfers and Payments',
    altText: 'Multicurrency Transfers and Payments',
    // caption: 'Payments with exchange along the way of offers'
  },
  {
    src: `${process.env.PUBLIC_URL}/img/slider/3.png`,
    // header: 'Decentralized Asset Exchange (DEX)',
    altText: 'Decentralized Exchange (DEX)',
    // caption: 'Distributed trading of crypto assets without commissions'
  },
  {
    src: `${process.env.PUBLIC_URL}/img/slider/4.png`,
    // header: 'Crypto / Fiat exchange',
    altText: 'Crypto / Fiat exchange',
    // caption: 'Buying / selling crypto for fiat money'
  },
  {
    src: `${process.env.PUBLIC_URL}/img/slider/5.png`,
    // header: 'Tokenization Assets (Issuing Tokens)',
    altText: 'Tokenization Assets (Issuing Tokens)',
    // caption: 'Issue of own tokens and output to SDEX'
  },
  {
    src: `${process.env.PUBLIC_URL}/img/slider/6.png`,
    // header: 'Claimable Balance',
    altText: 'Claimable Balance',
    // caption: 'Claimable Balance'
  },
  {
    src: `${process.env.PUBLIC_URL}/img/slider/7.png`,
    // header: 'QR Payment Request',
    altText: 'QR Payment Request',
    // caption: 'QR Payment Request'
  },
  // {
  //   src: `${process.env.PUBLIC_URL}/img/slider/1.png`,
  //   // header: 'IEO Launchpad',
  //   altText: 'IEO Launchpad',
  //   // caption: 'Investing in startups through IEO Launchpad'
  // },
  {
    src: `${process.env.PUBLIC_URL}/img/slider/8.png`,
    // header: 'Block explorer',
    altText: 'Block explorer',
    // caption: 'Search and view all data of the Stellar blockchain'
  }
];



const categories = [],
      txs = [],
      ops = [];

const ledger_opts = {
  categories: [],
  transactions: [],
  operations: [],
}


class Home extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      data_tx_op: [],
      transactions: null,
      refresh: false,
      txs_ops: [],
      load_txs_ops: false,
      load_ledgers: false,
      ledgers: [],
      ledger_opts: {
        operations: [],
        transactions: []
      },
      base_price_history: false,
      price: false,
      assets_db: [],
      cap: false,
    };
  }

  count = 0

  componentDidMount() {

    const title = 'Stellar Wallet, SDEX and Block Explorer for Stellar Network';
    const description = 'Scopuly is the perfect way to buy, trade, pay & transfer crypto and invest in promising startups';

    getTitle(title, description);

    this.getStat30Days();
    this.getLadgers();
    // this.getBasePriceHistory();
    this.getBasePrice();
    this.getAssets()
  }



  getAssets = () => {
    getMarketAssets(0,20)
      .then(result => {
        // console.log('result: ', result)
        if (result.data && result.data.length) {
          this.setState({ assets_db: result.data })
        }
      })
      .catch(error => {
        console.log('error: ', error)
      })
  }



  getBasePrice() {
    let price = JSON.parse(localStorage.getItem('price'));

    if (price && price.crypto_price) {
      this.setState({
        price: price.crypto_price.xlm_usd,
        cap: price.crypto_price.usd.market_cap,
        volume: price.crypto_price.usd.volume_24h,
        change: price.crypto_price.usd.percent_change_24h,
        changeColor: Number(price.crypto_price.usd.percent_change_24h) < 0 ? 'danger' : 'success',
      });
    }
    else if (price && price.local_price) {
      this.setState({ price: price.local_price.USD })
    }
    else {
      if (this.count < 2) {
        getPrice()
        setTimeout(() => {
          this.getBasePrice()
          this.count++;
        }, 2000)
      }
    }
  }



  getBasePriceHistory() {

    const formData = new FormData();
      formData.append('limit', 90)

      axios.post(GLOBE.API_URL+`/get_base_price`, formData)
        .then(response => {
          // console.log('response: ', response);
          this.setState({
            base_price_history: response.data
          })
      }).catch (error => {
        console.log('error: ', error)
    })
  }



  getArea() {

    const data = this.state.base_price_history;
    const { price } = this.state;

    let spline1 = [],
        volume1 = [];

    data.forEach((item) => {

      const toTimestamp = strDate => {
        const dateR = strDate.replace(/ /g,"T")
        return Date.parse(dateR)
      }

      spline1.unshift([
        Number(toTimestamp(item.date)),
        Number(item.price)
      ])

      volume1.unshift([
        Number(toTimestamp(item.date)),
        Number(item.volume_24)
      ])

      // trades1.unshift([
      //   Number(item.timestamp),
      //   Number(item.trade_count)
      // ])
    })


    const optionsArea = {
      chart:{
          zoomType: 'x',
          height: '400px',
          backgroundColor:'transparent',
      },
      // tooltip: {
      //     xDateFormat: '%d/%m/%Y',
      //     shared: true,
      //     split: false,
      //     enabled: true
      // },
      title: {
          text: `<span>Price XLM/USD: <span class="text-${this.state.changeColor}">${numFormat(price && price, 4)}</span> </span>`
      },
      scrollbar: {
          enabled: false
      },
      rangeSelector: {
          selected: 1
      },
      yAxis: [{ // Primary yAxis
          title: {
              text: `Price USD`,
          },
          labels: {
              style: {
                  color: Highcharts.getOptions().colors[1]
              }
          },
      },

      { // Secondary yAxis
          title: {
              style: {
                  color: Highcharts.getOptions().colors[0]
              }
          },
          labels: {
              // format: 'Test {value}',
              color: '#666666',
              style: {
                  color: Highcharts.getOptions().colors[0]
              }
          },
          opposite: false,
        }
      ],
      tooltip: {
          formatter: function() {
            const formatted = moment(this.x).format('L');
            return `Price XLM: <b>${numFormat(this.y, 4)}</b> USD | ${formatted}`;
          }
      },
      responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal'
                },
                yAxis: {
                    labels: {
                        align: 'left',
                        x: 0,
                        y: -5
                    },
                    title: {
                        text: null
                    }
                },
                subtitle: {
                    text: null
                },
                credits: {
                    enabled: false
                }
            }
        }]
    },
      series:
      [
        {
          name: 'Price XLM/USD for the last 90 days',
          type: 'areaspline',
          color: '#4ce1b6',
          threshold: null,
          data: spline1,
          tooltip: {
              valueSuffix: ' USD',
              valueDecimals: 4
          },
          fillColor: {
              linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1
              },
              stops: [
                  [0, '#4ce1b63b'],
                  [1, Highcharts.Color('#4ce1b6').setOpacity(0).get('rgba')]
              ]
          }
        },
        {
          name: 'Volume',
          type: 'column',
          color: 'rgba(189, 158, 251, 0.54)', // #c88ffa, #cca8fb
          yAxis: 1,
          data: volume1,
          tooltip: {
              valueSuffix: ' USD',
              valueDecimals: 0
          }
        }
      ]
    }

    this.setTheme()

    return optionsArea;
  }


  setTheme() {
    const theme = localStorage.getItem('theme');
    if (theme === 'theme-dark') {
      this.themeDark()
    }
  }



  themeDark() {

      Highcharts.theme = {
          colors: ['#2b908f', '#90ee7e', '#f45b5b', '#7798BF', '#aaeeee', '#ff0066',
              '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
          chart: {
              backgroundColor: {
                  linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
                  stops: [
                      [0, '#2a2a2b'],
                      [1, '#3e3e40']
                  ]
              },
              style: {
                  fontFamily: '\'Unica One\', sans-serif'
              },
              plotBorderColor: '#606063'
          },
          title: {
              style: {
                  color: '#E0E0E3',
                  textTransform: 'uppercase',
                  fontSize: '20px'
              }
          },
          subtitle: {
              style: {
                  color: '#E0E0E3',
                  textTransform: 'uppercase'
              }
          },
          xAxis: {
              gridLineColor: '#707073',
              labels: {
                  style: {
                      color: '#E0E0E3'
                  }
              },
              lineColor: '#707073',
              minorGridLineColor: '#505053',
              tickColor: '#707073',
              title: {
                  style: {
                      color: '#A0A0A3'

                  }
              }
          },
          yAxis: {
              gridLineColor: '#707073',
              labels: {
                  style: {
                      color: '#E0E0E3'
                  }
              },
              lineColor: '#707073',
              minorGridLineColor: '#505053',
              tickColor: '#707073',
              tickWidth: 1,
              title: {
                  style: {
                      color: '#A0A0A3'
                  }
              }
          },
          tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.85)',
              style: {
                  color: '#F0F0F0'
              }
          },
          plotOptions: {
              series: {
                  dataLabels: {
                      color: '#B0B0B3'
                  },
                  marker: {
                      lineColor: '#333'
                  }
              },
              boxplot: {
                  fillColor: '#505053'
              },
              candlestick: {
                  lineColor: 'white'
              },
              errorbar: {
                  color: 'white'
              }
          },
          legend: {
              itemStyle: {
                  color: '#E0E0E3'
              },
              itemHoverStyle: {
                  color: '#FFF'
              },
              itemHiddenStyle: {
                  color: '#606063'
              }
          },
          credits: {
              style: {
                  color: '#666'
              }
          },
          labels: {
              style: {
                  color: '#707073'
              }
          },

          drilldown: {
              activeAxisLabelStyle: {
                  color: '#F0F0F3'
              },
              activeDataLabelStyle: {
                  color: '#F0F0F3'
              }
          },

          navigation: {
              buttonOptions: {
                  symbolStroke: '#DDDDDD',
                  theme: {
                      fill: '#505053'
                  }
              }
          },

          // scroll charts
          rangeSelector: {
              buttonTheme: {
                  fill: '#505053',
                  stroke: '#000000',
                  style: {
                      color: '#CCC'
                  },
                  states: {
                      hover: {
                          fill: '#707073',
                          stroke: '#000000',
                          style: {
                              color: 'white'
                          }
                      },
                      select: {
                          fill: '#000003',
                          stroke: '#000000',
                          style: {
                              color: 'white'
                          }
                      }
                  }
              },
              inputBoxBorderColor: '#505053',
              inputStyle: {
                  backgroundColor: '#333',
                  color: 'silver'
              },
              labelStyle: {
                  color: 'silver'
              }
          },

          navigator: {
              handles: {
                  backgroundColor: '#666',
                  borderColor: '#AAA'
              },
              outlineColor: '#CCC',
              maskFill: 'rgba(255,255,255,0.1)',
              series: {
                  color: '#7798BF',
                  lineColor: '#A6C7ED'
              },
              xAxis: {
                  gridLineColor: '#505053'
              }
          },

          scrollbar: {
              barBackgroundColor: '#808083',
              barBorderColor: '#808083',
              buttonArrowColor: '#CCC',
              buttonBackgroundColor: '#606063',
              buttonBorderColor: '#606063',
              rifleColor: '#FFF',
              trackBackgroundColor: '#404043',
              trackBorderColor: '#404043'
          },

          // special colors for some of the
          legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
          background2: '#505053',
          dataLabelsColor: '#B0B0B3',
          textColor: '#C0C0C0',
          contrastTextColor: '#F0F0F3',
          maskColor: 'rgba(255,255,255,0.3)'
      }

      Highcharts.setOptions(Highcharts.theme);
  }



  getLadgers() {

    Server.ledgers()
      .limit(50)
      .order('desc')
      .call()
      .then(ledgerResult => {

        this.pushLedgers(ledgerResult.records);
        this.streamLadgers(ledgerResult.records[0].paging_token);
      })
      .catch(function(err) {
        console.log(err)
    })
  }

  pushLedgers(ledgers) {

    ledgers.forEach((item, index) => {
      // ledger_opts.categories.unshift(moment(item.closed_at).format('LTS'));
      this.state.ledger_opts.transactions.unshift( Number(item.successful_transaction_count));
      this.state.ledger_opts.operations.unshift(item.operation_count);
    })

    this.setState({
      ledgers: this.state.ledger_opts,
      load_ledgers: true,
    })
  }


  streamLadgers(paging_token) {

    let ledgers = [], count = 0;

    Server.ledgers()
      .order('desc')
      .limit(50)
      // .cursor(paging_token)
      .stream({
        onmessage: (data) => {
          count ++;

          if (count >= 50) {
            ledger_opts.transactions = (Number(data.successful_transaction_count));
            ledger_opts.operations = data.operation_count;
            count = 0;

            // this.setState({
            //   ledgers: ledger_opts,
            //   load_ledgers: true,
            // })
          }
        }
    });
  }



  ledgersChart(ledgers) {
    const $this = this;

    const options = {

      chart: {
          type: 'column',
          backgroundColor:'transparent',
          events: {
              load: function () {
                  var series1 = this.series[0];
                  var series2 = this.series[1];

                  var intervalId = setInterval(() => {
                    let pathname = window.location.pathname;
                    if (pathname === '/' || pathname === '/home') {
                      series1.addPoint(ledger_opts.operations, true, true);
                      series2.addPoint(ledger_opts.transactions, true, true);
                    }
                    else {
                      clearInterval(intervalId);
                    }
                  }, 2000);
              }
          }
      },

      title: {
          text: ''
      },

      xAxis: {
          // categories: this.state.ledgers.categories
          type: 'datetime'
      },

      yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
              text: ''
          }
      },

      tooltip: {
          formatter: function () {
              return this.series.name + ': <b>'+this.y+'</b><br/>'
          }
      },

      plotOptions: {
          column: {
              stacking: 'normal'
          }
      },

      series: [
        {
          name: 'Operations',
          data: this.state.ledgers.operations,
          color: '#4ce1b6',
        },
        {
          name: 'Transactions',
          data: this.state.ledgers.transactions,
          color: '#70bbfd',
        }
      ],

      responsive: {
          rules: [{
              condition: {
                  maxWidth: 500
              },
              chartOptions: {
                  legend: {
                      layout: 'horizontal',
                      align: 'center',
                      verticalAlign: 'bottom'
                  }
              }
          }]
      }
    }

    return options;
  }


  getStat30Days() {
    // const data_tx_op = [];
    const txs_ops = {
      day: [],
      transactions: [],
      operations: [],
    }

    axios.post(GLOBE.API_URL+'/get_tx_30day')
    .then(response => {

      let data = response.data.reverse();

      data.forEach((item, index) => {
        txs_ops.day.push(item.date);
        txs_ops.transactions.push(item.transaction_count);
        txs_ops.operations.push(item.operation_count);
      })

      this.setState({
        txs_ops: txs_ops,
        load_txs_ops: true,
      })
    }).catch (error => {
      console.log('error: ', error)
    });
  }


  setChartOptions() {

    let options = {
        chart: {
            type: 'column',
            backgroundColor:'transparent',
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: this.state.txs_ops.day,
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [{
            name: 'Transactions',
            data: this.state.txs_ops.transactions,
            color: '#70bbfd',

        }, {
            name: 'Operations',
            data: this.state.txs_ops.operations,
            color: '#4ce1b6',

        }]
    }

    return options;
  }




  render() {

    const t = this.props.t;
    const limit = 20;

    if (this.state.load_txs_ops) {
      var options = this.setChartOptions();
    }
    if (this.state.load_ledgers) {
      // var ledgers = this.ledgersChart();
    }


    return (
      <Container className="dashboard">

        <Row>
          <Col md={12}>
            <h3 className="page-title">{t('home.page_title')} </h3>
            <h3 className="page-subhead subhead">Scopuly is the perfect way to <b>buy</b>, <b>store</b>, <b>invest</b>, <b>trade</b>, <b>pay</b> and <b>transfer</b> crypto assets.</h3>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card>
               <CardBody style={{padding: '10px'}}>
                <UncontrolledCarousel items={items_slider} />
               </CardBody>
            </Card>
          </Col>
        </Row>


        <Row>
          <Col md={3}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">About Scopuly</h5>
                </div>
                <div>
                  <img src="https://www.scop.scopuly.com/images/scop/liquidity.png" alt=""/>
                </div>
                <br/>
                <p>
                  <span>
                    Scopuly allows you to make payments worldwide in any digital currency, trade digital assets on SDEX,
                    issue tokens and manage your Stellar account. No fees, cross-border, decentralized.
                  </span>
                  <br/><br/>
                  <a className={'btn btn-sm btn-primary mb-0'}
                     href={'https://about.scopuly.com/'}
                     target={'_blank'}
                  >Learm more</a>
                </p>
              </CardBody>
            </Card>
          </Col>

          <Col md={3}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">About SCOP Token</h5>
                </div>
                <div>
                  <img src="https://www.scop.scopuly.com/images/scop/trader.png" alt=""/>
                </div>
                <br/>

                <p>
                  <span>
                  SCOP rewards users, holders, traders, liquidity providers and contributes to the growth of the Scopuly
                    community and ecosystem by providing access to some of Scopuly's special services.
                </span>
                  <br/><br/>
                  <a className={'btn btn-sm btn-primary mb-0'}
                     href={'https://scop.scopuly.com/'}
                     target={'_blank'}
                  >Learm more</a>
                </p>
              </CardBody>
            </Card>
          </Col>

          <Col md={6}>
            <Card>
              <CardBody >
                <div className="card__title">
                  <h5 className="bold-text">Services</h5>
                </div>

                <div className={'services-start text-secondary'}>

                  <Row>
                    <Col>
                      <a href="/wallet">
                        <b className={'item-title'}>Wallet</b>
                        <img src="https://about.scopuly.com/images/content-screen/can-do/Can_do_0001.png" alt=""/>
                      </a>
                    </Col>
                    <Col>
                      <a href="/trade">
                        <b className={'item-title'}>Trade</b>
                        <img src="https://about.scopuly.com/images/content-screen/can-do/Can_do_0003.png" alt=""/>
                      </a>
                    </Col>
                    <Col>
                      <a href="/swap">
                        <b className={'item-title'}>Swap</b>
                        <img src="https://about.scopuly.com/images/content-screen/can-do/Can_do_002.png" alt=""/>
                      </a>
                    </Col>
                  </Row>

                  <br/><br/>

                  <Row>
                    <Col ml={3}>
                      <a href="/fiat">
                        <b className={'item-title'}>Buy/Sell Crypto</b>
                        <img src="https://about.scopuly.com/images/content-screen/can-do/Can_do_004.png" alt=""/>
                      </a>
                    </Col>
                    <Col ml={3}>
                      <a href="/claimable-balance">
                        <b className={'item-title'}>Claimable Balance</b>
                        <img src="https://about.scopuly.com/images/content-screen/can-do/Can_do_0006.png" alt=""/>
                      </a>
                    </Col>
                    <Col ml={3}>
                      <a href="/create-token">
                        <b className={'item-title'}>Create Token</b>
                        <img src="https://about.scopuly.com/images/content-screen/can-do/Can_do_0005.png" alt=""/>
                      </a>
                    </Col>
                  </Row>

                </div>

              </CardBody>
            </Card>
          </Col>
        </Row>


        <Row className="wr-row">
          <Col md={12} className="">

            <Carousel
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={10000}
              arrows={false}
              customButtonGroup={''}
              keyBoardControl
              renderButtonGroupOutside
              renderDotsOutside={false}
              className="assets-carousel"
              infinite={true}
            >
              {
                this.state.assets_db ?
                  this.state.assets_db.map((item, index) => {
                    return (
                      <div key={item.id} style={{opacity: 0.7}}>
                        <a href={`/trade/${item.asset_code}-XLM/${item.asset_issuer}/native`}>
                          <div className="wr-asset-item-icon">
                            <img className="asset-item-icon-top" src={item.image == 'undefined' || item.image == '' ? '/img/digitalcoin.png' : item.image} />
                          </div>
                          <div style={{fontSize: 12}}>
                            <div><b className="text-secondary">{item.asset_code}</b> <small className={`text-${item.change_24h_color}`}>{item.change24}%</small></div>
                            <div className="asset-domain">{item.home_domain}</div>
                          </div>
                        </a>
                      </div>
                    )
                  })
                  : null
              }
            </Carousel>
            <br/>
          </Col>
        </Row>


        {
          // this.state.base_price_history ?
          //   <Row>
          //     <Col md={12}>
          //       <Card>
          //         <CardBody>
          //
          //           <HighchartsReact
          //             highcharts={Highcharts}
          //             constructorType={'stockChart'}
          //             options={this.getArea()}
          //           />
          //
          //         </CardBody>
          //       </Card>
          //     </Col>
          //   </Row>
          //   :
          //     null
        }

        {
          this.state.price ?
            <div>
              { this.state.cap && <StellarCap {...this.state} /> }

              <Top4Assets {...this.state} />
            </div>
          : null
        }


        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Stellar Assets</h5>
                  <h5 className="subhead">Top {limit} Stellar Assets</h5>
                </div>

                {
                  this.state.price > 0 ?
                    <TopAssets page={'home'} limit={limit} {...this.state} />
                  : null
                }

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card>
              <CardBody className="dashboard">
                {this.state.refresh ? <div className="panel__refresh"><LoadingIcon /></div> : ''}
                <div className="card__title">
                  <h5 className="bold-text">Stellar Transactions & Operations in the last 30 days</h5>
                </div>
                {
                  this.state.load_txs_ops ?
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                      />
                  : null
                }
              </CardBody>
            </Card>
          </Col>

          {/*<Col md={12}>
            <Card>
              <CardBody className="dashboard">
                {this.state.refresh ? <div className="panel__refresh"><LoadingIcon /></div> : ''}
                <div className="card__title">
                  <h5 className="bold-text">TRANSACTIONS & OPERATIONS IN THE LAST LADGERS (LIVE)</h5>
                </div>

                {
                  this.state.load_ledgers ?
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={ledgers}
                      />
                  : null
                }

              </CardBody>
            </Card>
          </Col>*/}

          {/*<ABTestingAnalytics data={this.state.data_tx_op} />*/}
        </Row>
        <Row>
          <TableTxs />
          <TableOps />
        </Row>

        <Footer />

      </Container>
    );
  }
}


// Home.propTypes = {
//   t: PropTypes.func.isRequired,
// };


export default translate('common')(Home);
