import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody, Button, ButtonToolbar, Nav, NavItem, NavLink, TabContent, TabPane, Modal  } from 'reactstrap';
import { Field, reduxForm, change} from 'redux-form';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { GLOBE } from '../../../modules/globeVars/index';
import axios from 'axios';
import StellarSdk, { Transaction } from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import { parseStellarUri, PayStellarUri } from '@stellarguard/stellar-uri';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import {
  shortAddr,
  numFormat,
  formatDate,
  get_loc_wallets,
  baseReserve,
  minBalance,
  getAuth,
  needDeauthAll,
  checkAuth,
  getTitle,
  getIconConnectProvider
} from '../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from 'sweetalert';
import Footer from '../../Layout/footer/Footer';
import { QRCode } from 'react-qrcode-logo';
import iconIdent from "../Account/components/iconIdent";


const wallets_arr = [];


const renderTextField = ({
  input, label, meta: { touched, error }, children, select, type,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);



class Receive extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      wallets: get_loc_wallets(),
      loader: false,
      select_addr: false,
      select_asset: false,
      paste_recepient_address: true,
      select_recepient_address: false,
      modal: false,
      uri: false,
      amount: 0,
      memo: false,
      msg: false,
      msg_show: false,
      msg_length: false,
      invoice_id: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }


  componentDidMount() {
    // this.filterAddress()
    // this.setState({ key: 'value' }, () => {
    //     console.log('State modified: ', this.state);
    // })
  }



  toggleModal() {
    this.setState({
      modal: !this.state.modal,
    });
  }


  filterAddress() {

    const wallets = this.state.wallets;

    this.setState({ loader: true, });

    if (wallets) {
      wallets.forEach((item, index) => {
        this.getAccount(item, index);
      })
    }

    if (this.state.wallets) {
        setTimeout(() => {
          ToastStore.info(`Loading ${this.state.wallets.length} Accounts...`);
        }, 3000)
    }
    else {
        this.setState({
          loader: false
        })
    }
  }


  getAccount(item, index) {

    Server.loadAccount(item.pk)
      .then(account => {
        console.log('account: ', account);

        item.account = account;
        item.balance = numFormat(account.balances[account.balances.length-1].balance, 2);
        item.reserve = (account.subentry_count * baseReserve());
        item.avaliable = (item.balance - item.reserve).toFixed(2);
        item.id = index;
        wallets_arr.push(item);

        if (this.state.wallets.length === wallets_arr.length) {
          newState();
        }
      })
      .catch((err) => {
        console.error(err);

        wallets_arr.push(item)

        if (this.state.wallets.length === wallets_arr.length) {
          newState();
        }
    })

    const newState = () => {
      this.setState({
        select_addrs: wallets_arr,
        load_select_addrs: true,
        loader: false
      })
    }
  }


  getPasteAccount(account_id) {

    this.setState({ loader: true })
    ToastStore.info(`Loading Account...`);

    Server.loadAccount(account_id)
      .then(account => {
        console.log('getPasteAccount: ', account);

        const balances = account.balances.map(item => {
          // console.log('item: ', item)

          if (item.asset_type === 'native') {
            item.asset_code = 'XLM';
            item.asset_issuer = 'native';
            item.reserve = (account.subentry_count * baseReserve() + minBalance());
            item.avaliable = (item.balance - item.reserve).toFixed(7);
          }
          return item;
        })
        console.log('balances: ', balances);

        this.setState({
          loader: false,
          select_assets: balances.reverse(),
          load_select_assets: true,
          select_addr: account_id,
        })
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loader: false })

        // wallets_arr.push(item)
        //
        // if (this.state.wallets.length === wallets_arr.length) {
        //   newState();
        // }
      })

    // const newState = () => {
    //   this.setState({
    //     select_addrs: wallets_arr,
    //     load_select_addrs: true,
    //     loader: false
    //   })
    // }
  }



  changeSender(value) {

    let addr = '';
    for (const key of Object.keys(value)) {
      if (key < 56) {
        addr = (addr+value[key])
      }
      else {

        let select_account = wallets_arr.filter((item) => {
          return addr === item.pk;
        });

        const account = select_account[0].account;
        const balances = account.balances;

        balances.forEach((item, index) => {
          if (item.asset_type === 'native') {
            item.asset_code = 'XLM';
            item.asset_issuer = 'native';
            item.reserve = (account.subentry_count * baseReserve() + minBalance());
            item.avaliable = (item.balance - item.reserve).toFixed(7);

            // let reserve = ((account.subentry_count * baseReserve()) + minBalance());

            this.setState({
              balance_native: item.balance,
              avaliable_native: item.avaliable, // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
              reserve_balance: item.reserve, // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            })
          }
          else {
            item.avaliable = item.balance;
          }
        })

        this.setState({
          select_assets: balances.reverse(),
          load_select_assets: true,
          select_addr: addr,
          tx_hash: false,
          paste_recepient_address: false
        })
      }
    }
  }



  changeSelectAsset(value) {
    let asset = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        asset = asset + value[key];
      }
    }

    let part = asset.split('-');
    this.setState({
      select_asset_code: part[0],
      select_asset: part,
    });
  }


  changeMsg(value) {
    let text = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        text = text + value[key];
      }
    }
    console.log('text: ', text);

    const limit = 300;
    if (text.length <= limit) {
      this.setState({ msg_length: text.length })
    }
    else {
      ToastStore.warning('Maximum length 300 characters');
    }
  }



  changePasteAddress(value) {
    console.log('changePasteAddress value: ', value);

    let name = '';
    for (const key of Object.keys(value)) {
      if (key !== 'preventDefault') {
        name = (name+value[key])
      }
    }
    console.log('name: ', name);

    this.getPasteAccount(name)

    // if (name.length === 5) {
    //   this.setState({ select_recepient_address: false })
    // } else {
    //   this.setState({ select_recepient_address: true })
    // }
  }



  submitForm = (values) => {
    console.log('values: ', values);

    const origin_domain = 'scopuly.com';
    const secret_key = 'SDPHNZINMTDMKVO5UQKAXAGUEEOAL6U3FDPBIKWDWJW5URECCDI3APJK';
    const public_key = 'GCTBATCNXTDRHDRBJ2PZREPN5GNCI5GVZUNF6CQ22LZVF2TIFXQ5V4XF';

    if (values.select_addr && values.amount && values.select_asset) {

      const asset_code = values.select_asset.split('-')[0];
      const asset_issuer = values.select_asset.split('-')[1];

      let uri = `web+stellar:pay?destination=${values.select_addr}&amount=${values.amount}&origin_domain=${origin_domain}`;
      const parse_uri = parseStellarUri(uri);

      if (asset_issuer !== 'native') {
        parse_uri.assetCode = asset_code;
        parse_uri.assetIssuer = asset_issuer;
      }
      if (values.memo) {
        parse_uri.memo = encodeURIComponent(values.memo);
        parse_uri.memoType = 'MEMO_TEXT';
      }
      if (values.msg) {
        parse_uri.msg = encodeURIComponent(values.msg);
      }

      const signature = parse_uri.addSignature(StellarSdk.Keypair.fromSecret(secret_key))
      console.log('signature: ', signature);

      const res_uri = parse_uri.toString();
      console.log('res_uri: ', res_uri);


      parse_uri.verifySignature().then(isVerified => {
        console.log('isVerified: ', isVerified);

        if (isVerified) {
          this.setState({
            uri: res_uri,
            amount: values.amount,
            memo: values.memo,
            msg: values.msg,
          },
          () => this.setInvoice(res_uri, values.select_addr))
        }
        else {
          // something is wrong, warn the user
        }
      });
    }
    else {
      ToastStore.warning('Fill in all the fields');
    }
  }


  setInvoice(uri, address) {

    this.setState({ loader: true })

    const formData = new FormData();
    formData.append('uri', uri)
    formData.append('address', address)

    axios.post(GLOBE.API_URL+`/create_qr_pay`, formData)
      .then(result => {
        // console.log('result DB: ', result)

        this.setState({ loader: false })

        if (result.data) {
          this.setState({ invoice_id: result.data },
          () => this.toggleModal())
        }
      })
      .catch((error) => {
        console.log('error: ', error)
        this.setState({ loader: false })
        ToastStore.error(`Server request error...`)
    })
  }


  addMsg(e) {
    e.preventDefault()
    this.setState({
      msg_show: !this.state.msg_show,
      msg: false,
    })

    this.props.change('msg', '')
  }




  renderModal() {
    const {reset, handleSubmit } = this.props;

    let classElem = '';
    if (localStorage.getItem('theme') === 'theme-dark') {
      classElem = 'notification-dark'
    }

    const { activeTab } = this.state;

    return (
      <Modal
        isOpen={this.state.modal}
        toggle={this.toggleModal}
        className={`modal-dialog--primary modal-dialog--header ${classElem}`}
        style={{maxWidth: 400}}
      >
        <div>
          { this.state.loader ? <div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div> : null }

          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggleModal} />
            <h4 className="bold-text  modal__title">Receive <b>Payment by QR Code</b></h4>
          </div>

          <div className="modal__body">

            <a href={this.state.uri} target='_blank'rel='noopener noreferrer'>
              <small className="uri-str"><b>{this.state.uri && this.state.uri.substr(0, 43)}...</b></small>
            </a>
            <CopyToClipboard text={this.state.uri} onCopy={() => {}}>
              <i className="fa fa-clone"
                 onClick={() => ToastStore.success(`web+stellar:pay... copied`)}
                 style={{cursor: 'pointer'}}></i>
            </CopyToClipboard>

            <div className="text-center">
              <QRCode
                value={this.state.uri}
                logoWidth="60"
                logoHeight="60"
                size="300"
                quietZone="0"
                fgColor="#217fe4"
                qrStyle="dots"
                logoOpacity="0.9"
                logoImage="/img/logo/icon-transtparent-512.png"
                 />
            </div>

            <br/>
            <div className="text-secondary" style={{marginLeft: 30}}>
              <div>Recipient: <a href={`/account/${this.state.select_addr}`}
                                 target='_blank'
                                 rel='noopener noreferrer'><b>{shortAddr(this.state.select_addr, 8)}</b></a></div>
              <div>Asset: <a href={`/asset/${this.state.select_asset[0]}-${this.state.select_asset[1]}`}
                             target='_blank'
                             rel='noopener noreferrer'><b className="text-warning">{this.state.select_asset[0]}</b></a></div>
              <div>Amount: <b className="text-info">{numFormat(this.state.amount, 7)}</b></div>
              {this.state.memo ? <div>Memo: <span className="text-info">{this.state.memo}</span></div> : null}
              {this.state.msg ? <div>Message: <span className="text-info">{this.state.msg}</span></div> : null}
              {/*<div><small>{formatDate(new Date())}</small></div>*/}
            </div>
            <br/>
            <ButtonToolbar className="form__button-toolbar float-right" style={{margin: 0}}>
              <CopyToClipboard text={`${window.location.origin}/pay/${this.state.invoice_id}`} onCopy={() => {}}>
                <Button color="success"
                        onClick={() => ToastStore.success(`scopuly.com/pay/... copied`)}>
                        <i className="fa fa-clone"></i> Copy link to QR</Button>
              </CopyToClipboard>
              {/*<CopyToClipboard text={this.state.uri} onCopy={() => {}}>
                <Button color="secondary"
                        onClick={() => ToastStore.success(`web+stellar:pay... copied`)}>
                        <i className="fa fa-clone"></i> SEP-0007 URI</Button>
              </CopyToClipboard>*/}
            </ButtonToolbar>
          </div>
        </div>
      </Modal>
    )
  }


  selectTypeAddress = (type) => {
    // console.log('type: ', type)

    if (type === 'paste') {
      this.setState({
        paste_recepient_address: true,
        select_recepient_address: false,
      })
    }
    if (type === 'select') {
      this.setState({
        paste_recepient_address: false,
        select_recepient_address: true,
      })

      this.filterAddress()
    }
  }



  render() {

    const {reset, handleSubmit } = this.props;

    // console.log('state: ', this.state)

    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        { this.renderModal() }

        {/*<Row>
          <Col md={12}>
            <h3 className="page-title">Send Assets</h3>
          </Col>true
        </Row>*/}
        <Row>

          <Col md={{ size:8, offset:2 }}  className="align-self-center">
            <Card>
              <CardBody>

                {this.state.loader ? <div className="panel__refresh" style={{height: '95%'}}><LoadingIcon /></div> : null}

                <div className="card__title">
                  <h5 className="bold-text">Receive Assets</h5>
                  <h5 className="subhead">Accept QR code payments worldwide in any convenient currency in seconds,
                    no fees. Supports {' '}
                    <a href="https://github.com/stellar/stellar-protocol/blob/master/ecosystem/sep-0007.md"
                       target='_blank'
                       rel='noopener noreferrer'>SEP-0007</a>.
                  </h5>
                </div>

                <form
                  className="material-form"
                  onSubmit={handleSubmit(this.submitForm)}>

                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="radio"
                        component={renderRadioButtonField}
                        label="Paste Address"
                        radioValue="paste"
                        defaultChecked
                        onChange={() => this.selectTypeAddress('paste')}
                      />
                      <Field
                        name="radio"
                        component={renderRadioButtonField}
                        label="Select Address"
                        radioValue="select"
                        onChange={() => this.selectTypeAddress('select')}
                      />
                    </div>

                  </div>

                  {
                    this.state.paste_recepient_address ?
                      <div>
                        <Field
                          name="select_addr"
                          component={renderTextField}
                          label="Paste Recipient Address"
                          onChange={e => {
                            this.changePasteAddress(e)
                          }}
                        />
                      </div>
                    : null
                  }

                  {
                    this.state.select_recepient_address && this.state.wallets ?
                     <div>
                       <Field
                          name="select_addr"
                          component={renderTextField}
                          select
                          label="Select Recipient Address"
                          onChange={(e) => {
                            this.changeSender(e)
                          }}
                        >
                          {
                            this.state.select_addrs ? this.state.select_addrs.map((item, index) => {
                              if (item.balance) {
                                return (
                                  <MenuItem
                                    key={index}
                                    className={`material-form__option ${item && item.provider ?  'connect-provider' : ''}`}
                                    value={item.pk}>
                                    <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 8)}</b> <small>&nbsp;/ {item.title} / {item.balance} XLM</small> { getIconConnectProvider(item) }
                                  </MenuItem>
                                )
                              }
                            }) : null
                          }
                        </Field>
                      </div>
                    : null
                  }

                  <div>
                    <Field
                      name="select_asset"
                      component={renderTextField}
                      select
                      label="Select Asset"
                      onChange={(e) => {
                        this.changeSelectAsset(e)
                      }}
                    >
                      {
                        this.state.load_select_assets ? this.state.select_assets.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              className="material-form__option"
                              value={`${item.asset_code}-${item.asset_issuer}`}><b className="text-secondary">{item.asset_code}</b> &nbsp;({item.asset_code === 'XLM' ? item.avaliable : numFormat(item.balance, 7)})</MenuItem>
                          )
                        }) : null
                      }
                    </Field>
                  </div>

                  <div>
                    <Field
                      name="amount"
                      component={renderTextField}
                      placeholder="Name"
                      label="Amount"
                      onChange={(e) => {
                        // this.changeSelectAmount(e)
                      }}
                    />
                  </div>

                  <div>
                    <Field
                      name="memo"
                      component={renderTextField}
                      label="Memo (optional)"
                    />
                  </div>

                  {
                    // this.state.msg_show ?
                    //   <div>
                    //     <Field
                    //       name="msg"
                    //       component={renderTextField}
                    //       label={`Message (optional) ${this.state.msg_length ? '300/'+this.state.msg_length : ''}`}
                    //       onChange={(e) => this.changeMsg(e)}
                    //     />
                    //   </div>
                    // : null
                  }

                  {/*<div>*/}
                  {/*  <small>*/}
                  {/*    <a href="#" onClick={(e) => this.addMsg(e)}>{this.state.msg_show ? '-' : '+'} Message</a>*/}
                  {/*  </small>*/}
                  {/*</div>*/}

                  <ButtonToolbar className="form__button-toolbar float-right">
                    <Button type="button" onClick={reset}>Reset</Button>
                    <Button color="primary" type="submit">Create Payment Request</Button>
                  </ButtonToolbar>
                </form>
              </CardBody>
            </Card>
          </Col>

        </Row>

        <Footer />

      </Container>
    );
  }
}



// export default translate('common')(Send);
export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(translate('common')(Receive));
