/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { Col, Row, Container, Card } from 'reactstrap';
import scrollToComponent from 'react-scroll-to-component';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from './components/Header';
import Technologies from './components/Technologies';
import Demos from './components/Demos';
import Features from './components/Features';
import Roadmap from './components/Roadmap';
import Purchase from './components/Purchase';
import Footer from './components/Footer';
import Testimonials from './components/Testimonials';
import FeatureRequest from './components/FeatureRequest';
import { changeThemeToDark, changeThemeToLight } from '../../redux/actions/themeActions';
import { ThemeProps } from '../../shared/prop-types/ReducerProps';
import { Link } from 'react-router-dom';

const logo = `${process.env.PUBLIC_URL}/img/logo/500x200.png`;

class Landing extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    theme: ThemeProps.isRequired,
  };

  changeToDark = () => {
    this.props.dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    this.props.dispatch(changeThemeToLight());
  };

  render() {
    const { theme } = this.props;

    return (
      <div>
        {/*<iframe id="scopuly"*/}
        {/*        style={{width: '100%', height: document.body.clientWidth, border: 'none'}}*/}
        {/*        title="scopuly"*/}
        {/*        src="https://scop.scopuly.com">*/}
        {/*</iframe>*/}

        <div className="landing">
          <div className="landing__menu">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="landing__menu-wrap">
                    <p className="landing__menu-logo">
                      <Link to="/home"><img className="land_logo" src={logo} alt="" /></Link>
                    </p>
                    <nav className="landing__menu-nav">

                      <Link to="/wallet" className="link-a">Wallet</Link>
                      <Link to="/assets" className="link-a">Assets</Link>
                      <Link to="/trade" className="link-a">Trade</Link>
                      <Link to="/send" className="link-a">Send</Link>

                      {/*
                      <button
                        onClick={() => scrollToComponent(this.About, { offset: -50, align: 'top', duration: 1000 })}>
                        About
                      </button>

                      <button onClick={() => scrollToComponent(this.Features, {
                        offset: -50,
                        align: 'top',
                        duration: 1500,
                      })}>
                        Features
                      </button>

                      <button
                        onClick={() => scrollToComponent(this.Demos, { offset: -50, align: 'top', duration: 2000 })}>
                        Demos
                      </button>

                      <button
                        onClick={() => scrollToComponent(this.FeatureRequest, {
                          offset: -50,
                          align: 'top',
                          duration: 2500,
                        })}>
                        SCOP
                      </button>
                      <button
                        onClick={() => scrollToComponent(this.Roadmap, { offset: -50, align: 'top', duration: 2000 })}>
                        Roadmap
                      </button>
                      */}

                      <Link className="landing__btn" to="/auth/connect">Login</Link>
                      {/*<Link className="landing__btn" to="/auth/create">Create Wallet</Link>*/}
                    </nav>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <Header onClick={() => scrollToComponent(this.Demos, { offset: -50, align: 'top', duration: 2000 })} />

          <span ref={(section) => {
            this.About = section;
          }}
          />

          {/*<Row >
          <Col md={{ size: 8, offset: 2 }} className="mb-14">
            <Card className="embed-responsive embed-responsive-21by9">
              <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/UqjWydNMOFI" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </Card>
          </Col>
        </Row>

        <br/><br/>*/}

          <Technologies />

          <span ref={(section) => {
            this.Features = section;
          }}
          />
          <Features />

          <span ref={(section) => {
            this.Demos = section;
          }}
          />
          <Demos theme={theme} changeToDark={this.changeToDark} changeToLight={this.changeToLight} />

          <span ref={(section) => {
            this.FeatureRequest = section;
          }}/>


          {/*<Testimonials />*/}


          {/*<FeatureRequest />*/}
          {/*  <span ref={(section) => {*/}
          {/*  this.Roadmap = section;*/}
          {/*}} />*/}

          {/*<Roadmap/>*/}

          <Purchase />
          <Footer />
        </div>
      </div>
    );
  }
}

export default connect(state => ({ theme: state.theme }))(Landing);
