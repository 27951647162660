import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Button, ButtonGroup } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { GLOBE } from '../../../modules/globeVars/index';
import { shortAddr, get_loc_wallets, getAuth, getTitle } from '../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from 'sweetalert';
import renderToggleButtonField from '../../../shared/components/form/ToggleButton';
import Footer from '../../Layout/footer/Footer';

import { changeMobileSidebarVisibility, changeSidebarVisibility } from '../../../redux/actions/sidebarActions';
import { changeThemeToDark, changeThemeToLight } from '../../../redux/actions/themeActions';
import { changeBorderRadius, toggleBoxShadow, toggleTopNavigation, toggleShowNotification, addNotification } from '../../../redux/actions/customizerActions';
import {connect} from "react-redux";
import ToggleTheme from "../../Layout/customizer/ToggleTheme";
import ToggleCollapsedMenu from "../../Layout/customizer/ToggleCollapsedMenu";
import ToggleSquared from "../../Layout/customizer/ToggleSquared";
import ToggleShadow from "../../Layout/customizer/ToggleShadow";
import ToggleShowNotification from "../../Layout/customizer/ToggleShowNotification";


const renderTextField = ({
  input, label, meta: { touched, error }, children, select, type,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);



class Settings extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      wallets: get_loc_wallets(),
      account_settings: false,
      local_currency: false,
      settings_offer: false,
      toggle_checkbox: false,
      show_auth_method: false,
      auth_method: localStorage.getItem('2fa'),
      type_verify: 'enable',
      auth_user: localStorage.getItem('auth_user'),
    };
  }

  componentDidMount() {
    getTitle(`Settings`);

    this.check2fa();
    this.checkOrderbook();
  }


  qrCode(data, email) {

    let template = (`<div>
                        <div style="margin: 0 auto; width: 60%;">
                          <img src=${data.qrCodeUrl} />
                        </div>
                        <br/>
                        <p>
                          Scan this QR code with the Google Authenticator app on your mobile device. Or enter this code manually.
                          <br/>Account: <b>${email}</b>
                          <br/>Key: <b>${data.secret}</b>
                        </p>
                      </div>`);

    swal({
      title: "2FA Google Authenticator",
      content: {
        element: 'div',
        attributes: {
          innerHTML: `${template}`,
        },
      },
      buttons: {
        cancel: false,
        confirm: "Enter authorization code",
      },
    })
    .then((value) => {
      if (value) {
        this.authCode(data.secret);
      }
    });
  }


  authCode(secret) {

    swal({
      title: "Google Autenicator Code",
      text: "Enter the six-digit code from the Google Autenicator application.",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter your Google code",
          type: "number",
        },
      },
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
    .then((value) => {
      if (value) {
        this.verifyAuthCode(value, secret)
      }
    });
  }


  validateEmail(email) {
      var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
      if( !emailReg.test(email) ) {
          return false;
      } else {
          return true;
      }
  }


  getAuthCode(email) {

    axios.post(`${GLOBE.API_URL}/get_auth_code/?email=${email}`)
      .then((response) => {
        if (response) {

          var cipher = CryptoJS.AES.encrypt(response.data.secret, email);

          localStorage.setItem('gkey', cipher);

          this.qrCode(response.data, email);
        }
      })
      .catch(function (error) {
        console.log(error);
    });
  }

  verifyAuthCode(code, secret) {

    axios.post(`${GLOBE.API_URL}/verify_auth_code/?code=${code}&secret=${secret}`)
      .then((response) => {
        if (response) {

          if (response.data === 'FAILED') {
            ToastStore.error(`Incorrect value`);
          }
          else if (response.data === 'OK') {

            if (this.state.type_verify === 'enable') {

              localStorage.setItem('2fa', 'gooauth');

              swal({
                title: "2FA successfully installed!",
                text: "2FA authorization through Google Authenticator has been successfully installed.",
                icon: "success"
              })
            }
            if (this.state.type_verify === 'disable') {

              localStorage.removeItem('gkey');
              localStorage.setItem('2fa', 'false');

              this.setState({
                show_auth_method: !this.state.show_auth_method,
                auth_method: 'false'
              })

              swal({
                title: "2FA authorization disabled",
                text: "2FA authorization through Google Authenticator is disabled.",
                icon: "success"
              })
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
    });
  }


  pincodeAuthenticator(target) {

    swal({
      title: "PIN Code Authorization",
      text: `To enable 2FA authentication, set the PIN code. Without this PIN code it will be impossible to send transactions.`,
      icon: "info",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter your PIN-code (4 digits)",
          type: "password",
        },
      },
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
    .then((value) => {
      if (value) {

        let format = 4;
        if (isNaN(value)) {
          ToastStore.error('The value entered must be a number');
        }
        else if (value.length < format || value.length > format) {
          ToastStore.warning(`PIN code must be ${format} digits.`);
        }
        else {

          var cipher = CryptoJS.AES.encrypt(value, target);

          localStorage.setItem('2fa', target);
          localStorage.setItem('pin', cipher);

          this.setState({
            auth_method: target,
          });

          swal({
            title: "PIN code set",
            text: `PIN code authorization successfully installed! Now the PIN code will be requested when sending each transaction.`,
            icon: "success",
          })
        }
      }
    });
  }


  googleAuthenticator() {

    // SET
    // если не user_local - получить емэйл
    // отобразить google key (нужно сохранить!)

    // получить auth_code - да/нет

    // CHECK
    // получить auth_code - да/нет

    const user = localStorage.getItem('user');
    if (!user) {
      // получить емэйл
      this.getEmail();
    }
    else {
      // получить auth_code - да/нет
      this.getAuthCode(user);
    }

    return
  }


  getEmail() {

    swal({
      title: "Enter you Email",
      text: "Enter your Email to create a local User.",
      content: {
        element: "input",
        attributes: {
          placeholder: "Email",
          type: "email",
        },
      },
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
    .then((value) => {
      if (value) {
        if (this.validateEmail(value)) {

          localStorage.setItem('user', value);

          this.getAuthCode(value);
        }
        else {
          ToastStore.warning(`The entered email is not valid`);
        }
      }
    });
  }



  changeSettingsAccount(values) {

    let addr = '';
    for (const key of Object.keys(values)) {
      if (key < 56) {
        addr = (addr+values[key])
      }
      else {

        this.setState({
          account_settings: addr,
        })
      }
    }
  }


  changeLocalCurrency(values) {

    let target = '';
    for (const key of Object.keys(values)) {
      if (key < 3) {
        target = (target+values[key])
      }
      else {
        this.setState({
          local_currency: target,
        })
      }
    }
  }


  changeAuth(values) {

    let target = '';
    for (const key of Object.keys(values)) {
      if (key < 7) {
        target = (target+values[key])
      }
      else {

        if (target === 'pincode') {
          if (this.state.auth_method === 'gooauth') {
            this.setState({ type_verify: 'disable' })

            swal({
              title: '2FA Google Authentication!',
              text: "To continue, you must cancel the authorization by Google Authenticator code. Want to do it right now?",
              buttons: {
                cancel: true,
                confirm: true,
              },
            })
            .then((value) => {
              if (value) {
                this.disabledGoogleAuth();
              }
            });
          }
          else if (this.state.auth_method === 'pincode') {
            this.disabledPin();
          }
          else {
            this.pincodeAuthenticator(target);
          }

          // const pin_text = localStorage.getItem('pin');
          // if (pin_text) {
          //   this.disabledPin();
          // }
          // else {
          //   this.pincodeAuthenticator(target);
          // }
        }

        else if (target === 'gooauth') {
          if (this.state.auth_method === 'pincode') {

            swal({
              title: 'PIN authorization!',
              text: "To continue, you must cancel the authorization by PIN code. Want to do it right now?",
              buttons: {
                cancel: true,
                confirm: true,
              },
            })
            .then((value) => {
              if (value) {
                this.disabledPin();
              }
            });
          }
          else if (this.state.auth_method === 'gooauth') {
            this.setState({type_verify: 'disable'})
            this.disabledGoogleAuth();
          }
          else if (this.state.auth_method === 'false') {
            this.googleAuthenticator(target)
          }
        }

      }
    }
  }


  // encryptPin() {
  //   // Encrypt
  //   var ciphertext = CryptoJS.AES.encrypt('my message', 'secret key 123');

  //   // Decrypt
  //   var bytes  = CryptoJS.AES.decrypt(ciphertext.toString(), 'secret key 123');
  //   var plaintext = bytes.toString(CryptoJS.enc.Utf8);

  //   console.log(plaintext);
  // }


  check2fa() {

    let auth_method = localStorage.getItem('2fa');

    if (!auth_method) {
      // this.setState({
      //   toggle_checkbox: !this.state.toggle_checkbox
      // })
      // localStorage.setItem('2fa', false)
    }
    else {

      const upState = () => {
        this.setState({
          show_auth_method: !this.state.show_auth_method
        })
      }

      if (auth_method === 'pincode') {
        this.props.change('auth_method', 'pincode')
        upState();
      }
      else if (auth_method === 'gooauth') {
        this.props.change('auth_method', 'gooauth')
        upState();
      }
      // else if (auth_method === 'false') {
      //   this.props.change('auth_method', 'gooauth')
      // }
    }
  }


  // click
  onChange() {

    const method = localStorage.getItem('2fa');

    if (method === 'pincode') {
      this.disabledPin();
    }
    else if (method === 'gooauth') {
      this.disabledGoogleAuth();
    }
    else {
      this.setState({
        show_auth_method: !this.state.show_auth_method
      })
    }

    // if (this.state.show_auth_method) {
    //   this.disabledPin();
    // }
    // else {
    //   this.setState({
    //     show_auth_method: !this.state.show_auth_method
    //   })
    // }
  }


  disabledGoogleAuth() {

    const gkey = localStorage.getItem('gkey');
    const email = localStorage.getItem('user');

    const bytes  = CryptoJS.AES.decrypt(gkey.toString(), email);
    const secret = bytes.toString(CryptoJS.enc.Utf8);

    this.setState({ type_verify: 'disable' })

    this.setState({

    })

    this.authCode(secret);
  }


  disabledPin() {

    swal({
      title: "Disable 2FA Authentication",
      text: `To disable 2FA authentication, enter your PIN code`,
      icon: "info",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter your PIN-code (4 digits)",
          type: "password",
        },
      },
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
    .then((value) => {
      if (value) {

        let pincode = 'pincode';

        let format = 4;
        if (isNaN(value)) {
          ToastStore.error('The value entered must be a number');
        }
        else if (value.length < format || value.length > format) {
          ToastStore.warning(`PIN code must be ${format} digits.`);
        }
        else {

          const pin_text = localStorage.getItem('pin');

          let bytes  = CryptoJS.AES.decrypt(pin_text.toString(), pincode);
          let pin = bytes.toString(CryptoJS.enc.Utf8);

          if (pin === value) {
            localStorage.removeItem('pin');
            localStorage.setItem('2fa', 'false');

            this.setState({
              auth_method: 'false'
            })

            this.props.change('auth_method', false)

            this.setState({
              show_auth_method: !this.state.show_auth_method
            })

            swal({
              title: "PIN code deleted",
              text: `PIN code authorization has been successfully disabled.`,
              icon: "success",
            })
          }
          else {
            ToastStore.error('The entered PIN code does not match the one set.');
          }
        }
      }
    })
  }


  checkOrderbook() {

    let orderbook_length = localStorage.getItem('orderbook_length');
    // console.log('orderbook_length: ', orderbook_length)

    if (orderbook_length) {
      this.props.change('select_item', orderbook_length)
    }
  }


  changeOrderbook(values) {

    let length = '';
    for (const key of Object.keys(values)) {
      if (key < 4) {
        length = (length+values[key])
      }
    }

    localStorage.setItem('orderbook_length', length)

    ToastStore.success('Orderbook length successfully changed!');
  }



  logout() {

    swal({
      title: 'Confirmation!',
      text: "Do you really want to log out right now?",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
    .then((value) => {
      if (value) {
        localStorage.removeItem('auth_user');

        ToastStore.success('You have successfully logged out of your account.');

        this.setState({ auth_user: false, });
      }
    });
  }



  deauthAll() {

    swal({
      title: 'Confirmation!',
      text: "Do you really want to deauthorize all wallets at once right now?",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
    .then((value) => {
      if (value) {

        localStorage.removeItem('wallets');

        ToastStore.success('All wallets have been successfully deauthorized!');
      }
    });
  }


  setUrlHandler = () => {
    if (window.navigator.registerProtocolHandler) {
      window.navigator.registerProtocolHandler('web+stellar', window.location.origin + '/sep-0007/%s', 'Scopuly - Stellar Wallet & SDEX')
    }
  }



  renderLanguage() {

    const {reset, handleSubmit } = this.props;

    return (
      <form className="material-form">
        <div>
          <Field
            name="select_lang"
            component={renderTextField}
            select
            label="Select Language"
            onChange={(value) => {
              this.changeLanguage(value)
            }}
          >
            <MenuItem className="material-form__option" value="ENG">ENG</MenuItem>
            <MenuItem className="material-form__option" value="RUS">RUS</MenuItem>
          </Field>
        </div>
      </form>
    )
  }


  renderLocalCurrency() {

    return (
      <form className="material-form">
        <div>
          <Field
            name="select_currency"
            component={renderTextField}
            select
            label="Select Local Currency"
            onChange={(value) => {
              this.changeLocalCurrency(value)
            }}
          >
            <MenuItem className="material-form__option" value="USD">USD</MenuItem>
            <MenuItem className="material-form__option" value="EUR">EUR</MenuItem>
            <MenuItem className="material-form__option" value="CNY">CNY</MenuItem>
            <MenuItem className="material-form__option" value="RUB">RUB</MenuItem>
            <MenuItem className="material-form__option" value="JPY">JPY</MenuItem>
            <MenuItem className="material-form__option" value="INR">INR</MenuItem>
            <MenuItem className="material-form__option" value="KRW">KRW</MenuItem>
            <MenuItem className="material-form__option" value="AUD">AUD</MenuItem>
            <MenuItem className="material-form__option" value="BRL">BRL</MenuItem>
            <MenuItem className="material-form__option" value="CAD">CAD</MenuItem>
            <MenuItem className="material-form__option" value="CHF">CHF</MenuItem>
            <MenuItem className="material-form__option" value="CLP">CLP</MenuItem>
            <MenuItem className="material-form__option" value="CNY">CNY</MenuItem>
            <MenuItem className="material-form__option" value="CZK">CZK</MenuItem>
            <MenuItem className="material-form__option" value="GBP">GBP</MenuItem>
            <MenuItem className="material-form__option" value="HKD">HKD</MenuItem>
            <MenuItem className="material-form__option" value="HUF">HUF</MenuItem>
            <MenuItem className="material-form__option" value="IDR">IDR</MenuItem>
            <MenuItem className="material-form__option" value="ILS">ILS</MenuItem>
            <MenuItem className="material-form__option" value="MXN">MXN</MenuItem>
            <MenuItem className="material-form__option" value="ZAR">ZAR</MenuItem>
            <MenuItem className="material-form__option" value="MYR">MYR</MenuItem>
            <MenuItem className="material-form__option" value="NOK">NOK</MenuItem>
            <MenuItem className="material-form__option" value="NZD">NZD</MenuItem>
            <MenuItem className="material-form__option" value="PHP">PHP</MenuItem>
            <MenuItem className="material-form__option" value="PKR">PKR</MenuItem>
            <MenuItem className="material-form__option" value="PLN">PLN</MenuItem>
            <MenuItem className="material-form__option" value="SEK">SEK</MenuItem>
            <MenuItem className="material-form__option" value="SGD">SGD</MenuItem>
            <MenuItem className="material-form__option" value="THB">THB</MenuItem>
            <MenuItem className="material-form__option" value="TRY">TRY</MenuItem>
            <MenuItem className="material-form__option" value="TWD">TWD</MenuItem>
          </Field>
        </div>
      </form>
    )
  }


  renderSettingsAccount() {

    const {reset, handleSubmit } = this.props;

    return (
      <form className="material-form">
        <div>
          <Field
            name="select_address"
            component={renderTextField}
            select
            label="Select Account"
            onChange={(value) => {
              this.changeSettingsAccount(value)
            }}
          >
            {
              this.state.wallets
              ? this.state.wallets.map((item, index) => {
                  return (
                    <MenuItem key={index} className="material-form__option" value={item.pk}>{shortAddr(item.pk, 10)}</MenuItem>
                  );
                })
              : null
            }
          </Field>
        </div>
      </form>
    )
  }


  changeToDark = () => {
    this.props.dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    this.props.dispatch(changeThemeToLight());
  };

  changeSidebarVisibility = () => {
    this.props.dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    this.props.dispatch(changeMobileSidebarVisibility());
  };

  toggleTopNavigation = () => {
    this.props.dispatch(toggleTopNavigation());
  };

  changeBorderRadius = () => {
    this.props.dispatch(changeBorderRadius());
  };

  toggleBoxShadow = () => {
    this.props.dispatch(toggleBoxShadow());
  };

  toggleShowNotification = () => {
    if (this.props.customizer.showNotification) {
      localStorage.setItem('is-show-notif', 0)
    } else {
      localStorage.setItem('is-show-notif', 1)
    }
    this.props.dispatch(toggleShowNotification());
  }




  render() {

    const {
      // changeSidebarVisibility,
      sidebar,
      customizer,
      theme,
      // toggleTopNavigation,
      // changeToDark,
      // changeToLight,
      // changeBorderRadius,
      // toggleBoxShadow,
      // toggleShowNotification,
    } = this.props;

    const size_col = 6, offset_col = 3;


    return (
        <Container>

          <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

          <Row>
            <Col md={{ size: size_col, offset: offset_col }}>
              <h3 className="page-title">Settings</h3>
              {
                this.state.auth_user ?
                  <h4 className="subhead" style={{marginBottom: '18px'}}>
                    <a href="/profile"><b>{this.state.auth_user ? JSON.parse(this.state.auth_user).email : null}</b></a> {' '}
                    <a href="#" onClick={this.logout.bind(this)} style={{float: 'right'}}><i className="fa fa-sign-out"></i> Logout</a>
                  </h4>
                :
                  <h4 className="subhead" style={{marginBottom: '18px'}}>
                    <Link to="login" ><i className="fa fa-sign-in"></i> Login</Link>
                  </h4>
              }
            </Col>
          </Row>

          <Row>
            <Col md={{ size: size_col, offset: offset_col }}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Interface options</h5>
                    <h4 className="subhead">This customizer allows you to see the different options for the Scopuly interface.</h4>
                  </div>

                  <div className={'customizer'} style={{position: 'relative'}}>
                    {/*<p className="customizer__caption">This customizer allows you to see the different options for the Scopuly interface.</p>*/}
                    <ToggleCollapsedMenu changeSidebarVisibility={this.changeSidebarVisibility} sidebar={sidebar} />
                    {/*<ToggleTopMenu toggleTopNavigation={toggleTopNavigation} customizer={customizer} />*/}
                    <ToggleTheme changeToDark={this.changeToDark} changeToLight={this.changeToLight} theme={theme} />
                    <ToggleSquared customizer={customizer} changeBorderRadius={this.changeBorderRadius} />
                    <ToggleShadow customizer={customizer} toggleBoxShadow={this.toggleBoxShadow} />
                    <ToggleShowNotification customizer={customizer} toggleShowNotification={this.toggleShowNotification} />
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>

          {/*<Row>
            <Col md={{ size: size_col, offset: offset_col }}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Interface language</h5>
                    <h4 className="subhead">Select interface language</h4>
                  </div>

                  { this.renderLanguage() }

                </CardBody>
              </Card>
            </Col>
          </Row>*/}


          {/*<Row>
            <Col md={{ size: size_col, offset: offset_col }}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Local currency</h5>
                    <h4 className="subhead">Choosing a local currency to convert crypto to fiat</h4>
                  </div>

                  { this.renderLocalCurrency() }

                </CardBody>
              </Card>
            </Col>
          </Row>*/}

          <Row>
            <Col md={{ size: size_col, offset: offset_col }}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Account settings</h5>
                    <h4 className="subhead">Manage basic Account settings, as well as Balance settings</h4>
                  </div>

                  { this.renderSettingsAccount() }

                  {
                    this.state.account_settings ?
                      <Link to={`/account/${this.state.account_settings}#settings`} className="btn btn-outline-primary btn-sm">Go to Account Settings</Link>
                    : null
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={{ size: size_col, offset: offset_col }}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Offer Settings</h5>
                    <h4 className="subhead">Manage my Offers for exchange on all Accounts</h4>
                  </div>

                  {
                    this.state.wallets ?
                      <Link to={`/offers/all`}
                            className="btn btn-outline-secondary btn-sm"
                            disabled={this.state.wallets ? false : true}
                          >Go to offer settings</Link>
                    :
                      <Link to={`/auth/connect`} className="btn btn-outline-secondary btn-sm">Connect Account</Link>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={{ size: size_col, offset: offset_col }}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Orderbook settings</h5>
                    <h4 className="subhead">Control the length of the displayed Orderbook</h4>
                  </div>

                  <form className="material-form">
                    <div>
                      <Field
                        name="select_item"
                        component={renderTextField}
                        select
                        label="Orderbook length"
                        onChange={(value) => {
                          this.changeOrderbook(value)
                        }}
                      >
                        <MenuItem className="material-form__option" value="30">30 items</MenuItem>
                        <MenuItem className="material-form__option" value="50">50 items</MenuItem>
                        <MenuItem className="material-form__option" value="150">150 items</MenuItem>
                        <MenuItem className="material-form__option" value="200">200 items</MenuItem>
                      </Field>
                    </div>
                  </form>

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={{ size: size_col, offset: offset_col }}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Web+Stellar handler</h5>
                    <h4 className="subhead">Set the browser to handle links like web + stellar</h4>
                  </div>

                  <Button color="secondary"
                          size="sm"
                          outline
                          onClick={this.setUrlHandler}>Install as web+stellar handler</Button>

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={{ size: size_col, offset: offset_col }}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Restore Wallet</h5>
                    <h4 className="subhead">Recovery / Authorization of the wallet by the recovery token or the Secret key</h4>
                  </div>

                  <Link to={`/auth/connect`} className="btn btn-outline-secondary btn-sm">Connect Wallet</Link>

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={{ size: size_col, offset: offset_col }}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Deauthorization</h5>
                    <h4 className="subhead">This allows you to deauthorize all wallets from local memory at once</h4>
                  </div>

                  <Button color="secondary"
                          size="sm"
                          outline
                          disabled={this.state.wallets ? false : true}
                          onClick={() => this.deauthAll()}>Deauthorize all wallets</Button>

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={{ size: size_col, offset: offset_col }}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Two-Factor Authentication (2FA)</h5>
                    <h4 className="subhead">Select an authentication method. This can be <b>PIN-code</b> or <b>Google Authenticator</b></h4>
                  </div>

                  {
                    this.state.wallets ?
                      <div>
                        {/*<div className="form__form-group">
                          <div className="form__form-group-field">
                            <Field
                              name="is_2fa"
                              component={renderToggleButtonField}
                              type="checkbox"

                              onChange={() => {this.changeSetAuth()}}
                            />
                          </div>
                          defaultChecked={(!this.state.toggle_checkbox ? true : false)}
                        </div> */}

                        <div className="toggle-btn">
                          <input
                            className="toggle-btn__input"
                            type="checkbox"
                            name='is_2fa'
                            checked={this.state.show_auth_method}
                          />
                          <button
                            className="toggle-btn__input-label"
                            onClick={() => this.onChange()}
                          >Toggle
                          </button>
                        </div>

                        {
                          this.state.show_auth_method ?
                            <form className="material-form">
                              <div>
                                <Field
                                  name="auth_method"
                                  component={renderTextField}
                                  select
                                  label="Select authentication method"
                                  onChange={(value) => {
                                    this.changeAuth(value)
                                  }}
                                >
                                  <MenuItem className="material-form__option" value="pincode">PIN-code</MenuItem>
                                  <MenuItem className="material-form__option" value="gooauth">Google Authenticator</MenuItem>
                                  {/*gooauth*/}
                                </Field>
                              </div>
                            </form>
                          :
                            null
                        }
                      </div>
                    :
                      <p>To enable 2FA authentication, you must have at least one Address authorized. &nbsp;
                        <Link to={'/auth/connect'}>Connect Wallet</Link>
                      </p>
                  }

                  <p><small>If you have forgotten your PIN code or cannot get an authorization code from the Google Authenticator
                            application to authorize transactions, de-authorize ALL of your wallets immediately and then simply authorize them again.
                            After de-authorization - 2FA will be disabled.
                            </small></p>

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Footer />

        </Container>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    customizer: state.customizer,
    sidebar: state.sidebar,
    theme: state.theme,
  }
}

export default connect(mapStateToProps)(translate('common')(reduxForm({ form: 'settings' })(Settings)));


// export default reduxForm({
//   form: 'floating_labels_form',
// })(translate('common')(Settings));
