import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderToggleButtonField from '../../../../../../shared/components/form/ToggleButton';
import renderSelectField from '../../../../../../shared/components/form/Select';
import { shortAddr, get_loc_wallets } from '../../../../../../modules/index';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, ToastStore } from 'react-toasts';
import slugify from '@sindresorhus/slugify';
import { GLOBE } from '../../../../../../modules/globeVars/index';
import axios from 'axios';
import swal from 'sweetalert';


class WizardFormFive extends PureComponent {

  constructor() {
    super();
    this.state = {
      federation_name: false,
      route_to_wallet: false,
      status_name: true,
      showPublic: false,
      showSecret: false,
    };
  }

  componentDidMount() {
    //
  }


  changeFederationName(value) {
    console.log('changeFederationName value: ', value.target.value);

    const formData = new FormData();
    formData.append('name', value.target.value);

    axios.post(GLOBE.API_URL+`/check_federation`, formData)
      .then(result => {
        console.log('check_federation result: ', result)

        if (result.data === 'free') {
          this.setState({ status_name: true })
        } else {
          this.setState({ status_name: false })
        }
      })
      .catch((error) => {
        console.log('error: ', error)
    });

    if (value.target.value) {
      this.setState({ federation_name: slugify(value.target.value) })
    } else {
      this.setState({ federation_name: false })
    }
  }


  setFederationName = (values) => {
    console.log('setFederationName values: ', values);
    console.log('status_name: ', this.state.status_name);

    const routeToWallet = () => {
      this.setState({ route_to_wallet: true })
    }

    const showExists = () => {
      ToastStore.warning('This name already exists');
    }

    if (values.federation_name && !this.state.status_name) {
      showExists()
      return;
    }

    if (values.federation_name && this.state.status_name) {

      const federation_name = slugify(values.federation_name);

      const formData = new FormData();
      formData.append('name', federation_name);
      formData.append('pk', values.public_key);

      axios.post(GLOBE.API_URL+`/set_federation`, formData)
        .then(result => {
          console.log('set_federation result: ', result)

          if (result.data === 'success') {

            const message = `Your Federation Address <b class="text-info">${federation_name}*scopuly.com</b> has been successfully set.`
            const element = document.createElement("span");
            element.innerHTML = message;

            swal({
              title: `Federation name set!`,
              content: element,
              icon: "success",
              closeOnClickOutside: false,
              buttons: {
                confirm: true,
              },
            })
            .then((confirm) => {
              if (confirm)
                routeToWallet()
            })
          }
          else {
            showExists()
          }
        })
        .catch((error) => {
          console.log('error: ', error)
      });
    }
    else {
      //... route to wallet
      routeToWallet()
    }
  }

  togglePublic = (e) => {
    e.preventDefault()
    this.setState({ showPublic: !this.state.showPublic })
  }

  toggleSecret = (e) => {
    e.preventDefault()
    this.setState({ showSecret: !this.state.showSecret })
  }


  render() {
    console.log('this.state: ', this.state)
    console.log('this.props: ', this.props)

    const { handleSubmit } = this.props;

    if (this.state.route_to_wallet) {
      return <Redirect to={'/wallet'} />
    }

    return (
      <div>

        <form className="form wizard__form" onSubmit={handleSubmit(this.setFederationName)}>

          <h3 className="wizard__title text-success">Account created successfully!</h3>
          <div>
            <div>Name: <b className="text-info">{slugify(this.props.name_wallet)}</b></div>

            <div>
              Public Key: {' '}
              <span style={{cursor: 'pointer'}}>
                <CopyToClipboard text={this.props.public_key}
                                   onCopy={() => {}}>
                  <a onClick={(e) => {ToastStore.success('Public Key copied!')}}>
                    <b className="text-info">{shortAddr(this.props.public_key, 8)}</b> <i className="fa fa-clone"></i>
                  </a>
                </CopyToClipboard> {' '}
              </span>
              <span><a href="#" onClick={this.togglePublic}>{!this.state.showPublic ? 'Show' : 'Hide'}</a></span>
              {
                this.state.showPublic && <div><small><b>{this.props.public_key}</b></small><br/><br/></div>
              }
            </div>

            <div>
              Secret Key: {' '}
              <span style={{cursor: 'pointer'}}>
                <CopyToClipboard text={window.atob(this.props.private_key)}
                                   onCopy={() => {}}>
                  <a onClick={(e) => {ToastStore.success('Private Key copied!')}}>
                    <b className="text-info">{shortAddr(window.atob(this.props.private_key), 8)}</b> <i className="fa fa-clone"></i>
                  </a>
                </CopyToClipboard> {' '}
              </span>
              <span><a href="#" onClick={this.toggleSecret}>{!this.state.showSecret ? 'Show' : 'Hide'}</a></span>
              {
                this.state.showSecret && <div><small><b>{window.atob(this.props.private_key)}</b></small><br/><br/></div>
              }
              <p className="text-warning"><i className="fa fa-exclamation-triangle"></i> This <b>private key</b> cannot be shown to anyone!</p>
            </div>

            <p><small>Before using this wallet for serious purposes, make sure that you safely save all the received recovery data.</small></p>
          </div>
          <div>
            <br/><br/>
            <div>Federation Address { this.state.federation_name ? <span className="text-info">: <b className={`text-${this.state.status_name ? 'success' : 'danger'}`}>{this.state.federation_name}</b><b>*scopuly.com</b></span>: '(optional)' }</div>
            <p>Set a name for the Federation Address. This is a short and easy-to-read analogue of your newly created Stellar address.</p>
            <br/>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="federation_name"
                  component="input"
                  type="text"
                  placeholder="name (optional)"
                  onChange={(value) => this.changeFederationName(value)}
                />
                <div className="form__form-group-icon">*scopuly.com</div>
              </div>
              {
                !this.state.status_name ?
                  <div><small className="text-danger">This name already exists</small></div>
                : null
              }
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar wizard__toolbar" style={{marginTop: 40}}>
            <Button color="primary">{this.state.federation_name ? 'Set Federation Name' : 'Go to wallet'}</Button>
          </ButtonToolbar>
        </form>
      </div>
    )
  }
}



WizardFormFive.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormFive);
