import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, ButtonToolbar, Button, Table } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import { shortAddr, numFormat, get_loc_wallets, getAuth, needDeauthAll, checkAuth, getTitle } from '../../../modules/index';
import { GLOBE } from '../../../modules/globeVars/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import classnames from 'classnames';
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from 'sweetalert';
import Footer from '../../Layout/footer/Footer';
import getPrice from "../../App/GetPrice";


const renderTextField = ({
  input, label, meta: { touched, error }, children, select, type, disabled
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    disabled={disabled}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};



const sell_assets = [
  { code: 'USD', issuer: 'fiat', type: 'fiat' },
  { code: 'EUR', issuer: 'fiat', type: 'fiat' },
  { code: 'RUB', issuer: 'fiat', type: 'fiat' },
  // { code: 'BTC', issuer: 'fiat' },
  { code: 'XLM', issuer: 'native', type: 'crypto' },
  // { code: 'MOBI', issuer: 'GA6HCMBLTZS5VYYBCATRBRZ3BZJMAFUDKYYF6AH6MVCMGWMRDNSWJPIH' },
  // { code: 'SLT', issuer: 'GCKA6K5PCQ6PNF5RQBF7PQDJWRHO6UOGFMRLK3DYHDOI244V47XKQ4GP' },
];

let buy_assets = [
  // { code: 'SKY', issuer: 'GB2WB73G5IWRXEUTJANAIF52JFTDXOXORAP4S5HZRW23TZG2ONDVZMVA' },
];



class Fiat extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      price: 0,
      fee: 0,
      cost_deposit: 1.5,
      cost_withdraw: 2.5,
      cost: 0,
      total: 0,
      total_with_fee: 0,
      activeTab: '1',
      loader: false,
      sell_asset: '',
      buy_asset: '',
      direct_ex: false,
      wallets: get_loc_wallets(),
      load_price_xlm: false,
      price: false,
      type_order: '',
      select_address: false,
      local_address: false,

      all_trades: [],
      all_trades_load: false,
      my_trades: [],
      my_trades_load: false,
      my_orders: [],
      my_orders_load: false,
      is_ex_addr: false,
      ex_addr: '',
      is_auth: false,
    };
  }

  min_limit_native = 2
  count = 0;

  componentDidMount() {

    const title = 'Buy / Sell Crypto for Fiat, Crypto / Fiat Exchange';
    const description = 'Buy and sell crypto for fiat money. 200 countries of the world are supported and such currencies as: USD, EUR, RUB +10 digital coins BTC, LTC, ETH, DASH, BCH.';
    getTitle(title, description);

    this.getBasePrice();
    this.getAllTrades();
    this.checkLocalAddress();
  }


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };


  // getPriceBase() {
  //
  //   const $this = this;
  //   const query_url = 'https://min-api.cryptocompare.com/data/price?fsym=XLM&tsyms=USD,EUR,RUB,CNY,AUD,BRL,CAD,CHF,CLP,CNY,CZK,GBP,HKD,HUF,IDR,ILS,INR,JPY,KRW,MXN,MYR,NOK,NZD,PHP,PKR,PLN,SEK,SGD,THB,TRY,TWD,ZAR'
  //   function getPriceXlmUsd() {
  //     return fetch(query_url)
  //             .then(response => response.json());
  //   }
  //
  //   async function price() {
  //     let price = await getPriceXlmUsd()
  //
  //     $this.setState({
  //       load_price_xlm: true,
  //       price_xlm: price,
  //     })
  //   }
  //   price();
  // }


  getBasePrice() {
    let price = JSON.parse(localStorage.getItem('price'));

    if (price && price.local_price) {
      this.setState({ price: price.local_price })
    }
    else {
      if (this.count < 3) {
        getPrice()
        setTimeout(() => {
          this.getBasePrice()
          this.count++;
        }, 2000)
      }
    }
  }


  checkLocalAddress() {
    let address = localStorage.getItem('select_address');
    if (address) {
      this.setState({
        local_address: address,
      })
      this.getMyTrades(address);
      this.getMyOffers(address);
      this.getExAddress();
    }
  }


  getExAddress() {

    axios.get(`${GLOBE.API_URL}/get_admin_address`)
      .then((response) => {

        this.setState({
          is_ex_addr: true,
          ex_addr: response.data[0].address,
        })
      })
      .catch(function (error) {
        console.log(error);
    });
  }


  getAllTrades() {

    axios.get(`${GLOBE.API_URL}/get_fiat_trades`)
      .then((response) => {
        if (response) {

          if (response.data.length > 0) {
            this.setState({
              all_trades: response.data,
              all_trades_load: true,
            })
          }
        }
      })
      .catch(function (error) {
        console.log(error);
    });
  }


  getMyTrades(address) {

    axios.post(`${GLOBE.API_URL}/get_my_fiat_trades/?address=${address}`)
      .then((response) => {
        if (response) {

          if (response.data.length > 0) {
            this.setState({
              my_trades: response.data,
              my_trades_load: true,
            })
          }
        }
      })
      .catch(function (error) {
        console.log(error);
    });
  }


  getMyOffers(address) {

    axios.post(`${GLOBE.API_URL}/get_my_fiat_orders/?address=${address}`)
      .then((response) => {
        if (response) {

          if (response.data.length > 0) {
            this.setState({
              my_orders: response.data,
              my_orders_load: true,
            })
          }
        }
      })
      .catch(function (error) {
        console.log(error);
    });
  }


  changeAddress(value) {

    let address = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        address = address + value[key];
      }
    }

    this.setState({
      select_address: address,
      local_address: address,
    });

    localStorage.setItem('select_address', address);

    this.getMyTrades(address);
    this.getMyOffers(address);
  }


  changeSellAsset(value) {

    buy_assets = [];
    this.props.change('select_buy_asset', '');
    let asset = '';

    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        asset = asset + value[key];
      }
    }

    let select_type = asset.split('-')[2];

    sell_assets.forEach((item) => {

      if (select_type === 'fiat') { // sell asset
        // not fiat buy render
        if (item.type !== 'fiat') { // item
          buy_assets.unshift(item);
        }
      }
      else if (select_type === 'crypto') {

        if (item.type !== 'crypto') {
          buy_assets.unshift(item);
        }
      }
    })

    let type_order, cost;
    if (asset === 'USD-fiat-fiat' || asset === 'EUR-fiat-fiat' || asset === 'RUB-fiat-fiat') {
      type_order = 'sell_fiat';
      cost = this.state.cost_deposit;
    } else {
      type_order = 'sell_crypto';
      cost = this.state.cost_withdraw;
    }

    this.setState({
      sell_asset: asset,
      cost: cost,
      fee: 0,
      total_with_fee: 0,
      type_order: type_order
    })

    this.props.change('asset_price', 0);
    this.props.change('asset_amount', 0);
    this.props.change('asset_total', 0);
  }


  changeBuyAsset(value) {

    let buy_asset = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        buy_asset = buy_asset + value[key];
      }
    }

    let price;

    if (this.state.sell_asset === 'USD-fiat-fiat') { price = (this.state.price.USD + (this.state.price.USD / 100 * 5)).toFixed(7) }
    if (this.state.sell_asset === 'EUR-fiat-fiat') { price = (this.state.price.EUR + (this.state.price.EUR / 100 * 5)).toFixed(7) }
    if (this.state.sell_asset === 'RUB-fiat-fiat') { price = (this.state.price.RUB + (this.state.price.RUB / 100 * 5)).toFixed(7) }

    if (buy_asset === 'USD-fiat-fiat') { price = (1 / this.state.price.USD - (this.state.price.USD / 100 * 5)).toFixed(7) }
    if (buy_asset === 'EUR-fiat-fiat') { price = (1 / this.state.price.EUR - (this.state.price.EUR / 100 * 5)).toFixed(7) }
    if (buy_asset === 'RUB-fiat-fiat') { price = (1 / this.state.price.RUB - (this.state.price.RUB / 100 * 5)).toFixed(7) }

    this.props.change('asset_price', price);

    this.setState({
      buy_asset: buy_asset,
      price: price,
    })

    if (buy_asset ==='XLM-native-crypto') this.props.change('asset_amount', this.min_limit_native)
  }


  amountChange(value) {
    let amount = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        amount = Number(amount + value[key]);
      }
    }

    let total = (amount * this.state.price).toFixed(4);
    let fee = Number((total * this.state.cost / 100).toFixed(4));

    this.props.change('asset_total', Number(total))

    if (this.state.buy_asset === "XLM-native-crypto" && amount < this.min_limit_native) {
      this.props.change('asset_amount', this.min_limit_native)
      // this.forceUpdate()
    }

    this.setStateFee(fee, total);
  }


  totalChange(value) {
    let total = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        total = total + value[key];
      }
    }

    let amount = (total / this.state.price).toFixed(7);
    let fee = Number((total * this.state.cost / 100).toFixed(4));

    this.setStateFee(fee, total);

    this.props.change('asset_amount', Number(amount))
  }


  getPriceAsset(asset) {
    //
  }


  setStateFee(fee, total) {

    if (this.state.type_order === 'sell_fiat') {
      var num = 2;
    } else {
      var num = 4;
    }

    this.setState({
      fee: fee,
      total_with_fee: Number((Number(total) + Number(fee)).toFixed(num)),
    });
  }


  submitForm = (values) => {

    console.log('values: ', values)

    const { sell_asset, type_order } = this.state

    const showMess = () => {
      ToastStore.warning('The amount must be at least 100');
    }

    if (type_order === 'sell_fiat') {

      if (sell_asset === 'USD-fiat-fiat' || sell_asset === 'EUR-fiat-fiat') {
        if (values.asset_total < 100) {
          showMess()
          return
        }
      }
      if (sell_asset === 'RUB-fiat-fiat' && values.asset_total < 10000) {
        showMess()
        return
      }
    }

    if (!values.select_address) {
      ToastStore.warning('Select Trading Address');
    }
    else {

      if (!values.select_sell_asset) {
        ToastStore.warning('Choose a selling Asset');
      }
      else if (!values.select_buy_asset) {
        ToastStore.warning('Select the buing Asset');
      }
      else if (!values.asset_amount) {
        ToastStore.warning('Enter Asset amount');
      }
      else if (!values.asset_total) {
        ToastStore.warning('Not total amount');
      }
      else {
        this.setExchange(values);
      }
    }
  }


  setExchange(values) {
    const $this = this;


    // --- AWAIT ----------\
    // swal({
    //   title: 'Coming soon',
    //   text: 'Deposit / Withdrawal of fiat funds will be available very soon. Currently, the service is working and is undergoing closed testing.',
    //   icon: 'info',
    //   buttons: {
    //     confirm: true,
    //   },
    // })

    // return;
    // --- AWAIT ----------/


    const confirmSwal = () => {

      getAuth()
        .then((value) => {
          if (value === 'accept') {
            acceptSendTX();
          }
          else if (value === 'need_deauth_all') {
            needDeauthAll();
          }
        });

      const acceptSendTX = () => {

        swal({
          title: `Create an exchange order?`,
          icon: "info",
          buttons: {
            cancel: true,
            confirm: true,
          },
        })
        .then((confirm) => {
          if (confirm)
            confirmation();
        })
      }
    }


    let type_order;
    if (values.select_sell_asset === 'USD-fiat-fiat' || values.select_sell_asset === 'EUR-fiat-fiat' || values.select_sell_asset === 'RUB-fiat-fiat') {
      type_order = 'sell_fiat';
    }
    else {
      type_order = 'sell_crypto';
    }

    let sell_asset = values.select_sell_asset.split('-')[0],
        buy_asset = values.select_buy_asset.split('-')[0];

    const obj = {
      sell_asset: values.select_sell_asset,
      buy_asset: values.select_buy_asset,
      amount_sell_asset: this.state.total_with_fee,
      amount_buy_asset: values.asset_amount,
      price: this.state.price,
      amount_fee: this.state.fee,
      address: values.select_address,
      type_order: this.state.type_order,
      total_with_fee: this.state.total_with_fee,
      payeer_id: '',
    }

    if (type_order === 'sell_crypto') {
      swal({
        title: 'Your Payeer Account ID',
        text: `Enter your Payeer account number (example: P63084069) so that we can transfer ${obj.amount_buy_asset} ${buy_asset} to it.  `,
        icon: 'info',
        content: "input",
      })
      .then(value => {
        if (value) {
          let first = value.charAt(0).toUpperCase();
          if (!isNaN(value)) {
            ToastStore.error('The correct format: P63084069');
          }
          else if (first !== 'P') {
            ToastStore.error('The correct format: P63084069');
          }
          else {
            obj.payeer_id = value;
            confirmSwal();
          }
        }
      })
    }
    else if (type_order === 'sell_fiat') {
      confirmSwal();
    }

    let confirmation = () => {
      const url = `${GLOBE.API_URL}/set_order/?sell_asset=${obj.sell_asset}&buy_asset=${obj.buy_asset}&amount_sell_asset=${obj.amount_sell_asset}&amount_buy_asset=${obj.amount_buy_asset}&price=${obj.price}&amount_fee=${obj.amount_fee}&address=${obj.address}&type=${obj.type_order}&total_with_fee=${obj.total_with_fee}&payeer_id=${obj.payeer_id}`;
      axios.post(url)
      .then(res => {
        if (res) {
          if (type_order === 'sell_fiat') {
            let url = 'https://payeer.com/merchant?m_shop='+res.data.m_shop+'&m_orderid='+res.data.m_orderid+'&m_amount='+res.data.m_amount+'&m_curr='+res.data.m_curr+'&m_desc='+res.data.m_desc+'&m_sign='+res.data.m_sign;
            window.open(url);
          }
          if (type_order === 'sell_crypto') {
            // send crypto
            this.sendCrypto(obj, res.data);
          }
        }
      });
    }
  }


  sendCrypto(obj, order_id) {

    let asset;
    if (obj.sell_asset === 'XLM-native-crypto') {
      asset = StellarSdk.Asset.native()
    }
    else {
      let asset_code = obj.buy_asset.split('-')[0];
      let asset_issuer = obj.buy_asset.split('-')[1];

      asset = new StellarSdk.Asset(asset_code, asset_issuer);
    }

    if (this.state.wallets) {
      let sender = this.state.wallets.filter((item) => {
        return item.pk === this.state.select_address;
      });

      if (sender.length > 0) {
        this.setState({ loader: true })

        Server.loadAccount(this.state.select_address)
          .then((sourceAccount) => {

            var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: StellarSdk.BASE_FEE,
                networkPassphrase: StellarSdk.Networks.PUBLIC
              })
              .addOperation(StellarSdk.Operation.payment({
                destination: this.state.ex_addr,
                asset: asset,
                amount: String(obj.amount_sell_asset)
              }))
              .setTimeout(100)
              .build();

            transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(sender[0].sk)));
            return Server.submitTransaction(transaction);
          })
          .then((result) => {
            this.setState({ loader: false })
            this.updateOrder(result.hash, obj, order_id);
          })
          .catch((error) => {
            this.setState({ loader: false })
            ToastStore.error('Transaction error...');
            console.error('Transaction error!', error);
        });
      }
    }
  }


  updateOrder(hash, obj, order_id) {

    axios.get(`${GLOBE.API_URL}/update_order/?order_id=${order_id}&hash=${hash}`)
      .then((response) => {
        if (response) {

          if (response.data === 'update') {
            swal({
              title: "Order exchange created!",
              text: "Your exchange order has been successfully created. Expect crediting to your Payeer account.",
              icon: "success",
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
    });
  }


  renderTradeAddress() {
    return (
        <div>
          <div>
            <Field
              name="select_address"
              component={renderTextField}
              select
              label="Select an exchange Address"
              onChange={(value) => {
                this.changeAddress(value)
              }}
            >
              {
                this.state.wallets
                ? this.state.wallets.map((item, index) => {
                    return (
                      <MenuItem key={index} className="material-form__option" value={item.pk}><b className="text-secondary">{shortAddr(item.pk, 8)}</b> <small> / {item.title}</small></MenuItem>
                    );
                  })
                : null
              }
            </Field>
          </div>
        </div>
    )
  }


  renderTrades(trades) {

    return (
      <Table striped responsive>
        <thead>
          <tr>
            {/*<th>Order</th>*/}
            <th>Sell <font>Asset</font> </th>
            {/*<th>Amount Sell</th>*/}
            <th>Buy <font>Asset</font> </th>
            {/*<th>Amount Buy</th>*/}
            <th>Price</th>
            <th>Address</th>
            <th>Status</th>
            <th>Date</th>
            <th>Hash</th>
          </tr>
        </thead>
        <tbody>

          {
            trades.map((item, index) => {
              return (
                <tr key={index}>
                  {/*<td>{item.id}</td>*/}
                  <td><b className="text-info">{numFormat(item.amount_sell_asset, 7)}</b> <b className="text-warning">{item.sell_asset.split('-')[0]}</b></td>
                  {/*<td><b className="">{item.amount_sell_asset}</b></td>*/}
                  <td><b className="text-info">{numFormat(item.amount_buy_asset, 7)}</b> <b className="text-warning">{item.buy_asset.split('-')[0]}</b></td>
                  {/*<td><b className="">{item.amount_buy_asset}</b></td>*/}
                  <td>{item.price} <small className="text-secondary">{item.sell_asset.split('-')[0]}</small></td>
                  <td><Link to={`/account/${item.address}`}><b>{shortAddr(item.address, 4)}</b></Link></td>
                  <td>{item.status.split('_')[0]}</td>
                  <td>{item.create_date}</td>
                  <td>{item.hash !== '' ? <Link to={`/transaction/${item.hash}`}><b>{shortAddr(item.hash, 4)}</b></Link> : 'Waiting'}</td>
                </tr>
              )
            })
          }

        </tbody>
      </Table>
    )
  }


  renderMyTrades(item, index) {
    // console.log('renderMyTrades item: ', item)
    return (
      <div>{index}</div>
    )
  }


  renderMyOrders(item, index) {
    // console.log('renderMyOrders item: ', item)
    return (
      <div>{index}</div>
    )
  }


  checkAuthAddr = () => {
    checkAuth().then((value) => {
      if (value) {
        this.setState({
          is_auth: true
        })
      }
    })
  }



  render() {
    console.log('this.state: ', this.state)

    const { reset, handleSubmit } = this.props;

    if (this.state.is_auth) {
      return <Redirect to='/wallet' />
    }

    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />
        {/*<Row>
          <Col md={12}>
            <h3 className="page-title">Distributed Exchange</h3>
          </Col>
        </Row>*/}
        <Row>

          <Col md={4} lg={4}>

            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Select fiat exchange direction</h5>
                </div>

                { this.state.loader ? <div className="panel__refresh" style={{height: '95%'}}><LoadingIcon /></div> : null }


                <form className="material-form"
                      onSubmit={handleSubmit(this.submitForm)}
                      onClick={this.checkAuthAddr}>

                  { this.renderTradeAddress() }

                  <div>
                    <Field
                      name="select_sell_asset"
                      component={renderTextField}
                      select
                      label="Sell Asset"
                      onChange={(value) => {
                        this.changeSellAsset(value);
                      }}
                    >
                      {
                        sell_assets.map((item, index) => {
                          let issuer;
                          if (item.issuer === 'native' || item.issuer === 'fiat') {
                            issuer = item.issuer;
                          } else {
                            issuer = shortAddr(item.issuer, 4);
                          }
                          return (
                            <MenuItem key={index}
                                      className="material-form__option"
                                      value={`${item.code}-${item.issuer}-${item.type}`}><span className="text-secondary"><b>{item.code}</b>&nbsp;&nbsp;<small>{issuer}</small></span></MenuItem>
                          )
                        })
                      }
                    </Field>
                  </div>

                  <div>
                    <Field
                      name="select_buy_asset"
                      component={renderTextField}
                      select
                      label="Buy Asset"
                      onChange={(value) => {
                        this.changeBuyAsset(value)
                      }}
                    >
                      {
                        this.state.sell_asset !== '' ?
                          buy_assets.map((item, index) => {
                            let issuer;
                            if (item.issuer === 'native' || item.issuer === 'fiat') {
                              issuer = item.issuer;
                            } else {
                              issuer = shortAddr(item.issuer, 4);
                            }
                            return (
                              <MenuItem key={index}
                                    className="material-form__option"
                                    value={`${item.code}-${item.issuer}-${item.type}`}><span className="text-secondary"><b>{item.code}</b>&nbsp;&nbsp;<small>{issuer}</small></span></MenuItem>
                            )
                          })
                        : null
                      }
                    </Field>
                  </div>

                  <div>
                    <Field
                      name="asset_price"
                      component={renderTextField}
                      className="m-form"
                      label={`Price ${this.state.buy_asset.split('-')[0]} / ${this.state.sell_asset.split('-')[0]}`}
                      type="text"
                      disabled
                    />
                  </div>

                  <div>
                    <Field
                      name="asset_amount"
                      component={renderTextField}
                      label={`Amount buy ${this.state.buy_asset.split('-')[0]}`}
                      type="text"
                      onChange={this.amountChange.bind(this)}
                    />
                  </div>

                  <div>
                    <Field
                      name="asset_total"
                      component={renderTextField}
                      label={`Total sell ${this.state.sell_asset.split('-')[0]}`}
                      type="text"
                      onChange={this.totalChange.bind(this)}
                    />
                  </div>

                  {
                    this.state.fee > 0 ?
                      <div className="text-center text-secondary">
                        <small>{this.state.cost}% &nbsp;/&nbsp; Fee: {numFormat(this.state.fee, 4)} &nbsp;/&nbsp; Total: <b>{numFormat(this.state.total_with_fee, 4)} {this.state.sell_asset.split('-')[0]}</b></small>
                      </div>
                    : null
                  }

                  <ButtonToolbar className="form__button-toolbar float-right">
                    <Button type="button" onClick={reset}>Reset</Button>
                    <Button color="primary" type="submit">Exchange</Button>
                  </ButtonToolbar>
                </form>

              </CardBody>
            </Card>

          </Col>


          <Col md={8} lg={8}>
            <Card>
              <CardBody style={{position: 'relative'}}>

                <div className="card__title">
                  <h5 className="bold-text">Crypto / Fiat exchange rules</h5>
                  <h5 className="subhead">Buying and selling cryptocurrency using bank cards and other payment systems</h5>
                </div>

                <p>
                  You can replenish your wallet and withdraw money to various payment systems, bank cards and banks
                  in more than 200 countries around the world. Depositing and withdrawing is done using the payment
                  system <a href="https://payeer.com" target='_blank' rel='noopener noreferrer'>Payeer</a>.
                </p>
                <br/>

                <h4>Fiat exchange rules:</h4>
                <p>&nbsp; &nbsp; 1. Select Address for the exchange of Assets.</p>
                <p>&nbsp; &nbsp; 2. Choose an asset that you sell and receive.</p>
                <p>&nbsp; &nbsp; 3. Specify the amount to exchange and go to the exchange.</p>
                <p>&nbsp; &nbsp; 4. After payment of your order, wait until funds are credited to your account.</p>
                <br/>

                <h4>About Crypto / Fiat Exchange</h4>
                <p><small>Now, for depositing / withdrawing funds, you do not need to go to third-party sites in order to
                          buy or sell your assets for fiat money, risking your data and security.
                          Do crypto-fiat exchanges right in Scopuly, in one trusted application.</small></p>

                <p><small>A key feature of Scopuly Exchange is that users keep the funds at their place, and do not trust
                          them to a third party.</small></p>

                <p><small>Here you can trade safely and always have full control over your tokens. Scopuly combines the
                          security that decentralization provides with the speed and convenience of centralized sites.
                          This is the next step in cryptocurrency trading.</small></p>

              </CardBody>
            </Card>
          </Col>
        </Row>


        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="tabs tabs--bordered-bottom">
                  <div className="tabs__wrap">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => {
                            this.toggle('1');
                          }}
                        >
                          <b>All Trades</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => {
                            this.toggle('2');
                          }}
                        >
                          <b>My Trades</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '3' })}
                          onClick={() => {
                            this.toggle('3');
                          }}
                        >
                          <b>My Orders</b>
                        </NavLink>
                      </NavItem>
                      {/*<NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '4' })}
                          onClick={() => {
                            this.toggle('4');
                          }}
                        >
                          <b>Exchange Pairs</b>
                        </NavLink>
                      </NavItem>*/}
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>

                      <TabPane tabId="1">
                        <Card>
                          <CardBody>
                            <div className="card__title">
                              <h5 className="bold-text">Last 30 Fiat Trades</h5>
                            </div>

                            {
                              this.state.all_trades_load ?
                                this.renderTrades(this.state.all_trades)
                              :
                                <p>...</p>
                            }
                          </CardBody>
                        </Card>
                      </TabPane>

                      <TabPane tabId="2">
                        <Card>
                          <CardBody>
                            <div className="card__title">
                              <h5 className="bold-text">My Last Fiat Trades</h5>
                              <h5 className="subhead">For Account: {this.state.local_address ? <Link to={`/account/${this.state.local_address}`}><b>{shortAddr(this.state.local_address, 8)}</b></Link> : 'No Address selected' } </h5>
                            </div>

                            {
                              this.state.my_trades_load ?
                                this.renderTrades(this.state.my_trades)
                              :
                                <p>To display the history of your exchanges - you must select your Address in the form of exchange directions.</p>
                            }
                          </CardBody>
                        </Card>
                      </TabPane>

                      <TabPane tabId="3">
                        <Card>
                          <CardBody>
                            <div className="card__title">
                              <h5 className="bold-text">My Last Fiat Orders</h5>
                              <h5 className="subhead">For Account: {this.state.local_address ? <Link to={`/account/${this.state.local_address}`}><b>{shortAddr(this.state.local_address, 8)}</b></Link> : 'No Address selected' } </h5>
                            </div>

                            {
                              this.state.my_orders_load ?
                                this.renderTrades(this.state.my_orders)
                              :
                                <p>To display your active exchange orders - you must select your Address in the form of exchange directions.</p>
                            }
                          </CardBody>
                        </Card>
                      </TabPane>

                      <TabPane tabId="4">
                        <Row>
                          <Col md={12}>
                            {/*<Card>
                              <CardBody>
                                <div className="card__title">
                                  <h5 className="bold-text">My exchange pairs</h5>
                                </div>

                              </CardBody>
                            </Card>*/}
                            <p>
                              <a href="#" className="ex_pair"><span className="text-warning"><b>SKY/XLM</b></span></a>
                              <a href="#" className="ex_pair"><span className="text-warning"><b>SKY/MOBI</b></span></a>
                              <a href="#" className="ex_pair"><span className="text-warning"><b>SLT/BTC</b></span></a>
                              <a href="#" className="ex_pair"><span className="text-warning"><b>ETH/XLM</b></span></a>
                              <a href="#" className="ex_pair"><span className="text-warning"><b>XLM/USD</b></span></a>
                              <a href="#" className="ex_pair"><span className="text-warning"><b>XRP/XLM</b></span></a>
                              <a href="#" className="ex_pair"><span className="text-warning"><b>CNZ/DHT</b></span></a>
                              <a href="#" className="ex_pair"><span className="text-warning"><b>SCOP/XLM</b></span></a>
                              <a href="#" className="ex_pair"><span className="text-warning"><b>XLM/BTC</b></span></a>
                              <a href="#" className="ex_pair"><span className="text-warning"><b>EOS/BTH</b></span></a>
                              <a href="#" className="ex_pair"><span className="text-warning"><b>SKY/BTC</b></span></a>
                            </p>
                            <br/>
                          </Col>

                          {/*<Col md={6} lg={6}>
                            <Card>
                              <CardBody>
                                <div className="card__title">
                                  <h5 className="bold-text">Recommended exchange pairs</h5>
                                </div>
                                <p>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>SKY/XLM</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>XLM/SKY</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>SKY/PULS</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>PULS/SKY</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>SKY/BTC</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>BTC/SKY</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>PULS/XLM</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>XLM/PULS</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>BTC/XLM</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>XLM/BTC</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>BTC/PULS</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>PULS/BTC</b></span></a>
                                </p>
                              </CardBody>
                            </Card>
                          </Col>*/}
                        </Row>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Footer />

      </Container>
    );
  }
}


Fiat.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'floating_labels_form',
})(translate('common')(Fiat));
