import React, { PureComponent } from 'react';
import {Card, CardBody, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Button, Table} from 'reactstrap';
// import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Server } from '../../../../modules/stellar/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import Operations from './Effects/Operations';
import Effects from './Effects/Effects';
import Transactions from './Effects/Transactions';
import Payments from './Effects/Payments';
import Trades from './Effects/Trades';
import Offers from './Effects/Offers';
import ClaimableBalances from '../../ClaimableBalances/ClaimableBalances'
import SpinnerLoader from '../../../../modules/SpinnerLoader/index';
import { getPendingPayments } from "../../../../endpoints/StellarAPI";
import {baseReserve, formatDate, getAsset, numFormat, shortAddr, shortAddress} from "../../../../modules";


const limit_items = 30;


class AccountEffects extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      activeTab: '1',
      effects: [],
      transactions: [],
      operations: [],
      payments: [],
      trades: [],
      offers: [],
      trades_offer: [],
      data: [],

      load_effects: false,
      last_effect: false,
      effect_more_btn: false,

      load_transactions: false,
      last_transactions: false,
      transactions_more_btn: false,

      load_operations: false,
      last_operations: false,
      operations_more_btn: false,

      load_payments: false,
      last_payments: false,
      payments_more_btn: false,

      load_trades: false,
      last_trades: false,
      trades_more_btn: false,

      load_offers: false,
      last_offers: false,
      offers_more_btn: false,

      load_trades_offer: false,
      last_trades_offer: false,
      trades_offer_more_btn: false,

      load_data: false,

      load_more: false,
      load_more_btn: false,
      count_claimable: 0,
      claimable_balances: false,
    };
  }

  componentDidMount() {
    const account_id = this.props.account_id;

    this.getEffects(account_id);
    this.getTransactions(account_id);
    this.getOperations(account_id);
    this.getPayments(account_id);
    this.getTrades(account_id);
    this.getOffers(account_id);
    this.getClaimableBalances(account_id)


    switch(window.location.hash) {
      case '#effects':
        this.toggleEffect('1');
        break

      case '#transactions':
        this.toggleEffect('2');
        break

      case '#operations':
        this.toggleEffect('3');
        break

      case '#payments':
        this.toggleEffect('4');
        break

      case '#trades':
        this.toggleEffect('5');
        break

      case '#offers':
        this.toggleEffect('6');
        break

      case '#airdrops':
        this.toggleEffect('7');
        break

      case '#data':
        this.toggleEffect('8');
        break

      default:
        break
    }
  }

  // componentWillMount() {
  //   const account_id = this.props.account_id;
  //   this.getEffects(account_id);
  // }

  updateCountClaimable = (count_claimable) => {
    this.setState({ count_claimable })
  }

  toggleEffect = (tab) => {

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }

    const account_id = this.props.account_id;

    switch(tab) {
      case '1':
        window.location.hash = 'effects';
        break

      case '2':
        window.location.hash = 'transactions';
        if (!this.state.load_transactions)
          this.getTransactions(account_id);
        break

      case '3':
        window.location.hash = 'operations';
        if (!this.state.load_operations)
          this.getOperations(account_id);
        break

      case '4':
        window.location.hash = 'payments';
        if (!this.state.load_payments)
          this.getPayments(account_id);
        break

      case '5':
        window.location.hash = 'trades';
        if (!this.state.load_trades)
          this.getTrades(account_id);
        break

      case '6':
        window.location.hash = 'offers';
        if (!this.state.load_offers)
          this.getOffers(account_id);
        break

      case '7':
        window.location.hash = 'airdrops';
        if (!this.state.claimable_balances)
          this.getClaimableBalances(account_id)
        break

      case '8':
        window.location.hash = 'data';
        // if (!this.state.load_offers)
        //   this.getOffers(account_id);
        break

      default:
        break
    }
  };


  getEffects(account_id) {
    const $this = this;

    this.setState({ load_more: false })

    let ServerEffects = Server.effects()
      .forAccount(account_id)
      .limit(limit_items)
      .order('desc');

    if ($this.state.last_effect) {
      ServerEffects.cursor($this.state.last_effect);
      // var length = this.state.effects.length;
    }

    ServerEffects
      .call()
      .then(function (result) {

        if(result.records.length < limit_items || result.records.length === 0) {
          $this.setState({ effect_more_btn: false });
        } else {
          $this.setState({ effect_more_btn: true });
        }

        $this.setState(({
          effects: $this.state.effects.concat(result.records), // result.records, // [...prevState.effects, result.records],
          load_effects: true,
          last_effect: result.records[result.records.length - 1].paging_token,
          load_more: true,
        }))
      })
      .catch(function (err) {
        console.log('err: ', err)
      })
  }


  getTransactions(account_id) {
    const $this = this;

    this.setState({ load_more: false })

    let ServerTx = Server.transactions()
      .forAccount(account_id)
      .limit(limit_items)
      .order('desc')

    if ($this.state.last_transactions) {
      ServerTx.cursor($this.state.last_transactions);
      // var length = this.state.effects.length;
    }

    ServerTx
      .call()
      .then( (result) => {

        if(result.records.length < limit_items || result.records.length === 0) {
          this.setState({transactions_more_btn: false});
        } else {
          this.setState({transactions_more_btn: true});
        }

        this.setState({
          transactions: this.state.transactions.concat(result.records),
          load_transactions: true,
          load_more: true,
          last_transactions: result.records[result.records.length - 1].paging_token,
        })
      })
      .catch( (err) => {
        console.log(err)
    })
  }


  getOperations = (account_id) => {

    this.setState({ load_more: false })

    let ServerOperations = Server.operations()
      .forAccount(account_id)
      .limit(limit_items)
      .order('desc')

    if (this.state.last_operations) {
      ServerOperations.cursor(this.state.last_operations);
    }

    ServerOperations
      .call()
      .then( (result) => {

        if(result.records.length < limit_items || result.records.length === 0) {
          this.setState({operations_more_btn: false});
        } else {
          this.setState({operations_more_btn: true});
        }

        this.setState({
          operations: this.state.operations.concat(result.records),
          load_operations: true,
          load_more: true,
          last_operations: result.records[result.records.length - 1].paging_token,
        })
      })
      .catch( (err) => {
        console.log(err)
    })
  }


  getPayments = (account_id) => {

    this.setState({ load_more: false })

    let ServerPayments =  Server.payments()
      .forAccount(account_id)
      .limit(limit_items)
      .order('desc')

    if (this.state.last_payments) {
      ServerPayments.cursor(this.state.last_payments);
    }

    ServerPayments
      .call()
      .then( (result) => {

        if(result.records.length < limit_items || result.records.length === 0) {
          this.setState({payments_more_btn: false});
        } else {
          this.setState({payments_more_btn: true});
        }

        this.setState({
          payments: this.state.payments.concat(result.records),
          load_payments: true,
          load_more: true,
          last_payments: result.records[result.records.length - 1].paging_token,
        })
      })
      .catch( (err) => {
        console.log(err)
    })
  }


  getTrades = (account_id) => {

    this.setState({ load_more: false })

    let ServerTrades = Server.trades()
      .forAccount(account_id)
      .limit(limit_items)
      .order('desc')

    if (this.state.last_trades) {
      ServerTrades.cursor(this.state.last_trades);
    }

    ServerTrades
      .call()
      .then( (result) => {

        if(result.records.length < limit_items || result.records.length === 0) {
          this.setState({ trades_more_btn: false });
        } else {
          this.setState({ trades_more_btn: true });
        }

        this.setState({
          trades: this.state.trades.concat(result.records),
          load_trades: true,
          load_more: true,
          last_trades: result.records[result.records.length - 1].paging_token,
        })
      })
      .catch( (err) => {
        console.log(err)
    })
  }


  getOffers = (account_id) => {

    this.setState({ load_more: false })

    let ServerOffers =   Server.offers().forAccount(account_id)
      .limit(50)
      .order('desc')

    if (this.state.last_offers) {
      ServerOffers.cursor(this.state.last_offers);
    }

    ServerOffers
      .call()
      .then((result) => {

        if (result.records.length < limit_items || result.records.length === 0) {
          this.setState({trades_offer_more_btn: false});
        } else {
          this.setState({trades_offer_more_btn: true});
        }

        this.setState({
          offers: this.state.offers.concat(result.records),
          load_offers: true,
          load_more: true,
          last_offers: result.records[result.records.length - 1].paging_token,
        })
      })
      .catch( (err) => {
        console.error(err);
    })
  }


  getClaimableBalances = (account_id) => {

    getPendingPayments(account_id, 200)
      .then(result => {
        // console.log('getClaimableBalances result: ', result)
        this.setState({ claimable_balances: result.records })
      })
  }


  render() {

    const { t, account_data } = this.props;

    // console.log('state: ', this.state)


    return (
      <Col xs={12} md={12} lg={12} xl={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">All Effects</h5>
            </div>
            <div className="tabs tabs--bordered-bottom">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => {
                        this.toggleEffect('1');
                      }}
                    >
                      <b>All Effects</b>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '5' })}
                      onClick={() => {
                        this.toggleEffect('5');
                      }}
                    >
                      <b>Trades</b>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '4' })}
                      onClick={() => {
                        this.toggleEffect('4');
                      }}
                    >
                      <b>Payments</b>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => {
                        this.toggleEffect('2');
                      }}
                    >
                      <b>Transactions</b>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '3' })}
                      onClick={() => {
                        this.toggleEffect('3');
                      }}
                    >
                      <b>Operations</b>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '6' })}
                      onClick={() => {
                        this.toggleEffect('6');
                      }}
                    >
                      <b>Offers {this.state.offers.length ? <span>(<b className={'text-info'}>{this.state.offers.length}</b>)</span> : null}</b>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '7' })}
                      onClick={() => {
                        this.toggleEffect('7');
                      }}
                    >
                      <b>Airdrops {this.state.claimable_balances.length ? <span>(<b className={'text-info'}>{this.state.claimable_balances.length}</b>)</span> : null}</b>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '8' })}
                      onClick={() => {
                        this.toggleEffect('8');
                      }}
                    >
                      <b>Data</b>
                    </NavLink>
                  </NavItem>

                </Nav>

                <TabContent activeTab={this.state.activeTab}>

                  <TabPane tabId="1">
                    <Card>{ !this.state.load_effects ? <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> : ''}</Card>
                    { this.state.load_effects ? <Effects items={this.state.effects} /> : null }
                    <br/>
                    <div className="text-center">
                      {
                        this.state.effect_more_btn ?
                          <Button color="secondary" onClick={() => {this.getEffects(this.props.account_id)}}>
                            {
                              this.state.load_more ? 'Load more...' :
                              <SpinnerLoader />
                            }
                          </Button> : null
                      }
                    </div>
                  </TabPane>

                  <TabPane tabId="2">
                    { !this.state.load_transactions ? <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> : ''}
                    { this.state.load_transactions ? <Transactions items={this.state.transactions} /> : null }
                    <br/>
                    <div className="text-center">
                      {
                        this.state.transactions_more_btn ?
                          <Button color="secondary" onClick={() => {this.getTransactions(this.props.account_id)}}>
                            {
                              this.state.load_more ? 'Load more...' :
                              <SpinnerLoader />
                            }
                          </Button> : null
                      }
                    </div>
                  </TabPane>



                  <TabPane tabId="3">
                    { !this.state.load_operations ? <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> : ''}
                    { this.state.load_operations ? <Operations items={this.state.operations} /> : null }
                    <br/>
                    <div className="text-center">
                      {
                        this.state.operations_more_btn ?
                          <Button color="secondary" onClick={() => {this.getOperations(this.props.account_id)}}>
                            {
                              this.state.load_more ? 'Load more...' :
                              <SpinnerLoader />
                            }
                          </Button> : null
                      }
                    </div>
                  </TabPane>


                  <TabPane tabId="4">
                    { !this.state.load_payments ? <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> : ''}
                    { this.state.load_payments ? <Payments items={this.state.payments} account_id={this.props.account_id} /> : null }
                    <br/>
                    <div className="text-center">
                      {
                        this.state.payments_more_btn ?
                          <Button color="secondary" onClick={() => {this.getPayments(this.props.account_id)}}>
                            {
                              this.state.load_more ? 'Load more...' :
                              <SpinnerLoader />
                            }
                          </Button> : null
                      }
                    </div>
                  </TabPane>

                  <TabPane tabId="5">
                    { !this.state.load_trades ? <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> : ''}
                    {
                      this.state.load_trades ?
                        <Trades items={this.state.trades} />
                      : null
                    }
                    <br/>
                    <div className="text-center">
                      {
                        this.state.trades_more_btn ?
                          <Button color="secondary" onClick={() => {this.getTrades(this.props.account_id)}}>
                            {
                              this.state.load_more ? 'Load more...' :
                              <SpinnerLoader />
                            }
                          </Button> : null
                      }
                    </div>
                  </TabPane>

                  <TabPane tabId="6">
                    { !this.state.load_offers ? <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> : ''}
                    {
                      this.state.load_offers ?
                        <Offers items={this.state.offers} account_id={this.props.account_id} />
                      : null
                    }
                    <br/>
                    <div className="text-center">
                      {
                        this.state.trades_offer_more_btn ?
                          <Button color="secondary" onClick={() => {this.getTrades(this.props.account_id)}}>
                            {
                              this.state.load_more ? 'Load more...' :
                              <SpinnerLoader />
                            }
                          </Button> : null
                      }
                    </div>
                  </TabPane>


                  <TabPane tabId="7">
                    { !this.state.claimable_balances ? <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> : ''}
                    {
                      this.state.claimable_balances ?
                        <ClaimableBalances
                          account={this.props.account_id}
                          claimable_balances={this.state.claimable_balances} />
                      : null
                    }
                  </TabPane>


                  <TabPane tabId="8">
                    <Row>
                      <Col md={12}>

                        <Table striped responsive>
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>Key</th>
                            <th>Value</th>
                          </tr>
                          </thead>
                          <tbody>

                          {
                            account_data ?
                              Object.keys(account_data).map((item, index) => {
                                console.log('item: ', item)
                                console.log('item1[item]: ', account_data[item])

                                return (
                                  // <p key={index}><b className="text-info">{item.toUpperCase()}:</b> {item1[item]}</p>
                                  <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{item}</td>
                                    <td><b className={'text-info'}>{account_data[item]}</b></td>
                                  </tr>
                                )
                              })
                            :
                              <p><br/>Loading...</p>
                          }

                          </tbody>
                        </Table>

                        {/*account: AccountResponse*/}
                        {/*account_id: "GCS7L3YZYPYOCPV3LZYDGCSO6UCFYL4BKKS2KHQKHPNXLBURCO34SNHZ"*/}
                        {/*balances: (4) [{…}, {…}, {…}, {…}]*/}
                        {/*data: ƒ (opts)*/}
                        {/*data_attr:*/}
                        {/*CURSOR: "MTQ5NTEwNTU2MDU4NDIzMjk4"*/}
                        {/*CURSOR_BUSD1: "MTY1ODc2NDA0Mzg3MzQ4NDky"*/}
                        {/*CURSOR_USD1: "MTY0NDYzMzU1ODUwODIxNjQ0"*/}
                        {/*CURSOR_XLM: "MTY1NzkzOTYyNDg4ODUyNDky"*/}
                        {/*GRID_CURSOR: "MTU4NjI0ODcxNzk3NTEwMTU2"*/}
                      </Col>
                    </Row>
                  </TabPane>

                </TabContent>

              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default translate('common')(AccountEffects);
